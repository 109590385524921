// components
import FacebookLoginWrapper from "../FacebookLoginWrapper";
import GoogleLoginWrapper from "../GoogleLoginWrapper";
import { Input } from "../Input";
import { PrimaryButton } from "../PrimaryButton";
import { RegisterHeader } from "../RegisterHeader";
import { TermsAndConditions } from "../TermsAndConditions";

// icon
import { SvgEmail as Email } from "assets/icons/js/SvgEmail";
import { SvgFacebook as Facebook } from "assets/icons/js/SvgFacebook";
import { SvgGoogle as Google } from "assets/icons/js/SvgGoogle";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// scss
import "./style.scss";
import { useContext } from "react";
import { UserContext } from "store";

export const PhoneSignup = ({
	setPhone,
	setSend,
	phoneNumber,
	setPhoneNumber,
	setCC,
}) => {
	const handleClick = () => {
		setPhone(false);
	};

	const handleChange = (e) => {
		const re = /^[0-9\b]+$/;
		if (e.target.value.length <= 10) {
			if (e.target.value === "" || re.test(e.target.value)) {
				setPhoneNumber(e.target.value);
			}
		}
	};
	const { state } = useContext(UserContext);

	return (
		<div className="phoneSignup__wrapper--page">
			<div className="phoneSignup__wrapper--page-2">
				<RegisterHeader title={"to SaffronStays"} subtitle={"sign you up"} />
				<div className="phoneSignup__space"></div>
				<div className="phoneSignup__form">
					<form
						onSubmit={(e) => {
							e.preventDefault();
							setSend(true);
						}}
					>
						<PhoneInput
							country={"in"}
							value={""}
							enableSearch={true}
							onChange={(value, country, e, formattedValue) => {
								setCC(country.dialCode);
								setPhoneNumber(
									value.slice(country?.dialCode?.toString()?.length),
								);
							}}
						/>
						<div className="phoneSignup__space"></div>
						<div>
							<PrimaryButton
								type="submit"
								value={"Send OTP"}
								disabled={phoneNumber.length < 10}
							/>
						</div>
					</form>
					{!state.isFromBookingBtn && (
						<>
							<div className="phoneSignup__line">
								<hr />
								<span>OR</span>
								<hr />
							</div>
							<div className="phoneSignup__different-login">
								<GoogleLoginWrapper>
									<span className="google icon">
										<Google />
										<p>Google</p>
									</span>
								</GoogleLoginWrapper>
								<FacebookLoginWrapper>
									<span className="facebook icon">
										<Facebook />
										<p>Facebook</p>
									</span>
								</FacebookLoginWrapper>
								<span className="phone icon" onClick={handleClick}>
									<Email />
									<p>Email</p>
								</span>
							</div>
							<div className="phoneSignup__space"></div>
							<TermsAndConditions />
						</>
					)}
				</div>
			</div>
		</div>
	);
};
