export const SvgTick = (props) => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M19.4917 8.72613C19.0915 8.30414 18.4243 8.28556 18.0016 8.68678L11.2726 15.0684L8.06299 11.7731C7.65648 11.356 6.98998 11.3469 6.57256 11.7534C6.15549 12.1596 6.1467 12.8268 6.55285 13.2438L10.488 17.284C10.6942 17.4959 10.9683 17.6027 11.2431 17.6027C11.5038 17.6027 11.7645 17.5064 11.9686 17.3135L19.4524 10.2163C19.8747 9.8157 19.8926 9.14848 19.4917 8.72613Z"
				fill="#2368a2"
			/>
			<path
				d="M13 0C5.83172 0 0 5.83172 0 13C0 20.1683 5.83172 26 13 26C20.1683 26 26 20.1683 26 13C26 5.83172 20.1683 0 13 0ZM13 23.8919C6.99436 23.8919 2.10808 19.0061 2.10808 13C2.10808 6.99436 6.9943 2.10808 13 2.10808C19.006 2.10808 23.8919 6.9943 23.8919 13C23.8919 19.006 19.006 23.8919 13 23.8919Z"
				fill="#2368a2"
			/>
		</svg>
	);
};
