import React from "react";
import "./style.scss";

const UploadModal = ({
	open,
	setOpen,
	pencentage,
	mode = "",
	total,
	order,
}) => {
	if (mode === "total") {
		const pen = (order / total) * 100;
		return (
			<>
				{open && (
					<div className="upload-modal-wrapper">
						<div className="upload-modal-container">
							<div>Uploading {pen.toFixed(2)}%</div>
							<div className="processbar-wrapper">
								<div className="processbar" style={{ width: `${pen}%` }} />
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
	return (
		<>
			{open && (
				<div className="upload-modal-wrapper">
					<div className="upload-modal-container">
						<div>Uploading {pencentage}%</div>
						<div className="processbar-wrapper">
							<div className="processbar" style={{ width: `${pencentage}%` }} />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default UploadModal;
