// style
import { RequestSubmited } from "components/NewForm/RequestSubmited";
import "./style.scss";

export const PersonalStep3 = () => {
	return (
		<div className="personal__step__three__container">
			<RequestSubmited />
		</div>
	);
};
