import { SvgClose } from "assets/icons/js/SvgClose";

// style
import "./style.scss";

export const ViewImage = ({ image, onClose }: any) => {
	return (
		<div className="view__image__container">
			<div className="top__close">
				<span></span>
				<span></span>
				<span onClick={onClose}>
					<SvgClose />
				</span>
			</div>

			<div className="img__container">
				<img src={URL.createObjectURL(image)} alt="" />
			</div>
		</div>
	);
};
