import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import Modal from "components/LoginAndRegister/Modal";
import { axiosInstance } from "helpers/axiosInstance";
import { useMutation } from "react-query";
import PhoneInput from "react-phone-input-2";
import { UserContext } from "store";
// import { ListingInterface } from "context/ListingData";
import RedirectionImage from "assets/redirection.png";

import clevertap from "clevertap-web-sdk";

const AskHelp = ({ data }: any) => {
	const [open, setOpen] = useState(false);
	const {
		state: { currentUser },
	} = useContext(UserContext);
	return (
		<div className="ask-help-wrapper" style={{ ...data?.theme?.borderBtn }}>
			<AskMeModal open={open} setOpen={setOpen} data={data} />
			<span
				style={
					Object.keys(data?.theme || {}).length !== 0
						? {
								color: data?.theme?.primaryTextColor,
						  }
						: {}
				}
			>
				Still unsure? Ask us anything!
			</span>
			<button
				className="send-msg-btn"
				style={
					Object.keys(data?.theme || {}).length !== 0
						? {
								background: data?.theme?.bookingBtnBackground,
						  }
						: {}
				}
				onClick={() => {
					window.dataLayer.push({
						event: "connect_with_host",
						FULL_NAME: currentUser.fullName,
						MOBILE_NUMBER: currentUser.mobile,
						EMAIL_ID: currentUser.email,
						LISTING_ID: data?.LISTING_ID,
						CHECKIN: data?.FROM_DATE,
						CHECKOUT: data?.TO_DATE,
						NO_OF_GUEST: data?.NO_OF_GUESTS,
						NO_OF_ROOMS: data?.NO_OF_ROOMS,
						BUDGET: data?.TOTAL_AMOUNT_WITH_SERVICE,
						PAGE_SOURCE: data?.isListing ? "LISTING" : "BOOKING",
					});
					clevertap.event.push("connect_with_host", {
						FULL_NAME: currentUser.fullName,
						MOBILE_NUMBER: currentUser.mobile,
						EMAIL_ID: currentUser.email,
						LISTING_ID: data?.LISTING_ID,
						CHECKIN: data?.FROM_DATE,
						CHECKOUT: data?.TO_DATE,
						NO_OF_GUEST: data?.NO_OF_GUESTS,
						NO_OF_ROOMS: data?.NO_OF_ROOMS,
						BUDGET: data?.TOTAL_AMOUNT_WITH_SERVICE,
						PAGE_SOURCE: data?.isListing ? "LISTING" : "BOOKING",
					});
					setOpen(true);
				}}
			>
				Send Message
			</button>
		</div>
	);
};

export default AskHelp;

export const AskMeModal = ({
	open,
	setOpen,
	data,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	data: any;
}) => {
	const {
		state: { currentUser },
	} = useContext(UserContext);

	const [query, setQuery] = useState("");
	const [firstName, setFirstName] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [email, setEmail] = useState("");
	const [cc, setCc] = useState("+91");
	const [event, setEvent] = useState("No");
	const [isLessRoom, setIsLessRoom] = useState(
		data?.allowSelectLessRoom || false,
	);
	const [phoneIsValid, setPhoneIsValid] = useState(false);
	const [rooms, setRooms] = useState(data.NO_OF_ROOMS);
	const [isCompany, setIsCompany] = useState(false);
	const [companyName, setCompanyName] = useState("");

	const [openRedirectionPopup, setOpenRedirectionPopup] = useState(false);
	const [rediretionData, setRedirectionData] = useState({
		title: "",
		link: "",
	});

	const { dispatch }: any = useContext(UserContext);

	useEffect(() => {
		setFirstName(currentUser.fullName);
		setMobileNumber(currentUser.mobile);
		setEmail(currentUser.email);
	}, [currentUser]);

	const sendQuestion = useMutation(
		() => {
			let body: any = {
				LISTING_ID: data?.LISTING_ID,
				CHECKIN: data?.FROM_DATE,
				CHECKOUT: data?.TO_DATE,
				NO_OF_GUEST: data?.NO_OF_GUESTS,
				NO_OF_ROOMS: data?.NO_OF_ROOMS,
				BUDGET: data?.TOTAL_AMOUNT_WITH_SERVICE,
				QUERY: query,
				PAGE_SOURCE: data?.isListing ? "LISTING" : "BOOKING",
				CELEBRATING: event,
			};
			if (data.isListing) {
				body = {
					...body,
					FULL_NAME: firstName,
					MOBILE_NUMBER: mobileNumber,
					CC_MOBILE_NUMBER: cc,
					EMAIL_ID: email,
					UTM: localStorage.getItem("utm_source")
						? `${localStorage.getItem("utm_source")} - ${localStorage.getItem(
								"utm_content",
						  )}`
						: "",
				};
			}
			if (isLessRoom) {
				body = {
					...body,
					QUERY:
						query + ` I want to book ${rooms} out of ${data.NO_OF_ROOMS} rooms`,
				};
			}
			if (isCompany) {
				body = {
					...body,
					QUERY:
						query +
						` This is a corporate enquiry ${
							companyName && `for ${companyName}`
						}`,
				};
			}
			if (data?.isReserveNow) {
				body = {
					...body,
					QUERY: "I want to reserve this villa",
				};
			}
			return axiosInstance({
				method: "POST",
				maxBodyLength: Infinity,
				url: "https://ecapi.saffronstays.com/recordGuestQuery",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
				data: body,
			});
		},
		{
			onError: (err: any) => {
				toast.error(err.response.data.error, {
					position: window.innerWidth < 800 ? "top-right" : "bottom-right",
					autoClose: 2000,
					hideProgressBar: false,
					icon: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
			},
			onSuccess: (res) => {
				if (data?.isListing) {
					setRedirectionData({
						title: "Continue to chat",
						link: "/customer-chat/" + res.data.data.chat_id,
					});
					setOpenRedirectionPopup(true);
					window.open("/customer-chat/" + res.data.data.chat_id, "_blank");
				}
				toast.success(res.data.data, {
					position: window.innerWidth < 800 ? "top-right" : "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					icon: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
				// setOpen(false);
				// setQuery("");

				// if (data.isListing)
				// 	dispatch({
				// 		type: "SHOW_HIGHLIGHT",
				// 		payload: true,
				// 	});
			},
		},
	);

	if (openRedirectionPopup) {
		return (
			<Modal
				shouldShow={openRedirectionPopup}
				onRequestClose={() => {
					setOpenRedirectionPopup(false);
					setOpen(false);
					setQuery("");
					if (data.isListing)
						dispatch({
							type: "SHOW_HIGHLIGHT",
							payload: true,
						});
				}}
			>
				<RedirectionRequestPopUp {...rediretionData} />
			</Modal>
		);
	}

	return (
		<Modal
			shouldShow={open}
			onRequestClose={() => {
				setOpen(false);
			}}
		>
			<div
				className="guest-data-wrapper ask-booking-popup"
				style={{ ...data?.theme?.pricePopUp }}
			>
				{data?.theme?.name !== "xseries" && data?.isReserveNow ? (
					<h3 style={{ color: data?.theme?.primaryTextColor }}>Reserve Now</h3>
				) : (
					<>
						<h3 style={{ color: data?.theme?.primaryTextColor }}>
							Do you have a question? Ask Us
						</h3>
						<p style={{ color: data?.theme?.secondaryTextColor }}>
							Feel free to ask us anything
						</p>
					</>
				)}

				{data?.isListing && (
					<>
						<input
							style={
								Object.keys(data?.theme || {})?.length !== 0
									? data?.theme?.borderBtn
									: {}
							}
							value={firstName}
							className="ask-me-input"
							placeholder="Full Name *"
							onChange={(e: any) => {
								setFirstName(e.target.value);
							}}
						/>
						<PhoneInput
							country={"in"}
							value={cc + mobileNumber}
							enableSearch={true}
							isValid={(value, country): any => {
								//@ts-ignore
								if (country?.countryCode !== "91") {
									setPhoneIsValid(true);
									return true;
								} //@ts-ignore
								if (country?.countryCode === "91" && value.length === 12) {
									setPhoneIsValid(true);
									return true;
								} else {
									setPhoneIsValid(false);
									return false;
								}
							}}
							onChange={(value, country, e, formattedValue): any => {
								//@ts-ignore
								setCc(country?.dialCode || "");
								setMobileNumber(
									//@ts-ignore
									value.slice(country?.dialCode?.toString()?.length || ""),
								);
							}}
						/>
						<input
							style={
								Object.keys(data?.theme || {})?.length !== 0
									? data?.theme?.borderBtn
									: {}
							}
							value={email}
							className="ask-me-input"
							placeholder="Email"
							onChange={(e: any) => {
								setEmail(e.target.value);
							}}
						/>
					</>
				)}
				{data?.theme?.name === "xseries" && (
					<>
						<label
							htmlFor="company-input"
							style={{
								color: data?.theme?.secondaryTextColor,
							}}
						>
							<input
								style={
									Object.keys(data?.theme || {})?.length !== 0
										? data?.theme?.borderBtn
										: {}
								}
								className={
									data?.theme?.name === "xseries" ? "xseries-checkbox" : ""
								}
								id={`company-input`}
								checked={isCompany}
								onChange={({ target }) => {
									setIsCompany(target.checked);
								}}
								type="checkbox"
							/>
							This is a corporate booking
						</label>
						{isCompany && (
							<input
								style={
									Object.keys(data?.theme || {})?.length !== 0
										? data?.theme?.borderBtn
										: {}
								}
								className="ask-me-input"
								value={companyName}
								onChange={(e) => setCompanyName(e.target.value)}
								placeholder="Company name"
							/>
						)}
					</>
				)}
				{(!data?.isReserveNow || data?.theme?.name === "xseries") && (
					<textarea
						placeholder="Enter your question"
						value={query}
						style={
							Object.keys(data?.theme || {})?.length !== 0
								? data?.theme?.borderBtn
								: {}
						}
						onChange={(e) => setQuery(e.target.value)}
					/>
				)}
				{/* {data?.theme?.name === "xseries" && (
					<textarea
						placeholder="Enter your question"
						value={query}
						style={
							Object.keys(data?.theme || {})?.length !== 0
								? data?.theme?.borderBtn
								: {}
						}
						onChange={(e) => setQuery(e.target.value)}
					/>
				)} */}
				{data?.theme?.name !== "xseries" &&
					data.isListing &&
					data.showLessRoom && (
						<>
							<label htmlFor="less-room-input">
								<input
									id="less-room-input"
									defaultChecked={data?.allowSelectLessRoom}
									checked={isLessRoom}
									onChange={({ target }) => {
										setIsLessRoom(target.checked);
									}}
									type="checkbox"
								/>
								I wish to book fewer than {data?.NO_OF_ROOMS}{" "}
								{data.isVilla ? "villas" : "rooms"}
							</label>
							{isLessRoom && (
								<div className="room-counter">
									<span>{data.isVilla ? "Villa(s)" : "Room(s)"}</span>
									<div className="counter-wrapper">
										<button
											disabled={rooms <= 1}
											onClick={() => {
												if (rooms > 1) setRooms(rooms - 1);
											}}
										>
											-
										</button>
										{rooms}
										<button
											disabled={rooms >= data?.NO_OF_ROOMS}
											onClick={() => {
												if (rooms < data?.NO_OF_ROOMS) setRooms(rooms + 1);
											}}
										>
											+
										</button>
									</div>
								</div>
							)}
						</>
					)}
				<select
					className="ask-me-input"
					style={{
						marginBottom: data?.isListing ? 100 : 200,
						...(Object.keys(data?.theme || {})?.length !== 0
							? data?.theme?.borderBtn
							: {}),
					}}
					id="eventSelect"
					value={event}
					onChange={(e) => {
						if (e.target.value) setEvent(e.target.value);
					}}
					placeholder="Select Event"
				>
					<option value="No">Are You Celebrating Something?</option>
					<option value="Anniversary">Anniversary</option>
					<option value="Proposal">Proposal</option>
					<option value="Pre Wedding">Pre Wedding Function</option>
					<option value="My Birthday">My Birthday</option>
					<option value="Partner Birthday">Spouse/Partner's Birthday</option>
					<option value="Parents Anniversary">Parents' Anniversary</option>
					<option value="Parents Birthday">Parents' Birthday</option>
					<option value="Pets Birthday">Pet's Birthday</option>
					<option value="Get Together">Get Together with Friends</option>
					<option value="Reunion">College/Alumni Reunion</option>
				</select>
				<div
					className="btn-wrapper"
					style={{ background: data?.theme?.background }}
				>
					<button
						onClick={() => {
							setOpen(false);
						}}
						style={{ color: data?.theme?.primaryTextColor }}
					>
						Close
					</button>
					<button
						style={{
							...data?.theme?.unlockPrice,
						}}
						className={`blue-btn ${
							sendQuestion.isLoading ? "button__loader" : ""
						}`}
						disabled={
							data?.isReserveNow
								? !firstName || !(mobileNumber && phoneIsValid)
								: data?.isListing
								? !firstName || !(mobileNumber && phoneIsValid) || !query
								: !query
						}
						onClick={() => sendQuestion.mutateAsync()}
					>
						{sendQuestion.isLoading ? "Loading..." : "Submit"}
					</button>
				</div>
			</div>
		</Modal>
	);
};

const RedirectionRequestPopUp = ({
	link,
	title,
}: {
	link: string;
	title: string;
}) => {
	return (
		<div className="redirection-popup ask-booking-popup">
			<img src={RedirectionImage} alt="" />
			<b>Aaaah! Something went wrong</b>
			<p>
				Sorry, we couldn't redirect you automatically. Please click the button
				below to continue to chat.
			</p>
			<a href={link}>Continue to chat</a>
		</div>
	);
};
