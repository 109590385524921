export const SvgBTCorporate = () => {
	return (
		<svg
			width="29"
			height="16"
			viewBox="0 0 29 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.08203 3.59175C2.08203 1.60966 3.71063 0 5.70724 0C7.70855 0 9.33715 1.6096 9.33715 3.59175C9.33715 5.5692 7.70855 7.1835 5.70724 7.1835C3.71085 7.1835 2.08203 5.56899 2.08203 3.59175Z"
				fill="#666666"
			/>
			<path
				d="M28.6573 13.4917V13.8595C28.6573 14.1222 28.4424 14.3371 28.1797 14.3371H20.9439C20.9058 14.0124 20.8437 13.6924 20.7529 13.3819C20.4663 12.3215 19.9171 11.3712 19.1814 10.5925C18.9618 10.3584 18.723 10.1434 18.4746 9.94304C19.535 8.60102 21.1636 7.78906 22.9499 7.78906C26.0975 7.78885 28.6573 10.3441 28.6573 13.4916V13.4917Z"
				fill="#666666"
			/>
			<path
				d="M9.47591 10.5929C8.74027 11.3714 8.19103 12.3219 7.90446 13.3823C7.81363 13.6928 7.75158 14.0128 7.71341 14.3375H0.477619C0.214929 14.3375 0 14.1226 0 13.8599V13.4921C0 10.3445 2.55993 7.78906 5.70741 7.78906C7.49382 7.78906 9.12236 8.60102 10.1827 9.94304C9.92964 10.1439 9.69552 10.3588 9.47591 10.5927V10.5929Z"
				fill="#666666"
			/>
			<path
				d="M17.9535 5.25581C17.9535 7.23943 16.3305 8.84756 14.3283 8.84756C12.3262 8.84756 10.7031 7.23943 10.7031 5.25581C10.7031 3.27219 12.3262 1.66406 14.3283 1.66406C16.3305 1.66406 17.9535 3.27219 17.9535 5.25581Z"
				fill="#666666"
			/>
			<path
				d="M20.0409 15.1605V15.5234C20.0409 15.7861 19.8259 16.0011 19.5632 16.0011H9.09383C8.83114 16.0011 8.61621 15.7861 8.61621 15.5234V15.1605C8.61621 12.0129 11.1761 9.45312 14.3236 9.45312H14.3332C17.4806 9.45312 20.0406 12.0131 20.0406 15.1605H20.0409Z"
				fill="#666666"
			/>
			<path
				d="M19.3203 3.59175C19.3203 1.60966 20.9489 0 22.9502 0C24.9466 0 26.5754 1.6096 26.5754 3.59175C26.5754 5.5692 24.9468 7.1835 22.9502 7.1835C20.9489 7.1835 19.3203 5.56899 19.3203 3.59175Z"
				fill="#666666"
			/>
		</svg>
	);
};
