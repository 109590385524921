import clevertap from "clevertap-web-sdk";
import { memo, useContext } from "react";

// contex
import { SearchPageDataInterface } from "context/SearchPageData";

// style
import "./style.scss";

const CustomCheckbox = ({
	setChanged,
	type,
	filter,
	filterName = "",
	name = "checkbox",
}) => {
	const { amenitiesFilter, eventFilter, moreFilter } = useContext(
		SearchPageDataInterface,
	);

	const amenitiesCheck =
		amenitiesFilter && amenitiesFilter?.includes(filter) ? true : false;
	const eventCheck =
		eventFilter && eventFilter?.includes(filter) ? true : false;
	const moreCheck = moreFilter && moreFilter?.includes(filter) ? true : false;

	const newObj = {
		collectionImage:
			filter === 29
				? "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/image/upload/ss3/Pet-Friendly_Saee_at_Masaya"
				: filter === 16
				? "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-1650,h-762/image/uploadss3/Veg_Only_Gardenia"
				: filter === 52
				? "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/image/upload/collections/ym7jyn2dnhybzmxqhhg5"
				: filter === 12
				? "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-1650,h-762/image/upload"
				: "",
		collection:
			filter === 29
				? "https://saffronstays.com/collection/pet-friendly-villas"
				: filter === 16
				? "https://saffronstays.com/collection/veg-only-homes"
				: filter === 52
				? "https://saffronstays.com/collection/corporate-getaway"
				: filter === 12
				? "https://saffronstays.com/collection/beachside-getaways"
				: "",
	};

	const handleChange = (e) => {
		if (e.target.checked) {
			const newData = {
				type_of_filter: filterName,
				timestamp: new Date(),
				...newObj,
				url: window.location.pathname + window.location.search,
				search_location: window.location.pathname?.split("/")[2]?.split("-")[2],
			};
			clevertap.event.push("search_filter", newData);
		}
		window.scrollTo({ top: 110, left: 0, behavior: "smooth" });

		setChanged((prev) =>
			!prev?.includes(filter)
				? [...prev, filter]
				: prev.length === 1
				? []
				: [...prev].filter((v) => v !== filter),
		);
	};

	return (
		<div className="checkbox-container">
			<input
				type="checkbox"
				id={name}
				name={name}
				checked={
					type === "amenities"
						? amenitiesCheck
						: type === "event"
						? eventCheck
						: moreCheck
				}
				onChange={handleChange}
			/>
		</div>
	);
};

export default memo(CustomCheckbox);
