import { useLocation } from "react-router-dom";

// icons
import { SvgClose as Close } from "../../../assets/icons/js/SvgClose";
import { SvgTick as Tick } from "../../../assets/icons/js/SvgTick";

// css
import "./style.scss";

export const EmailSent = ({ onRequestClose, email }) => {
	const { pathname } = useLocation();

	return (
		<div className="emailSent">
			<div className="emailSent-container">
				{pathname !== "/forget-password" && (
					<div className="emailSent__close-icon">
						<span onClick={onRequestClose}>
							<Close height={12} width={12} />
						</span>
					</div>
				)}
				<div className="emailSent__sub-container">
					<div className="emailSent__title">
						<div className="emailSent__tick">
							<Tick />
						</div>
						<div className="emailSent__subject">
							<p className="emailSent__subject-title">Email sent!</p>
							<p className="emailSent__subject-subtitle">
								A link to reset your password has been sent to {email}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
