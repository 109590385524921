import { useRef } from "react";

// scss
import "./style.scss";

export const Input = ({
	type,
	name,
	placeholder,
	code,
	defaultVlaue = "",
	value,
	onChange,
	required = true,
	error = false,
	read = false,
	readOnly = false,
	disabled = false,
	onFocus = () => {},
	onBlur = () => {},
	onPaste = () => {},
}) => {
	const input = useRef(null);

	const handleFocus = () => {
		input.current.focus();
	};

	return (
		<div className="input__form">
			<input
				id="input"
				required={required}
				type={type}
				name={name}
				defaultValue={defaultVlaue}
				/**
				 * * inputMode will change according to the input name
				 */
				inputMode={
					name === "mobileNumber"
						? "tel"
						: name === "url"
						? "url"
						: name === "number"
						? "number"
						: name === "email"
						? "email"
						: name === "password"
						? "password"
						: "on"
				}
				/**
				 * * autoComplete will change according to the input name
				 */
				autoComplete={
					name === "mobileNumber"
						? "tel"
						: name === "number"
						? "number"
						: name === "email"
						? "email"
						: name === "password"
						? "password"
						: name === "fullname"
						? "name"
						: "on"
				}
				ref={input}
				placeholder=" "
				autoCapitalize="off"
				autoCorrect="off"
				className="input__input"
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				onPaste={onPaste}
				style={{
					paddingLeft: code ? "3.4rem" : "",
					border: error && "1px red solid",
					width: type === "checkbox" && "20px",
				}}
				readOnly={read}
				disabled={read}
			/>
			{/**
			 * * if code prop exist then it will show the code for it for example +91
			 */}
			{code && (
				<label htmlFor={name} className="input__code" onClick={handleFocus}>
					{code}
				</label>
			)}
			<label
				htmlFor={name}
				style={{ left: code ? "3rem" : "", color: error && "red" }}
				className="input__label"
				onClick={handleFocus}
			>
				{placeholder}
			</label>
		</div>
	);
};
