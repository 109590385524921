export const SvgFooterLinkdin = (props: any) => {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16.732 16.412V10.401C16.732 7.44702 16.096 5.19002 12.649 5.19002C12.0022 5.16342 11.3603 5.3136 10.7925 5.6244C10.2246 5.9352 9.75219 6.39487 9.42598 6.95402H9.38498V5.45402H6.12598V16.412H9.53198V10.975C9.53198 9.53902 9.79898 8.16402 11.563 8.16402C13.307 8.16402 13.327 9.78502 13.327 11.057V16.39H16.733L16.732 16.412Z"
				fill="white"
			/>
			<path
				d="M0.586914 5.45703H3.99291V16.412H0.586914V5.45703Z"
				fill="white"
			/>
			<path
				d="M2.28882 3.04006e-05C1.89629 -0.00214436 1.51196 0.112408 1.18467 0.329134C0.857385 0.54586 0.6019 0.854979 0.450677 1.21722C0.299454 1.57947 0.259317 1.97848 0.335364 2.36359C0.411412 2.74869 0.600212 3.1025 0.877781 3.38007C1.15535 3.65764 1.50916 3.84644 1.89426 3.92249C2.27937 3.99854 2.67839 3.9584 3.04063 3.80718C3.40287 3.65595 3.71199 3.40047 3.92872 3.07318C4.14545 2.74589 4.26 2.36157 4.25782 1.96903C4.25756 1.4469 4.05003 0.946232 3.68082 0.57703C3.31162 0.207828 2.81095 0.000295374 2.28882 3.04006e-05Z"
				fill="white"
			/>
		</svg>
	);
};
