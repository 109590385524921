import { goAxiosInstance } from "helpers/axiosInstance";
import getImg from "helpers/getCloudinaryImage";
import { useQuery } from "react-query";
import { ReactComponent as LocationBig } from "assets/icons/svg/location.svg";

// style
import "./style.scss";
import { elasticLog } from "helpers/elastic";

export const AutoComplete = ({
	query,
	type,
	list,
	close,
	setQuery,
	data,
}: any) => {
	const autocomplete = useQuery(
		["autocomplete__form", query],
		() => {
			elasticLog({
				index: "autocomplete",
				payload: {
					text: query,
				},
			});
			return goAxiosInstance({
				method: "GET",
				url: `https://go.saffronstays.com/api/auto-complete?text=${query}&type=${type}`,
			});
		},
		{
			enabled: type !== "none",
		},
	);

	const resData = autocomplete.data?.data?.data;

	return (
		<>
			{data?.length > 0 || resData?.length > 0 ? (
				<div className="autocomplete__container">
					<div className="autocomplete__result__container">
						{type === "none" &&
							[...data, query]
								?.filter((v: any) =>
									v?.toLowerCase().includes(query.toLowerCase()),
								)
								?.map((v: any, i: number) => {
									return (
										<div
											className="autocomplete__box"
											onClick={() => {
												if (type === "listing") {
													list(v);
												} else if (type === "none") {
													list(v);
												} else {
													setQuery(v);
												}
												close();
												setQuery("");
											}}
											key={i}
										>
											<span>{/* <LocationBig /> */}</span>
											<p>{v}</p>
										</div>
									);
								})}
						{type !== "none" &&
							resData?.slice(0, 5)?.map((v: any, i: number) => {
								const img = getImg({
									id: v.image_id,
									version: "",
									quality: "40",
									height: "30",
									width: "30",
								});
								return (
									<div
										className="autocomplete__box"
										onClick={() => {
											if (type === "listing") {
												list(v);
												setQuery("");
											} else if (type === "none") {
												list(v);
											} else {
												// setQuery(v.key);
												setQuery(v);
											}
											close();
										}}
										key={i}
									>
										<span>
											{type !== "listing" ? (
												<LocationBig />
											) : (
												<img src={img} alt="#" />
											)}
										</span>
										<p>{v.title}</p>
									</div>
								);
							})}
					</div>
				</div>
			) : null}
		</>
	);
};
