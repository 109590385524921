import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import { PhoneLogin } from "../PhoneLogin";
import { OTP } from "../OTP";
import { PhoneSignup } from "../PhoneSignup";

// custom hook
import { useMediaQuery } from "customHooks/useMediaQuery";

// icon
// import { SvgClose as Close } from "assets/icons/js/SvgClose";
import SvgBackArrow from "assets/icons/js/SvgBackArrow";
import { SvgLogo as Logo } from "assets/icons/js/SvgLogo";

// scss
import "./phone.scss";
import { UserContext } from "store";
const PhoneLoginHandler = ({
	onRequestClose,
	setPhone,
	noIcon = true,
	redirect = "",
	showRegister = true,
}) => {
	const [active, setActive] = useState("Log in");
	const [send, setSend] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const navigate = useNavigate();
	const width = useMediaQuery();
	const isMobile = width < 768;
	const [cc, setCC] = useState("");

	const { state } = useContext(UserContext);
	return !send ? (
		<>
			<div className="phone__wrapper-box">
				{!state.isFromBookingBtn && (
					<div className="phone__header-2">
						<div className="phone__header--top">
							<div className="phone__header-empty"></div>
							<div className="phone__header-logo">
								{isMobile && (
									<button
										aria-label="go-back"
										className="phone__header-back-btn"
										onClick={() => {
											if (!window.location.href.includes("login"))
												window.location.reload();
											else {
												window.location.href = "/";
											}
										}}
									>
										<SvgBackArrow />
									</button>
								)}
								<Link
									aria-label="logo"
									to="/"
									className="phone__logo--container"
								>
									<Logo height={24} />
								</Link>
							</div>
							<div className="phone__wrapper-close">
								{/* <span className="phone__svg--container" onClick={onRequestClose}>
								{noIcon && !isMobile && <Close height={12} width={12} />}
							</span> */}
							</div>
						</div>

						<div className="phone__header-title">
							<span
								className={
									active === "Log in" ? "phone__header-login active" : ""
								}
								onClick={() => setActive("Log in")}
							>
								<p className={active === "Log in" ? "active-tab" : ""}>
									Log in
								</p>
							</span>
							<span
								className={
									active === "Sign up" ? "phone__header-register active" : ""
								}
								onClick={() => setActive("Sign up")}
							>
								{showRegister && (
									<p className={active === "Sign up" ? "active-tab" : ""}>
										Register
									</p>
								)}
							</span>
						</div>
					</div>
				)}
				<>
					{active === "Sign up" && (
						<PhoneSignup
							setPhone={setPhone}
							setSend={setSend}
							phoneNumber={phoneNumber}
							setPhoneNumber={setPhoneNumber}
							setCC={setCC}
						/>
					)}
					{active === "Log in" && (
						<PhoneLogin
							setPhone={setPhone}
							setCC={setCC}
							setSend={setSend}
							phoneNumber={phoneNumber}
							setPhoneNumber={setPhoneNumber}
							cc={cc}
						/>
					)}
				</>
			</div>
		</>
	) : (
		<OTP
			onRequestClose={onRequestClose}
			phoneNumber={phoneNumber}
			setSend={setSend}
			active={active}
			redirectPhone={redirect}
			CC={cc}
		/>
	);
};

export default PhoneLoginHandler;
