import { createContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";

// components
import { UniversalURLLoader } from "components/Loader/UniversalURL";
import { UniversalURLMainErrorScreen } from "components/UniversalURL/UniversalURLMainErrorScreen";

// helpers
import { axiosInstance } from "helpers/axiosInstance";

// import MD5 from "helpers/MD5HashGenerator";

// @ts-ignore
export const UniversalURLInterface = createContext();

export const UniversalURLData = ({ children }: any) => {
	// booking ID from URL
	const { bookingID: hashedBookingId } = useParams();

	// );

	// query parametrs
	const useSearchQuery = () => {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	};
	let query = useSearchQuery();

	// Universal URL Main API Cal
	const universalURLFetchData = useQuery(
		["universalURLQuery"],
		() =>
			axiosInstance({
				method: "POST",
				url: "/ss3/universalURL2",
				headers: {
					"Content-Type": "application/json",
				},
				data: {
					BOOKING_ID: hashedBookingId,
				},
			}),
		{
			onSuccess: (res) => {
				universalURLFetchMeals.mutate({
					LISTING_ID: res?.data?.data?.LISTING_ID,
				});
			},
		},
	);

	// Universal URL Guest API Call
	const universalURLGuestDetails = useQuery(
		["universalURLGuestQuery"],
		() =>
			axiosInstance({
				method: "POST",
				url: "/ops/fetch/guestDetails",
				headers: {
					"Content-Type": "application/json",
				},
				data: {
					BOOKING_ID: hashedBookingId,
				},
			}),
		{
			cacheTime: 0,
			staleTime: 0,
		},
	);

	// Guest data
	const [guestFirstName, setGuestFirstName] = useState("");
	const [guestLastName, setGuestLastName] = useState("");
	const [guestEmail, setGuestEmail] = useState("");
	const [guestMobileNumber, setGuestMobileNumber] = useState("");
	// const [guestGender, setGuestGender] = useState("male");
	const [guestAge, setGuestAge] = useState<number>();
	const [guestPhotoIDFront, setGuestPhotoIDFront] = useState<File>();
	const [guestPhotoIDBack, setGuestPhotoIDBack] = useState<File>();
	const [guestFrontSideIdURL, setGuestFrontSideIdURL] = useState("");
	const [guestBackSideIdURL, setGuestBackSideIdURL] = useState("");
	const [addingGuestNotification, setAddingGuestNotification] = useState(false);
	// Universal URL Add Guest API Call
	const universalURLAddGuest = useMutation(
		(data: any) => {
			setAddingGuestNotification(true);
			return axiosInstance({
				method: "POST",
				url: "/ops/create/extraGuest",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			});
		},
		{
			onSuccess: () => {
				setGuestFirstName("");
				setGuestLastName("");
				setGuestEmail("");
				setGuestMobileNumber("");
				setGuestAge(undefined);
				setGuestPhotoIDFront(undefined);
				setGuestPhotoIDBack(undefined);
				setGuestFrontSideIdURL("");
				setGuestBackSideIdURL("");
				setAddingGuestNotification(false);
				universalURLGuestDetails.refetch();
			},
		},
	);
	// Universal URL Add Guest API Call
	const universalURLAddGuestUpdate = useMutation(
		(data: any) => {
			setAddingGuestNotification(true);
			return axiosInstance({
				method: "POST",
				url: "/ops/user/editGuest2",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			});
		},
		{
			onSuccess: () => {
				setGuestFirstName("");
				setGuestLastName("");
				setGuestEmail("");
				setGuestMobileNumber("");
				setGuestAge(undefined);
				setGuestPhotoIDFront(undefined);
				setGuestPhotoIDBack(undefined);
				setGuestFrontSideIdURL("");
				setGuestBackSideIdURL("");
				setAddingGuestNotification(false);
				universalURLGuestDetails.refetch();
			},
		},
	);

	const universalURLRemoveGuest = useMutation(
		(mapID) => {
			return axiosInstance({
				method: "POST",
				url: "/ss/user/removeGuest",
				headers: {
					"Content-Type": "application/json",
				},
				data: {
					MAP_ID: mapID,
				},
			});
		},
		{
			onSuccess: () => {
				universalURLGuestDetails.refetch();
			},
		},
	);

	//! deprecate
	const universalURLAddReview = useMutation((data: any) => {
		return axiosInstance({
			method: "POST",
			url: "/ssalliance/guestReviews",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: {
				BOOKING_ID: data.bookingID,
				CLEANLINESS_RATING: data.cleanlinessRating,
				SAFETY_RATING: data.safetyRating,
				MEAL_RATING: data.mealRating,
				STAFF_RATING: data.staffRating,
				REVIEWS_FROM_GUEST: data.guestReview,
			},
		});
	});

	// cancel booking flow
	const [cancelBookingData, setCancelBookingData] = useState({});

	const universalURLCancelBooking = useMutation(
		(data: any) => {
			return axiosInstance({
				method: "POST",
				url: "/booking/fullyCancel",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					bookingid: data.bookingID,
					refund_through_travel_credit: data.refundByTravelCredit,
					reason_of_cancellation: data.reason,
					refund_fee: 0,
				},
			});
		},
		{
			onSuccess: () => {
				universalURLFetchData.refetch();
			},
		},
	);

	const universalURLFetchMeals = useMutation(
		({ LISTING_ID }: { LISTING_ID: string }) => {
			return axiosInstance({
				method: "POST",
				url: "/fetch/meal/details/withoutToken",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					LISTING_ID: LISTING_ID,
				},
			});
		},
	);

	// ADD MEALS FLOW
	const [recordedMealData, setRecordedMealData] = useState<any>({});

	const [mealType, setMealType] = useState("allMeals");
	const [actualMealType, setActualMealType] = useState("All Meals");
	const [numberOfAdultVeg, setNumberOfAdultVeg] = useState(
		universalURLFetchData?.data?.data?.data?.NO_OF_ADULTS_VEG || 0,
	);
	const [numberOfAdultNonVeg, setNumberOfAdultNonVeg] = useState(0);
	const [numberOfChildrenVeg, setNumberOfChildrenVeg] = useState(0);
	const [numberOfChildrenNonVeg, setNumberOfChildrenNonVeg] = useState(0);
	const [numberOfBarbequeVeg, setNumberOfBarbequeVeg] = useState(0);
	const [numberOfBarbequeNonVeg, setNumberOfBarbequeNonVeg] = useState(0);
	const [instructions, setInstructions] = useState("");

	const [isFlowAllowed, setIsFlowAllowed] = useState(false);

	const checkOutDate = Date.parse(
		universalURLFetchData?.data?.data?.data?.CHECKOUT_DATE,
	);
	const checkOutDateParsed = new Date(checkOutDate);
	const diffInDate = new Date() <= checkOutDateParsed;

	useEffect(() => {
		if (
			(universalURLFetchData?.data?.data?.data?.BOOKING_FINAL_STATUS ===
				"confirmed" ||
				universalURLFetchData?.data?.data?.data?.BOOKING_FINAL_STATUS ===
					"approval pending") &&
			diffInDate
		) {
			setIsFlowAllowed(true);
		} else {
			setIsFlowAllowed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [diffInDate]);

	const parsedData: any = {
		query,
		hashedBookingId: hashedBookingId,
		universalURLFetchData: universalURLFetchData,
		universalData: universalURLFetchData?.data?.data,
		isFlowAllowed: isFlowAllowed,
		diffInDate: diffInDate,
		universalURLGuestDetails,
		guestData: universalURLGuestDetails?.data?.data,
		addGuest: universalURLAddGuest,
		universalURLAddGuestUpdate,
		getGuestDataState: {
			guestFirstName,
			guestLastName,
			guestEmail,
			guestMobileNumber,
			guestAge,
			guestPhotoIDFront,
			guestPhotoIDBack,
			guestFrontSideIdURL,
			guestBackSideIdURL,
			addingGuestNotification,
		},
		setGuestDataState: {
			setGuestFirstName,
			setGuestLastName,
			setGuestEmail,
			setGuestMobileNumber,
			setGuestAge,
			setGuestPhotoIDFront,
			setGuestPhotoIDBack,
			setGuestFrontSideIdURL,
			setGuestBackSideIdURL,
		},
		universalURLAddGuest: universalURLAddGuest,
		removeGuest: universalURLRemoveGuest,
		addReview: universalURLAddReview,
		cancelBookingData: cancelBookingData,
		setCancelBookingData: setCancelBookingData,
		cancelBooking: universalURLCancelBooking,
		isMealAvailable: universalURLFetchData?.data?.data?.data?.MEALS
			.MEALS_AVAILABLE_NEW
			? true
			: false,
		isMealRecorded: universalURLFetchData?.data?.data?.data?.MEALS
			.MEALS_RECORDED
			? true
			: false,
		isMealPaymentRequired:
			universalURLFetchData?.data?.data?.data?.MEALS.MEALS_PAYMENT_REQUIRED ===
			"Yes"
				? true
				: false,
		isMealPaid: universalURLFetchData?.data?.data?.data?.MEALS.MEALS_PAID
			? true
			: false,
		fetchMealsDetails: universalURLFetchMeals?.data?.data,
		getMealRecordState: {
			mealType,
			actualMealType,
			numberOfAdultVeg,
			numberOfAdultNonVeg,
			numberOfChildrenVeg,
			numberOfChildrenNonVeg,
			numberOfBarbequeVeg,
			numberOfBarbequeNonVeg,
			instructions,
		},
		setMealsData: {
			setMealType,
			setActualMealType,
			setNumberOfAdultVeg,
			setNumberOfAdultNonVeg,
			setNumberOfChildrenVeg,
			setNumberOfChildrenNonVeg,
			setNumberOfBarbequeVeg,
			setNumberOfBarbequeNonVeg,
			setInstructions,
		},
		// recordMeal: universalURLRecordMeals,
		getRecordedMealData: recordedMealData,
		setRecordedMealData: setRecordedMealData,
	};

	const renderUniversalURL = () => {
		if (universalURLFetchData?.isLoading) {
			return <UniversalURLLoader />;
		} else if (
			universalURLFetchData?.isError &&
			!universalURLFetchData?.isLoading
		) {
			return <UniversalURLMainErrorScreen />;
		} else {
			return (
				<UniversalURLInterface.Provider value={parsedData}>
					{children}
				</UniversalURLInterface.Provider>
			);
		}
	};

	return <>{renderUniversalURL()}</>;
};
