import { useContext, useEffect, useState } from "react";

// assets
import { SvgSearch } from "assets/icons/js/SvgSearch";
import { AutoComplete } from "../../WantToBook/PersonalStep2/AutoComplete";

// style
import "./style.scss";
import { SvgArrowDown } from "assets/icons/js/SvgArrowDown";
import { SvgMedia } from "assets/icons/travelPartner/SvgMedia";
import { SvgRemove } from "assets/icons/js/SvgRemove";
import { SvgAdd } from "assets/icons/js/SvgAdd";
import { SvgVectorImage } from "assets/icons/js/SvgVectorImage";
import { SvgDustbin } from "assets/icons/js/SvgDustbin";
import Modal from "components/LoginAndRegister/Modal";
import { ViewImage } from "./ViewImage";
import { RegisterFormDataInterface } from "context/RegisterForm";
import { UploadApi } from "helpers/uploadAPI";
import UploadModal from "components/uploadAnimation";
import { MultiUploadApi } from "helpers/multiUploader";

export const ListHomeStep2 = () => {
	const {
		// @ts-ignore
		planToGo,
		// @ts-ignore
		setPlanToGo,
		// @ts-ignore
		websiteLink,
		// @ts-ignore
		setWebsiteLink,
		// @ts-ignore
		homeImages,
		// @ts-ignore
		setHomeImages,
	} = useContext(RegisterFormDataInterface);

	const [location, setLocation] = useState("");
	const [showAuto, setShowAuto] = useState(false);

	const [selectedFile, setSelectedFile] = useState<any[]>([]);
	const [showImage, setShowImage] = useState(false);
	const [imgData, setImageData] = useState({});
	const [open, setOpen] = useState(false);
	const [percent, setPercent] = useState(0);

	const onFileChange = async (event: any) => {
		if (event.target.files[0].name) {
			const data = await MultiUploadApi(
				event.target.files[0],
				setOpen,
				setPercent,
				"image",
			);

			setSelectedFile((prev: any) => [...prev, event.target.files[0]]);
			setHomeImages((prev: any) => [...prev, data?.url]);
		}
		setPercent(0);
	};

	const removeUpload = (name: string) => {
		setSelectedFile((prev: any) => {
			return prev.filter((v: any) => v?.name !== name);
		});
	};

	const handleShowImage = (data: any) => {
		setShowImage(true);
		setImageData(data);
	};

	return (
		<div className="list__home__step__two__container other__details__container">
			<UploadModal
				open={open}
				setOpen={setOpen}
				pencentage={percent}
				total={undefined}
				order={undefined}
			/>
			<div className="top" style={{ border: "none", marginBottom: "0px" }}>
				<h1>Tell us about the home you want to list</h1>
				<div className="search__villa">
					<label htmlFor="">Location of the home </label>
					<div className="span">
						<SvgSearch />
						<input
							type="text"
							placeholder="Search for a location..."
							value={planToGo}
							onChange={(e) => setPlanToGo(e.target.value)}
							onClick={() => setShowAuto(true)}
						/>
						{showAuto && planToGo.length > 0 && (
							<AutoComplete
								query={planToGo}
								type="city"
								setQuery={(v: any) => {
									setPlanToGo(v.title);
								}}
								close={() => setShowAuto(false)}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="bottom">
				<div className="message__container">
					<div className="hear__about">
						<label htmlFor="">Website/Airbnb link of the home (optional)</label>
						<input
							type="text"
							className="form__input"
							placeholder="Website/Airbnb link"
							value={websiteLink}
							onChange={(e) => setWebsiteLink(e.target.value)}
						/>
					</div>
					<div className="hear__about">
						<label htmlFor="">Upload photos of the home (optional)</label>
						{selectedFile && (
							<>
								<div className="uploaded__image__container">
									{selectedFile.map((item: any, index: number) => {
										return (
											<>
												<div className="content" key={index}>
													<div className="left">
														<div className="left__top">
															<SvgVectorImage />
															<p>{item?.name}</p>
														</div>
														<p className="upload">Upload complete.</p>
													</div>
													<div
														className="mid"
														onClick={() => handleShowImage(item)}
													>
														View photo
													</div>
													<div
														className="right"
														onClick={() => removeUpload(item.name)}
													>
														<SvgDustbin />
													</div>
												</div>
											</>
										);
									})}
								</div>
							</>
						)}
						<button className="upload__btn">
							<label htmlFor="file">Add Photo</label>
							<input
								type="file"
								onChange={onFileChange}
								accept="image/jpeg, image/png, image/jpg"
								multiple
							/>
						</button>
					</div>
				</div>
			</div>
			<Modal shouldShow={showImage} onRequestClose={() => setShowImage(false)}>
				<ViewImage image={imgData} onClose={() => setShowImage(false)} />
			</Modal>
		</div>
	);
};
