const SvgBackArrow = (props) => {
	return (
		<svg
			width="19"
			height="12"
			viewBox="0 0 19 12"
			fill=""
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M4.0543 7.19995H17.8125C18.4683 7.19995 19 6.66271 19 5.99997C19 5.33723 18.4683 4.79999 17.8125 4.79999H4.0543L6.77713 2.04852C7.24087 1.57991 7.24087 0.820082 6.77713 0.351464C6.31339 -0.117155 5.56147 -0.117155 5.09773 0.351464L0.347653 5.15144C0.319988 5.1794 0.293825 5.20882 0.268999 5.23942C0.257755 5.25325 0.247958 5.26788 0.237437 5.28211C0.224746 5.29921 0.211664 5.31592 0.199863 5.33375C0.188564 5.35079 0.178767 5.36851 0.168413 5.386C0.158894 5.40209 0.148875 5.41784 0.14008 5.43443C0.130617 5.45227 0.122601 5.47066 0.11414 5.48888C0.106069 5.50615 0.0975521 5.52314 0.09026 5.54086C0.0830236 5.55858 0.0771232 5.57669 0.0707774 5.59469C0.064042 5.61365 0.0568613 5.63232 0.0510722 5.65167C0.0456727 5.66967 0.0417206 5.68795 0.0372117 5.70618C0.0322576 5.72592 0.0268025 5.74544 0.0228503 5.76558C0.0187311 5.78667 0.0162819 5.80793 0.013276 5.82914C0.0108267 5.84663 0.00748688 5.8639 0.00576128 5.88162C-0.00192043 5.96037 -0.00192043 6.03974 0.00576128 6.11849C0.00748688 6.13621 0.0107711 6.15342 0.013276 6.17097C0.0162819 6.19218 0.0187311 6.2135 0.022906 6.23453C0.0268581 6.25467 0.0323133 6.27413 0.0372674 6.29388C0.0417762 6.3121 0.0457284 6.33038 0.0511279 6.34838C0.056917 6.36773 0.0640977 6.38647 0.0708331 6.40536C0.0772345 6.42337 0.0830793 6.44148 0.0903157 6.4592C0.0975521 6.47692 0.106124 6.49385 0.114196 6.51112C0.122712 6.52934 0.130728 6.54773 0.140191 6.56562C0.148986 6.58216 0.15895 6.59785 0.168469 6.61394C0.178822 6.63149 0.188619 6.64921 0.199919 6.66631C0.211664 6.68408 0.224746 6.70073 0.237381 6.71783C0.247958 6.73212 0.257755 6.74675 0.269054 6.76064C0.293714 6.79101 0.319709 6.82021 0.347152 6.84794C0.347374 6.84816 0.347486 6.84839 0.347708 6.84856L5.09773 11.6485C5.56147 12.1172 6.31339 12.1172 6.77713 11.6485C7.24087 11.1799 7.24087 10.4201 6.77713 9.95147L4.0543 7.19995Z"
				fill="#666666"
			/>
		</svg>
	);
};

export default SvgBackArrow;
