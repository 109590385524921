import ClaimFriendCoupon from "components/UniversalUrlNew/LeftSide/ReviewHeader/NPS/ClaimFriendCoupons";
import { UniversalURLData } from "context/UniversalURLData";

import { useParams } from "react-router-dom";

const ClaimFriendCouponPage = () => {
	const { bookingID } = useParams();
	const validHashId = bookingID ?? "";
	return (
		<UniversalURLData>
			<ClaimFriendCoupon hashId={validHashId} />
		</UniversalURLData>
	);
};

export default ClaimFriendCouponPage;
