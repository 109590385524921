import { BottomContainer } from "components/NewForm/BottomContainer";
import { RequestSubmited } from "components/NewForm/RequestSubmited";
import { Stepper } from "components/Stepper";
import React, { useState } from "react";
import { HaveQueryIssue } from "../HaveQuery/HaveQueryIssue";
import { WantToRegister } from "../WantToRegister";
import { BottomBar } from "./BottomBar";

// style
import "./style.scss";

export const WantToRegisterAndHaveQuery = () => {
	const [currentStep, setCurrentStep] = useState(0);

	return (
		<div className="want__t__r__and__q__container">
			<BottomContainer>
				<Stepper currentStep={currentStep}>
					<WantToRegister showBtn={false} />
					<HaveQueryIssue showBtn={false} />
					<RequestSubmited />
				</Stepper>
				<BottomBar currentStep={currentStep} setStep={setCurrentStep} />
			</BottomContainer>
		</div>
	);
};
