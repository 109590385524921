export const SvgConceernOther = () => {
	return (
		<svg
			width="42"
			height="11"
			viewBox="0 0 42 11"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M36.2355 10.5283C33.6095 10.5283 31.4727 8.39101 31.4727 5.76369C31.4727 3.13768 33.609 1 36.2355 1C38.8628 1 41 3.13725 41 5.76369C41 8.38971 38.8628 10.5283 36.2355 10.5283ZM36.2355 2.90496C34.6593 2.90496 33.3789 4.18752 33.3789 5.76369C33.3789 7.33987 34.6607 8.62243 36.2359 8.62243C37.8121 8.62243 39.0947 7.33987 39.0947 5.76369C39.0942 4.18752 37.8117 2.90496 36.2355 2.90496Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.8"
			/>
			<path
				d="M20.9985 10.5283C18.3711 10.5283 16.2339 8.39101 16.2339 5.76369C16.2339 3.13768 18.3711 1 20.9985 1C23.6258 1 25.763 3.13725 25.763 5.76369C25.763 8.38971 23.6245 10.5283 20.9985 10.5283ZM20.9985 2.90496C19.4223 2.90496 18.1397 4.18676 18.1397 5.76282C18.1397 7.339 19.4223 8.62156 20.9985 8.62156C22.5746 8.62156 23.8572 7.339 23.8572 5.76282C23.8572 4.18752 22.5742 2.90496 20.9985 2.90496Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.8"
			/>
			<path
				d="M5.76075 10.5283C3.13561 10.5283 1 8.39101 1 5.76369C1 3.13768 3.1355 1 5.76075 1C8.38807 1 10.5253 3.13725 10.5253 5.76369C10.5253 8.38971 8.38763 10.5283 5.76075 10.5283ZM5.76075 2.90496C4.18676 2.90496 2.90583 4.18676 2.90583 5.76282C2.90583 7.339 4.18676 8.62156 5.76075 8.62156C7.33693 8.62156 8.61949 7.339 8.61949 5.76282C8.61949 4.18752 7.33769 2.90496 5.76075 2.90496Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.8"
			/>
		</svg>
	);
};
