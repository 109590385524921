import { useContext, useEffect, useState } from "react";
import { DatePicker } from "react-responsive-calendar-picker";
import { format } from "multi-date";

// components
import { GuestInfo } from "../GuestInfo";
import { AutoComplete } from "../AutoComplete";

// assets
import { SvgCalendar } from "assets/icons/js/SvgCalendar";
import { SvgLocation } from "assets/icons/js/SvgLocation";
import { SvgCross } from "assets/icons/js/SvgCross";
import { SvgSearch } from "assets/icons/js/SvgSearch";
import PlusMinus from "assets/icons/plus_minus.svg";

// data
import { otherDetailsAmenitesList } from "lib/otherDetailsAmenitesList";
import { RegisterFormDataInterface } from "context/RegisterForm";

// styles
import "./style.scss";
import { SvgPlusMInus } from "assets/icons/js/SvgPlusMInus";

const data = [
	"Exact Dates",
	"1 Day",
	"3 Days",
	"1 Week",
	"2 Weeks",
	"1 Month",
	"Flexible",
];

export const TripDetails = () => {
	const {
		// @ts-ignore
		setDates,
		// @ts-ignore
		dates,
		// @ts-ignore
		planToGo,
		// @ts-ignore
		setPlanToGo,
		// @ts-ignore
		setLat,
		// @ts-ignore
		amenitiesList,
		// @ts-ignore
		setAmenitiesList,
		// @ts-ignore
		flexiblity,
		// @ts-ignore
		setFlexiblity,
		// @ts-ignore
		amenQuery,
		// @ts-ignore
		setAmenQuery,
	} = useContext(RegisterFormDataInterface);

	const [showLocationAuto, setShowLocationAuto] = useState(false);
	const [showAuto, setShowAuto] = useState(false);

	const [openCalenderCheckIn, setOpenCalendarCheckIn] = useState(false);
	const [openCalenderCheckOut, setOpenCalendarCheckOut] = useState(false);

	const [inputDetail, setInputDetail] = useState(planToGo || "");

	useEffect(() => {
		if (dates.checkin) {
			setOpenCalendarCheckIn(false);
			setOpenCalendarCheckOut(true);
		}
		if (dates.checkout) {
			setOpenCalendarCheckOut(false);
		}
	}, [dates]);

	const onChangeOtherDetails = (e: any) => {
		setInputDetail(e.target.value);
		setPlanToGo(e.target.value);
	};

	return (
		<div className="trip__details__container">
			<div className="wrapper">
				<div className="middle__top">
					<div className="middle__left">
						<label htmlFor="">Check-in</label>
						<DatePicker
							style={{
								zIndex: 10,
							}}
							noMonth={1}
							open={openCalenderCheckIn}
							setOpen={setOpenCalendarCheckIn}
							dates={dates}
							setDates={setDates}
						>
							<span
								onClick={() => {
									setOpenCalendarCheckIn(!openCalenderCheckIn);
								}}
							>
								<p>
									{dates?.checkin !== ""
										? format(dates?.checkin, "DD/MM/YYYY")
										: "Enter Check in"}
								</p>
								<SvgCalendar />
							</span>
						</DatePicker>
					</div>
					<div className="middle__right">
						<label htmlFor="">Check-out</label>
						<DatePicker
							style={{
								zIndex: 10,
							}}
							noMonth={1}
							open={openCalenderCheckOut}
							setOpen={setOpenCalendarCheckOut}
							dates={dates}
							setDates={setDates}
						>
							<span
								onClick={() => {
									setOpenCalendarCheckOut(!openCalenderCheckOut);
								}}
							>
								<p>
									{dates?.checkout !== ""
										? format(dates?.checkout, "DD/MM/YYYY")
										: "Enter Check out"}
								</p>
								<SvgCalendar />
							</span>
						</DatePicker>
					</div>
				</div>
				<div className="btn__list__cont">
					{data.map((v: any, i: number) => {
						return (
							<span
								className={flexiblity === v ? "caps caps__active" : "caps"}
								key={i}
								onClick={() => {
									setFlexiblity(v);
								}}
							>
								{i > 0 && i < 6 && <img src={PlusMinus} alt="#" />}
								{v}
							</span>
						);
					})}
				</div>
				<div className="top">
					<label htmlFor="">Location (optional)</label>
					<span>
						<SvgLocation />
						<input
							type="text"
							name="searchPlace"
							placeholder="Where do you plan to go? (optional)"
							value={inputDetail}
							onChange={onChangeOtherDetails}
							onClick={() => setShowLocationAuto(true)}
						/>

						{showLocationAuto && inputDetail.length > 0 && (
							<AutoComplete
								query={inputDetail}
								type="city"
								setQuery={(v: any) => {
									setPlanToGo(v.title);
									setInputDetail(v.title);
									setLat({
										lat: v.latitude,
										lon: v.longitude,
									});
								}}
								close={() => setShowLocationAuto(false)}
							/>
						)}
					</span>
				</div>
				<div className="middle">
					<h4>Rooms and Guest</h4>
					{/* <div className="middle__bottom">
						<input type="checkbox" />
						<label htmlFor="">I am flexible with the dates</label>
					</div> */}
					<GuestInfo />
				</div>
				<div className="bottom">
					{/* <label htmlFor="">
						Select the event you are celebrating (optional)
					</label> */}

					<div className="search__villa">
						<label htmlFor="">Choose amenities (optional)</label>
						<div className="span">
							<SvgSearch />
							<input
								type="text"
								placeholder="Tell us any amenities you prefer."
								value={amenQuery}
								onChange={(e) => setAmenQuery(e.target.value)}
								onClick={() => setShowAuto(true)}
							/>
							{showAuto && amenQuery.length > 0 && (
								<AutoComplete
									data={otherDetailsAmenitesList}
									query={amenQuery}
									type="none"
									list={(val: string) =>
										// @ts-ignore
										setAmenitiesList((prev: any) => [
											...prev.filter((v: any) => v !== val),
											val,
										])
									}
									close={() => setShowAuto(false)}
									setQuery={setAmenQuery}
								/>
							)}
						</div>
						<div className="small__box__container">
							{amenitiesList?.map((v: any, i: number) => {
								return (
									<div
										className="small__box"
										key={i}
										onClick={() => {
											setAmenitiesList((prev: any) => [
												...prev.filter((j: any) => j !== v),
											]);
											setAmenQuery("");
										}}
									>
										{v}
										<SvgCross />
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
