import React, { useState } from "react";
import "./style.scss";

import { ReactComponent as DropDownIcon } from "../assets/dropdown.svg";

const Collapse = ({
	children,
	init,
	title = "",
}: {
	children: React.ReactNode;
	init: boolean;
	title: string;
}) => {
	const [open, setOpen] = useState<boolean>(init);
	return (
		<div className="booking-collapse">
			<div
				className="collapse-title"
				onClick={() => {
					setOpen(!open);
				}}
			>
				<b>{title}</b>
				<DropDownIcon
					style={{
						transition: "0.3s ease",
						transform: open ? "rotate(180deg)" : "",
					}}
				/>
			</div>
			{open && <div className="collapse-body">{children}</div>}
		</div>
	);
};

export default Collapse;
