import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";
import { axiosInstance } from 'helpers/axiosInstance';

// components
import { Input } from "../Input";
import { PrimaryButton } from "../PrimaryButton";

// styles
import './style.scss';

export const AddReferralCode = ({ redirect }: any) => {
  const width = useMediaQuery();
  const navigate = useNavigate();

  const isMobile = width <= 500 ? true : false;

  const [referralCode, setReferralCode] = useState('');

  const handleReload = () => {
    if (redirect) {
      window.location.assign(redirect);
    }
    if (isMobile) {
      navigate(-1)
      window.location.reload()
    }
    else window.location.reload();
  }

  const addReferralCode = useMutation(() => {
    return axiosInstance({
      method: "POST",
      url: '/ref_code/update',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      data: {
        'REF_CODE': referralCode
      }
    })
  }, {
    onSuccess: (res) => {
      handleReload();
    },
    onError: (err: any) => {
      toast.error(err.response.data.error, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        icon: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      })
    }
  })

  return (
    <>
      <div className='add-referral-code__container'>
        <div className='add-referral-code__header-container'>
          <h3>Referral Code</h3>
          <p>Add your referral code</p>
        </div>
        <div className='add-referral-code__body-container'>
          <Input
            type="text"
            name={'referral code'}
            code={null}
            placeholder={"Referral Code"}
            value={referralCode}
            onChange={(e: any) => setReferralCode(e.target.value)}
          />
          <PrimaryButton
            type="button"
            value={addReferralCode.isLoading ? "Loading..." : "Add Referral Code"}
            disabled={addReferralCode.isLoading}
            onClick={() => {
              addReferralCode.mutate();
            }}
          />
          <button
            className='add-referral-code__btn-skip'
            onClick={() => handleReload()}>
            {"Skip >>"}
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
