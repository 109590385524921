// helpers
import { formatDate } from "helpers/dateFormatter";
import parseHTML from "helpers/parseHTML";

// customHooks
import { useSearchQuery } from "customHooks/useQuery";

// style
import "./style.scss";
import moment from "moment";
import { SearchPageDataInterface } from "context/SearchPageData";
import { useContext } from "react";
import { useMediaQuery } from "customHooks/useMediaQuery";
import capitalizeFirstLetter from "helpers/cap";

export const HomeSearchNavbar = ({ showSearchBar, openSearchBar }: any) => {
	// @ts-ignore
	const {
		// @ts-ignore
		setShowLocationPopup,
		// @ts-ignore
		setShowCalendarPopup,
		// @ts-ignore
		setShowGuestPopup,
	} = useContext(SearchPageDataInterface);

	const query = useSearchQuery();
	const width = useMediaQuery();
	const isLaptopOrLower = width < 1080 ? true : false;

	return (
		<div
			className={
				!showSearchBar
					? "home__navbar__search__container"
					: " home__navbar__search__container home__navbar__search__container__hide"
			}
			onClick={!isLaptopOrLower ? openSearchBar : undefined}
		>
			<div className="navbar__search">
				<div className="search__container">
					<div className="left">
						<div
							className="search__input add__guest__text guest__text__active"
							onClick={
								isLaptopOrLower ? () => setShowLocationPopup(true) : undefined
							}
						>
							{(query.get("location") ||
							window.location.pathname?.split("/")[2]?.split("-")[2] !== "me"
								? capitalizeFirstLetter(
										parseHTML(
											window.location.pathname.split("/")[1] === "villas" &&
												window.location?.pathname
													?.split("/")[2]
													?.split("-")[2]
													?.replace(/%20/g, " "),
										),
								  )
								: "Homes Near me") || ""}
						</div>
						<div
							className="search__date"
							onClick={
								isLaptopOrLower ? () => setShowCalendarPopup(true) : undefined
							}
						>
							<div className="search__checkin__checkout">
								{query.get("checkin") && query.get("checkout") ? (
									<p className="add__guest__text guest__text__active">
										{query.get("checkin")
											? /* @ts-ignore */
											  new Date(query.get("checkin")).getDate()
											: ""}
										{"  "} -{"  "}
										{query.get("checkout")
											? moment(query.get("checkout")).format("D MMM")
											: ""}
									</p>
								) : (
									<p className="add__guest__text ">Add date</p>
								)}
							</div>
						</div>
						<div
							className="search__guest"
							onClick={
								isLaptopOrLower ? () => setShowGuestPopup(true) : undefined
							}
						>
							<span className={"add__guest__text guest__text__active"}>
								{`${query.get("rooms") ? query.get("rooms") : "1"} rooms,
								${query.get("guests") ? query.get("guests") : "1"} guests `}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
