import React from "react";

export const SvgContactUsPress = () => {
	return (
		<svg
			width="15"
			height="14"
			viewBox="0 0 15 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.874 0.613281L5.13194 4.1679H3.66334C2.0946 4.1679 0.80957 5.45293 0.80957 7.02166C0.80957 8.32335 1.69405 9.40803 2.87895 9.75855V9.77519V11.1269C2.87895 11.394 3.06253 11.4941 3.54643 11.5107H4.09714C4.48094 11.4941 4.66452 11.3938 4.68129 11.177C4.69794 10.9934 4.68129 10.7932 4.68129 10.6096V9.85867H5.13191L11.874 13.4133C13.4928 13.4133 14.8111 10.5429 14.8111 7.00496C14.8111 3.46674 13.4928 0.613329 11.874 0.613329L11.874 0.613281ZM11.6738 11.9447C10.4556 11.9447 9.47088 9.74174 9.47088 7.0216C9.47088 4.30137 10.4555 2.09854 11.6738 2.09854C12.892 2.09854 13.8767 4.30146 13.8767 7.0216C13.8766 9.74183 12.892 11.9447 11.6738 11.9447Z"
				fill="#00306B"
			/>
		</svg>
	);
};
