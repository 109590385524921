import { useContext, useState } from "react";

// assets
import { SvgCross } from "assets/icons/js/SvgCross";
import { SvgSearch } from "assets/icons/js/SvgSearch";
import instagram from "assets/icons/Instagram.svg";
import Twitter from "assets/icons/Twitter.svg";
import Facebook from "assets/icons/Facebook.svg";
import LinkedIn from "assets/icons/linkedin.svg";
import Youtube from "assets/icons/Youtube.svg";
import Others from "assets/icons/user.svg";

// comp
import DropDownWithImg from "pages/registerForm/DropdownImg";
import NewFlowRangeSilder from "./NewFlowRangeSlider";
import { AutoComplete } from "../AutoComplete";

// context
import { RegisterFormDataInterface } from "context/RegisterForm";

// style
import "./style.scss";

const dropDownOptions = [
	{
		label: "Instagram",
		image: instagram,
	},
	{
		label: "Facebook",
		image: Facebook,
	},
	{
		label: "Twitter",
		image: Twitter,
	},
	{
		label: "LinkedIn",
		image: LinkedIn,
	},
	{
		label: "Youtube",
		image: Youtube,
	},
	{
		label: "From someone you know",
		image: Others,
	},
];

export const OtherDetails = () => {
	const {
		// @ts-ignore
		particularVilla,
		// @ts-ignore
		setParticularVilla,
		// @ts-ignore
		selectBudget,
		// @ts-ignore
		setSelectBudget,
		// @ts-ignore
		particularVillasList,
		// @ts-ignore
		setParticularVillasList,
		// @ts-ignore
		hearAbout,
		// @ts-ignore
		setHearAbout,
		// @ts-ignore
		request,
		// @ts-ignore
		setRequest,
	} = useContext(RegisterFormDataInterface);

	const [selected, setSelected] = useState(["Select"]);
	const [showAuto, setShowAuto] = useState(false);

	return (
		<div className="other__details__container">
			<div className="top">
				<div className="search__villa">
					{/* <label htmlFor=""></label> */}
					<div className="span">
						<SvgSearch />
						<input
							type="text"
							placeholder="Do you have any particular villa in mind?"
							value={particularVilla}
							onChange={(e) => setParticularVilla(e.target.value)}
							onClick={() => setShowAuto(true)}
						/>
						{showAuto && particularVilla.length > 0 && (
							<AutoComplete
								query={particularVilla}
								type="listing"
								list={(val: any) =>
									setParticularVillasList((prev: any) => [
										...prev.filter((v: any) => v.id !== val.id),
										val,
									])
								}
								close={() => setShowAuto(false)}
								setQuery={setParticularVilla}
							/>
						)}
					</div>
					<div className="small__box__container">
						{particularVillasList.map((v: any, i: number) => {
							return (
								<div
									className="small__box"
									key={i}
									onClick={() => {
										setParticularVillasList((prev: any) => [
											...prev.filter((j: any) => j.id !== v.id),
										]);
										setParticularVilla("");
									}}
								>
									{v.title}
									<SvgCross />
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="bottom">
				<div className="range__filter">
					<h3>Select your total budget</h3>
					<NewFlowRangeSilder
						min={selectBudget?.min === 0 ? 0 : selectBudget?.min}
						max={selectBudget?.max === 0 ? 0 : selectBudget?.max}
						defaultMin={0}
						defaultMax={249999}
						onChange={({ min, max }: any) =>
							setSelectBudget({
								// @ts-ignore
								min: min,
								max: max,
							})
						}
					/>
				</div>
			</div>
			<div className="message__container">
				<div className="request">
					<label htmlFor="">Any additional requests?</label>
					<textarea
						name=""
						id=""
						onChange={(e) => setRequest(e.target.value)}
						value={request}
					></textarea>
				</div>
			</div>
		</div>
	);
};
