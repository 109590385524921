import React from "react";

export const SvgDownArrow = (props) => {
	return (
		<svg
			width="8"
			height="5"
			viewBox="0 0 8 5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3.99998 4.77995C3.8566 4.77995 3.71324 4.72521 3.60393 4.61595L0.164113 1.17609C-0.0547044 0.957277 -0.0547044 0.602502 0.164113 0.383773C0.382842 0.165044 0.737546 0.165044 0.956381 0.383773L3.99998 3.42755L7.04359 0.38388C7.26241 0.165151 7.61708 0.165151 7.83579 0.38388C8.05472 0.602608 8.05472 0.957383 7.83579 1.1762L4.39602 4.61606C4.28666 4.72533 4.1433 4.77995 3.99998 4.77995Z"
				fill={props.color ? props.color : "white"}
			/>
		</svg>
	);
};
