import React from "react";

export const SvgDustbin = () => {
	return (
		<svg
			width="16"
			height="21"
			viewBox="0 0 16 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.30614 16.7873H6.31859C6.63835 16.7805 6.89246 16.5159 6.88551 16.1958L6.65369 5.12177C6.64723 4.80589 6.38906 4.55451 6.07481 4.55451H6.06236C5.7426 4.5613 5.48849 4.82594 5.49544 5.14602L5.72726 16.2201C5.73405 16.5359 5.99189 16.7873 6.30614 16.7873ZM9.6956 16.7873C10.0099 16.7873 10.268 16.5359 10.2745 16.2201L10.5063 5.14602C10.5131 4.82626 10.2593 4.5613 9.93938 4.55451H9.92693C9.61267 4.55451 9.3545 4.80589 9.34805 5.12177L9.11623 16.1958C9.10944 16.5156 9.36324 16.7805 9.68315 16.7873H9.6956ZM14.2541 4.55534C13.9371 4.54516 13.6661 4.79411 13.6545 5.11386L13.1905 18.145C13.1905 18.5378 12.8716 18.8571 12.4797 18.8571H3.52292C3.13026 18.8571 2.81082 18.5376 2.81033 18.1243L2.34686 5.11386C2.33555 4.7941 2.06526 4.54611 1.74729 4.55534C1.42753 4.56666 1.17743 4.83533 1.18893 5.15492L1.65194 18.145C1.65194 19.1768 2.49125 20.0159 3.5228 20.0159H12.4796C13.5105 20.0159 14.3492 19.1766 14.3487 18.1656L14.8121 5.15517C14.8239 4.83573 14.5739 4.56722 14.2541 4.55575L14.2541 4.55534ZM15.4209 2.23782H11.89V0.579381C11.89 0.259298 11.6307 0 11.3106 0H4.69161C4.37153 0 4.11223 0.259298 4.11223 0.579381V2.23782H0.57938C0.259298 2.23782 0 2.49711 0 2.8172C0 3.13728 0.259298 3.39658 0.57938 3.39658H15.4206C15.7407 3.39658 16 3.13728 16 2.8172C16 2.49711 15.7404 2.23782 15.4206 2.23782H15.4209ZM10.7312 2.23782H5.271V1.15876H10.7312V2.23782Z"
				fill="#666666"
			/>
		</svg>
	);
};
