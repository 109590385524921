import { Link } from "react-router-dom";

// assets
import { SvgBooking } from "assets/icons/js/SvgBooking";
import { SvgHelpAndSupport } from "assets/icons/js/SvgHelpAndSupport";
import { SvgHomeIconDark } from "assets/icons/js/SvgHomeIconDark";
import { SvgLogout } from "assets/icons/js/SvgLogout";
import { SvgProfile } from "assets/icons/js/SvgProfile";
import { SvgReward } from "assets/icons/js/SvgReward";
import { SvgWishlist } from "assets/icons/js/SvgWishlist";
import { SVGContactUs } from "assets/icons/js/SVGContactUs";

// style
import "./style.scss";

export const NavbarDropdown = ({ currentUser, handleLogout }: any) => {
	return (
		<div className="user__dropdown-box">
			<Link aria-label="profile" to="/dashboard" className="dropdown-link">
				<SvgProfile />
				<p>Profile</p>
			</Link>
			<Link aria-label="bookings" to="/my-booking" className="dropdown-link">
				<SvgBooking />
				<p>My Bookings</p>
			</Link>
			{currentUser.isHomeowner && (
				<a
					aria-label="MyHome"
					href={`https://host.saffronstays.com/account/dashboard?jwt=${localStorage.getItem(
						"token",
					)}`}
					className="dropdown-link"
					target="_blank"
					rel="noreferrer"
				>
					<SvgHomeIconDark />
					<p>My homes</p>
				</a>
			)}
			<Link aria-label="rewards" to="/dashboard" className="dropdown-link">
				<SvgReward />
				<p>Rewards</p>
			</Link>
			<Link aria-label="wishlist" to="/collection" className="dropdown-link">
				<SvgWishlist className="svg-wishlist" />
				<p>Wishlist</p>
			</Link>
			<Link aria-label="contact-us" to="/contact-us" className="dropdown-link">
				{/* <SvgWishlist className="svg-wishlist" /> */}
				<SVGContactUs />
				<p>Contact Us</p>
			</Link>
			<Link
				aria-label="help-support"
				to="/help-and-policies"
				className="dropdown-link"
			>
				<SvgHelpAndSupport />
				<p>Help & Support</p>
			</Link>
			<hr />
			<button
				aria-label="Logout"
				type="button"
				className="btn-logout"
				onClick={handleLogout}
			>
				<SvgLogout className="svg-logout" />
				<p> Log out</p>
			</button>
		</div>
	);
};
