import { Content } from "./Content";
import { FormHeader } from "./FormHeader";

// @ts-ignore
import { Route, Routes } from "react-router-dom";

// style
import "./style.scss";
import { WantToBook } from "./NewFormFlows/WantToBook";
import ExistingBooking from "./NewFormFlows/ExistingBooking";
import { NavbarAndSidebar } from "components/NavbarAndSidebar";
import { ListHome } from "./NewFormFlows/ListHome";
import { Other } from "./NewFormFlows/Other";
import { TravelAgent } from "./NewFormFlows/TravelAgent";

export const NewForm = () => {
	return (
		<div className="new__form__container">
			<NavbarAndSidebar />
			<Routes>
				<Route path="/" element={<Content />} />
				<Route element={<WantToBook />} path="/i-want-to-book" />

				<Route element={<ListHome />} path="/i-want-to-list-a-home" />
				<Route element={<Other />} path="/other-issue" />
				<Route element={<TravelAgent />} path="/i-am-a-travel-agent/*" />
			</Routes>
		</div>
	);
};
