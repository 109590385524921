import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import clevertap from "clevertap-web-sdk";
import FacebookLoginWrapper from "../FacebookLoginWrapper";
import GoogleLoginWrapper from "../GoogleLoginWrapper";
import { Input } from "../Input";
import { PrimaryButton } from "../PrimaryButton";
import { RegisterHeader } from "../RegisterHeader";
import { RememberMe } from "../RememberMe";
import { TermsAndConditions } from "../TermsAndConditions";

// context
import { UserContext } from "store";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

//helpers
import { axiosInstance } from "helpers/axiosInstance";
import getUserData from "helpers/getUserDataAfterLogin";
import validateEmail from "helpers/validateEmail";

// assets
import { SvgFacebook as Facebook } from "assets/icons/js/SvgFacebook";
import { SvgGoogle as Google } from "assets/icons/js/SvgGoogle";
import { SvgHide as Hide } from "assets/icons/js/SvgHide";
import { SvgPhone as Phone } from "assets/icons/js/SvgPhone";
import { ReactComponent as Loader } from "assets/spinning_wheel.svg";

// scss
import "./style.scss";

export const Signup = ({
	setPhone,
	setLoading,
	setForgetPassword,
	showReferralCodeScreen,
	redirect,
}) => {
	const [hidePassword, setHidePassword] = useState(true);
	const [values, setValues] = useState({
		email: "",
		password: "",
		fullname: "",
	});
	const [remember, setRemember] = useState(false);
	const [loadingAnimation, setLoadingAnimation] = useState(false);
	const [inputErrorName, setInputErrorName] = useState(false);
	const [inputErrorEmail, setInputErrorEmail] = useState(false);
	const [inputErrorPassword, setInputErrorPassword] = useState(false);
	const errorMessage = useRef("");

	const width = useMediaQuery();
	const navigate = useNavigate();

	const isMobile = width <= "500" ? true : false;

	const { fullname, email, password } = values;

	const { dispatch } = useContext(UserContext);

	const handleClick = () => {
		setPhone(true);
	};

	const handleChange = (e) => {
		errorMessage.current = "";
		setInputErrorName(false);
		setInputErrorEmail(false);
		setInputErrorPassword(false);
		setValues({
			...values,
			[e.target.name]: e.target.value,
		});
	};

	const handleCheck = (event) => {
		setRemember(event.target.checked);
	};

	const validateSignup = () => {
		let flag = true;
		if (!validateEmail(email)) {
			setInputErrorEmail(true);
			flag = false;
		}
		if (password.length < 8) {
			setInputErrorPassword(true);
			flag = false;
		}
		if (fullname.length < 1) {
			setInputErrorName(true);
			flag = false;
		}
		return flag;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validateSignup()) return;
		setLoadingAnimation(true);

		const requestOptions = {
			method: "POST",
			url: "/signup",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				EMAIL_ID: email,
				PASSWORD: password,
				FIRST_NAME: fullname.split(" ")[0],
				LAST_NAME: fullname.split(" ")[1] || "",
			},
		};

		axiosInstance(requestOptions)
			.then((res) => {
				if (res.data.success) {
					clevertap.onUserLogin.push({
						Site: {
							Name: res.data.data.profile.LAST_NAME
								? `${res.data.data.profile.FIRST_NAME} ${res.data.data.profile.LAST_NAME}`
								: `${res.data.data.profile.FIRST_NAME}`,
							Identity: res.data.data.profile.UUID,
							Email: res.data.data.profile.EMAIL_ID
								? res.data.data.profile.EMAIL_ID
								: "",
							Phone: res.data.data.profile.MOBILE_NUMBER
								? `+91${res.data.data.profile.MOBILE_NUMBER}`
								: "",
							DOB: res.data.data.profile.DATE_OF_BIRTH
								? new Date(res.data.data.profile.DATE_OF_BIRTH)
								: "",

							"MSG-email": true,
							"MSG-push": true,
							"MSG-sms": true,
							"MSG-whatsapp": true,
						},
					});
					clevertap.event.push("user_signup", {
						Name: res.data.data.profile.LAST_NAME
							? `${res.data.data.profile.FIRST_NAME} ${res.data.data.profile.LAST_NAME}`
							: `${res.data.data.profile.FIRST_NAME}`,
						Email: res.data.data.profile.EMAIL_ID
							? res.data.data.profile.EMAIL_ID
							: "",
						Identity: res.data.data.profile.UUID,
						Phone: res.data.data.profile.MOBILE_NUMBER
							? `+91${res.data.data.profile.MOBILE_NUMBER}`
							: "",
						RewardTier: res.data.data.profile.REWARDS_TIER
							? res.data.data.profile.REWARDS_TIER
							: "BLUE",
						RefferalCode: res.data.data.profile.REF_CODE
							? res.data.data.profile.REF_CODE
							: "",
						timestamp: new Date(),
					});
					localStorage.setItem("token", res.data.data.token);
					localStorage.setItem("allow", "true");
					// show referal code screen
					showReferralCodeScreen();
					// getUserData(dispatch);
					errorMessage.current = "";
					setLoadingAnimation(false);
				}
			})
			.catch((error) => {
				setLoadingAnimation(false);
				if (error.response) {
					errorMessage.current = error.response.data?.error;
				} else if (error.request) {
					errorMessage.current = error.request;
				} else {
					errorMessage.current = "Something went wrong";
				}
			});
	};

	return (
		<div className="signup__wrapper--page">
			<div className="signup__wrapper--page-2">
				<RegisterHeader title={"to SaffronStays"} subtitle={"sign you up"} />
				<div className="signup__space"></div>
				<div className="signup__form">
					<form onSubmit={(e) => handleSubmit(e)}>
						<Input
							id="full-name"
							type={"text"}
							name={"fullname"}
							placeholder={"Full Name"}
							value={fullname}
							onChange={handleChange}
							error={inputErrorName}
						/>
						<div className={!inputErrorName && "signup__space"}></div>
						{inputErrorName && (
							<p style={{ color: "red", fontSize: "12px", margin: "6px 0" }}>
								Please enter a valid input
							</p>
						)}
						<Input
							id="email"
							type={"text"}
							name={"email"}
							placeholder={"Email"}
							value={email}
							onChange={handleChange}
							error={inputErrorEmail}
						/>
						<div className={!inputErrorEmail && "signup__space"}></div>
						{inputErrorEmail && (
							<p style={{ color: "red", fontSize: "12px", margin: "6px 0" }}>
								Please enter a valid email
							</p>
						)}
						<div className="signup__input-hide">
							<Input
								id="password"
								type={hidePassword ? "password" : "text"}
								name={"password"}
								placeholder={"Password"}
								value={password}
								onChange={handleChange}
								error={inputErrorPassword}
							/>
							<span
								className="signup__svg-hide"
								onClick={() => setHidePassword(!hidePassword)}
							>
								<Hide />
							</span>
						</div>
						<div className={!inputErrorPassword && "signup__space"}></div>

						<p style={{ color: "red", fontSize: "12px", margin: "6px 0" }}>
							{errorMessage.current}
						</p>

						<RememberMe
							setForgetPassword={setForgetPassword}
							handleCheck={handleCheck}
							remember={remember}
						/>
						<div>
							<PrimaryButton
								type="submit"
								value={
									loadingAnimation ? (
										<div
											style={{
												display: "flex",
												justifyContent: "center",
											}}
										>
											<Loader
												style={{
													width: "30px",
													height: "30px",
												}}
											/>
										</div>
									) : (
										"Sign Up"
									)
								}
							/>
						</div>
					</form>
					<div className="signup__line">
						<hr />
						<span>OR</span>
						<hr />
					</div>
					<div className="signup__different-login">
						<GoogleLoginWrapper rememberMe={remember}>
							<span className="google icon">
								<Google />
								<p>Google</p>
							</span>
						</GoogleLoginWrapper>
						<FacebookLoginWrapper>
							<span className="facebook icon">
								<Facebook />
								<p>Facebook</p>
							</span>
						</FacebookLoginWrapper>
						<span className="phone icon" onClick={handleClick}>
							<Phone />
							<p>Phone</p>
						</span>
					</div>
					<div className="signup__space"></div>
					<TermsAndConditions />
				</div>
			</div>
		</div>
	);
};
