import { LeftContact } from "../LeftContact";

// assets
import { SvgContactUsEmail } from "assets/icons/contactUs/SvgContactUsEmail";
import { SvgContactUsFaqs } from "assets/icons/contactUs/SvgContactUsFaqs";
import { SvgContactUsPress } from "assets/icons/contactUs/SvgContactUsPress";
import { SvgLocation } from "assets/icons/js/SvgLocation";
import { SvgFooterFacebook } from "assets/icons/js/SvgFooterFacebook";
import { SvgFooterInsta } from "assets/icons/js/SvgFooterInsta";
import { SvgFooterTwitter } from "assets/icons/js/SvgFooterTwitter";
import { SvgFooterYoutube } from "assets/icons/js/SvgFooterYoutube";

// style
import "./style.scss";
import { useMediaQuery } from "customHooks/useMediaQuery";

const data = [
	{
		icon: <SvgContactUsEmail />,
		title: "Write to us",
		subtitle: (
			<p>
				You can email us at{" "}
				<a href="mailto:book@saffronstays.com">book@saffronstays.com</a>.
			</p>
		),
	},
	{
		icon: <SvgContactUsPress />,
		title: "Press & Partnership enquiries",
		subtitle: (
			<p>
				If you are a member of the press, please contact us at
				<a href="mailto:marcomm@saffronstays.com">
					marcomm@saffronstays.com
				</a>{" "}
				and we will be happy to provide you with assets for press coverage.
			</p>
		),
	},
	{
		icon: <SvgContactUsFaqs />,
		title: "FAQs",
		subtitle: (
			<p>
				We've answered the most common questions on our
				<a href="/privacy-policy">Policy Page</a>, so you can find answers
				faster.
			</p>
		),
	},
	{
		icon: <SvgLocation />,
		title: "Registered address",
		subtitle: (
			<p>B-19, Shriram Industrial Estate, Wadala West, Mumbai -400031</p>
		),
	},

	{
		// icon: <SvgContactUsFaqs />,
		title: "Grievance officer",
		subtitle: (
			<div style={{ display: "flex", flexDirection: "column" }}>
				<p>
					Email -
					<a href="mailto:support@saffronstays.com">support@saffronstays.com</a>{" "}
				</p>
				<p>Phone - 08069160000</p>
			</div>
		),
	},
];

export const BottomContainer = ({ children }: any) => {
	const isFirstPage =
		window.location.pathname.split("/").filter(Boolean).length <= 1
			? true
			: false;

	const width = useMediaQuery();

	const isMobile = width < 768 ? true : false;

	return (
		<div className="bottom__container__wrapper">
			<div className="bottom__content__container">
				{isFirstPage ? (
					<div className="left__bottom__content">
						<LeftContact />
					</div>
				) : null}
				<div
					className={
						isFirstPage
							? "right__bottom__content"
							: "right__bottom__content right__center"
					}
				>
					{children}
				</div>
			</div>

			{isMobile && (
				<div className="bottom__card__wrapper">
					<div className="bottom__card__container">
						{data.map((v) => {
							return (
								<>
									<div className="bottom__card">
										<div className="top__heading">
											{v.icon && (
												<div className="icon__container">{v.icon}</div>
											)}
											<h4>{v.title}</h4>
										</div>
										<div className="bottom__subtitle">{v.subtitle}</div>
									</div>
								</>
							);
						})}
					</div>
					<div className="social__icons">
						<span
							onClick={() =>
								window.open("https://www.instagram.com/saffronstays/?hl=en")
							}
						>
							<SvgFooterInsta />
						</span>
						<span
							onClick={() =>
								window.open("https://www.facebook.com/saffronstays/")
							}
						>
							<SvgFooterFacebook />
						</span>
						<span
							onClick={() =>
								window.open("https://www.youtube.com/saffronstays")
							}
						>
							<SvgFooterYoutube />
						</span>
						<span
							onClick={() => window.open("https://twitter.com/SaffronStays")}
						>
							<SvgFooterTwitter />
						</span>
					</div>
					<h4 style={{ color: "#fff" }}>Saffron Studios Pvt Ltd </h4>
				</div>
			)}
		</div>
	);
};
