import { useContext, useEffect, useState } from "react";
import { RegisterFormDataInterface } from "context/RegisterForm";

// style
import "./style.scss";

export const BottomBar = ({
	currentStep,
	setStep,
	callback,
	setTab,
	data,
	tab,
}: any) => {
	// @ts-ignore
	const { registerFormDataMutation, details } = useContext(
		RegisterFormDataInterface,
	);

	const [tabNumber, setTabNumber] = useState(0);

	useEffect(() => {
		if (tabNumber >= 0 && tabNumber < data?.length) {
			setTab(data[tabNumber]?.split(" ")[0]?.toLowerCase());
		}
	}, [tabNumber]);

	return (
		<div className="review__bottom__bar__container__old">
			<div className="review__bottom__container">
				{currentStep === 0 ? (
					<>
						<div className="buttons__1">
							<span></span>
							<span></span>
							<button
								className="btn__continue"
								onClick={() => {
									registerFormDataMutation.mutate();
									window.scrollTo({
										top: 0,
										behavior: "smooth",
									});
									if (!callback) {
										setStep((prev: number) => (prev += 1));
									}
									if (callback) {
										callback();
									}
								}}
								disabled={details.firstname && details.phone ? false : true}
							>
								Save & Continue
							</button>
						</div>
					</>
				) : (
					<>
						<div className="buttons">
							{currentStep !== 2 && (
								<button
									className="btn__prev"
									onClick={() => {
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
										if (currentStep === 1) {
											if (tabNumber === 0) {
												setStep((prev: number) => (prev -= 1));
											} else {
												setTabNumber((prev) => (prev -= 1));
											}
										} else {
											setStep((prev: number) => (prev -= 1));
										}
									}}
								>
									Back
								</button>
							)}
							<div className="right">
								{/* {currentStep > 0 && currentStep <= 1 && tabNumber < 2 ? (
									<button
										className="btn__skip"
										onClick={() => {
											window.scrollTo({
												top: 0,
												behavior: "smooth",
											});
											if (currentStep === 1) {
												if (tabNumber === 2) {
												} else {
													setTabNumber((prev) => (prev += 1));
												}
											} else {
												setStep((prev: number) => (prev += 1));
											}
										}}
									>
										Skip
									</button>
								) : null} */}
								<button
									className="btn__continue"
									onClick={() => {
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
										if (!callback) {
											registerFormDataMutation.mutate();
											if (currentStep === 1) {
												if (tabNumber === 2) {
													if (registerFormDataMutation.isSuccess === true) {
														setStep((prev: number) => (prev += 1));
													}
												} else {
													setTabNumber((prev) => (prev += 1));
												}
											} else if (currentStep === 2) {
												window.location.href = "/";
											} else {
												setStep((prev: number) => (prev += 1));
											}
										}
										if (callback) {
											callback();
										}
									}}
								>
									{tabNumber === 2 && currentStep === 2 ? "Done" : ""}
									{tabNumber === 2 && currentStep < 2 ? "Submit" : ""}
									{currentStep <= 2 && tabNumber !== 2 ? "Save & Continue" : ""}
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
