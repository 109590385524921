export const filtersMore = [
	{
		label: "Water front",
		apiValue: 14,
		selected: false,
		type: "notVal",
	},
	{
		label: "Beach front",
		apiValue: 12,
		selected: false,
		type: "notVal",
	},

	{
		label: "Hill station",
		apiValue: 17,
		selected: false,
		type: "notVal",
	},

	{
		label: "Farm stay",
		apiValue: 15,
		selected: false,
		type: "notVal",
	},
	{
		label: "Plantation estates",
		apiValue: 21,
		selected: false,
		type: "notVal",
	},
	{
		label: "Disconnect in nature",
		apiValue: 56,
		selected: false,
		type: "notVal",
	},
	{
		label: "Old world experience",
		apiValue: 42,
		selected: false,
		type: "notVal",
	},
	{
		label: "Girls' getaway",
		apiValue: 63,
		selected: false,
		type: "notVal",
	},
	{
		label: "Road trips",
		apiValue: 61,
		selected: false,
		type: "notVal",
	},
	{
		label: "WiFi",
		apiValue: 68,
		selected: false,
		type: "notVal",
	},
	{
		label: "Elderly friendly",
		apiValue: 31,
		selected: false,
		type: "notVal",
	},
];
