import { useState } from "react";

// components
import { TripDetails } from "./TripDetails";
import { GuestDetails } from "./GuestDetails";
import { OtherDetails } from "./OtherDetails";

// style
import "./style.scss";

export const PersonalStep2 = ({ setTab, tab, data }: any) => {
	const comps: any = {
		other: <GuestDetails />,
		trip: <TripDetails />,
		guest: <OtherDetails />,
	};

	return (
		<div className="personal__step__two__container">
			<h1>
				{tab === "trip"
					? "Guest details"
					: tab === "guest"
					? "Trip details"
					: "Other details"}
			</h1>
			{/* <div className="steps">
				{data.map((v: string, i: number) => {
					return (
						<div
							className={`tabs ${
								tab === v.split(" ")[0].toLowerCase() ? "tabs__active" : ""
							} `}
							key={i}
							// onClick={() => setTab(v.split(" ")[0].toLowerCase())}
						>
							{v}
						</div>
					);
				})}
			</div> */}
			<div className="bottom__steps__container">
				{comps[tab?.split(" ")[0].toLowerCase()]}
			</div>
		</div>
	);
};
