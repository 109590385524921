/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./style.scss";
import { ReactComponent as LeftArrow } from "../../../UniversalUrlNew/UniUrlAssets/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../UniversalUrlNew/UniUrlAssets/rightArrow.svg";
import Modal from "components/LoginAndRegister/Modal";

import axios from "axios";

import PhoneLoginHandler from "components/LoginAndRegister/PhoneLoginHandler";
import EmailLoginHandler from "components/LoginAndRegister/EmailLoginHandler";
import { ToastContainer, toast } from "react-toastify";
import AddOnsLoader from "components/UniversalUrlNew/LeftSide/AddOns/AddOnsLoader";
import { ReactComponent as Info } from "../../../UniversalUrlNew/UniUrlAssets/info.svg";
import BookingExperienceCalc from "../BookingExperienceCalc";
type Props = {
	id: number;
	onClose: () => void;
	bookingId: string;
	refreshData: () => void;
	allAddons: any;
};
interface SingleAddon {
	ADD_ON_ID: number;
	ADD_ON_NAME: string;
	PRICE: number;
	GST_SLAB: number;
	TYPE: string;
	DESCRIPTION: string;
	POINTERS: string;
	IMAGE_URL: string;
	PAYMENT_TYPE: string;
	CREATED_ON: string;
	CREATED_BY: string;
	MODIFIED_ON?: string;
	MODIFIED_BY?: string;
	LISTING_ID: string;
	MAP_ID: number;
	ADD_ON_STATUS: number;
	IS_VISIBLE: number;
	CATEGORY: string;
}
const BookingExperienceDetails = ({
	id,
	onClose,
	bookingId,
	refreshData,
	allAddons,
}: Props) => {
	const addonTypes = [
		{
			type: "PP",
			label: "Per Person",
		},
		{
			type: "PPPN",
			label: "Per Person Per Night",
		},
		{
			type: "OT",
			label: "One Time",
		},
		{
			type: "PN",
			label: "Per Night",
		},
	];
	const [singleAddon, setSingleAddon] = useState({});
	const [slide, setSlide] = useState(id);
	const [isAddonCharges, setIsAddonCharges] = useState(false);
	const [shouldShowLogin, setShouldShowLogin] = useState(false);
	const [phone, setPhone] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const nextSlide = () => {
		setSlide((prev) => (prev === allAddons?.length - 1 ? 0 : prev + 1));
	};
	const previousSlide = () => {
		setSlide((prev) => (prev === 0 ? allAddons?.length - 1 : prev - 1));
	};
	const handleRequestAddon = (singleAddon: any) => {
		setSingleAddon(singleAddon);
		setIsAddonCharges(true);
	};

	const handleAddAddon = async (addon: SingleAddon) => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`https://go.saffronstays.com/api/addon-request?bookingId=${bookingId}&mapId=${
					addon?.MAP_ID
				}&quantity=${1}&type=${addon?.TYPE}&nights=${1}&isBookingPage=${true}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
					},
				},
			);
			if (res?.data.success) {
				toast.success(`${addon?.ADD_ON_NAME} has been requested`, {
					position: window.innerWidth < 800 ? "top-right" : "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					icon: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
				setIsLoading(false);
				onClose();
			}
		} catch (error: any) {
			console.error(error?.message);
		}
	};
	return (
		<>
			<ToastContainer />
			<div className="addon-details-wrapper">
				<div className="addon-details-content">
					<div className="adddon-gallery-slide">
						<span
							onClick={() => {
								previousSlide();
							}}
							className="gallery-slide-left"
						>
							<LeftArrow />
						</span>
						<span
							onClick={() => {
								nextSlide();
							}}
							className="gallery-slide-right"
						>
							<RightArrow />
						</span>
					</div>
					<div className="addon-gallery-details">
						{allAddons?.map((ele: SingleAddon, index: number) => {
							return (
								<div
									className={
										slide === index
											? "gallery-slide-img"
											: "gallery-slide-img gallery-slide-img-hidden"
									}
								>
									<img
										style={{
											height: "200px",
										}}
										src={
											"https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:q-90/" +
											ele.IMAGE_URL
										}
										alt=""
									/>
									<div className="addon-gallery-price">
										<p>{ele.ADD_ON_NAME}</p>
										<p>
											₹ {ele.PRICE} /{" "}
											<strong>
												{
													addonTypes.find(
														(addonType) => addonType.type === ele?.TYPE,
													)?.label
												}
											</strong>
										</p>
									</div>
									<div className="addon-gallery-description">
										<p>{ele.DESCRIPTION}</p>
									</div>
									<div className="addon-gallery-notes">
										{ele?.PAYMENT_TYPE === "Prepaid online" ? (
											<p>
												<Info />
												You can avail the experience by conveniently paying the
												fees online.
											</p>
										) : (
											<p>
												{" "}
												<Info /> You can request for the experience now, our
												team will get in touch with you after booking
												confirmation.
											</p>
										)}
									</div>
									<div className="addon-galler-btn-rack">
										<button onClick={onClose}>Cancel</button>
										<button
											disabled={isLoading}
											onClick={() => {
												if (ele?.TYPE === "OT") {
													handleAddAddon(ele);
												} else {
													handleRequestAddon(ele);
												}
											}}
										>
											{isLoading ? (
												<AddOnsLoader />
											) : ele?.PAYMENT_TYPE === "Prepaid Online" ? (
												"Avail Now"
											) : (
												"Request Now"
											)}
										</button>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<Modal
				shouldShow={isAddonCharges}
				onRequestClose={() => setIsAddonCharges(false)}
			>
				<BookingExperienceCalc
					data={singleAddon}
					onClose={() => setIsAddonCharges(false)}
					bookingId={bookingId}
					refreshData={refreshData}
					onDetailsClose={() => onClose()}
				/>
			</Modal>
			<Modal
				shouldShow={shouldShowLogin}
				onRequestClose={() => setShouldShowLogin(false)}
			>
				{phone ? (
					<PhoneLoginHandler
						onRequestClose={() => setShouldShowLogin(false)}
						setPhone={setPhone}
					/>
				) : (
					<EmailLoginHandler
						onRequestClose={() => setShouldShowLogin(false)}
						setPhone={setPhone}
						phone={phone}
					/>
				)}
			</Modal>
		</>
	);
};

export default BookingExperienceDetails;
