import { useCallback, useEffect, useState, useRef } from "react";

// helpers
import "./styles.scss";

const NewFlowRangeSilder = ({
	min,
	max,
	onChange,
	defaultMin,
	defaultMax,
}: any) => {
	const [minVal, setMinVal] = useState(min);
	const [maxVal, setMaxVal] = useState(max);
	const minValRef = useRef(min);
	const maxValRef = useRef(max);
	const range = useRef(null);

	// Convert to percentage
	const getPercent = useCallback(
		(value: any) =>
			Math.round(((value - defaultMin) / (defaultMax - defaultMin)) * 100),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[min, max],
	);

	// Set width of the range to decrease from the left side
	useEffect(() => {
		const minPercent = getPercent(minVal);
		const maxPercent = getPercent(maxValRef.current);

		if (range.current) {
			// @ts-ignore
			range.current.style.left = `${minPercent}%`;
			// @ts-ignore
			range.current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [minVal, getPercent]);

	// Set width of the range to decrease from the right side
	useEffect(() => {
		const minPercent = getPercent(minValRef.current);
		const maxPercent = getPercent(maxVal);

		if (range.current) {
			// @ts-ignore
			range.current.style.width = `${maxPercent - minPercent}%`;
		}
	}, [maxVal, getPercent]);

	// Get min and max values when their state changes
	useEffect(() => {
		if (typeof onChange === "function") {
			onChange({ min: minVal, max: maxVal });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minVal, maxVal]);

	return (
		<div className="search__range__slider__container_new">
			<div className="slider__value">
				{/* <div className="slider__left__value">
					<label htmlFor="">min price</label>
					<div className="val">
						₹
						<input
							type="text"
							placeholder="minimum"
							value={minVal ? minVal : ""}
							onChange={(event) => {
								setMinVal(event.target.value);
								minValRef.current = event.target.value;
							}}
							style={{
								outline: "none",
								border: "none",
								paddingLeft: "5px",
							}}
						/>
					</div>
				</div> */}
				<div className="slider__right__value">
					<label htmlFor="">max price</label>
					<div className="val">
						₹
						<input
							type="text"
							placeholder="maximum"
							value={maxVal ? maxVal : ""}
							onChange={(event) => {
								if (event.target.value.length <= 6) {
									setMaxVal(event.target.value);
									maxValRef.current = event.target.value;
								}
							}}
							style={{
								outline: "none",
								border: "none",
								paddingLeft: "5px",
							}}
						/>
					</div>
				</div>
			</div>

			<div className="slider__content">
				<div className="slider__container">
					<div className="slider__track" />
					<div ref={range} className="slider__range" />
					<input
						type="range"
						min={defaultMin}
						max={defaultMax}
						value={minVal}
						onChange={(event) => {
							const value = Math.min(Number(event.target.value), maxVal - 1);
							setMinVal(value);
							minValRef.current = value;
						}}
						className="thumb thumb__zindex-3 "
						// @ts-ignore
						style={{ zIndex: minVal > max - 100 && "5", display: "none" }}
					/>
					<input
						type="range"
						min={defaultMin}
						max={defaultMax}
						value={maxVal}
						onChange={(event) => {
							const value = Math.max(Number(event.target.value), minVal + 1);
							setMaxVal(value);
							maxValRef.current = value;
						}}
						className="thumb thumb__zindex-4"
					/>
				</div>
			</div>
		</div>
	);
};

export default NewFlowRangeSilder;
