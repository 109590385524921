import React from "react";

export const SvgFooterYoutube = (props: any) => {
	return (
		<svg
			width="19"
			height="14"
			viewBox="0 0 19 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.2993 0.136719H3.75326C3.29176 0.136588 2.83475 0.22739 2.40835 0.403938C1.98196 0.580487 1.59452 0.839322 1.26819 1.16565C0.94186 1.49198 0.683026 1.87942 0.506478 2.30581C0.329929 2.73221 0.239127 3.18922 0.239258 3.65072V9.89772C0.239127 10.3592 0.329929 10.8162 0.506478 11.2426C0.683026 11.669 0.94186 12.0565 1.26819 12.3828C1.59452 12.7091 1.98196 12.968 2.40835 13.1445C2.83475 13.321 3.29176 13.4119 3.75326 13.4117H15.2983C15.7598 13.4119 16.2168 13.321 16.6432 13.1445C17.0696 12.968 17.457 12.7091 17.7833 12.3828C18.1097 12.0565 18.3685 11.669 18.545 11.2426C18.7216 10.8162 18.8124 10.3592 18.8123 9.89772V3.65072C18.8124 3.1893 18.7216 2.73238 18.5451 2.30605C18.3686 1.87971 18.1099 1.49232 17.7837 1.16601C17.4575 0.839688 17.0701 0.580834 16.6439 0.404226C16.2176 0.227619 15.7607 0.136719 15.2993 0.136719ZM7.07926 9.62572V3.92572L11.9723 6.77872L7.07926 9.62572Z"
				fill="white"
			/>
		</svg>
	);
};
