export const SvgBTEvent = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 0C5.87816 0 3.84334 0.842669 2.34301 2.34301C0.842669 3.84334 0 5.87817 0 8C0 10.1218 0.842669 12.1567 2.34301 13.6567C3.84334 15.1571 5.87816 16 8 16C10.1218 16 12.1567 15.1571 13.6567 13.6567C15.1571 12.1566 16 10.1218 16 8C16 5.87823 15.1571 3.84334 13.6567 2.34301C12.1566 0.842669 10.1218 0 8 0ZM11.1583 12.3469L8 10.4954L4.84173 12.3466L5.62628 8.77085L2.88957 6.33934L6.53278 5.98067L7.99994 2.62662L9.46683 5.98067L13.11 6.33934L10.3733 8.77085L11.1583 12.3469Z"
				fill="#666666"
			/>
		</svg>
	);
};
