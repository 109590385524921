export const SvgClose = (props) => {
	return (
		<svg
			width="160px"
			height="160px"
			viewBox="0 0 16 16"
			fill={props.color ? "#fff" : "#666666"}
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M15.7762 1.42102L14.6709 0.328908C14.3718 0.0298422 13.8777 0.0298422 13.5657 0.328908L8.05247 5.84199L2.43537 0.224885C2.13617 -0.0743106 1.64206 -0.0743106 1.33013 0.224885L0.224885 1.33013C-0.0743106 1.62919 -0.0743106 2.1233 0.224885 2.43537L5.82911 8.0396L0.328908 13.5658C0.0298422 13.8649 0.0298422 14.359 0.328908 14.671L1.43415 15.7763C1.73321 16.0754 2.22732 16.0754 2.53939 15.7763L8.05247 10.2631L13.5657 15.7763C13.8647 16.0754 14.3588 16.0754 14.6709 15.7763L15.7762 14.671C16.0752 14.372 16.0752 13.8779 15.7762 13.5658L10.2499 8.0526L15.7632 2.53952C16.0752 2.22719 16.0752 1.73309 15.7762 1.42102Z"
				fill={props.color ? "#fff" : "#666666"}
			/>
		</svg>
	);
};
