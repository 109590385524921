import React from "react";
import "./style.scss";

const Toggler = ({ toggle, setToggle, toggle_value }) => {
	return (
		<div className="toggle_button_container ">
			<p>{toggle_value}</p>
			<label className="toggle_button">
				<input
					type="checkbox"
					value="checked"
					name="type"
					onChange={() => setToggle(!toggle)}
				/>
				<span className="toggler"></span>
			</label>
		</div>
	);
};

export default Toggler;
