import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import "./style.scss";
import { UserContext } from "store";
import { ReactComponent as VerifyIcon } from "../assets/verify.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BookingInterface } from "context/BookingData";
import { axiosInstance } from "helpers/axiosInstance";
import OtpInput from "react-otp-input";

import { ReactComponent as TickIcon } from "../assets/tick.svg";
import Toggler from "pages/registerForm/Toggler";

const PersonalDetails = ({ data }: any) => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [gst, setGst] = useState("");
	const [gstName, setGstName] = useState("");
	const [showOtpInput, setShowOtpInput] = useState(false);
	const [otp, setOtp] = useState("");
	const [showGST, setShowGST] = useState(false);
	const [autoGst, setAutoGst] = useState(false);
	const [address, setAddress] = useState("");
	const [gstDetails, setGstDetails] = useState<any>({});
	const {
		state: { currentUser },
	} = useContext(UserContext);

	const { updateBooking }: any = useContext(BookingInterface);
	const slackTrigger = async () => {
		const url =
			"https://hooks.slack.com/services/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";
		const slackData = {
			text: `*${currentUser?.firstName} ${
				currentUser?.lastName
			}* added GST Details 🧾\n
			Booking ID: ${data?.BOOKING_ID}\n
			GST no: ${gstDetails?.taxpayerInfo?.gstin}\n
			GST nm: ${gstDetails?.taxpayerInfo?.lgnm}\n
			State Code: ${gstDetails?.taxpayerInfo?.gstin.substring(0, 2)}\n
			Address: ${
				gstDetails?.taxpayerInfo?.pradr?.addr?.bno +
				"," +
				gstDetails?.taxpayerInfo?.pradr?.addr?.bnm +
				"," +
				gstDetails?.taxpayerInfo?.pradr?.addr?.st +
				"," +
				gstDetails?.taxpayerInfo?.pradr?.addr?.loc
			}\n
			Updated Invoice Link: https://old.saffronstays.com/InvoicePdf/${
				data?.BOOKING_ID
			}-invoice.pdf\n
			Source: Before booking`,
		};
		try {
			await axios.post(url, JSON.stringify(slackData), {
				transformRequest: [
					(data, headers) => {
						return data;
					},
				],
			});
			toast.success(`GST added to your booking`, {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
				closeButton: false,
			});
		} catch (error: any) {
			console.log(error?.message);
		}
	};
	useEffect(() => {
		setFirstName(currentUser.firstName);
		setLastName(currentUser.lastName);
		setEmail(currentUser.email);
		setPhone(currentUser.mobile);
	}, [currentUser]);

	useEffect(() => {
		// if (data?.GST_TO_NAME) {
		// 	setShowGST(true);
		// }
		if (autoGst) {
			setGst(currentUser.gstNumber);
			setGstName(currentUser.gstName);
		} else {
			setGst("");
			setGstName("");
		}
	}, [data, autoGst]);

	useEffect(() => {
		if (showGST) {
			setAutoGst(true);
		}
	}, [showGST]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (gst.length >= 14) {
				verifyGST();
			}
		}, 300);
		return () => clearTimeout(delayDebounceFn);
	}, [gst]);

	const [loadingGst, setLoadingGst] = useState(false);

	// const updateUserGST = async (address: string) => {
	// 	try {
	// 		const res = await axios.post(
	// 			"https://ecapi.saffronstays.com/user/payoutDetails",
	// 			{
	// 				GSTIN_NUMBER: gst,
	// 				GST_TO_NAME: gstName,
	// 				updateGstOnly: true,
	// 				STATE_CODE: address,
	// 			},
	// 			{
	// 				headers: {
	// 					authorization: "Bearer " + localStorage.getItem("token"),
	// 				},
	// 			},
	// 		);
	// 		if (res?.data?.success) {
	// 		}
	// 	} catch (error: any) {
	// 		console.log(error.message);
	// 	}
	// };

	const verifyGST = async () => {
		setLoadingGst(true);
		const res = await axios.post(
			"https://go.saffronstays.com/api/gst-wrapper",
			{
				GstNo: gst,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			},
		);
		setLoadingGst(false);
		if (res?.data?.error) {
			toast.error(`${res?.data?.message}`, {
				position: "top-right",
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				pauseOnHover: false,
				draggable: false,
				progress: undefined,
				closeButton: false,
			});
			return;
		}
		if (res?.data?.success) {
			setGstDetails(res?.data?.message);
			if (res?.data?.message?.taxpayerInfo?.tradeNam) {
				setGstName(res?.data?.message?.taxpayerInfo?.tradeNam);
			} else {
				setGstName(res?.data?.message?.taxpayerInfo?.lgnm);
			}
			setAddress(
				Object.values(res?.data?.message?.taxpayerInfo?.pradr?.addr).join(""),
			);
		}
	};

	// useEffect(() => {
	// 	let debounce: any;
	// 	if (gst) {
	// 		debounce = setTimeout(() => verifyGST(), 1000);
	// 	}
	// 	return () => {
	// 		clearTimeout(debounce);
	// 	};
	// }, [gst]);

	const verifyBookingOtp = () => {
		axiosInstance({
			method: "POST",
			url: "/verify/user/number",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: {
				MOBILE_NUMBER: phone,
				MOBILE_VERIFICATION_CODE: otp,
			},
		})
			.then((res) => {
				if (res.data.success) {
					window.location.reload();
				}
			})
			.catch((err) => {
				toast.error(err?.response?.data?.error, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
				});
			});
	};

	const sendOTP = async () => {
		axiosInstance({
			method: "POST",
			url: "/update/user/profile/ss3",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: {
				MOBILE_NUMBER: phone,
			},
		})
			.then((res) => {
				if (res.data.success) {
					setShowOtpInput(true);
				}
			})
			.catch((err) => {
				toast.error(err?.response?.data?.error, {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
				});
			});
	};

	const handleGst = async () => {
		await updateBooking.mutateAsync({
			GUEST_GST_NO: gst,
			GST_TO_NAME: gstName,
		});
		slackTrigger();
	};

	return (
		<>
			<div className="personal-details-wrapper">
				<ToastContainer />
				<div className="personsal-details-title">Enter your Details</div>
				<div className="personal-row">
					<div className="details-container">
						<span>Full Name</span>
						<b>
							{firstName} {lastName}
						</b>
					</div>
					{/* <InputWrapper
					className=""
					title="First Name"
					value={firstName}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setFirstName(e.target.value);
					}}
					disabled={firstName ? true : false}
				/>
				<InputWrapper
					className=""
					title="Last Name"
					value={lastName}
					onChange={(e: ChangeEvent<HTMLInputElement>) => {
						setLastName(e.target.value);
					}}
					disabled={lastName ? true : false}
				/> */}
				</div>
				{/* <InputWrapper
				className=""
				title="Email"
				value={email}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					setEmail(e.target.value);
				}}
				disabled={true}

			/> */}
				<div className="details-container">
					<span>Email</span>
					<b>{email}</b>
				</div>
				<div className="personal-row">
					{currentUser.mobileVerified ? (
						<div className="details-container">
							<span>Mobile Number</span>
							<b>{phone}</b>
						</div>
					) : (
						<InputWrapper
							className=""
							title="Mobile Number"
							value={phone}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								setPhone(e.target.value);
							}}
							disabled={currentUser.mobileVerified ? true : false}
						/>
					)}

					{showOtpInput ? (
						<></>
					) : currentUser.mobileVerified ? (
						<span className="mob-verified">
							<VerifyIcon /> MOBILE VERIFIED
						</span>
					) : (
						<button
							onClick={() => {
								if (!phone) {
									toast.error(`Enter A Phone Number!`, {
										position: "top-right",
										autoClose: 2000,
										hideProgressBar: true,
										closeOnClick: false,
										pauseOnHover: false,
										draggable: false,
										progress: undefined,
										closeButton: false,
									});
									return;
								}
								sendOTP();
							}}
						>
							Verify Phone
						</button>
					)}
				</div>

				{showOtpInput && (
					<div className="veify-otp-wrapper">
						<p>
							We have sent you a OTP to verify your phone. Please enter the OTP
							provided below.
						</p>
						<div className="otp-wrapper">
							<OtpInput
								value={otp}
								onChange={setOtp}
								numInputs={4}
								containerStyle="otp-box"
								inputStyle="otp-field"
								shouldAutoFocus={true}
								isInputNum
								errorStyle={{ color: "red" }}
							/>
							<button
								onClick={() => {
									if (otp.length !== 4) {
										toast.error(`Enter OTP`, {
											position: "top-right",
											autoClose: 2000,
											hideProgressBar: true,
											closeOnClick: false,
											pauseOnHover: false,
											draggable: false,
											progress: undefined,
											closeButton: false,
										});
										return;
									}
									verifyBookingOtp();
								}}
							>
								Submit
							</button>
						</div>
					</div>
				)}
				<div className="personal-row gst-wrapper" style={{ marginTop: 10 }}>
					{/* <div
					className="box"
					onClick={() => {
						setShowGST(!showGST);
					}}
				>
					{showGST ? <TickIcon /> : ""}
				</div>
				<span>Enter GST Details (optional)</span> */}

					<div className="auto-gst">
						<Toggler
							setToggle={setShowGST}
							toggle={showGST}
							toggle_value={showGST}
						/>
						<span>Add GST (Optional)</span>
					</div>
				</div>
				{showGST && (
					<>
						<div className="personal-row ">
							<InputWrapper
								className=""
								title="GSTN Number (Optional)"
								value={gst}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									setGst(e.target.value);
								}}
								disabled={false}
							/>
							<InputWrapper
								className=""
								title="Name on GST invoice (Optional)"
								value={gstName}
								onChange={(e: ChangeEvent<HTMLInputElement>) => {
									setGstName(e.target.value);
								}}
								disabled={true}
							/>
						</div>
						<div className="personal-row ">
							<button
								className={`gst-submit-btn ${
									loadingGst ? "button__loader" : ""
								}`}
								disabled={loadingGst || !gst}
								onClick={() => {
									handleGst();
								}}
							>
								Save
							</button>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default PersonalDetails;

const InputWrapper = ({
	className,
	title,
	value,
	onChange,
	disabled,
}: {
	className: string;
	title: string;
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	disabled: boolean;
}) => {
	return (
		<div className={`personal-input ${className}`}>
			<span>{title}</span>
			<input value={value} onChange={onChange} disabled={disabled} />
		</div>
	);
};
