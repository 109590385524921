import { useState } from "react";

// components
import EmailLoginHandler from "components/LoginAndRegister/EmailLoginHandler";
import Modal from "components/LoginAndRegister/Modal";
import PhoneLoginHandler from "components/LoginAndRegister/PhoneLoginHandler";

// style
import "./style.scss";

export const HaveQueryLogin = () => {
	const [shouldShowModal, setShouldShowModal] = useState(false);
	const [phone, setPhone] = useState(true);

	const closeModal = () => {
		if (localStorage.getItem("token")) {
			window.location.reload();
		}
		setShouldShowModal(false);
	};
	return (
		<div className="have__query__login__container">
			<h2>Log in with your Registered Travel Agent account</h2>
			<button
				className="btn__continue"
				onClick={() => setShouldShowModal(true)}
			>
				Log in
			</button>
			<Modal shouldShow={shouldShowModal} onRequestClose={closeModal}>
				{phone ? (
					<PhoneLoginHandler
						onRequestClose={closeModal}
						setPhone={setPhone}
						// @ts-ignore
						phone={phone}
					/>
				) : (
					<EmailLoginHandler
						onRequestClose={closeModal}
						setPhone={setPhone}
						phone={phone}
					/>
				)}
			</Modal>
		</div>
	);
};
