export const SvgReward = (props: any) => {
	return (
		<svg
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.333 4.83357H17.4113C17.4741 4.56034 17.5038 4.28055 17.4997 4.00024C17.5351 3.5535 17.4731 3.1044 17.3181 2.68392C17.1631 2.26344 16.9188 1.88159 16.6019 1.56471C16.285 1.24783 15.9031 1.00346 15.4827 0.848456C15.0622 0.693455 14.6131 0.631514 14.1663 0.666903C12.363 0.666903 10.8588 2.5594 9.99968 3.94024C9.14051 2.5594 7.63634 0.666903 5.83301 0.666903C5.38627 0.631514 4.93717 0.693455 4.51669 0.848456C4.09621 1.00346 3.71436 1.24783 3.39748 1.56471C3.0806 1.88159 2.83623 2.26344 2.68123 2.68392C2.52623 3.1044 2.46429 3.5535 2.49967 4.00024C2.49557 4.28055 2.52523 4.56034 2.58801 4.83357H1.66634C1.44533 4.83357 1.23337 4.92137 1.07709 5.07765C0.920805 5.23393 0.833008 5.44589 0.833008 5.6669V9.83357C0.833008 10.0546 0.920805 10.2665 1.07709 10.4228C1.23337 10.5791 1.44533 10.6669 1.66634 10.6669H2.49967V16.5002C2.49967 16.7213 2.58747 16.9332 2.74375 17.0895C2.90003 17.2458 3.11199 17.3336 3.33301 17.3336H16.6663C16.8874 17.3336 17.0993 17.2458 17.2556 17.0895C17.4119 16.9332 17.4997 16.7213 17.4997 16.5002V10.6669H18.333C18.554 10.6669 18.766 10.5791 18.9223 10.4228C19.0785 10.2665 19.1663 10.0546 19.1663 9.83357V5.6669C19.1663 5.44589 19.0785 5.23393 18.9223 5.07765C18.766 4.92137 18.554 4.83357 18.333 4.83357ZM5.83301 2.33357C6.76051 2.33357 7.84884 3.65274 8.58968 4.83357H6.29634C4.16634 4.83357 4.16634 4.50024 4.16634 4.00024C4.16634 2.50607 5.11801 2.33357 5.83301 2.33357ZM9.16634 15.6669H4.16634V10.6669H9.16634V15.6669ZM9.16634 9.00024H2.49967V6.50024H9.16634V9.00024ZM14.1663 2.33357C14.8813 2.33357 15.833 2.50607 15.833 4.00024C15.833 4.50024 15.833 4.83357 13.703 4.83357H11.4097C12.1505 3.65274 13.2388 2.33357 14.1663 2.33357ZM15.833 15.6669H10.833V10.6669H15.833V15.6669ZM17.4997 9.00024H10.833V6.50024H17.4997V9.00024Z"
				fill="#666666"
			/>
		</svg>
	);
};
