import clevertap from "clevertap-web-sdk";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "store";

// components
import { AddReferralCode } from "../AddReferralCode";
import { Input } from "../Input";
import { PhoneLoadState } from "../PhoneLoadState";
import { PrimaryButton } from "../PrimaryButton";
import { OTP } from "../OTP";

//helpers
import { axiosInstance } from "helpers/axiosInstance";
import getUserData from "helpers/getUserDataAfterLogin";
import validateEmail from "helpers/validateEmail";

// icon
import { SvgCharacter as Character } from "assets/icons/js/SvgCharacter";
import { SvgLogo as Logo } from "assets/icons/js/SvgLogo";
import { ReactComponent as Loader } from "assets/spinning_wheel.svg";

// scss
import "./style.scss";
import { useMutation, useQuery } from "react-query";
import Modal from "../Modal";
import OtpInput from "react-otp-input";
import { useMediaQuery } from "customHooks/useMediaQuery";
import PhoneInput from "react-phone-input-2";

export const FinishPhoneSignup = ({
	onRequestClose,
	userName,
	userEmail,
	redirect,
	userUUID,
	userMobileNumber,
}) => {
	const [loadState, setLoadState] = useState(false);
	const [showReferralCodeScreen, setShowReferralCodeScreen] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [cc, setCC] = useState("");
	const [showOtp, setShowOtp] = useState(false);
	const [otp, setOTP] = useState("");
	const [shouldRequest, setShouldRequest] = useState(false);

	const { dispatch } = useContext(UserContext);

	const [values, setValues] = useState({
		email: userEmail || "",
		fullname: userName || "",
	});

	const userData = useQuery(
		["getUserData"],
		() => {
			return axiosInstance({
				method: "post",
				url: "/fetch/user/using-token",
				headers: {
					Authorization: localStorage.getItem("token")
						? `Bearer ${localStorage.getItem("token")}`
						: null,
				},
			});
		},
		{
			onSuccess: ({ data }) => {
				setValues({
					email: data?.data?.profile?.EMAIL_ID,
					fullname: data?.data?.profile?.FULL_NAME,
				});
			},
		},
	);

	const userDataObj = userData?.data?.data?.data?.profile;

	const { email, fullname } = values;

	const [inputErrorName, setInputErrorName] = useState(false);
	const [inputErrorEmail, setInputErrorEmail] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const handleChange = (e) => {
		setErrorMessage("");
		setInputErrorName(false);
		setInputErrorEmail(false);
		setValues({
			...values,
			[e.target.name]: e.target.value,
		});
	};

	const validateSignup = () => {
		let flag = true;
		if (!validateEmail(email)) {
			setInputErrorEmail(true);
			flag = false;
		}
		if (fullname.length < 1) {
			setInputErrorName(true);
			flag = false;
		}
		return flag;
	};
	const { pathname } = useLocation();
	const trimmedFullName = fullname?.trim();

	const updateObj = {
		FIRST_NAME:
			trimmedFullName?.split(" ")[0] || userDataObj?.FULL_NAME?.split(" ")[0],
		LAST_NAME:
			trimmedFullName?.split(" ")?.slice(1)?.join(" ") ||
			userDataObj?.FULL_NAME?.split(" ")?.slice(1)?.join(" "),
		EMAIL_ID: email || userDataObj?.EMAIL_ID,
		MOBILE_NUMBER: phoneNumber,
		CC: cc,
	};

	const adminOTPVerify = useMutation(
		(data) => {
			return axiosInstance({
				method: "POST",
				url: "/verify/user/number",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
				data: {
					MOBILE_VERIFICATION_CODE: otp,
					MOBILE_NUMBER: phoneNumber,
					CC: cc,
				},
			});
		},
		{
			onSuccess: () => {
				setShowReferralCodeScreen(true);
			},
		},
	);

	const updateUserProfile = (e) => {
		e.preventDefault();
		// if (!validateSignup()) {
		// 	return;
		// }

		setLoadState(true);
		setErrorMessage(false);

		const requestOptions = {
			method: "POST",
			url: "/update/user/profile/ss3",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: updateObj,
		};

		if (!showOtp) {
			axiosInstance(requestOptions)
				.then((res) => {
					clevertap.onUserLogin.push({
						Site: {
							Name: trimmedFullName,
							Identity: userUUID,
							Email: email,
							Phone: `+${cc}${userMobileNumber}`,
							timestamp: new Date(),

							"MSG-email": true,
							"MSG-push": true,
							"MSG-sms": true,
							"MSG-whatsapp": true,
						},
					});

					clevertap.event.push("user_signup", {
						Name: trimmedFullName,
						Email: email,
						Identity: userUUID,
						Phone: userMobileNumber,
						timestamp: new Date(),
					});
					getUserData(dispatch);
					if (userDataObj?.MOBILE_NUMBER) {
						setShowReferralCodeScreen(true);
					} else {
						setLoadState(false);
						setShowOtp(true);
					}
				})
				.catch((error) => {
					setLoadState(false);
					if (error.response) {
						setErrorMessage(error.response.data?.error);
					} else if (error.request) {
						setErrorMessage(error.request);
					} else {
						setErrorMessage("Something went wrong");
					}
				});
		} else {
			if (otp.length > 3) {
				adminOTPVerify.mutate();
			}
		}
	};

	const handlePhoneNumberChange = (e) => {
		const re = /^[0-9\b]+$/;
		if (e.target.value.length <= 10) {
			if (e.target.value === "" || re.test(e.target.value)) {
				setPhoneNumber(e.target.value);
			}
		}
	};

	return !showReferralCodeScreen ? (
		<div className="FinishPhoneSignup">
			<div className="finishPhone__container">
				{pathname !== "/phone" ? (
					<div className="finishPhone__close-icon">
						<div></div>
						<div className="finishPhone__header-logo">
							<span className="logo--container">
								<Logo height={24} />
							</span>
						</div>
					</div>
				) : null}
				<div className="finishPhone__sub-container">
					<div>
						<div className="finishPhone__header">
							<div className="finishPhone__header-title">
								<p>Finish signing up</p>
							</div>
						</div>
						<div>
							<form onSubmit={updateUserProfile}>
								<Input
									id="fullname"
									type={"text"}
									name={"fullname"}
									placeholder={"Full Name"}
									className="form__input"
									value={fullname || userDataObj?.FULL_NAME}
									onChange={handleChange}
									error={inputErrorName}
								/>
								<div className={!inputErrorName && "finish__space"}></div>
								{inputErrorName && (
									<p
										style={{
											color: "red",
											fontSize: "12px",
											margin: "6px 0",
										}}
									>
										Please enter a valid input
									</p>
								)}
								<Input
									id="email"
									type={"text"}
									name={"email"}
									placeholder={"Email"}
									className="form__input"
									onChange={handleChange}
									value={email || userDataObj?.EMAIL_ID}
									error={inputErrorEmail}
								/>
								<div className={"finish__space"}></div>
								{inputErrorEmail && (
									<p
										style={{
											color: "red",
											fontSize: "12px",
											margin: "6px 0",
										}}
									>
										Please enter a valid email
									</p>
								)}
								{userDataObj?.MOBILE_NUMBER ? null : (
									// <Input
									// 	id="phone"
									// 	type={"tel"}
									// 	name={"mobileNumber"}
									// 	placeholder={"Mobile Number"}
									// 	code={"+91"}
									// 	value={phoneNumber}
									// 	onChange={handlePhoneNumberChange}
									// />

									<PhoneInput
										country={"in"}
										value={""}
										enableSearch={true}
										onChange={(value, country, e, formattedValue) => {
											setCC(country.dialCode);
											setPhoneNumber(
												value.slice(country?.dialCode?.toString()?.length),
											);
										}}
									/>
								)}
								<div className={!inputErrorEmail && "finish__space"}></div>
								<p className="finish__error">{errorMessage}</p>
								{showOtp ? (
									<div className="form__input">
										<h4>Enter Otp</h4>
										<OtpInput
											value={otp}
											onChange={setOTP}
											numInputs={4}
											containerStyle="otp-box"
											inputStyle="otp-field"
											shouldAutoFocus={true}
											isInputNum
											hasError={adminOTPVerify.isError}
											errorStyle={{ color: "red" }}
										/>
									</div>
								) : null}
								<div className="finish__button">
									<PrimaryButton
										type="submit"
										value={
											loadState ? (
												<div
													style={{
														display: "flex",
														justifyContent: "center",
													}}
												>
													<Loader
														style={{
															width: "30px",
															height: "30px",
														}}
													/>
												</div>
											) : showOtp ? (
												"Verify OTP"
											) : (
												"Continue"
											)
										}
										onClick={() => {
											updateUserProfile();
										}}
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<AddReferralCode redirect={redirect} />
	);
};
