export const SvgLongArrow = (props: any) => {
	return (
		<svg
			width="42"
			height="18"
			viewBox="0 0 42 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M41 9L1 9"
				stroke={props.color ? props.color : "#888888"}
				stroke-width="2"
				stroke-linecap="square"
				stroke-linejoin="round"
			/>
			<path
				d="M41 8L42 8L42 10L41 10L41 8ZM41 10C38.2872 10 36.3069 11.3084 34.9757 12.9946C33.6178 14.7146 33 16.7507 33 18L31 18C31 16.2493 31.8032 13.7854 33.4059 11.7554C35.0352 9.69163 37.5549 8 41 8L41 10Z"
				fill={props.color ? props.color : "#888888"}
			/>
			<path
				d="M41 10L42 10L42 8L41 8L41 10ZM41 8C38.2872 8 36.3069 6.69163 34.9757 5.00536C33.6178 3.2854 33 1.24927 33 -1.74846e-07L31 1.74846e-07C31 1.75073 31.8032 4.2146 33.4059 6.24465C35.0352 8.30837 37.5549 10 41 10L41 8Z"
				fill={props.color ? props.color : "#888888"}
			/>
		</svg>
	);
};
