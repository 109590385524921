import { useContext, useState } from "react";

// comp
import DropDownWithImg from "pages/registerForm/DropdownImg";

// assets
import instagram from "assets/icons/Instagram.svg";
import Twitter from "assets/icons/Twitter.svg";
import Facebook from "assets/icons/Facebook.svg";
import LinkedIn from "assets/icons/linkedin.svg";
import Youtube from "assets/icons/Youtube.svg";
import Others from "assets/icons/user.svg";
import ImgFmaily from "assets/images/Form/ImgFmaily.png";
import ImgFriends from "assets/images/Form/ImgFriends.png";
import ImgColleagues from "assets/images/Form/ImgColleagues.png";
import ImgMen from "assets/images/Form/ImgMen.png";
import ImgWomen from "assets/images/Form/ImgWomen.png";
import ImgMixed from "assets/images/Form/ImgMixed.png";
import { SvgArrowDown } from "assets/icons/js/SvgArrowDown";

// context
import { RegisterFormDataInterface } from "context/RegisterForm";

// style
import "./style.scss";

const occasionData = [
	"Birthday",
	"Anniversary",
	"Family get-together",
	"Friends reunion",
	"Girls getaway",
	"Other",
];

const data = [
	{
		title: "Family",
		icon: ImgFmaily,
	},
	{
		title: "Friends",
		icon: ImgFriends,
	},
	{
		title: "Colleagues",
		icon: ImgColleagues,
	},
];

const groupCompData = [
	{
		title: "All men",
		icon: ImgMen,
	},
	{
		title: "All women",
		icon: ImgWomen,
	},
	{
		title: "mixed",
		icon: ImgMixed,
	},
];

const dropDownOptions = [
	{
		label: "Instagram",
		image: instagram,
	},
	{
		label: "Facebook",
		image: Facebook,
	},
	{
		label: "Twitter",
		image: Twitter,
	},
	{
		label: "LinkedIn",
		image: LinkedIn,
	},
	{
		label: "Youtube",
		image: Youtube,
	},
	{
		label: "From someone you know",
		image: Others,
	},
];

export const GuestDetails = () => {
	const {
		// @ts-ignore
		setOccassion,
		// @ts-ignore
		occasion,
		// @ts-ignore
		setTravelingWith,
		// @ts-ignore
		travelingWith,
		// @ts-ignore
		groupComp,
		// @ts-ignore
		setGroupComp,
		// @ts-ignore
		hearAbout,
		// @ts-ignore
		setHearAbout,
		// @ts-ignore
		request,
		// @ts-ignore
		setRequest,

		// @ts-ignore
	} = useContext(RegisterFormDataInterface);

	const [dropdown, setDropdown] = useState(false);
	const [options, setOptions] = useState("Are you celebrating something?");
	const [selected, setSelected] = useState(["Select"]);

	return (
		<div className="personal__step__three__container">
			<div className="bottom" style={{ borderTop: "none" }}>
				<h3>
					Select the group composition. <strong>(optional)</strong>
				</h3>
				<div className="travelling__container">
					{groupCompData.map((v: any, i: number) => {
						return (
							<span
								key={i}
								className={
									groupComp === v.title
										? "travelingWith active"
										: "travelingWith"
								}
								onClick={() => setGroupComp(v.title)}
							>
								<div className="icon__container">
									<img src={v.icon} alt="" />
								</div>
								<p>{v.title}</p>
							</span>
						);
					})}
				</div>
			</div>
			<div className="bottom">
				<h3>
					Who are you travelling with? <strong>(optional)</strong>
				</h3>
				<div className="travelling__container">
					{data.map((v: any, i: number) => {
						return (
							<span
								key={i}
								className={
									travelingWith === v.title
										? "travelingWith active"
										: "travelingWith"
								}
								onClick={() => setTravelingWith(v.title)}
							>
								<div className="icon__container">
									<img src={v.icon} alt="" />
								</div>
								<p>{v.title}</p>
							</span>
						);
					})}
				</div>
			</div>

			<div
				className="div__celebrating"
				onClick={() => setDropdown((prev) => !prev)}
			>
				<p
					style={{
						color: occasion !== "" ? "#333333" : "#999999",
					}}
				>
					{occasion ? occasion : "Are you celebrating something?"}
				</p>
				<SvgArrowDown />
				{dropdown && (
					<div className="dropdown__container">
						{occasionData.map((v: string, i: number) => {
							return (
								<div
									className="box"
									onClick={() => {
										setOptions(v);
										setOccassion(v);
									}}
								>
									{v}
								</div>
							);
						})}
					</div>
				)}
			</div>
			<div className="message__container">
				<div className="hear__about">
					<label htmlFor="">How did you hear about SaffronStays?</label>
					<DropDownWithImg
						dropDownOptions={dropDownOptions}
						selected={selected}
						setSelected={setSelected}
						selectedValue={hearAbout}
						setSelectedValue={setHearAbout}
					/>
				</div>
				{/* <div className="request">
					<label htmlFor="">Any additional requests?</label>
					<textarea
						name=""
						id=""
						onChange={(e) => setRequest(e.target.value)}
						value={request}
					></textarea>
				</div> */}
			</div>
		</div>
	);
};
