import { useEffect } from "react";

// scss
import "./style.scss";

export const PrimaryButton = ({ value, type, onClick, disabled = false }) => {
	//runs onClick on pressing enter
	useEffect(() => {
		const listener = (event) => {
			if (event.code === "Enter" || event.code === "NumpadEnter") {
				event.preventDefault();
				onClick();
			}
		};
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<button
			aria-label="primary button"
			type={type}
			className="primaryButton"
			onClick={onClick}
			// disabled={disabled}
		>
			{value}
		</button>
	);
};
