import { useContext, useState } from "react";

// components
import { SortbyFilter } from "components/NewSearch/desktop/MiddleSide/SortbyFilter";
import NewCategoryMoreFilters from "components/NewSearch/desktop/LeftSide/LeftFiltersAndSort/NewCategoriesMoreFilters";
import NewCategoryAmenitiesFilters from "components/NewSearch/desktop/LeftSide/LeftFiltersAndSort/NewCategoryAmenitiesFilters";
import SearchRangeSlider from "components/NewSearch/SearchRangeSlider";

//customData
import { filtersAmenityTypes } from "lib/filtersAmenityTypes";
import { filtersMore } from "lib/filtersMore";

// context
import { SearchPageDataInterface } from "context/SearchPageData";

// styles
import "./styles.scss";
import { NewCategoryBudgetFilters } from "components/NewSearch/desktop/LeftSide/LeftFiltersAndSort/NewCategoryBudgetFilters";

export const PhoneSortFilter = () => {
	return (
		<div className="phone__all__filters__container">
			<SortbyFilter />
		</div>
	);
};

export const PhoneBudgetFilter = () => {
	const {
		// @ts-ignore
		setSelectBudget,
		// @ts-ignore
		selectBudget,
		// @ts-ignore
		query,
	} = useContext(SearchPageDataInterface);

	return (
		<div className="phone__all__filters__container">
			<NewCategoryBudgetFilters
				setSelectBudget={setSelectBudget}
				selectBudget={selectBudget}
				query={query}
			/>
		</div>
	);
};

export const PhoneMoreFilter = () => {
	const {
		// @ts-ignore
		showMore,
	} = useContext(SearchPageDataInterface);

	return (
		<div className="phone__all__filters__container">
			{/* @ts-ignore */}
			{showMore && <NewCategoryMoreFilters moreFilters={filtersMore} />}
		</div>
	);
};

export const PhoneAmenitiesFilter = () => {
	const {
		// @ts-ignore
		showAmenities,
		// @ts-ignore
		query,
	} = useContext(SearchPageDataInterface);
	return (
		<div className="phone__all__filters__container">
			{showAmenities && (
				<NewCategoryAmenitiesFilters
					amenityTypes={filtersAmenityTypes}
					toggleFilters={undefined}
				/>
			)}
		</div>
	);
};
