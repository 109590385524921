export const SvgAddMaid = () => {
	return (
		<svg
			width="19"
			height="24"
			viewBox="0 0 19 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.0891 22.9994H1.1692C1.07568 22.9994 1 22.9238 1 22.8302V20.1231C1 17.6528 3.30102 15.8153 7.15545 15.2061C7.19973 15.1972 7.2455 15.2083 7.28069 15.2365C7.31721 15.2631 7.34166 15.3035 7.34827 15.3482C7.45782 16.0777 7.91187 16.7099 8.56837 17.0465C9.22486 17.3832 10.0031 17.3832 10.6596 17.0465C11.3159 16.7099 11.7699 16.0777 11.8795 15.3482C11.8863 15.3035 11.9106 15.2631 11.9472 15.2365C11.9824 15.2083 12.0282 15.1972 12.0725 15.2061C15.9269 15.8152 18.2313 17.6528 18.2313 20.1231V22.8302H18.2312C18.2323 22.914 18.1718 22.986 18.0891 22.9994H18.0891ZM1.36547 22.661H17.9063V20.1231C17.9063 17.8693 15.7745 16.1705 12.1976 15.5648C12.0231 16.3505 11.4989 17.0133 10.7744 17.3639C10.05 17.7145 9.20499 17.7145 8.48048 17.3639C7.7561 17.0133 7.2318 16.3505 7.05731 15.5648C3.48384 16.1673 1.35193 17.866 1.35193 20.1231L1.36547 22.661Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
			<path
				d="M15.9221 23.0014H3.33033C3.23764 22.9996 3.16295 22.9249 3.16113 22.8322V19.3333C3.16113 19.2397 3.23681 19.1641 3.33033 19.1641H15.9221C15.9669 19.1641 16.01 19.1819 16.0417 19.2136C16.0734 19.2454 16.0913 19.2883 16.0913 19.3333V22.8322C16.0913 22.8772 16.0735 22.9202 16.0417 22.9519C16.01 22.9836 15.9669 23.0014 15.9221 23.0014ZM3.49953 22.663H15.7529V19.5025H3.49953V22.663Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
			<path
				d="M9.63026 17.6262C8.93134 17.6252 8.26148 17.3473 7.76725 16.8531C7.27302 16.3589 6.99494 15.6889 6.99414 14.9901V13.8971C6.99414 13.8306 7.03231 13.77 7.09229 13.7414C7.15326 13.7143 7.22447 13.7249 7.27504 13.7685C7.93068 14.3324 8.76695 14.6428 9.63193 14.6428C10.4969 14.6428 11.3332 14.3324 11.989 13.7685C12.0409 13.7275 12.1111 13.7183 12.1716 13.7447C12.2306 13.7728 12.2686 13.8318 12.2697 13.8971V14.9868V14.9866C12.2697 15.6867 11.9916 16.3581 11.4968 16.8531C11.0018 17.3481 10.3304 17.6262 9.63031 17.6262L9.63026 17.6262ZM7.33254 14.2422V14.9868V14.9866C7.36327 15.7872 7.80807 16.5142 8.50703 16.9058C9.20582 17.2974 10.0581 17.2974 10.757 16.9058C11.4558 16.5142 11.9007 15.7872 11.9313 14.9866V14.249C11.2603 14.7277 10.4565 14.9851 9.63195 14.9851C8.80761 14.9851 8.00375 14.7277 7.33258 14.249L7.33254 14.2422Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
			<path
				d="M9.62928 14.9808C8.58139 14.9816 7.57593 14.5661 6.83471 13.8253C6.09332 13.0846 5.67676 12.0796 5.67676 11.0318V7.5293C5.67858 7.4366 5.75326 7.36192 5.84596 7.3601C6.40029 7.35068 6.93665 7.16198 7.37471 6.82209C7.81272 6.48237 8.12883 6.00981 8.27573 5.47527L8.31291 5.3297C8.33241 5.26278 8.38942 5.2137 8.45848 5.20445C8.52689 5.19569 8.59331 5.23089 8.62421 5.29252C9.02837 6.02781 9.6224 6.64116 10.3445 7.0686C11.0665 7.49591 11.89 7.72162 12.7291 7.72208H13.4059C13.4507 7.72208 13.4938 7.73993 13.5255 7.77166C13.5572 7.80338 13.5751 7.84651 13.5751 7.89128V10.9369C13.5943 11.9689 13.2131 12.9682 12.5115 13.7253C11.8099 14.4824 10.8424 14.9383 9.81207 14.9976L9.62928 14.9808ZM6.01516 7.69173V11.0318C6.01499 12.0194 6.41948 12.964 7.13428 13.6456C7.84906 14.3271 8.81188 14.6862 9.79848 14.6389C10.7418 14.5833 11.6271 14.1645 12.2686 13.4706C12.9102 12.7768 13.2583 11.8615 13.24 10.9166V8.0605H12.7392C11.0462 8.05934 9.4677 7.20591 8.5396 5.78985C8.34793 6.31711 8.00738 6.77729 7.55926 7.11484C7.11114 7.45242 6.57482 7.65285 6.01516 7.69168V7.69173Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
			<path
				d="M14.7049 10.0405C14.6286 10.0393 14.563 9.98609 14.5458 9.91174C14.4957 9.7085 14.3787 9.52774 14.2137 9.39886C14.0486 9.26997 13.845 9.20041 13.6355 9.20124H13.4121C13.3186 9.20124 13.2431 9.12539 13.2431 9.03204V8.06409H12.7388C11.0458 8.06294 9.46733 7.20951 8.53923 5.79345C8.34756 6.32071 8.00702 6.78089 7.55889 7.11844C7.11077 7.45602 6.57445 7.65645 6.01479 7.69528V9.03204C6.01479 9.07682 5.99694 9.11994 5.96521 9.15167C5.93349 9.18339 5.89053 9.20124 5.84559 9.20124H5.62566C5.37236 9.19727 5.12813 9.29625 4.94887 9.47519C4.82924 9.59301 4.74481 9.74188 4.70532 9.90496C4.68631 9.97882 4.62221 10.032 4.5462 10.037C4.4687 10.0431 4.39782 9.99336 4.377 9.91851C4.21606 9.3876 4.13493 8.83555 4.13675 8.28071C4.13807 6.67878 4.83884 5.15713 6.05525 4.1147C7.2717 3.07239 8.88273 2.61306 10.4658 2.85713C12.0491 3.10134 13.4468 4.02468 14.2928 5.38504C15.1388 6.74557 15.3485 8.40748 14.8672 9.93564C14.8402 10.0011 14.7756 10.0428 14.7049 10.0405ZM13.578 8.86283H13.6322C14.034 8.86597 14.4106 9.0593 14.6474 9.3838C14.9828 7.94692 14.6862 6.43521 13.8328 5.23166C12.9793 4.02794 11.651 3.24772 10.1841 3.08859C8.71713 2.92964 7.25251 3.40683 6.16084 4.39954C5.0693 5.39227 4.45565 6.80534 4.47511 8.28071C4.47544 8.65496 4.5151 9.02806 4.59359 9.39403C4.63258 9.3443 4.67439 9.29688 4.71883 9.25193C4.95858 9.01036 5.28526 8.8752 5.62565 8.87619H5.67638V7.53284C5.67819 7.44014 5.75288 7.36546 5.84557 7.36364C6.39991 7.35422 6.93627 7.16553 7.37433 6.82563C7.81234 6.48591 8.12844 6.01335 8.27535 5.47881L8.31253 5.33324C8.33203 5.26632 8.38903 5.21725 8.4581 5.208C8.52651 5.19924 8.59293 5.23443 8.62383 5.29606C9.02799 6.03136 9.62202 6.6447 10.3441 7.07214C11.0661 7.49945 11.8897 7.72516 12.7287 7.72563H13.4055C13.4503 7.72563 13.4934 7.74347 13.5251 7.7752C13.5569 7.80693 13.5747 7.85005 13.5747 7.89483L13.578 8.86283Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
			<path
				d="M5.8555 11.4243H5.62533C5.28528 11.4243 4.95894 11.2893 4.71851 11.0487C4.47793 10.8081 4.34277 10.482 4.34277 10.1418C4.34277 9.80172 4.47793 9.47555 4.71851 9.23495C4.95893 8.99437 5.28524 8.85938 5.62533 8.85938H5.84526C5.8902 8.85938 5.93316 8.87705 5.96488 8.90878C5.99661 8.94067 6.01446 8.98363 6.01446 9.02857V11.0318V11.245C6.01627 11.2913 5.99942 11.3365 5.96753 11.3702C5.9358 11.4039 5.89169 11.4234 5.84526 11.4243L5.8555 11.4243ZM5.62533 9.19759V9.19776C5.28809 9.19776 4.97629 9.3777 4.80777 9.66982C4.63906 9.96179 4.63906 10.3218 4.80777 10.6138C4.9763 10.9059 5.28808 11.0858 5.62533 11.0858H5.67953C5.67771 11.0678 5.67771 11.0498 5.67953 11.0318V9.19775L5.62533 9.19759Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
			<path
				d="M13.6329 11.4243H13.4027C13.3565 11.4234 13.3123 11.4039 13.2805 11.3702C13.2486 11.3365 13.2317 11.2913 13.2335 11.245V11.0318V9.02857C13.2335 8.93505 13.3094 8.85938 13.4027 8.85938H13.6261C13.9657 8.8602 14.291 8.99569 14.5308 9.2361C14.7705 9.47652 14.9052 9.8022 14.9052 10.1417C14.9072 10.4823 14.7717 10.8093 14.5296 11.0487C14.2935 11.2896 13.9701 11.4251 13.6329 11.4243ZM13.5787 11.0859H13.6329C13.8865 11.091 14.1311 10.9919 14.3097 10.8119C14.4886 10.6341 14.5891 10.3924 14.5888 10.1402C14.5883 9.88809 14.487 9.64652 14.3075 9.46941C14.1279 9.29244 13.885 9.19463 13.6329 9.19776H13.5787L13.5787 11.0859Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
			<path
				d="M7.62525 3.49746C7.5927 3.49845 7.56065 3.48887 7.53388 3.47036C7.48827 3.44128 7.45936 3.3922 7.45605 3.33834V3.17592C7.45605 2.39849 7.87079 1.68024 8.54396 1.29159C9.21729 0.902802 10.0466 0.902802 10.7199 1.29159C11.3931 1.68022 11.8078 2.39849 11.8078 3.17592C11.8113 3.23342 11.8113 3.29109 11.8078 3.34859C11.8032 3.40113 11.7728 3.44806 11.7267 3.47367C11.6794 3.49581 11.6249 3.49581 11.5776 3.47367C10.3359 2.97284 8.94828 2.97284 7.70637 3.47367C7.68125 3.48705 7.65366 3.49498 7.62524 3.49746L7.62525 3.49746ZM9.62852 2.76995C10.2523 2.76978 10.8714 2.87619 11.4593 3.08455C11.421 2.45585 11.0634 1.89043 10.5115 1.58655C9.95964 1.28252 9.29063 1.28252 8.73875 1.58655C8.18686 1.89042 7.82931 2.45585 7.79098 3.08455C8.38136 2.87652 9.00266 2.77011 9.62852 2.76995Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.8"
			/>
		</svg>
	);
};
