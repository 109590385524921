import React, { useContext, useEffect, useMemo, useState } from "react";
import "./style.scss";
// import { ReactComponent as NoCancelIcon } from "../../assets/booking-nocancel.svg";
// import { ReactComponent as NormalIcon } from "../../assets/booking-normal.svg";

import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "helpers/axiosInstance";
import { format } from "multi-date";
import { BookingInterface } from "context/BookingData";
import Collapse from "../Collapse";

import { ReactComponent as CancelIcon } from "../assets/cancel.svg";
import { ReactComponent as CashIcon } from "../assets/cash.svg";
import { setOptions } from "marked";

const BookingChoosePayment = ({ data }: any) => {
	let bookingData = data;

	const [searchParams, setSearchParams] = useSearchParams();

	const [option, setoption] = useState(1);
	const [show, setShow] = useState(false);
	const [dis, setDis] = useState(0);

	const [regularPrice, setRegularPrice] = useState(0);
	const [noCancelPrice, setnoCancelPrice] = useState(0);
	const { updateBooking, fetchCoupons }: any = useContext(BookingInterface);
	const [taDiscount, setTaDiscount] = useState<string>("");

	const newData = fetchCoupons?.data?.data?.data;

	const recalData = async () => {
		const res = await axiosInstance({
			method: "POST",
			url: "/manageBookings/recal",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: {
				LISTING_ID: bookingData.LISTING_ID,
				FROM_DATE: format(bookingData.FROM_DATE, "YYYY-MM-DD"),
				TO_DATE: format(bookingData.TO_DATE, "YYYY-MM-DD"),
				NUM_ROOMS: bookingData.NO_OF_ROOMS,
				NUM_CHILD: bookingData.NUM_CHILDREN,
				NUM_INFANTS: bookingData.NUM_SMALL_CHILDREN,
				NUM_ADULTS: bookingData.NO_OF_GUESTS,
				NUM_MAIDS: bookingData.NUMBER_OF_MAIDS,
				NUM_DRIVERS: bookingData.NUMBER_OF_DRIVERS,
				addMeals: bookingData?.MEAL_CHARGES ? 1 : 0,
			},
		});
		if (res.data.success) {
			setRegularPrice(res.data?.data?.TOTAL_AMOUNT_WITH_SERVICE);
		}
	};
	useEffect(() => {
		if (bookingData) {
			recalData();
		}
	}, [bookingData]);

	useEffect(() => {
		if (show) {
			setoption(2);
			UpdateBooking("NOCANCEL");
		}
	}, [show, taDiscount]);

	useEffect(() => {
		if (newData)
			newData?.forEach((element: any) => {
				if (element.COUPON_CODE.includes("TA-FLEX-") && element.isApplicable) {
					setTaDiscount(element.COUPON_CODE);
				}
				if (element.COUPON_CODE === "NOCANCEL" && !element.isApplicable) {
					setShow(false);
				}
				if (element.COUPON_CODE === "NOCANCEL" && element.isApplicable) {
					setShow(true);
					// setDis(element);
					setDis(element.service_charge_coupon_discount);
					setnoCancelPrice(element.newPrice);
				}
			});
	}, [newData]);

	// console.log(amount);

	const UpdateBooking = async (couponCode: string) => {
		updateBooking.mutate({
			COUPON_CODE: couponCode,
		});
		searchParams.set("coupon", couponCode);
		setSearchParams(searchParams, { replace: true });
	};

	const couponHandler = (option: Number) => {
		if (option === 2) {
			UpdateBooking("NOCANCEL");
		} else {
			UpdateBooking(taDiscount ? taDiscount : "");
		}
	};

	useEffect(() => {
		// setAmount({
		// 	old: 0,
		// 	new: 0,
		// 	dis: 0,
		// });
		if (bookingData && bookingData.COUPON_CODE === "NOCANCEL") {
			setoption(2);
		} else {
			setoption(1);
		}
	}, [bookingData]);

	if (
		!(
			bookingData?.COUPON_CODE === "" || bookingData?.COUPON_CODE === "NOCANCEL"
		)
	) {
		return null;
	}
	// if (fetchCoupons.isLoading) {
	// 	return <div className="loading">Loading...</div>;
	// }

	if (!show) {
		return null;
	}
	return (
		<Collapse init={true} title="What would you like to choose?">
			<div className="new-choose-payment-wrapper">
				<div className="booking__choose-payment">
					<OptionCard
						active={option === 1}
						onClick={() => {
							couponHandler(1);
							// window.location.href = window.location.pathname;
						}}
						title="Regular Price, More Flexible"
						description="You have the freedom to cancel within the cancellation range. Have peace of mind if your plans unexpectedly change."
						price={
							option === 1
								? bookingData?.TOTAL_AMOUNT_WITH_SERVICE
								: regularPrice
						}
						link={taDiscount ? "/?coupon=" + taDiscount : "/"}
						SvgIcon={<CancelIcon />}
						save={null}
					/>
					<OptionCard
						active={option === 2}
						onClick={() => {
							couponHandler(2);
						}}
						title="No Cancellations, Discounted Price"
						description="This option offers a discounted price for your reservation; however, please note that once booked, it is non-refundable and cannot be cancelled."
						price={
							option === 2
								? bookingData?.TOTAL_AMOUNT_WITH_SERVICE
								: noCancelPrice
						}
						link="/"
						SvgIcon={<CashIcon />}
						save={option === 2 ? bookingData?.COUPONAMOUNT_TAX : dis}
					/>
				</div>
			</div>
		</Collapse>
	);
};

export default BookingChoosePayment;

const OptionCard = ({
	active,
	onClick,
	title,
	price,
	description,
	link,
	save,
	SvgIcon,
}: any) => {
	return (
		<div
			onClick={onClick}
			className={`payment-option-wrapper ${active && "payment-active"}`}
		>
			<div className="payment-title">{title}</div>
			<p>{description}</p>
			{/* <span>Payment Amount</span> */}
			<div className="payment-price">
				₹ {price ? price : "-"}{" "}
				{save ? (
					<span>You Save ₹ {save?.toFixed(2) ? save?.toFixed(2) : "-"}</span>
				) : null}
			</div>

			{/* <a href={link} target="_blank" rel="noreferrer">
				{!save ? "View benefits" : "Terms & Conditions"}
			</a> */}
			{SvgIcon}
		</div>
	);
};
