import { useContext } from "react";
import GoogleLogin from "react-google-login";
import { useNavigate } from "react-router-dom";
import { UserContext } from "store";

// custom hooks
import { useMediaQuery } from "customHooks/useMediaQuery";
import getUserData from "helpers/getUserDataAfterLogin";

// helpers
import { axiosInstance } from "helpers/axiosInstance";

const GoogleLoginWrapper = ({ children, rememberMe, onSuccess, onFailure }) => {
	const width = useMediaQuery();
	const navigator = useNavigate();

	const { dispatch } = useContext(UserContext);

	const onLoginSuccess = (response) => {
		// To get details of user from SaffronStays database
		axiosInstance({
			method: "POST",
			url: "/googleSignup",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			data: {
				FIRST_NAME: response.profileObj.givenName,
				LAST_NAME: response.profileObj.familyName,
				EMAIL_ID: response.profileObj.email,
				GOOGLE_ID: response.profileObj.googleId,
				IMAGE_UPDATE: response.profileObj.imageUrl,
			},
		})
			.then((res) => {
				localStorage.setItem("token", res?.data?.data?.token);
				localStorage.setItem("allow", "true");
				window.location.reload();
				if (width < 500) {
					getUserData(dispatch);
					navigator(-1);
				}
			})
			.catch((err) => {});
	};

	const onLoginFailed = (response) => {};

	return (
		<GoogleLogin
			clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
			render={(renderProps) => (
				<div onClick={renderProps.onClick} disabled={renderProps.disabled}>
					{children}
				</div>
			)}
			onSuccess={onSuccess ? onSuccess : onLoginSuccess}
			onFailure={onFailure ? onFailure : onLoginFailed}
			isSignedIn={rememberMe}
			uxMode={"popup"}
			cookiePolicy={"single_host_origin"}
		/>
	);
};

export default GoogleLoginWrapper;
