export const SvgCrown = () => {
	return (
		<svg
			width="24"
			height="16"
			viewBox="0 0 24 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.9541 1.79176C22.591 1.68165 22.1775 1.85574 21.998 2.19351C21.0635 3.9783 19.5717 6.11848 17.843 6.03315C15.4488 5.95131 13.1953 2.00152 12.5266 0.487061C12.5123 0.451598 12.4869 0.43027 12.4691 0.40522C12.4333 0.34843 12.3972 0.287917 12.347 0.238074C12.3112 0.202611 12.2681 0.177564 12.2249 0.152761C12.1816 0.127962 12.1492 0.085307 12.1026 0.063982H12.0845C12.0341 0.0426544 11.9767 0.0354627 11.9193 0.0285192C11.8689 0.0176078 11.8258 0 11.7754 0C11.725 0 11.6819 0.0176072 11.6352 0.028271C11.5741 0.0354627 11.5166 0.0424068 11.4662 0.0637337H11.4447C11.4016 0.0850613 11.3727 0.120524 11.3334 0.141854C11.2902 0.174092 11.2398 0.19542 11.2002 0.238073C11.1498 0.28792 11.1175 0.344711 11.0816 0.40522C11.0636 0.430019 11.0385 0.451346 11.0242 0.48706C10.352 2.00152 8.102 5.95132 5.71151 6.03315C3.99351 6.1326 2.49091 3.97829 1.5527 2.19351C1.37292 1.85227 0.963233 1.67793 0.589387 1.79176C0.215554 1.90187 -0.0324165 2.26096 0.00343791 2.64508L1.17182 14.9639C1.21144 15.3836 1.56371 15.7 1.99148 15.7L11.7754 15.6998H21.5631C21.9836 15.6998 22.3431 15.3833 22.379 14.9637L23.5471 2.64483C23.5795 2.26093 23.3353 1.90185 22.9542 1.7917L22.9541 1.79176ZM20.8188 14.0821H2.73887L1.94809 5.71648C3.13778 7.05318 4.42099 7.72176 5.76891 7.66125C8.55472 7.56156 10.6935 4.53259 11.7753 2.61632C12.8572 4.53259 14.9959 7.56158 17.7815 7.65751C19.1474 7.71802 20.4126 7.04968 21.6023 5.71273L20.8188 14.0821Z"
				fill="url(#paint0_linear_16152_26317)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_16152_26317"
					x1="-8.34044e-08"
					y1="1.04667"
					x2="23.9612"
					y2="2.59595"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#857D4C" />
					<stop offset="0.0808516" stop-color="#BD9A64" />
					<stop offset="0.510071" stop-color="#FBDE98" />
					<stop offset="0.897799" stop-color="#BD9A64" />
					<stop offset="1" stop-color="#857D4C" />
				</linearGradient>
			</defs>
		</svg>
	);
};
