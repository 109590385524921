import React, { useState } from "react";

// style
import "./style.scss";

const data = [
	{
		title:
			"I have registered as a Travel Agent with SaffronStays and I have a query",
		link: "/i-have-query",
	},
	{
		title: "I want to register as a Travel Agent with SaffronStays",
		link: "/i-want-to-register",
	},
	{
		title:
			"I want to register as a Travel Agent with SaffronStays and I have a query",
		link: "/i-want-to-register-and-have-query",
	},
];

export const TravelAgentOptionPage = () => {
	const [toLink, setToLink] = useState("");

	return (
		<div className="travel__agent__option__page__container">
			<h2>Choose your reason for connecting</h2>
			<div className="options__container">
				<h3>Select one of the following</h3>
				{data.map((v: any, i: number) => {
					return (
						<div className="tab" onClick={() => setToLink(v.link)}>
							<span className="border">
								<span
									className={v.link === toLink ? "active" : "not__active"}
								></span>
							</span>
							<label htmlFor="">{v.title}</label>
						</div>
					);
				})}
			</div>
			<div className="bottom">
				<span></span>
				<span></span>
				<button
					className="btn__continue"
					onClick={() =>
						(window.location.href = `/form/i-am-a-travel-agent${toLink}`)
					}
				>
					Save & Continue
				</button>
			</div>
		</div>
	);
};
