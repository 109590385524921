export const SvgFooterInsta = (props: any) => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.1729 0.254883H5.89188C4.46921 0.256471 3.10527 0.822326 2.0993 1.8283C1.09332 2.83428 0.527467 4.19822 0.525879 5.62088V12.9019C0.527467 14.3245 1.09332 15.6885 2.0993 16.6945C3.10527 17.7004 4.46921 18.2663 5.89188 18.2679H13.1729C14.5955 18.2663 15.9595 17.7004 16.9655 16.6945C17.9714 15.6885 18.5373 14.3245 18.5389 12.9019V5.62088C18.5373 4.19822 17.9714 2.83428 16.9655 1.8283C15.9595 0.822326 14.5955 0.256471 13.1729 0.254883ZM16.7259 12.9019C16.7256 13.8441 16.3512 14.7477 15.6849 15.4139C15.0187 16.0802 14.1151 16.4546 13.1729 16.4549H5.89188C4.9493 16.4549 4.04533 16.0804 3.37882 15.4139C2.71232 14.7474 2.33788 13.8435 2.33788 12.9009V5.62088C2.33788 4.6783 2.71232 3.77433 3.37882 3.10783C4.04533 2.44132 4.9493 2.06688 5.89188 2.06688H13.1729C14.1153 2.06715 15.019 2.4417 15.6853 3.10818C16.3516 3.77465 16.7259 4.67848 16.7259 5.62088V12.9019Z"
				fill="white"
			/>
			<path
				d="M9.53279 5.11279C8.71219 5.11279 7.91002 5.35613 7.22772 5.81203C6.54542 6.26793 6.01364 6.91591 5.69961 7.67404C5.38558 8.43217 5.30342 9.2664 5.46351 10.0712C5.6236 10.8761 6.01875 11.6153 6.599 12.1956C7.17924 12.7758 7.91853 13.171 8.72336 13.3311C9.52818 13.4912 10.3624 13.409 11.1205 13.095C11.8787 12.7809 12.5267 12.2491 12.9826 11.5668C13.4385 10.8845 13.6818 10.0824 13.6818 9.2618C13.6807 8.16174 13.2433 7.10704 12.4654 6.32918C11.6875 5.55132 10.6328 5.11385 9.53279 5.11279ZM9.53279 11.7968C9.03141 11.7968 8.5413 11.6481 8.12442 11.3696C7.70754 11.091 7.38262 10.6951 7.19075 10.2319C6.99888 9.76869 6.94868 9.25898 7.04649 8.76724C7.1443 8.2755 7.38575 7.82381 7.74028 7.46928C8.0948 7.11476 8.54649 6.87331 9.03824 6.7755C9.52998 6.67768 10.0397 6.72789 10.5029 6.91975C10.9661 7.11162 11.362 7.43655 11.6406 7.85342C11.9191 8.2703 12.0678 8.76042 12.0678 9.2618C12.0678 9.93412 11.8007 10.5789 11.3253 11.0543C10.8499 11.5297 10.2051 11.7968 9.53279 11.7968Z"
				fill="white"
			/>
			<path
				d="M14.123 5.9608C14.8117 5.9608 15.37 5.4025 15.37 4.7138C15.37 4.0251 14.8117 3.4668 14.123 3.4668C13.4343 3.4668 12.876 4.0251 12.876 4.7138C12.876 5.4025 13.4343 5.9608 14.123 5.9608Z"
				fill="white"
			/>
		</svg>
	);
};
