import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import moment from "moment";
import { useQuery } from "react-query";
import { axiosInstance } from "helpers/axiosInstance";

const DeadlineStickyNavbar = () => {
	const [obj, setObj] = useState({});
	const [time, setTime] = useState(new Date());
	const [isScrolledDown, setIsScrolledDown] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);

	const fetchCoupon = async () => {
		try {
			const res = await axiosInstance("/fetch/deadline-coupon", {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			if (res.data.success) {
				setObj({
					couponCode: res?.data?.data?.COUPON_CODE,
					expireAt: res?.data?.data?.EXPIRY_DATE,
					discount: res.data.data.DISCOUNT,
				});
				localStorage.setItem(
					"unlock_best_offer",
					JSON.stringify({
						couponCode: res?.data?.data?.COUPON_CODE,
						expireAt: res?.data?.data?.EXPIRY_DATE,
						discount: res.data.data.DISCOUNT,
					}),
				);
			}
		} catch (err) {
			setObj({});
			localStorage.removeItem("unlock_best_offer");
		}
	};

	useEffect(() => {
		fetchCoupon();
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.scrollY;

			if (currentScrollPos > prevScrollPos) {
				// Scrolling down
				setIsScrolledDown(true);
			} else {
				// Scrolling up
				setIsScrolledDown(false);
			}

			// Update previous scroll position
			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener("scroll", handleScroll);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [prevScrollPos]);

	useState(() => {
		if (new Date(obj?.expireAt)?.getTime() - time.getTime() < 0) {
			setObj({});
			localStorage.removeItem("unlock_best_offer");
			return;
		}

		let timer = setInterval(() => {
			setTime(new Date());
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [time]);

	useEffect(() => {
		if (localStorage.getItem("unlock_best_offer"))
			setObj(JSON.parse(localStorage.getItem("unlock_best_offer") || ""));
		else {
		}
	}, []);

	useEffect(() => {
		if (document) {
			const navbar = document.querySelector(".home__navbar");
			const deadline = document.querySelector(".deadline-sticky-bar");
			if (navbar && deadline && Object.keys(obj).length !== 0) {
				navbar.style.cssText = "margin-top:30px";
			} else {
				navbar.style.cssText = "margin-top:0px";
			}
		}
	}, [obj]);

	if (window.location.href?.includes("universal")) {
		return null;
	}
	if (Object.keys(obj)?.length <= 0) {
		return null;
	}
	if (new Date(obj?.expireAt)?.getTime() < new Date()?.getTime()) {
		return null;
	}
	return (
		<div className={`deadline-sticky-bar ${isScrolledDown && "move-up-bar"}`}>
			⏳ Hurry! Your best price at <b>{obj?.discount}% off</b> expires in&nbsp;
			{moment(
				new Date(obj?.expireAt)?.getTime() - time.getTime() - 19800000,
			).format("HH:mm:ss")}
			&nbsp;:&nbsp;
			<br className="mob-br" />
			use <b>{obj?.couponCode}</b>
		</div>
	);
};

export default DeadlineStickyNavbar;
