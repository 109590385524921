import { axiosInstance } from "helpers/axiosInstance";

const getUserData = (dispatch: any) => {
	if (localStorage.getItem("token")) {
		axiosInstance({
			method: "post",
			url: "/fetch/user/using-token",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
		})
			.then((res: any) => {
				dispatch({
					type: "SET_CURRENT_USER",
					payload: {
						firstName: res.data.data.profile.FIRST_NAME
							? res.data.data.profile.FIRST_NAME
							: "",
						lastName: res.data.data.profile.LAST_NAME
							? res.data.data.profile.LAST_NAME
							: "",
						fullName: res.data.data.profile.FULL_NAME
							? res.data.data.profile.FULL_NAME
							: "",
						gender: res.data.data.profile.GENDER
							? res.data.data.profile.GENDER
							: "",
						mobile: res.data.data.profile.MOBILE_NUMBER
							? res.data.data.profile.MOBILE_NUMBER
							: "",
						cc: res.data.data.profile.CC_MOBILE_NUMBER
							? res.data.data.profile.CC_MOBILE_NUMBER
							: "",
						email: res.data.data.profile.EMAIL_ID
							? res.data.data.profile.EMAIL_ID
							: "",
						dateOfBirth: res.data.data.profile.DATE_OF_BIRTH
							? res.data.data.profile.DATE_OF_BIRTH
							: "",
						anniversaryDate: res.data.data.profile.DATE_OF_ANI
							? res.data.data.profile.DATE_OF_ANI
							: "",
						spouseBirthday: res.data.data.profile.SPOUSE_ANI
							? res.data.data.profile.SPOUSE_ANI
							: "",
						address: res.data.data.profile.ADDRESS
							? res.data.data.profile.ADDRESS
							: "",
						city: res.data.data.profile.CITY ? res.data.data.profile.CITY : "",
						state: res.data.data.profile.STATE
							? res.data.data.profile.STATE
							: "",
						country: res.data.data.profile.COUNTRY
							? res.data.data.profile.COUNTRY
							: "",
						pincode: res.data.data.profile.PINCODE
							? res.data.data.profile.PINCODE
							: "",
						isHomeowner: res.data.data.profile.IS_HO === "1",
						isVIP: res.data.data.profile.IS_VIP === 1,
						isTravelAgent: res.data.data.profile.IS_AGENT === "Y",
						profilePic: res.data.data.profile.PROFILE_IMAGE_PATH,
						uuid: localStorage.getItem("uuid") || res.data.data.profile.UUID,
						referralCode: res.data.data.profile.REF_CODE
							? res.data.data.profile.REF_CODE
							: "",
						rewardPoint: res.data.data.profile.REWARD_POINTS
							? res.data.data.profile.REWARD_POINTS
							: 0,
						rewardPointExpire: res.data.data.profile.RP_EXPIRY
							? res.data.data.profile.RP_EXPIRY
							: null,
						travelCredit: res.data.data.profile.TRAVEL_CREDIT
							? res.data.data.profile.TRAVEL_CREDIT
							: 0,
						travelCreditExpire: res.data.data.profile.TRAVEL_CREDIT_EXPIRES
							? res.data.data.profile.TRAVEL_CREDIT_EXPIRES
							: null,
						rewardTier: res.data.data.profile.REWARDS_TIER
							? res.data.data.profile.REWARDS_TIER
							: "",
						mobileVerified:
							res.data.data.profile.MOBILE_VERIFICATION === "Y" ? true : false,
						gstName: res.data.data.profile.GST_TO_NAME,
						gstNumber: res.data.data.profile.GSTIN_NUMBER,
					},
				});

				if (res.data.data.profile.IS_AGENT === "Y") {
					axiosInstance({
						method: "GET",
						url: `/ta-user/fetch-by-uuid?UUID=${res.data.data.profile.UUID}`,
					})
						.then((res) => {
							dispatch({
								type: "SET_CURRENT_USER",
								payload: {
									couponCode: res.data.data.AMOUNT,
									couponId: res.data.data.COUPON_ID,
									amount: res.data.data.AMOUNT ? +res.data.data.AMOUNT : 0,
									discountPercentage: res.data.data.DISCOUNT || "",
									uuid: res.data.data.profile.UUID,
								},
							});
						})
						.catch((err) => {});
				}
			})
			.catch((err: any) => {
				if (err?.response?.data?.error === "Invalid Token") {
					localStorage.removeItem("token");
					localStorage.removeItem("uuid");
				}
			});
	}
};

export default getUserData;
