import React from "react";
import "./style.scss";

import SvgBrand from "../assets/SvgBrand";
import { BiLogoPinterestAlt } from "react-icons/bi";
import { SvgFooterFacebook } from "assets/icons/js/SvgFooterFacebook";
import { SvgFooterInsta } from "assets/icons/js/SvgFooterInsta";
import { SvgFooterLinkdin } from "assets/icons/js/SvgFooterLinkdin";
import { SvgFooterTwitter } from "assets/icons/js/SvgFooterTwitter";
import { SvgFooterYoutube } from "assets/icons/js/SvgFooterYoutube";

import clevertap from "clevertap-web-sdk";

const FooterDesktop = () => {
	const Community = [
		{
			title: "Club Infinitum",
			slug: "/club-infinitum",
		},
		{
			title: "Our Blog",
			slug: "/blog",
		},
		{
			title: "Our Partners",
			slug: "/partnership",
		},
		{
			title: "Travel Partners",
			slug: "/travelpartner",
		},
	];
	const ForHomeOwners = [
		{
			title: "List your home",
			slug: "https://www.partner.saffronstays.com/",
		},
		{
			title: "Help for Owners",
			slug: "/why-host",
		},
	];
	const Guests = [
		{
			title: "For Celebrations",
			slug: "https://www.celebrations.saffronstays.com/",
		},
		{
			title: "For Corporates",
			slug: "https://www.corporate.saffronstays.com/",
		},
		{
			title: "Staycations",
			slug: "https://www.partner.saffronstays.com/",
		},
		{
			title: "Villas for Corporate Retreats",
			slug: "/collection/corporate-getaway",
		},
		{
			title: "Help for Guests",
			slug: "/responsible-guest-help",
		},
		{
			title: "Offers & Affiliates",
			slug: "/offers",
		},
	];
	const PopularDestinations = [
		{
			title: "Villas in Lonavala",
			slug: "/villas/villas-in-lonavala",
		},
		{
			title: "Villas in Karjat",
			slug: "/villas/villas-in-karjat",
		},
		{
			title: "Villas in Alibaug",
			slug: "/villas/villas-in-alibaug",
		},
		{
			title: "Villas in Kamshet",
			slug: "/villas/villas-in-kamshet",
		},
		{
			title: "Villas in Mahabaleshwar",
			slug: "/villas/villas-in-mahabaleshwa",
		},
		{
			title: "Villas in Panchgani",
			slug: "/villas/villas-in-panchgani",
		},
		{
			title: "Villas in Palghar",
			slug: "/villas/villas-in-palghar",
		},
		{
			title: "Villas in Nashik",
			slug: "/villas/villas-in-nashik",
		},
		{
			title: "Villas in Udaipur",
			slug: "/villas/villas-in-udaipur",
		},
		{
			title: "Villas in Goa",
			slug: "/villas/villas-in-goa",
		},
	];
	const HomeStays = [
		{
			title: "Homestays in Manali",
			slug: "/homestays/homestays-in-manali",
		},
		{
			title: "Homestays in Shimla",
			slug: "/homestays/homestays-in-shimla",
		},
		{
			title: "Homestays in Himachal",
			slug: "/homestays/homestays-in-himachal",
		},
		{
			title: "Homestays in Uttarakhand",
			slug: "/homestays/homestays-in-uttarakhand",
		},
		{
			title: "Homestays in Nainital",
			slug: "/homestays/homestays-in-nainital",
		},
		{
			title: "Homestays in Dehradun",
			slug: "/homestays/homestays-in-dehradun",
		},
		{
			title: "Homestays in Bhimtal",
			slug: "/homestays/homestays-in-bhimtal",
		},
		{
			title: "Homestays in Mukteshwar",
			slug: "/homestays/homestays-in-mukteshwar",
		},
		{
			title: "Homestays in Mussoorie",
			slug: "/homestays/homestays-in-mussoorie",
		},
		{
			title: "Homestays in Wayanad",
			slug: "/homestays/homestays-in-wayanad",
		},
		{
			title: "Homestays in Kodaikanal",
			slug: "/homestays/homestays-in-kodaikanal",
		},
		{
			title: "Homestays in Coorg",
			slug: "/homestays/homestays-in-coorg",
		},
		{
			title: "Homestays in Ooty",
			slug: "/homestays/homestays-in-ooty",
		},
		{
			title: "Homestays in Udaipur",
			slug: "/homestays/homestays-in-udaipur",
		},
	];
	const CollectionUrl = [
		{
			ID: 60,
			TITLE: "Newly Launched Villas",
			DESCRIPTION:
				"Best introductory offer on new villas -  Use Code NEWGEM. First 5 bookings get flat 40% Off.  Book the best new homes near you, today!",
			HOME_COVER_IMAGE: "collections/mcqqkql6yng2aye7k39j",
			SLUG: "newly-launched-villas",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 24,
			TITLE: "Corporate Offsite Villas",
			DESCRIPTION:
				'"Go as colleagues, Come back as Family". WFH has strained relations with colleagues, and left many new joinees clueless on Company culture and feeling left out.  Bring your flock together, organise ',
			HOME_COVER_IMAGE: "collections/ym7jyn2dnhybzmxqhhg5",
			SLUG: "corporate-getaway",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 25,
			TITLE: "Top Luxury Villas & Estates",
			DESCRIPTION:
				"Our Signature collection of luxury villas and estates across India like Uttarakhand, Himachal Pradesh, Maharashtra, Goa and more.",
			HOME_COVER_IMAGE: "collections/qpxolf8xj39ubl2jksdu",
			SLUG: "Signature-villas-and-estates",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 58,
			TITLE: "Top Heated Pools",
			DESCRIPTION:
				"When you don't want to jump into an icy pool, these heated pools and jacuzzis will take your vacations up a notch! Book a getaway, come rain or shine.",
			HOME_COVER_IMAGE: "collections/wrbqvn51foblbfbvskli",
			SLUG: "best-heated-pools-and-jacuzzi-villa",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 56,
			TITLE: "Couple Friendly Villas",
			DESCRIPTION:
				"Book these top couple-friendly villas for your honeymoon, anniversary or just a romantic holiday. Private spaces, modern amenities and full-stack service and hospitality, just for you!  ",
			HOME_COVER_IMAGE: "collections/kmohdskufqhbc6sruu6g",
			SLUG: "top-couple-friendly-villas-for-romantic-holiday",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 34,
			TITLE: "Surprise Me",
			DESCRIPTION:
				'Book hidden gems in offbeat destinations across India. These founder-recommended homes will make you say, "I saw this first"!',
			HOME_COVER_IMAGE: "collections/cplxxxkkgfpdpa58u39a",
			SLUG: "hidden-gems",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 54,
			TITLE: "Best Bucket List Ideas In India",
			DESCRIPTION:
				"Here are the top bucket list trip ideas for couples, families and friends looking for unique stay options in India. Add these boutique getaways to your travel list, to explore and make unforgettable m",
			HOME_COVER_IMAGE: "collections/pl3avsslt5agphzwot5c",
			SLUG: "top-bucket-list-trip-ideas-india-stay",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 71,
			TITLE: "Top Celebrity Choice Villas",
			DESCRIPTION:
				"Did you even go on holiday if you didn't Instagram it? These 'Gram-favourite villas are celebrity and influencer loved! Book your holiday, now!",
			HOME_COVER_IMAGE: "collections/slty7luyrwa1wyxrhvln",
			SLUG: "celebrity-choice-perfect-for-instagram-villa",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 38,
			TITLE: "Award Winning Villas",
			DESCRIPTION:
				"While the brand has received innumerable awards and accolades, these private villas and estates have also won awards in their own right.",
			HOME_COVER_IMAGE: "collections/wfnvkiw9jrb7i7m5uwtz",
			SLUG: "award-winning-best-villas",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 39,
			TITLE: "Winter Wonderland",
			DESCRIPTION:
				"Hot chocolate, an indoor fireplace and unobstructed views of snow-capped mountains from your private villa. Winters in Uttarakhand and Himachal Pradesh spell out magic at these estates!",
			HOME_COVER_IMAGE: "collections/js0srs5jk1faevsupq8w",
			SLUG: "best-winter-wonderland-villas-with-snow",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 29,
			TITLE: "Pure Veg Homes",
			DESCRIPTION:
				"A collection of Veg only homes across India like Uttarakhand, Himachal Pradesh, Maharashtra, Goa and more.",
			HOME_COVER_IMAGE: "collections/skufsga4fct74utuypan",
			SLUG: "veg-only-homes",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 30,
			TITLE: "Top Pet-Friendly Villas",
			DESCRIPTION:
				"We have more than 100 pet-friendly villas across India. Plan a pet getaway with your fur babies to our homes. Many are at driveable distance from Mumbai and Pune. ",
			HOME_COVER_IMAGE: "collections/k26ulpiwynm4qywkld9h",
			SLUG: "pet-friendly-villas",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 87,
			TITLE: "Big Villas for Large Groups",
			DESCRIPTION:
				"Looking for large homes for big celebrations? These villas and clusters are ideal for birthdays, events and occasions that call for 8+ rooms for your big group getaway.",
			HOME_COVER_IMAGE: "collections/c3saa9drvjhb8ogf166j",
			SLUG: "book-large-villas-big-group-bungalow-rent",
			ALT_TEXT_IMAGE: "",
		},
		{
			ID: 57,
			TITLE: "Amazing Pool Villas",
			DESCRIPTION:
				"Looking for the best private pool villas? From infinity pools to heated Jacuzzis, book these top pool villas for your next vacation.",
			HOME_COVER_IMAGE: "collections/c5qksymrwifccblbhnez",
			SLUG: "top-private-pool-villas",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 27,
			TITLE: "Pocket-Friendly Villas",
			DESCRIPTION:
				"Looking for Pocket-Friendly Villas? Book these private villas starting from Rs 3,000",
			HOME_COVER_IMAGE: "collections/u65q3uqb4ds8pqi3weon",
			SLUG: "budget-villas",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 67,
			TITLE: "Kid-Friendly Villas",
			DESCRIPTION:
				"Pamper your kids to a holiday in a private children-friendly villa, and give them the biggest git - all your attention and time!",
			HOME_COVER_IMAGE: "collections/nt7dyijijm21jhnplfsh",
			SLUG: "children-friendly-holiday-villas",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 68,
			TITLE: "Impeccable View Villas",
			DESCRIPTION:
				"SaffronStays' hand-picked breathtaking view villas collection in India to rent in beautiful locations and top-notch service for amazing stay experience. Book Now!\"",
			HOME_COVER_IMAGE: "collections/dmmcmpnfww7fydyxngw3",
			SLUG: "best-impeccable-view-villas",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 85,
			TITLE: "Top Villas With Games, Activities & Facilities ",
			DESCRIPTION:
				"Book the best villa with activities and facilities near you. Who said a getaway in a bungalow is boring when you have a private arcade just for yourself? Rent these private estates with indoor and outdoor games for your next weekend getaway. ",
			HOME_COVER_IMAGE: "collections/k6pqxyydrburlebitrjg",
			SLUG: "villas-with-activities-and-facilities",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 59,
			TITLE: "Best Heritage Homes",
			DESCRIPTION:
				"Stay in India's top heritage havelis and old-world-inspired homes, unlocked just for you. Dive into history books that come alive via the Home Owner's eyes.",
			HOME_COVER_IMAGE: "collections/ohrmdcn6bea6b17xg9pc",
			SLUG: "top-heritage-homes",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 70,
			TITLE: "Top Media Favourites",
			DESCRIPTION:
				"Don't listen to us, just go ahead and book these stunning private villas and homes that have been recognised by and featured in India's top media publications.",
			HOME_COVER_IMAGE: "collections/kog2dst41n1d5obhnf9d",
			SLUG: "famous-media-favourite-villas-india",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 80,
			TITLE: "Off The Grid Getaways",
			DESCRIPTION:
				"Tired of staring at the screen all day long? Book a digital detox from this list of must-try villas for a getaway immersed in nature, where you will be pampered with in-house staff and chef and top-class service.",
			HOME_COVER_IMAGE: "collections/czo7msl4x5pg1sfpsfns",
			SLUG: "villas-for-digital-detox",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 83,
			TITLE: "Best Private Al Fresco Dining ",
			DESCRIPTION:
				"Looking for private alfresco dining options near you? Book private villas to celebrate any events or occasions, and enjoy an outdoor dining experience. We personalise veg and non-veg gourmet meals for your needs, and provide warm hospitality and service. Book now!",
			HOME_COVER_IMAGE: "collections/hlt7irmbq37zbj2edusl",
			SLUG: "top-private-alfresco-dining-villas",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
		{
			ID: 84,
			TITLE: "Top Glistening Lake View Villas",
			DESCRIPTION:
				"The best holiday view has to be a lakefront view. Book these lake view villas and enjoy a calm holiday with SaffronStays. ",
			HOME_COVER_IMAGE: "collections/dqkyqmzqvuihwkf5mnlg",
			SLUG: "best-lake-view-villa",
			ALT_TEXT_IMAGE: "Swimming Pool",
		},
	];
	const About = [
		{
			title: "Our Story",
			slug: "/about-us",
		},
		{
			title: "Join us",
			slug: "/join-us",
		},
		{
			title: "Contact us",
			slug: "/contact-us",
		},
		{
			title: "Submit a Bug",
			slug: "/reportbugs",
		},
		{
			title: "Privacy Policy",
			slug: "/privacy-policy",
		},
		{
			title: "Help",
			slug: "/help-and-policies",
		},
		{
			title: "Policies",
			slug: "/privacy-policy",
		},
		{
			title: "Sitemap",
			slug: "/sitemap",
		},
	];
	return (
		<div className="footer-wrapper">
			<div className="footer-container">
				<div className="footer-content">
					<div className="footer-column1">
						<div className="footer-brand-wrapper">
							<SvgBrand />
						</div>
						<div className="footer-socials">
							<div className="footer-socials-text">
								<p>
									© {new Date().getFullYear()} SaffronStays. All rights reserved
								</p>
							</div>
							<div className="footer-socials-icons">
								<a
									aria-label="Fb"
									href="https://www.facebook.com/saffronstays"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Facebook",
										});
									}}
								>
									<SvgFooterFacebook />
								</a>
								<a
									aria-label="Twitter"
									href="https://twitter.com/saffronstays"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Twitter",
										});
									}}
								>
									<SvgFooterTwitter />
								</a>
								<a
									aria-label="Linkedin"
									href="https://www.linkedin.com/company/saffronstays"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Linkedin",
										});
									}}
								>
									<SvgFooterLinkdin />
								</a>
								<a
									aria-label="Instagram"
									href="https://www.instagram.com/saffronstays/"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Instagram",
										});
									}}
								>
									<SvgFooterInsta />
								</a>
								<a
									aria-label="Youtube"
									href="https://www.youtube.com/channel/UC0lvPYqLKtJ_GkoCG-YPY0A"
									target="_blank"
									rel="noreferrer"
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Youtube",
										});
									}}
								>
									<SvgFooterYoutube />
								</a>
								<a
									aria-label="Pinterest"
									href="https://pin.it/3o3iGHe"
									target="_blank"
									rel="noreferrer"
									style={{ fontSize: "19px", color: "white" }}
									onClick={() => {
										clevertap.event.push("social_media_links", {
											timestamp: new Date(),
											type: "Pinterest",
										});
									}}
								>
									<BiLogoPinterestAlt />
								</a>
							</div>
						</div>
					</div>
					<div className="footer-column2">
						<div className="footer-sub-column1">
							<div className="footer-sub-column1-details">
								<p>Community</p>
								<div className="footer-sub-column1-details-links">
									{Community.map((ele) => {
										return (
											<a href={ele.slug}>
												<p>{ele.title}</p>
											</a>
										);
									})}
								</div>
							</div>
							<div className="footer-sub-column1-details">
								<p>For Home Owners</p>
								<div className="footer-sub-column1-details-links">
									{ForHomeOwners.map((ele) => {
										return (
											<a href={ele.slug}>
												<p>{ele.title}</p>
											</a>
										);
									})}
								</div>
							</div>
							<div className="footer-sub-column1-details">
								<p>Guests</p>
								<div className="footer-sub-column1-details-links">
									{Guests.map((ele) => {
										return (
											<a href={ele.slug}>
												<p>{ele.title}</p>
											</a>
										);
									})}
								</div>
							</div>
						</div>
						<div className="footer-sub-column1">
							<div className="footer-sub-column1-details">
								<p>Popular Destinations</p>
								<div className="footer-sub-column1-details-links">
									{PopularDestinations.map((ele) => {
										return (
											<a href={ele.slug}>
												<p>{ele.title}</p>
											</a>
										);
									})}
								</div>
							</div>
							<div className="footer-sub-column1-details">
								<p>Homestays</p>
								<div className="footer-sub-column1-details-links">
									{HomeStays.map((ele) => {
										return (
											<a href={ele.slug}>
												<p>{ele.title}</p>
											</a>
										);
									})}
								</div>
							</div>
						</div>
						<div className="footer-sub-column1">
							<div className="footer-sub-column1-details">
								<p>Collections</p>
								<div className="footer-sub-column1-details-links">
									{CollectionUrl.map((ele) => {
										return (
											<a
												aria-label="collection"
												href={"/collection/" + ele.SLUG}
											>
												<p>{ele.TITLE}</p>
											</a>
										);
									})}
								</div>
							</div>
						</div>
						<div className="footer-sub-column1">
							<div className="footer-sub-column1-details">
								<p>About SaffronStays</p>
								<div className="footer-sub-column1-details-links">
									{About.map((ele) => {
										return (
											<a href={ele.slug}>
												<p>{ele.title}</p>
											</a>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterDesktop;
