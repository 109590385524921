import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { ReactComponent as Success } from "./assets/Success.svg";
import { ReactComponent as Copy } from "./assets/Copy.svg";
import "./style.scss";
import Modal from "components/LoginAndRegister/Modal";
import SuccessClaimPopup from "./SuccessClaimPopup";
import EmailLoginHandler from "components/LoginAndRegister/EmailLoginHandler";
import PhoneLoginHandler from "components/LoginAndRegister/PhoneLoginHandler";
import { NavbarAndSidebar } from "components/NavbarAndSidebar";
import Footer from "components/Footer";
import { UniversalURLInterface } from "context/UniversalURLData";
import { Rings } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ClaimFriendCoupon = ({ hashId }: { hashId: string }) => {
	const { universalData }: any = useContext(UniversalURLInterface);
	const [friendCoupon, setFriendCoupon] = useState([]);
	const [successPopup, setSuccessPopup] = useState(false);
	const [couponCode, setCouponCode] = useState<string>("");
	const [shouldShowModal, setShouldShowModal] = useState<boolean>(false);
	const [phone, setPhone] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	interface FriendCoupon {
		MAPPING_ID: number;
		COUPON_TYPE: string;
		CLAIM_STATUS: boolean;
		coupon: Coupon;
	}

	interface Coupon {
		COUPON_ID: number;
		COUPON_CODE: string;
	}

	const fetchFriendCoupons = async () => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`https://ecapi.saffronstays.com/fetch/referral-coupons/${hashId}`,
			);
			if (res?.data?.success) {
				setIsLoading(false);
				setFriendCoupon(res?.data?.data);
			}
		} catch (error) {
			if (error instanceof Error) {
				console.log(error?.message);
			}
		}
	};
	const redeemCoupon = async (mappingId: number) => {
		const payload = {
			MAPPING_ID: mappingId,
		};
		try {
			const res = await axios.patch(
				"https://ecapi.saffronstays.com/update/rating/coupon",
				payload,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				},
			);
			if (res?.data?.success) {
				fetchFriendCoupons();
				setSuccessPopup(true);
			}
		} catch (error) {
			if (error instanceof Error) {
				console.log(error?.message);
			}
		}
	};
	useEffect(() => {
		fetchFriendCoupons();
	}, []);

	return (
		<>
			<NavbarAndSidebar />
			<div className="claim-friend-coupon-wrapper">
				<div className="claim-friend-coupon-content">
					<div className="claim-friend-coupon-header">
						<h3>
							{universalData?.data?.GUEST_DETAILS?.FULL_NAME} has sent you a
							special coupon! Redeem ANY ONE now!
						</h3>
					</div>
					<div className="claim-friend-coupon-details">
						{isLoading ? (
							<Rings color="#2368a2" />
						) : friendCoupon.length === 0 ? (
							<p>No coupons left to be redeemed</p>
						) : (
							friendCoupon.map((ele: FriendCoupon, index: number) => {
								return (
									<div className="coupon-card-wrapper" key={index}>
										<div className="coupon-card-content">
											<div className="coupon-card-header">
												<h3>Coupon {index + 1}</h3>
											</div>
											<div className="coupon-card-mid">
												<p>
													Hurry up! This coupon is valid until 1st April, 2025.
												</p>
											</div>
											<div className="coupon-card-bottom">
												{
													ele?.CLAIM_STATUS?<p>{ele?.coupon?.COUPON_CODE}</p>:<p>XXXXXXX</p>
												}

												{ele?.CLAIM_STATUS ? (
													<button
														style={{
															opacity: 0.7,
														}}
														onClick={() => {
															toast.success("Coupon has already been claimed", {
																position: "top-right",
																autoClose: 1250,
																hideProgressBar: true,
																icon: true,
																closeOnClick: false,
																pauseOnHover: false,
																draggable: false,
																progress: undefined,
															});
														}}
													>
														Claimed
													</button>
												) : (
													<button
														onClick={() => {
															if (localStorage) {
																if (!localStorage?.getItem("token")) {
																	setShouldShowModal(true);
																} else {
																	setShouldShowModal(false);
																}
															}
															setCouponCode(ele?.coupon?.COUPON_CODE);
															redeemCoupon(ele?.MAPPING_ID);
														}}
													>
														Claim
													</button>
												)}
											</div>
										</div>
									</div>
								);
							})
						)}
					</div>
				</div>
			</div>
			<Footer />
			<Modal
				shouldShow={successPopup}
				onRequestClose={() => setSuccessPopup(false)}
			>
				<SuccessClaimPopup
					onClose={() => {
						setSuccessPopup(false);
					}}
					couponCode={couponCode}
				/>
			</Modal>
			<Modal
				shouldShow={shouldShowModal}
				onRequestClose={() => setShouldShowModal(true)}
			>
				{phone ? (
					<PhoneLoginHandler
						onRequestClose={() => setShouldShowModal(false)}
						setPhone={setPhone}
						// @ts-ignore
						phone={phone}
					/>
				) : (
					<EmailLoginHandler
						onRequestClose={() => setShouldShowModal(false)}
						setPhone={setPhone}
						phone={phone}
					/>
				)}
			</Modal>
		</>
	);
};

export default ClaimFriendCoupon;
