import { useState, useLayoutEffect } from "react";

export function useMediaQuery() {
	const [width, setWindowWidth] = useState(0);

	useLayoutEffect(() => {
		updateDimensions();

		window.addEventListener("resize", updateDimensions);

		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const updateDimensions = () => {
		const width = window.innerWidth;
		setWindowWidth(width);
	};

	return width;
}
