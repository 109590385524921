import { useState } from "react";

export const useToken = () => {
	const [token, setTokenInternal] = useState(() => {
		try {
			const item = window.localStorage.getItem("token");
			return item;
		} catch (error) {}
	});

	const setToken = (newToken: any) => {
		localStorage.setItem("token", newToken);
		localStorage.setItem("allow", "true");
		setTokenInternal(newToken);
	};

	return [token, setToken];
};
