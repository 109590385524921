export const SvgLocation = (props) => {
	return (
		<svg
			width="14"
			height="20"
			viewBox="0 0 14 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M12.9199 3.4249C11.7061 1.33021 9.55015 0.0504883 7.15269 0.00158203C7.05035 -0.000527344 6.94734 -0.000527344 6.84496 0.00158203C4.44754 0.0504883 2.2916 1.33021 1.07777 3.4249C-0.162933 5.566 -0.196879 8.13787 0.98695 10.3047L5.94644 19.3823C5.94867 19.3863 5.9509 19.3904 5.9532 19.3944C6.1714 19.7736 6.5623 20 6.9989 20C7.43547 20 7.82636 19.7736 8.04453 19.3944C8.04683 19.3904 8.04906 19.3863 8.05129 19.3823L13.0108 10.3047C14.1945 8.13787 14.1606 5.566 12.9199 3.4249ZM6.99883 9.06252C5.448 9.06252 4.18633 7.80084 4.18633 6.25002C4.18633 4.6992 5.448 3.43752 6.99883 3.43752C8.54965 3.43752 9.81133 4.6992 9.81133 6.25002C9.81133 7.80084 8.54968 9.06252 6.99883 9.06252Z"
				fill={props.color ? props.color : "#2368a2"}
			/>
		</svg>
	);
};
