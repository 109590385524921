import { useLocation } from "react-router-dom";
import { Oval } from "react-loader-spinner";

// icons
import { SvgClose as Close } from "assets/icons/js/SvgClose";

// scss
import "./style.scss";

export const CreatingAccount = ({ onRequestClose }) => {
	const { pathname } = useLocation();

	return (
		<div className="creatingAccount">
			<div className="creating__account">
				{pathname !== "/login" && (
					<div className="close-icon">
						<span onClick={onRequestClose}>
							<Close height={12} width={12} color={"#fff"} />
						</span>
					</div>
				)}
				<div className="creating__account-container">
					<div className="spinner">
						<Oval color="#fff" height={20} width={20} />
					</div>
					<div className="creating__account-text">
						<p>Please wait while we create your account!</p>
					</div>
				</div>
			</div>
		</div>
	);
};
