import React, { useState } from "react";
import "./style.scss";
import { ReactComponent as Success } from "../assets/Success.svg";
import { ReactComponent as Close } from "../assets/Close.svg";

type SuccessClaimPopupProps = {
	onClose: () => void;
	couponCode: string;
};

const SuccessClaimPopup: React.FC<SuccessClaimPopupProps> = ({
	onClose,
	couponCode,
}: SuccessClaimPopupProps): JSX.Element => {
	const [copied, setCopied] = useState<boolean>(false);

	const copyToClipboard = async (couponCode: string): Promise<void> => {
		try {
			await navigator.clipboard.writeText(couponCode);
			setCopied(true);
			setTimeout(() => setCopied(false), 2000);
		} catch (error) {
			console.error("Failed to copy: ", error);
		}
	};

	return (
		<div className="claim-success-wrapper">
			<div className="claim-success-content">
				<div className="claim-success-header">
					<p>Coupon redeemed</p>
				</div>
				<div className="claim-success-logo">
					<Success />
				</div>
				<div className="claim-success-code">
					<p>{couponCode}</p>
				</div>
				<div className="claim-success-copy-btn">
					<button onClick={() => copyToClipboard(couponCode)}>
						Copy
					</button>
					{copied && <span>Copied!</span>}
				</div>
			</div>
			<div className="claim-success-close">
				<Close onClick={()=>{
          onClose()
        }} />
			</div>
		</div>
	);
};



export default SuccessClaimPopup;
