import React from "react";

export const SvgMenu = (props) => {
	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3.25 19.5H22.75M3.25 6.5H22.75H3.25ZM3.25 13H22.75H3.25Z"
				stroke={props.color ? "#333333" : "white"}
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
