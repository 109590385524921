import { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useNavigate } from "react-router-dom";

// custom hooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// helpers
import { axiosInstance } from "helpers/axiosInstance";

import "./styles.scss";

const FacebookLoginWrapper = ({ children, onFailure, onSuccess = null }) => {
	const [showError, setShowError] = useState(false);

	const width = useMediaQuery();
	const navigator = useNavigate();

	const facebookResponse = (response) => {
		if (!response.email) {
			setShowError(true);
			setTimeout(() => {
				setShowError(false);
			}, 2000);
			return;
		}
		// To get details of user from SaffronStays database
		axiosInstance({
			method: "POST",
			url: "/FacebookSignup",
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				FIRST_NAME: response.name.split(" ")[0],
				LAST_NAME: response.name.split(" ").slice(1).join(" "),
				EMAIL_ID: response.email,
				FACEBOOK_ID: response.userID,
				IMAGE_UPDATE: response.picture.data.url,
			},
		})
			.then((res) => {
				localStorage.setItem("token", res?.data?.data?.token);

				localStorage.setItem("allow", "true");
				window.location.reload();
				if (width < 500) {
					navigator(-1);
				}
			})
			.catch((err) => {});
	};

	const facebookFailure = (response) => {};

	return (
		<>
			<FacebookLogin
				appId={process.env.REACT_APP_FACEBOOK_APP_ID}
				autoLoad={false}
				fields="name,email,picture"
				scope="email,public_profile"
				callback={onSuccess ? onSuccess : facebookResponse}
				onFailure={onFailure ? onFailure : facebookFailure}
				render={(renderProps) => (
					<div onClick={renderProps.onClick}>{children}</div>
				)}
			/>
			{showError && (
				<div className="facebook_email_error">
					<p>
						You email is not verified on facebook. Please try another login
						method.
					</p>
				</div>
			)}
		</>
	);
};

export default FacebookLoginWrapper;
