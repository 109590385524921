import { Link } from "react-router-dom";

// styles
import "./styles.scss";

export const UniversalURLMainErrorScreen = () => {
	return (
		<div className="universalURL_main_error_screen__container-page">
			<div className="universalURL_main_error_screen__container-content">
				<p className="universalURL_main_error_screen__para-main">Oops!</p>
				<h1 className="universalURL_main_error_screen__heading-main">
					An unexpected error has occurred...
				</h1>

				<p className="universalURL_main_error_screen__para-secondary">
					Try again after sometime or contact SaffronStays.
				</p>
				<Link
					aria-label="gohome"
					to="/"
					title="Go Home"
					className="universalURL_main_error_screen__link-go-home"
				>
					Go Home
				</Link>
			</div>
		</div>
	);
};
