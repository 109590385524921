export const SvgProfile = (props: any) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3.16699 17.5003C3.16699 18.0526 3.61471 18.5003 4.16699 18.5003C4.71928 18.5003 5.16699 18.0526 5.16699 17.5003H3.16699ZM14.8337 17.5003C14.8337 18.0526 15.2814 18.5003 15.8337 18.5003C16.3859 18.5003 16.8337 18.0526 16.8337 17.5003H14.8337ZM5.16699 17.5003C5.16699 14.8309 7.33095 12.667 10.0003 12.667V10.667C6.22638 10.667 3.16699 13.7264 3.16699 17.5003H5.16699ZM10.0003 12.667C12.6697 12.667 14.8337 14.8309 14.8337 17.5003H16.8337C16.8337 13.7264 13.7743 10.667 10.0003 10.667V12.667Z"
				fill="#666666"
			/>
			<path
				d="M12.3337 5.83333C12.3337 7.122 11.289 8.16667 10.0003 8.16667V10.1667C12.3936 10.1667 14.3337 8.22657 14.3337 5.83333H12.3337ZM10.0003 8.16667C8.71166 8.16667 7.66699 7.122 7.66699 5.83333H5.66699C5.66699 8.22657 7.60709 10.1667 10.0003 10.1667V8.16667ZM7.66699 5.83333C7.66699 4.54467 8.71166 3.5 10.0003 3.5V1.5C7.60709 1.5 5.66699 3.4401 5.66699 5.83333H7.66699ZM10.0003 3.5C11.289 3.5 12.3337 4.54467 12.3337 5.83333H14.3337C14.3337 3.4401 12.3936 1.5 10.0003 1.5V3.5Z"
				fill="#666666"
			/>
		</svg>
	);
};
