import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import { AddReferralCode } from "../AddReferralCode";
import { CreatingAccount } from "../CreatingAccount";
import { ForgetPassword } from "../ForgetPassword";
import { Login } from "../Login";
import { Signup } from "../Signup";
// import { BottomNavbar } from "components/BottomNavbar";
// import { Admin } from "../Admin";

// custom hook
import { useMediaQuery } from "customHooks/useMediaQuery";

// icon
import SvgBackArrow from "assets/icons/js/SvgBackArrow";
import { SvgClose as Close } from "assets/icons/js/SvgClose";
import { SvgLogo as Logo } from "assets/icons/js/SvgLogo";

// css
import "./style.scss";

const EmailLoginHandler = ({
	onRequestClose,
	setPhone,
	phone,
	noIcon = true,
	redirect = "",
}) => {
	const [active, setActive] = useState("Log in");
	const [forgetPassword, setForgetPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showReferralCodeScreen, setShowReferralCodeScreen] = useState(false);
	const navigate = useNavigate();
	const width = useMediaQuery();
	const isMobile = width < 768;

	if (loading) {
		return <CreatingAccount onRequestClose={onRequestClose} />;
	}

	return !showReferralCodeScreen ? (
		!forgetPassword ? (
			<div className="register__wrapper-box">
				<div className="register__header-2">
					<div className="register__header--top">
						<div className="register__header-empty"></div>
						<div className="register__header-logo">
							{isMobile && (
								<button
									aria-label="go-back"
									className="register__header-back-btn"
									onClick={() => {
										if (!window.location.href.includes("login"))
											window.location.reload();
										else {
											window.location.href = "/";
										}
									}}
								>
									<SvgBackArrow />
								</button>
							)}
							<Link
								aria-label="logo"
								to="/"
								className="register__logo--container"
							>
								<Logo height={24} />
							</Link>
						</div>
						<div className="register__wrapper-close">
							{/* <span className="svg--container" onClick={onRequestClose}>
							{noIcon && !isMobile && <Close height={12} width={12} />}
						</span> */}
						</div>
					</div>
					<div className="register__header-title">
						<span
							className={
								active === "Log in" ? "register__header-login active" : ""
							}
							onClick={() => setActive("Log in")}
						>
							<p className={active === "Log in" ? "active-tab" : ""}>Log in</p>
						</span>
						<span
							className={
								active === "Sign up" ? "register__header-register active" : ""
							}
							onClick={() => setActive("Sign up")}
						>
							<p className={active === "Sign up" ? "active-tab" : ""}>
								Register
							</p>
						</span>
						{/* <span
						className={
							active === "Admin" ? "register__header-register active" : ""
						}
						onClick={() => setActive("Admin")}
					>
						<p className={active === "Admin" ? "active-tab" : ""}>Admin</p>
					</span> */}
					</div>
				</div>
				{active === "Log in" && (
					<Login
						setPhone={setPhone}
						phone={phone}
						onRequestClose={onRequestClose}
						setForgetPassword={setForgetPassword}
						redirect={redirect}
					/>
				)}
				{active === "Sign up" && (
					<Signup
						setPhone={setPhone}
						phone={phone}
						setLoading={setLoading}
						setForgetPassword={setForgetPassword}
						showReferralCodeScreen={() => setShowReferralCodeScreen(true)}
						redirect={redirect}
					/>
				)}
				{/* {active === "Admin" && (
				<Admin
					setPhone={setPhone}
					phone={phone}
					onRequestClose={onRequestClose}
					setForgetPassword={setForgetPassword}
					otherLink={false}
				/>
			)} */}
			</div>
		) : (
			<ForgetPassword onRequestClose={onRequestClose} />
		)
	) : (
		<AddReferralCode redirect={redirect} />
	);
};

export default EmailLoginHandler;
