import React from "react";

export const SvgAddDriver = () => {
	return (
		<svg
			width="19"
			height="24"
			viewBox="0 0 19 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.437 20.0653H10.7738C10.6173 20.111 10.5097 20.2544 10.5097 20.4173C10.5097 20.5804 10.6173 20.7238 10.7738 20.7695H17.7962C17.8934 20.7695 17.9867 20.7308 18.0555 20.6621C18.1243 20.5933 18.1629 20.5 18.1629 20.4027C18.1609 18.2925 17.3221 16.2692 15.8302 14.7768C14.3385 13.2842 12.3155 12.4444 10.2055 12.4414C9.01188 12.4386 7.83317 12.707 6.75839 13.2263C6.6451 13.2462 6.5477 13.3181 6.49531 13.4206C6.44292 13.5231 6.44161 13.6441 6.49187 13.7477C6.54213 13.8512 6.63807 13.925 6.75102 13.9473C6.86382 13.9695 6.98071 13.9374 7.06633 13.8606C8.04513 13.3879 9.11844 13.1433 10.2055 13.1456C12.0681 13.152 13.8572 13.8736 15.2032 15.1615C16.5491 16.4493 17.3488 18.2049 17.4372 20.0654L17.437 20.0653Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.5"
			/>
			<path
				d="M15.3198 4.27252C15.3843 4.10947 15.3244 3.92349 15.1767 3.82886L10.5013 1.04924C10.3878 0.983588 10.248 0.983588 10.1346 1.04924L5.46288 3.82886C5.30343 3.92676 5.24334 4.12911 5.3234 4.29821L5.96873 5.71007H5.96889C5.54472 6.98471 5.70057 8.38098 6.3952 9.53074C7.08981 10.6805 8.25348 11.4681 9.57905 11.6855C10.9046 11.9031 12.2588 11.5287 13.2845 10.6612C14.3101 9.79368 14.9039 8.52016 14.9093 7.17691C14.9096 6.69365 14.8329 6.21332 14.6819 5.75412L15.3198 4.27252ZM6.43452 7.14385C6.43518 6.83247 6.47332 6.52224 6.54814 6.21986H14.0915C14.3537 7.29791 14.1422 8.43681 13.5103 9.34885C12.8786 10.2611 11.8867 10.8594 10.7852 10.9928C9.68362 11.1263 8.57758 10.7822 7.74625 10.0474C6.91476 9.31251 6.43741 8.25722 6.4346 7.14762L6.43452 7.14385ZM14.5499 4.27612L14.0035 5.51557H6.66926L6.09727 4.27235L10.3071 1.7643L14.5499 4.27612Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.5"
			/>
			<path
				d="M1.57982 21.2002C2.28131 22.3207 3.51044 23.0012 4.83257 23.0006C6.0332 22.9974 7.16284 22.4311 7.88382 21.4709C8.60479 20.5108 8.83349 19.2681 8.50165 18.1142C8.16981 16.9602 7.3159 16.0289 6.19498 15.5983C5.07422 15.1678 3.81626 15.2881 2.79735 15.9231C1.93736 16.4626 1.3264 17.3209 1.0982 18.31C0.869985 19.2992 1.04319 20.3384 1.57982 21.2002ZM7.91282 18.6589L1.7483 18.6587C1.89302 17.7747 2.41148 16.9956 3.17127 16.5208C4.05728 15.9678 5.16102 15.8935 6.11329 16.3227C7.06544 16.7521 7.74057 17.6285 7.91282 18.6587V18.6589ZM6.48625 21.82V21.8198C6.11233 22.0528 5.6939 22.2052 5.25779 22.2672V19.3628H7.94576C7.88322 20.371 7.33677 21.2864 6.47891 21.8198L6.48625 21.82ZM1.71901 19.363H4.5646V22.2967L4.56476 22.2965C3.81659 22.2324 3.11674 21.9002 2.59387 21.3613C2.07113 20.8222 1.76041 20.1126 1.719 19.3628L1.71901 19.363Z"
				fill="#2368a2"
				stroke="#2368a2"
				strokeWidth="0.5"
			/>
		</svg>
	);
};
