import { useState } from "react";

// components
import { BottomContainer } from "components/NewForm/BottomContainer";
import { Stepper } from "components/Stepper";
import { BottomBar } from "./BottomBar";
import { PersonalStep1 } from "./PersonalStep1";
import { PersonalStep2 } from "./PersonalStep2";
import { PersonalStep3 } from "./PersonalStep3";

// style
import "./style.scss";

const data = ["Trip details", "Guest details", "Other details"];

export const WantToBook = () => {
	// to control which step is review on
	const [currentStep, setCurrentStep] = useState(0);
	const [tab, setTab] = useState(data[0]);

	return (
		<div className="want__to__book__container">
			<BottomContainer>
				<Stepper currentStep={currentStep}>
					<PersonalStep1 />
					<PersonalStep2 setTab={setTab} tab={tab} data={data} />
					<PersonalStep3 />
				</Stepper>
				<BottomBar
					currentStep={currentStep}
					setStep={setCurrentStep}
					data={data}
					setTab={setTab}
					tab={tab}
				/>
			</BottomContainer>
		</div>
	);
};
