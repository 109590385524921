import "./style.scss";
interface AddOnsLoaderType {
	size?: "sm" | "md" | "lg";
}
const AddOnsLoader = ({ size = "md" }: AddOnsLoaderType) => {
	return (
		<div className={`addons-loader-wrapper ${size}`}>
		</div>
	);
};

export default AddOnsLoader; 
