import { useMediaQuery } from "customHooks/useMediaQuery";
import React from "react";
import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";

const Footer = () => {
	const screenWidth = useMediaQuery();

	return (
		<div>
			{screenWidth >= 768 && <FooterDesktop />}
			{screenWidth < 768 && <FooterMobile />}
		</div>
	);
};

export default Footer;
