import { axiosInstance } from "./axiosInstance";

interface Props {
	index: string;
	payload: object;
}

export const elasticLog = ({ index, payload }: Props) => {
	try {
		axiosInstance.post("/elasticsearch/document", {
			index,
			payload: {
				...payload,
				timestamp: new Date(),
			},
		});
	} catch (err) {
		console.log(err);
	}
};
