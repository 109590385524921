export const SvgFooterTwitter = (props: any) => {
	return (
		<svg
			width="19"
			height="16"
			viewBox="0 0 19 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.05882 15.3548C7.48601 15.3647 8.90094 15.0908 10.2214 14.5492C11.5419 14.0076 12.7415 13.2089 13.7507 12.1997C14.7599 11.1905 15.5586 9.99086 16.1002 8.6704C16.6418 7.34994 16.9157 5.93502 16.9058 4.50782C16.9058 4.34315 16.9022 4.17882 16.8948 4.01482C17.6402 3.47475 18.2836 2.80633 18.7948 2.04082C18.0993 2.34918 17.3614 2.55143 16.6058 2.64082C17.4022 2.16479 17.9985 1.41566 18.2838 0.532821C17.5353 0.976675 16.7166 1.2895 15.8628 1.45782C15.5058 1.07796 15.0746 0.775446 14.5958 0.569031C14.1171 0.362616 13.6011 0.256692 13.0798 0.257821C12.0696 0.258086 11.1008 0.659242 10.386 1.37321C9.67132 2.08717 9.26915 3.05559 9.26782 4.06582C9.26767 4.35837 9.30123 4.64996 9.36782 4.93482C7.8518 4.85909 6.36867 4.46534 5.01472 3.77915C3.66077 3.09296 2.46628 2.12966 1.50882 0.951821C1.17017 1.53352 0.992094 2.19473 0.99282 2.86782C0.99263 3.49587 1.14774 4.11422 1.44434 4.66781C1.74094 5.22141 2.16983 5.69308 2.69282 6.04082C2.08599 6.02335 1.49227 5.86008 0.96182 5.56482C0.96182 5.58082 0.96182 5.59682 0.96182 5.61382C0.962214 6.49391 1.26704 7.34677 1.8246 8.02772C2.38216 8.70868 3.15811 9.17579 4.02082 9.34982C3.69325 9.43887 3.35528 9.48394 3.01582 9.48382C2.77517 9.48364 2.53509 9.46054 2.29882 9.41482C2.54222 10.1724 3.01633 10.8349 3.65483 11.3097C4.29333 11.7845 5.06428 12.0478 5.85982 12.0628C4.50939 13.1228 2.84159 13.6977 1.12482 13.6948C0.821044 13.695 0.517521 13.6773 0.21582 13.6418C1.95896 14.7621 3.98776 15.3567 6.05982 15.3548"
				fill="white"
			/>
		</svg>
	);
};
