export const SvgClubInfinitumLogo = () => {
	return (
		<svg
			width="82"
			height="82"
			viewBox="0 0 82 82"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="82"
				height="82"
				rx="41"
				fill="url(#paint0_linear_1793_3844)"
			/>
			<rect
				x="5.58301"
				y="5.57617"
				width="70.8285"
				height="70.8285"
				rx="35.4142"
				stroke="url(#paint1_linear_1793_3844)"
				strokeWidth="3"
			/>
			<path
				d="M30.7686 35.7191L31.6951 38.3447C31.6951 38.3447 42.0676 31.8839 45.7747 29.5929C50.6655 25.3456 46.4182 18.5769 41.7856 18.6275C40.6646 18.5951 39.5565 18.8738 38.5842 19.4326C37.6119 19.9914 36.8134 20.8085 36.2771 21.7934C34.5015 24.6504 36.148 28.7448 37.718 29.5393C37.9236 29.4102 39.5194 28.2483 39.9832 27.9434C37.6147 26.5025 37.1033 21.7656 41.579 21.3028C45.1054 21.1737 46.1352 25.8848 44.153 27.5313C43.5095 27.9206 30.7686 35.7191 30.7686 35.7191Z"
				fill="url(#paint2_linear_1793_3844)"
			/>
			<path
				d="M43.0703 32.8621L45.5669 31.3953C45.5669 31.3953 55.6564 38.9108 57.0983 39.6834C57.5939 39.9978 58.1623 40.179 58.7484 40.2094C59.3346 40.2398 59.9186 40.1184 60.4441 39.857C60.9696 39.5955 61.4187 39.2029 61.748 38.717C62.0773 38.2312 62.2756 37.6685 62.3238 37.0836C62.5293 32.2444 55.9404 32.3735 55.3227 36.157C54.3187 35.4619 54.1131 35.2305 53.1092 34.5354C54.4478 30.443 59.4409 29.8769 62.0149 31.2404C63.0561 31.7876 63.9093 32.6344 64.4644 33.6713C65.0196 34.7083 65.2511 35.8879 65.1292 37.0578C64.5631 42.3597 59.3118 44.4193 55.9662 42.2058C54.2412 41.0211 43.0703 32.8621 43.0703 32.8621Z"
				fill="url(#paint3_linear_1793_3844)"
			/>
			<path
				d="M50.6678 40.3789L52.8813 42.0264C52.8813 42.0264 48.068 55.3334 47.8882 55.9292C47.7529 56.4556 47.7397 57.0059 47.8496 57.5381C47.9595 58.0704 48.1897 58.5704 48.5225 59.0001C48.8552 59.4297 49.2818 59.7776 49.7697 60.0172C50.2575 60.2567 50.7936 60.3815 51.3371 60.3821C56.0988 60.0991 56.1247 53.3552 51.0283 53.2519C51.5427 51.7336 51.6718 51.5012 52.0064 50.6521C53.6427 50.8809 55.1305 51.7238 56.1677 53.0098C57.205 54.2957 57.714 55.9282 57.5914 57.5757C57.3229 59.0142 56.587 60.3238 55.4979 61.3012C54.4089 62.2786 53.0276 62.8691 51.5685 62.981C46.4979 63.0803 44.6965 58.3225 45.3122 55.4138C45.8286 53.9689 50.6678 40.3789 50.6678 40.3789Z"
				fill="url(#paint4_linear_1793_3844)"
			/>
			<path
				d="M44.282 53.8877L45.286 51.0078C45.286 51.0078 31.1557 51.0336 29.0991 51.0336C23.9511 51.265 22.5359 56.1042 23.3334 58.987C26.4218 67.4281 37.3355 62.5621 35.2253 55.5898C34.3246 55.564 32.8568 55.5382 32.4447 55.5382C33.268 57.9066 32.0584 60.5571 29.3046 60.6345C28.446 60.6353 27.6184 60.3131 26.9863 59.7319C26.3542 59.1507 25.9638 58.3531 25.8925 57.4974C25.8212 56.6417 26.0743 55.7905 26.6016 55.1128C27.1288 54.4351 27.8916 53.9804 28.7386 53.839C29.6631 53.8648 44.282 53.8877 44.282 53.8877Z"
				fill="url(#paint5_linear_1793_3844)"
			/>
			<path
				d="M30.7175 49.7737H33.6778C33.6778 49.7737 29.1991 35.6941 28.9677 34.8967C28.7052 34.0522 28.2636 33.2744 27.6729 32.6163C27.0822 31.9582 26.3565 31.4353 25.5452 31.0835C24.7339 30.7316 23.8562 30.559 22.9721 30.5775C22.088 30.596 21.2183 30.8051 20.4224 31.1906C18.0798 32.6573 16.5614 34.7169 17.1274 37.9086C17.7709 41.4608 21.2983 44.8064 26.2636 42.1301C25.8773 40.7398 25.7234 40.144 25.491 39.2989C24.9913 39.7843 24.3573 40.1083 23.6712 40.2289C22.9851 40.3496 22.2786 40.2612 21.6433 39.9754C21.008 39.6896 20.4733 39.2195 20.1084 38.6261C19.7436 38.0327 19.5655 37.3433 19.5972 36.6474C19.6836 35.8948 20.0105 35.1902 20.5293 34.6382C21.048 34.0862 21.731 33.7163 22.4768 33.5833C23.2226 33.4504 23.9913 33.5616 24.6689 33.9003C25.3464 34.2391 25.8966 34.7873 26.2378 35.4637C26.5228 36.3118 30.7175 49.7737 30.7175 49.7737Z"
				fill="url(#paint6_linear_1793_3844)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_1793_3844"
					x1="41"
					y1="0"
					x2="41"
					y2="82"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#0F0F0F" />
					<stop offset="0.567708" stopColor="#1D1C1C" />
					<stop offset="0.682292" stopColor="#444444" />
					<stop offset="0.760417" stopColor="#1F1D1D" />
					<stop offset="1" stopColor="#201F1F" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_1793_3844"
					x1="40.9973"
					y1="4.07617"
					x2="40.9973"
					y2="77.9047"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CCAC54" />
					<stop offset="1" stopColor="#7A6834" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_1793_3844"
					x1="39.3592"
					y1="18.625"
					x2="39.3592"
					y2="38.3447"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8D773A" />
					<stop offset="0.380208" stopColor="#FCE29C" />
					<stop offset="0.572917" stopColor="#B79539" />
					<stop offset="0.807292" stopColor="#9C8C61" />
					<stop offset="1" stopColor="#EFD384" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_1793_3844"
					x1="54.1156"
					y1="30.5762"
					x2="54.1156"
					y2="43.0846"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8D773A" />
					<stop offset="0.380208" stopColor="#FCE29C" />
					<stop offset="0.572917" stopColor="#B79539" />
					<stop offset="0.807292" stopColor="#9C8C61" />
					<stop offset="1" stopColor="#EFD384" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_1793_3844"
					x1="51.4028"
					y1="40.3789"
					x2="51.4028"
					y2="62.9825"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8D773A" />
					<stop offset="0.380208" stopColor="#FCE29C" />
					<stop offset="0.572917" stopColor="#B79539" />
					<stop offset="0.807292" stopColor="#9C8C61" />
					<stop offset="1" stopColor="#EFD384" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_1793_3844"
					x1="34.206"
					y1="51.0078"
					x2="34.206"
					y2="63.4601"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8D773A" />
					<stop offset="0.380208" stopColor="#FCE29C" />
					<stop offset="0.572917" stopColor="#B79539" />
					<stop offset="0.807292" stopColor="#9C8C61" />
					<stop offset="1" stopColor="#EFD384" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_1793_3844"
					x1="25.3433"
					y1="30.5762"
					x2="25.3433"
					y2="49.7737"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#8D773A" />
					<stop offset="0.380208" stopColor="#FCE29C" />
					<stop offset="0.572917" stopColor="#B79539" />
					<stop offset="0.807292" stopColor="#9C8C61" />
					<stop offset="1" stopColor="#EFD384" />
				</linearGradient>
			</defs>
		</svg>
	);
};
