import { createContext, useState } from "react";
import { format } from "multi-date";

// @ts-ignore
import { useMutation, useQuery } from "react-query";

// assets
import Adult from "assets/icons/newAdults.svg";
import Children from "assets/icons/newChildren.svg";
import Driver from "assets/icons/driver.svg";
import Maid from "assets/icons/maid.svg";
import Pet from "assets/icons/pet.svg";
import Bedroom from "assets/icons/bedroom.svg";

// helpers
import { axiosInstance } from "helpers/axiosInstance";

// @ts-ignore
import moment from "moment";
import { useSearchQuery } from "customHooks/useQuery";
import { SvgBedroom } from "assets/icons/js/SvgBedroom";
import { useSearchParams } from "react-router-dom";

// @ts-ignore
export const RegisterFormDataInterface = createContext([]);

export const RegisterFormData = ({ children }: any) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [details, setDetails] = useState({
		firstname: "",
		lastname: "",
		email: "",
		phone: "",
	});
	const [showTripDetails, setShowTripDetails] = useState(false);
	const [selected, setSelected] = useState("");
	const [occasion, setOccassion] = useState("");
	const [guest, setGuest] = useState({
		adult: 0,
		children: 0,
		maid: 0,
		pet: 0,
		driver: 0,
		room: 0,
	});
	const [travelingWith, setTravelingWith] = useState("");
	const [inputData, setInputData] = useState("");
	const [amenData, setAmenData] = useState([]);
	const [selectBudget, setSelectBudget] = useState({
		min: 0,
		max: 0,
	});
	const [hearAbout, setHearAbout] = useState("");
	const [request, setRequest] = useState("");
	const [planToGo, setPlanToGo] = useState("");
	const [placeOfOrigin, setPlaceOfOrigin] = useState("");
	const [locationFlex, setLocationFlex] = useState(false);
	const [dateFlex, setDateFlex] = useState(false);
	const [typeOfUser, setTypeOfUser] = useState(0);
	const [dates, setDates] = useState({
		checkin: "",
		checkout: "",
	});
	const [type, setType] = useState("Vacation");
	const [particularVilla, setParticularVilla] = useState("");
	const [particularVillasList, setParticularVillasList] = useState([]);
	const [amenities, setAmenities] = useState("");
	const [amenitiesList, setAmenitiesList] = useState([]);
	const [groupComp, setGroupComp] = useState("");
	const [lat, setLat] = useState({
		lat: "",
		lon: "",
	});
	const [websiteLink, setWebsiteLink] = useState("");
	const [homeImages, setHomeImages] = useState([]);
	const [flexiblity, setFlexiblity] = useState("Exact Dates");
	const [amenQuery, setAmenQuery] = useState("");

	const query = useSearchQuery();

	const [guestDetails, setGuestDetail] = useState([
		{
			guestImg: Bedroom,
			guestName: "Room(s)",
			guestValue: "Room",
			needAcc: "No",
			key: "rooms",
			count: 0,
		},
		{
			guestImg: Adult,
			guestName: "Adult(s)",
			guestValue: "Adult",
			needAcc: "No",
			key: "adults",
			age: "12+",
			count: 0,
		},
		{
			guestImg: Children,
			guestName: "Children",
			guestValue: "Children",
			needAcc: "No",
			key: "children",
			age: "6-11",
			count: 0,
		},
		{
			guestImg: Driver,
			guestName: "Driver(s)",
			guestValue: "Driver",
			needAcc: "yes",
			key: "driver",
			count: 0,
		},

		{
			guestImg: Maid,
			guestName: "Maid(s)",
			guestValue: "Maid",
			needAcc: "yes",
			key: "maid",
			count: 0,
		},
		{
			guestImg: Pet,
			guestName: "Pet(s)",
			guestValue: "Pet",
			needAcc: "No",
			key: "pet",
			count: 0,
		},
	]);

	const dateData: any = {
		"Exact Dates": "E",
		"1 Day": "1D",
		"3 Days": "3D",
		"1 Week": "1W",
		"2 Weeks": "2W",
		"1 Month": "1M",
		Flexible: "F",
	};
	const dateDataReverse: any = {
		E: "Exact Dates",
		"1D": "1 Day",
		"3D": "3 Day",
		"1W": "1 Week",
		"2W": "2 Weeks",
		"1M": "1 Month",
		F: "Flexible",
	};

	const sendVillasListIds = particularVillasList.map((v: any) => v.id);

	const flex = dateData[flexiblity];

	const sendData: any = {
		GUEST_NAME: `${details.firstname}`,
		GUEST_EMAIL: details.email,
		GUEST_NUMBER: details.phone,
		GUEST_PINCODE: placeOfOrigin,
		LOCATION: planToGo,
		OCCASION: occasion,
		NO_OF_GUESTS: guest.adult,
		NO_OF_KIDS: guest.children,
		NO_OF_MAIDS_DRIVERS: guest.driver + guest.maid,
		NO_OF_PETS: guest.pet,
		NO_OF_ROOMS: guest.room,
		BUDGET_HIGH: selectBudget.max,
		SOURCE: hearAbout,
		GUEST_COMMENTS: request,
		LAT: lat.lat,
		LON: lat.lon,
		CHECK_IN: dates.checkin ? format(dates.checkin, "YYYY-MM-DD") : "",
		CHECK_OUT: dates.checkout ? format(dates.checkout, "YYYY-MM-DD") : "",
		TYPE: type,
		VILLAS_IN_MIND: sendVillasListIds,
		AMENITIES: amenitiesList,
		// @ts-ignore
		GROUP_COMPOSITION: groupComp,
		// @ts-ignore
		TRAVELLING_WITH: travelingWith,
		DATE_FLEXIBLE: flex,
		LEAD_ID: query.get("id") ? query.get("id") : "",
		MEDIUM: query.get("MEDIUM") ? query.get("MEDIUM") : "Website - Contact Us",
	};

	// @ts-ignore
	for (const item in sendData) {
		if (
			sendData[item] === 0 ||
			sendData[item] === "" ||
			sendData[item] === "0" ||
			sendData[item] === undefined ||
			sendData[item] === null ||
			sendData[item] === "NaN-NaN-NaN"
		) {
			delete sendData[item];
		}
		if (typeof sendData[item] === "object" && sendData[item].length === 0) {
			delete sendData[item];
		}
	}

	const registerFormDataMutation = useMutation(
		() => {
			// @ts-ignore
			return axiosInstance({
				method: "post",
				url: "/create-lead",
				data: sendData,
			});
		},
		{
			onSuccess: (data) => {
				searchParams.set("id", data?.data?.data);
				setSearchParams(searchParams, { replace: true });
			},
		},
	);

	const fetchLead = useQuery(
		"fetchLead",
		() => {
			return axiosInstance({
				method: "GET",
				url: `/leadInfo?ID=${query.get("id")}`,
			});
		},
		{
			onSuccess: (data: any) => {
				const prefillData = data?.data?.data;

				setDetails({
					firstname: prefillData?.GUEST_NAME,
					email: prefillData?.GUEST_EMAIL || "",
					// @ts-ignore
					phone: Number(prefillData?.GUEST_NUMBER) || "",
				});
				setGuest({
					adult: prefillData?.NO_OF_GUESTS || 0,
					children: prefillData?.NO_OF_KIDS || 0,
					maid: 0,
					pet: prefillData?.NO_OF_PET || 0,
					driver: 0,
					room: prefillData?.NO_OF_ROOMS || 0,
				});
				setTravelingWith(prefillData?.TRAVELLING_WITH || "");
				// @ts-ignore
				setPlaceOfOrigin(Number(prefillData?.GUEST_PINCODE) || "");
				setType(prefillData?.TYPE || "");
				setPlanToGo(prefillData?.LOCATION || "");
				setDates({
					// @ts-ignore
					checkin: new Date(prefillData?.CHECK_IN),
					// @ts-ignore
					checkout: new Date(prefillData?.CHECK_OUT),
				});
				setOccassion(prefillData?.OCCASION || "");
				setGroupComp(prefillData?.GROUP_COMPOSITION || "");
				setSelectBudget({
					min: 0,
					max: prefillData?.BUDGET_HIGH,
				});
				setGuestDetail([
					{
						guestImg: Bedroom,
						guestName: "Room(s)",
						guestValue: "Room",
						needAcc: "No",
						key: "rooms",
						count: prefillData?.NO_OF_ROOMS || 0,
					},
					{
						guestImg: Adult,
						guestName: "Adult(s)",
						guestValue: "Adult",
						needAcc: "No",
						key: "adults",
						age: "12+",
						count: prefillData?.NO_OF_GUESTS || 0,
					},
					{
						guestImg: Children,
						guestName: "Children",
						guestValue: "Children",
						needAcc: "No",
						key: "children",
						age: "6-11",
						count: prefillData?.NO_OF_KIDS || 0,
					},
					{
						guestImg: Driver,
						guestName: "Driver(s)",
						guestValue: "Driver",
						needAcc: "yes",
						key: "driver",
						count: 0,
					},

					{
						guestImg: Maid,
						guestName: "Maid(s)",
						guestValue: "Maid",
						needAcc: "yes",
						key: "maid",
						count: 0,
					},
					{
						guestImg: Pet,
						guestName: "Pet(s)",
						guestValue: "Pet",
						needAcc: "No",
						key: "pet",
						count: prefillData?.NO_OF_PET || 0,
					},
				]);
				setRequest(prefillData?.GUEST_COMMENTS || "");
				setFlexiblity(dateDataReverse[prefillData?.DATE_FLEXIBLE]);
				setAmenitiesList(
					prefillData?.AMENITIES ? prefillData?.AMENITIES.split(",") : [],
				);
				setParticularVillasList(
					prefillData?.VILLAS_IN_MIND.length > 0
						? prefillData?.VILLAS_IN_MIND
						: [],
				);
			},
			enabled: query.get("id") !== null ? true : false,
			refetchOnWindowFocus: false,
		},
	);

	const parsedData: any = {
		registerFormDataMutation,
		showTripDetails,
		details,
		occasion,
		selected,
		hearAbout,
		request,

		locationFlex,
		particularVilla,
		typeOfUser,
		dates,
		dateFlex,
		type,
		guest,
		travelingWith,
		inputData,
		selectBudget,
		setShowTripDetails,
		setDetails,
		setOccassion,
		setSelected,
		setInputData,
		setAmenData,
		setSelectBudget,
		setHearAbout,
		setRequest,

		setLocationFlex,
		setTypeOfUser,
		setDates,
		setDateFlex,
		setType,
		setGuest,
		setTravelingWith,
		setParticularVilla,
		particularVillasList,
		setParticularVillasList,
		planToGo,
		setPlanToGo,
		placeOfOrigin,
		setPlaceOfOrigin,
		groupComp,
		setGroupComp,
		amenitiesList,
		setAmenitiesList,
		amenities,
		setAmenities,
		lat,
		setLat,
		websiteLink,
		setWebsiteLink,
		homeImages,
		setHomeImages,
		guestDetails,
		setGuestDetail,
		flexiblity,
		setFlexiblity,
		amenQuery,
		setAmenQuery,
	};

	return (
		<RegisterFormDataInterface.Provider value={parsedData}>
			{children}
		</RegisterFormDataInterface.Provider>
	);
};
