import React, { useEffect, useState } from "react";
import "./style.scss";
import Modal from "components/LoginAndRegister/Modal";
import BookingExperienceCalc from "../BookingExperienceCalc";
import axios from "axios";
import AddOnsLoader from "components/UniversalUrlNew/LeftSide/AddOns/AddOnsLoader";
import { ReactComponent as Done } from "../../../UniversalUrlNew/UniUrlAssets/done_green.svg";
import { ToastContainer, toast } from "react-toastify";
import BookingExperienceDetails from "../BookingExperienceDetails";
const BookingExperienceCard = ({
	bookingId,
	data,
	index,
	allAddons,
	setIndex,
	setIsAddonDetails,
	refreshData,
}: {
	bookingId: string;
	data: any;
	index: number;
	allAddons: any;
	setIsAddonDetails: any;
	setIndex: any;
	refreshData: () => void;
}) => {
	const [isCalOpen, setIsCalOpen] = useState(false);
	const [addedAddons, setAddedAddons] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [singleAddon, setSingleAddon] = useState<any>({});

	// addon request
	const requestAddon = async () => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`https://go.saffronstays.com/api/addon-request?bookingId=${bookingId}&mapId=${
					data?.MAP_ID
				}&quantity=${1}&type=${data?.TYPE}&nights=${1}&isBookingPage=${true}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
					},
				},
			);
			if (res?.data.success) {
				setIsLoading(false);
				toast.success(`${data?.ADD_ON_NAME} has been requested`, {
					position: window.innerWidth < 800 ? "top-right" : "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					icon: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
				refreshData();
			}
		} catch (error: any) {
			console.log(error?.message);
		}
	};

	return (
		<>
			<ToastContainer />
			<div
				className="booking-experience-card-wrapper"
				onClick={() => {
					setIndex(index);
					if (data?.IS_ADDED) {
						setIsAddonDetails(false);
					} else {
						setIsAddonDetails(true);
					}
				}}
			>
				<div className="booking-experience-card-content">
					<div className="booking-experience-card-image">
						<img
							src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-200,h-164,q-62/image/upload/${data?.IMAGE_URL}`}
							alt=""
						/>
					</div>
					<div className="booking-experience-card-bottom">
						<div className="booking-experience-card-title">
							<p>{data?.ADD_ON_NAME}</p>
						</div>
						<div className="booking-experience-card-price">
							<p>₹ {data?.PRICE}</p>
							{isLoading && singleAddon?.ADD_ON_ID === data?.ADD_ON_ID ? (
								<AddOnsLoader />
							) : data?.IS_ADDED ? (
								<Done
									style={{
										background:
											"linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #11BF0E",
										borderRadius: "100%",
										cursor: "not-allowed",
									}}
								/>
							) : (
								<button
									onClick={(e) => {
										if (data?.TYPE === "OT") {
											e.stopPropagation();
											requestAddon();
											setSingleAddon(data);
										} else {
											e.stopPropagation();
											setIndex(index);
											setIsAddonDetails(true);
										}
									}}
								>
									+ Add
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BookingExperienceCard;
