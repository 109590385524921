import "./styles.scss";

export const UniversalURLLoader = () => {
	return (
		<main className="universalURL_loader-container-page">
			<section className="universalURL_loader-container-header skeleton-animation"></section>
			<section className="universalURL_loader-container-image">
				<div className="universalURL_loader-img img-main skeleton-animation"></div>
				<div className="universalURL_loader-img img-second skeleton-animation"></div>
				<div className="universalURL_loader-img img-third skeleton-animation"></div>
			</section>
			<section className="universalURL_loader-container-two-column">
				<section className="universalURL_loader-container-content skeleton-animation"></section>
				<aside className="universalURL_loader-container-side-pannel skeleton-animation"></aside>
			</section>
		</main>
	);
};
