import { useState } from "react";

// assets
import { SvgInfo } from "assets/icons/js/SvgInfo";

// style
import "./style.scss";

export const Tooltip = ({
	text,
	background,
	position,
	textBold,
	fontSize,
}: any) => {
	const [isShown, setIsShown] = useState(false);

	return (
		<span
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
			className="info-icon"
		>
			<SvgInfo color={background} />
			{isShown && (
				<div
					className="info-div"
					style={{
						left: position,
						fontWeight: textBold,
						fontSize: fontSize,
					}}
				>
					{text}
				</div>
			)}
		</span>
	);
};
