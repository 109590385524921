// components
import FacebookLoginWrapper from "../FacebookLoginWrapper";
import GoogleLoginWrapper from "../GoogleLoginWrapper";
import { Input } from "../Input";
import { PrimaryButton } from "../PrimaryButton";
import { RegisterHeader } from "../RegisterHeader";
import { TermsAndConditions } from "../TermsAndConditions";

// assets
import { SvgEmail as Email } from "assets/icons/js/SvgEmail";
import { SvgFacebook as Facebook } from "assets/icons/js/SvgFacebook";
import { SvgGoogle as Google } from "assets/icons/js/SvgGoogle";

// scss
import "./style.scss";
import { useContext } from "react";
import { UserContext } from "store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const PhoneLogin = ({
	setPhone,
	setSend,
	phoneNumber,
	setPhoneNumber,
	cc,
	setCC,
}) => {
	const redirectToEmailMode = () => {
		setPhone(false);
	};

	const handleChange = (e) => {
		const re = /^[0-9\b]+$/;
		if (e.target.value.length <= 10) {
			if (e.target.value === "" || re.test(e.target.value)) {
				setPhoneNumber(e.target.value);
			}
		}
	};

	const handlePrimaryClick = (e) => {
		e.preventDefault();
		setSend(true);
	};
	const { state, dispatch } = useContext(UserContext);

	return (
		<div className="phoneLogin__wrapper--page">
			<div className="phoneLogin__wrapper--page-2">
				<RegisterHeader title={"to SaffronStays"} subtitle={"log you in"} />
				<div className="phoneLogin__space"></div>
				<div className="phoneLogin__form">
					<form onSubmit={(e) => handlePrimaryClick(e)}>
						{/* <div>
							<CountryCodeSelector />
							<Input
								id="phone"
								type={"tel"}
								name={"mobileNumber"}
								placeholder={"Mobile Number"}
								value={phoneNumber}
								onChange={handleChange}
							/>
						</div> */}

						<PhoneInput
							country={"in"}
							value={""}
							enableSearch={true}
							onChange={(value, country, e, formattedValue) => {
								setCC(country.dialCode);
								setPhoneNumber(
									value.slice(country?.dialCode?.toString()?.length),
								);
							}}
						/>

						<div className="phoneLogin__space"></div>
						<PrimaryButton
							type="submit"
							value={"Send OTP"}
							disabled={phoneNumber.length < 10}
						/>
					</form>

					{!state.isFromBookingBtn && (
						<>
							<div className="phoneLogin__line">
								<hr />
								<span>OR</span>
								<hr />
							</div>
							<div className="phoneLogin__different-login">
								<GoogleLoginWrapper>
									<span className="google icon">
										<Google />
										<p>Google</p>
									</span>
								</GoogleLoginWrapper>
								<FacebookLoginWrapper>
									<span className="facebook icon">
										<Facebook />
										<p>Facebook</p>
									</span>
								</FacebookLoginWrapper>
								<span className="phone icon" onClick={redirectToEmailMode}>
									<Email />
									<p>Email</p>
								</span>
							</div>
							<div className="phoneLogin__space"></div>
							<TermsAndConditions />
						</>
					)}
					{state.isFromBookingBtn && (
						<div>
							<div className="phoneLogin__line">
								<hr />
								<span>OR</span>
								<hr />
							</div>
							<center>
								<button
									className="link-btn"
									onClick={() => {
										dispatch({
											type: "FROM_BOOK_BUTTON",
											payload: false,
										});
									}}
								>
									Click Here
								</button>
								&nbsp;to use other ways to login
							</center>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
