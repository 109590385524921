export const SvgVectorImage = () => {
	return (
		<svg
			width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.8959 0H0.895767C0.395726 0 0 0.395874 0 0.895767V15.1042C0 15.6043 0.395874 16 0.895767 16H18.8959C19.396 16 19.7917 15.6041 19.7917 15.1042L19.7918 0.895767C19.7918 0.395837 19.396 0 18.8961 0H18.8959ZM18 14.2085H1.79153V1.79168H18V14.2085Z"
				fill="#999999"
			/>
			<path
				d="M4.22863 12.4991H15.5204C15.8328 12.4991 15.9995 12.1657 15.8328 11.9158L13.0204 7.99906C12.8954 7.81159 12.6245 7.79067 12.4578 7.95735L11.4579 8.91577C11.2912 9.06168 11.0412 9.04075 10.8953 8.87406L8.47875 5.60327C8.31207 5.39488 7.99962 5.4158 7.85371 5.62405L3.89534 11.9158C3.74944 12.1865 3.9369 12.4991 4.22857 12.4991L4.22863 12.4991Z"
				fill="#999999"
			/>
			<path
				d="M14.958 4.98052C14.958 5.73983 14.3424 6.35557 13.5831 6.35557C12.8236 6.35557 12.208 5.73983 12.208 4.98052C12.208 4.22106 12.8236 3.60547 13.5831 3.60547C14.3424 3.60547 14.958 4.22106 14.958 4.98052Z"
				fill="#999999"
			/>
		</svg>
	);
};
