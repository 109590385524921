import { useNavigate } from "react-router-dom";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// scss
import "./style.scss";

export const RememberMe = ({ setForgetPassword, handleCheck, remember }) => {
	const navigate = useNavigate();

	const width = useMediaQuery();
	const isMobile = width <= "500" ? true : false;

	const handleClick = () => {
		if (!isMobile) {
			setForgetPassword(true);
		} else if (isMobile) {
			navigate("/forget-password");
		}
	};

	return (
		<div className="remember__checkbox">
			<div className="remember__checkbox-input">
				<label htmlFor="remember-me" className="remember__remember-me">
					<input
						id="remember-me"
						type="checkbox"
						name="remember-me"
						checked={remember}
						onChange={handleCheck}
					/>
					<p>Remember me</p>
				</label>
			</div>
			<div className="remember__forgot" onClick={handleClick}>
				<p>Forgot password?</p>
			</div>
		</div>
	);
};
