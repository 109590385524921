import clevertap from "clevertap-web-sdk";
import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";

// app
import App from "./App";

// store
import { Store } from "./store";

// react-query
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

// service worker

// clevertap crm
clevertap.privacy.push({
	optOut: localStorage.getItem("allow") ? false : true,
});
clevertap.privacy.push({ useIP: true });
clevertap.spa = true;
clevertap.init("464-W5Z-Z66Z");
clevertap.notifications.push({
	titleText: "Want to know our secret deals and offers?",
	bodyText: "We promise to only send you relevant content.",
	okButtonText: "Oh Yes",
	rejectButtonText: "I dont like deals",
	okButtonColor: "#2368a2",
	serviceWorkerPath: "./clevertap.js",
});

const queryClient = new QueryClient();

// @ts-ignore
const createdRoot = createRoot(document.getElementById("root"));

createdRoot.render(
	<QueryClientProvider client={queryClient}>
		{/* <ReactQueryDevtools /> */}
		<Store>
			<App />
		</Store>
		{/* <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} /> */}
	</QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
