import React from "react";

export const SvgInfo = (props) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8.00065 1.33594C4.32065 1.33594 1.33398 4.3226 1.33398 8.0026C1.33398 11.6826 4.32065 14.6693 8.00065 14.6693C11.6807 14.6693 14.6673 11.6826 14.6673 8.0026C14.6673 4.3226 11.6807 1.33594 8.00065 1.33594ZM8.66732 11.3359H7.33398V7.33594H8.66732V11.3359ZM8.66732 6.0026H7.33398V4.66927H8.66732V6.0026Z"
				fill={props.color ? props.color : "#2368a2"}
			/>
		</svg>
	);
};
