// assets
import { SvgClose } from "assets/icons/js/SvgClose";
import { SearchPageDataInterface } from "context/SearchPageData";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";

// styles
import "./styles.scss";

export const FilterPopup = ({ element, title, onclose }: any) => {
	const {
		// @ts-ignore
		setSelectBudget,
		// @ts-ignore
		selectBudget,
		// @ts-ignore
		query,
		// @ts-ignore
		setAmenitiesFilter,
		// @ts-ignore
		setMoreFilter,
	} = useContext(SearchPageDataInterface);
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<div className="filter__popup__container">
			<div className="top">
				<span></span>
				<h3>{title}</h3>
				<span className="close" onClick={() => onclose()}>
					<SvgClose />
				</span>
			</div>
			<div className="middle">{element}</div>
			<div className="bottom">
				<button
					className="clear"
					type="button"
					onClick={() => {
						if (title === "Price (per night)") {
							// @ts-ignore
							searchParams.delete("min");
							// @ts-ignore
							searchParams.delete("max");
							setSelectBudget({
								min: 4999,
								max: 249999,
							});
							setSearchParams(searchParams);
							onclose();
						} else if (title === "Top Filter") {
							searchParams.delete("same_day");
							searchParams.delete("pocket");
							searchParams.delete("breakfast");
							searchParams.delete("couple");
							setAmenitiesFilter([]);
							setSearchParams(searchParams);
							onclose();
						} else if (title === "Other Filters") {
							setMoreFilter([]);
							onclose();
						} else if (title === "Sort") {
							searchParams.delete("sortby");
							setSearchParams(searchParams);
							onclose();
						}
					}}
				>
					Clear
				</button>
				<button
					className="save"
					type="button"
					onClick={() => {
						if (title === "Price (per night)") {
							// @ts-ignore
							searchParams.set("min", selectBudget?.min);
							// @ts-ignore
							searchParams.set("max", selectBudget?.max);
							setSearchParams(searchParams);
							onclose();
						} else {
							onclose();
						}
					}}
				>
					Save
				</button>
			</div>
		</div>
	);
};
