export const SvgHelpAndSupport = (props: any) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M5.98747 16.3375L6.52321 15.4931C6.26934 15.3321 5.95647 15.2937 5.67125 15.3888L5.98747 16.3375ZM2.5 17.5L1.55132 17.1838C1.43154 17.5431 1.52506 17.9393 1.79289 18.2071C2.06073 18.4749 2.45689 18.5685 2.81623 18.4487L2.5 17.5ZM3.66249 14.0125L4.61117 14.3288C4.70625 14.0435 4.66795 13.7307 4.50688 13.4768L3.66249 14.0125ZM10 18.5C14.6944 18.5 18.5 14.6944 18.5 10H16.5C16.5 13.5899 13.5899 16.5 10 16.5V18.5ZM5.45174 17.1819C6.76752 18.0167 8.32885 18.5 10 18.5V16.5C8.71938 16.5 7.52808 16.1307 6.52321 15.4931L5.45174 17.1819ZM5.67125 15.3888L2.18377 16.5513L2.81623 18.4487L6.3037 17.2862L5.67125 15.3888ZM3.44868 17.8162L4.61117 14.3288L2.71381 13.6963L1.55132 17.1838L3.44868 17.8162ZM1.5 10C1.5 11.6711 1.98328 13.2325 2.8181 14.5483L4.50688 13.4768C3.86932 12.4719 3.5 11.2806 3.5 10H1.5ZM10 1.5C5.30558 1.5 1.5 5.30558 1.5 10H3.5C3.5 6.41015 6.41015 3.5 10 3.5V1.5ZM18.5 10C18.5 5.30558 14.6944 1.5 10 1.5V3.5C13.5899 3.5 16.5 6.41015 16.5 10H18.5Z"
				fill="#666666"
			/>
			<path
				d="M13.333 10H13.3347V10.0017H13.333V10Z"
				stroke="#666666"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 10H10.0017V10.0017H10V10Z"
				stroke="#666666"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.66699 10H6.66866V10.0017H6.66699V10Z"
				stroke="#666666"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
