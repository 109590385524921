import { useEffect } from "react";

// scss
import "./style.scss";

const Modal = ({ children, shouldShow, onRequestClose }) => {
	useEffect(() => {
		if (shouldShow === true) {
			document.body.style.overflowY = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
		return () => {
			document.body.style.overflowY = "auto";
		};
	}, [shouldShow]);

	return (
		<>
			{shouldShow ? (
				<div className="modal__container">
					<div className="modal__overlay" onClick={onRequestClose}></div>
					<div onClick={(e) => e.stopPropagation()}>{children}</div>
				</div>
			) : null}
		</>
	);
};

export default Modal;
