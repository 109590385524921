import { useContext, useState } from "react";
import "./style.scss";
import { BookingInterface } from "context/BookingData";
import { ReactComponent as TickIcon } from "../assets/tick.svg";

import clevertap from "clevertap-web-sdk";

const MealWrapper = ({ data }: any) => {
	const { updateBooking }: any = useContext(BookingInterface);

	const [loading, setLoading] = useState(false);
	const [readmore, setReadmore] = useState(true);

	const isDiscount = data?.mealDetails?.IS_MEAL_FIVE;

	const isAddMeal =
		data?.mealDetails?.MEAL_CHARGES_ADULT_ALLMEALS_NONVEG ||
		data?.mealDetails?.MEAL_CHARGES_ADULT_ALLMEALS_VEG ||
		data?.mealDetails?.MEAL_CHARGES_ADULT_NONVEG ||
		data?.mealDetails?.MEAL_CHARGES_ADULT_VEG ||
		data?.mealDetails?.MEAL_CHARGES_CHILDREN_ALLMEALS_NONVEG ||
		data?.mealDetails?.MEAL_CHARGES_CHILDREN_ALLMEALS_VEG ||
		data?.mealDetails?.MEAL_CHARGES_CHILDREN_NONVEG ||
		data?.mealDetails?.MEAL_CHARGES_CHILDREN_VEG;

	// console.log(data?.mealDetails?.MEAL_PREFERENCE.split("").length);

	return (
		<div className="booking-meal-wrapper">
			<div className="meal-left">
				<div className="meal-container">
					<div className="meal-title">Meals</div>
					<img
						src={
							"https://media.cnn.com/api/v1/images/stellar/prod/211006114703-best-meal-delivery-service-freshly.jpg?q=w_1601,h_900,x_0,y_0,c_fill"
						}
						alt=""
					/>
					{isAddMeal ? (
						<span>
							{[
								"xKrFmWI9INr8OUTo",
								"s5BTeCcQcvr6thld",
								"h7RoH9qCXJcL88xj",
								"7BCI2TUSSFmR3iZP",
								"iLIql69vyblULH4r",
								"lU8CMLfiaElR57YM",
								"V5wWlgl0FPtsBJ3a",
							].includes(data?.LISTING_ID)
								? "Breakfast & Dinner"
								: "All Meals"}{" "}
							Package
						</span>
					) : null}
				</div>
				<div className="mob-meal-container">
					{isAddMeal ? (
						<span>
							{[
								"xKrFmWI9INr8OUTo",
								"s5BTeCcQcvr6thld",
								"h7RoH9qCXJcL88xj",
								"7BCI2TUSSFmR3iZP",
								"iLIql69vyblULH4r",
								"lU8CMLfiaElR57YM",
								"V5wWlgl0FPtsBJ3a",
							].includes(data?.LISTING_ID)
								? "Breakfast & Dinner"
								: "All Meals"}{" "}
							Package
						</span>
					) : null}
					<div className="meal-price-mob">
						{/* {data?.MEAL_CHARGES ? <span>Added</span> : null} */}
						{isAddMeal ? (
							<button
								className={`${loading ? "loading" : ""} ${
									data?.MEAL_CHARGES && "meal-added"
								}`}
								onClick={async () => {
									setLoading(true);
									clevertap.event.push("addMealsToBooking_btn_booking_page", {
										villaName: data?.VILLA_NAME,
										bookingId: data?.BOOKING_ID,
										guestId: data?.GUEST_ID,
										timestamp: new Date(),
									});
									await updateBooking.mutateAsync({
										addMeals: !data?.MEAL_CHARGES ? 1 : 0,
									});
									setLoading(false);
								}}
								disabled={loading}
							>
								{" "}
								{!data?.MEAL_CHARGES ? (
									"+ ADD"
								) : (
									<>
										<TickIcon />
										ADDED
									</>
								)}
							</button>
						) : null}
					</div>
				</div>
			</div>
			<div className="meal-right">
				<span className="title">
					About meals{" "}
					<div className="meal-price">
						{/* {data?.MEAL_CHARGES ? <span>Added</span> : null} */}

						{isAddMeal ? (
							<button
								className={`${loading ? "loading" : ""} ${
									data?.MEAL_CHARGES && "meal-added"
								}`}
								onClick={async () => {
									setLoading(true);
									clevertap.event.push("addMealsToBooking_btn_booking_page", {
										villaName: data?.VILLA_NAME,
										bookingId: data?.BOOKING_ID,
										guestId: data?.GUEST_ID,
										timestamp: new Date(),
									});
									await updateBooking.mutateAsync({
										addMeals: !data?.MEAL_CHARGES ? 1 : 0,
									});
									setLoading(false);
								}}
								disabled={loading}
							>
								{" "}
								{!data?.MEAL_CHARGES ? (
									"+ ADD"
								) : (
									<>
										<TickIcon />
										ADDED
									</>
								)}
							</button>
						) : null}
					</div>
				</span>
				<div style={{ position: "relative" }}>
					{!!isAddMeal && !!isDiscount && (
						<>
							<p style={{ color: "darkgreen" }}>
								<b>Get 5% off on meals</b>
							</p>
							<br />
						</>
					)}
					<p
						className={readmore ? "more" : ""}
						dangerouslySetInnerHTML={{
							__html: data?.mealDetails?.MEAL_PREFERENCE,
						}}
					></p>
					<div
						className="readmore-btn"
						onClick={() => {
							setReadmore(!readmore);
						}}
					>
						{data?.mealDetails?.MEAL_PREFERENCE &&
							data?.mealDetails?.MEAL_PREFERENCE.split("").length >= 290 &&
							(readmore ? `Read More` : "Show Less")}
					</div>
				</div>
			</div>
		</div>
	);
};

export default MealWrapper;
