// assets
import { SvgClose } from "assets/icons/js/SvgClose";
import { SvgClubinfintum } from "assets/icons/js/SvgClubinfintum";
import { SvgCrown } from "assets/icons/js/SvgCrown";
import { SvgFilter } from "assets/icons/js/SvgFilter";
import { SvgNextLogo } from "assets/icons/js/SvgNextLogo";
import { SvgPhoneDownArrow } from "assets/icons/js/SvgPhoneDownArrow";
import Modal from "components/LoginAndRegister/Modal";
import { SearchPageDataInterface } from "context/SearchPageData";
import { useMediaQuery } from "customHooks/useMediaQuery";
import { useSearchQuery } from "customHooks/useQuery";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterPopup } from "../FilterPopup";
import {
	PhoneAmenitiesFilter,
	PhoneBudgetFilter,
	PhoneMoreFilter,
	PhoneSortFilter,
} from "../PhoneAllFilters";

// style
import "./styles.scss";
import { SvgMariott } from "assets/icons/js/SvgMariott";

const data = [
	{
		name: "Sort",
		icon: <SvgFilter />,
		moreFilter: true,
	},
	{
		name: "Signature",
		icon: <SvgCrown />,
		moreFilter: false,
	},
	{
		name: "Marriott Bonvoy",
		icon: <SvgMariott />,
		moreFilter: false,
	},
	{
		name: "Club Infinitum",
		icon: <SvgClubinfintum />,
		moreFilter: false,
	},
	{
		name: "Top Filters",
		icon: null,
		moreFilter: true,
	},
	{
		name: "Price (per night)",
		icon: null,
		moreFilter: true,
	},
	{
		name: "Other Filters",
		icon: null,
		moreFilter: true,
	},
];

export const PhoneSearchFilters = () => {
	const {
		// @ts-ignore
		amenitiesFilter,
		// @ts-ignore
		moreFilter,
		// @ts-ignore
		selectBudget,
	} = useContext(SearchPageDataInterface);

	const [openModal, setOpenModal] = useState(false);
	const [selected, setSelected] = useState("");
	const [searchParams, setSearchParams] = useSearchParams();

	const query = useSearchQuery();

	const width = useMediaQuery();
	const isMobile = width < 1080 ? true : false;

	const compObj: any = {
		sort: (
			<FilterPopup
				onclose={() => setOpenModal(false)}
				title={"Sort"}
				element={<PhoneSortFilter />}
			/>
		),
		top: (
			<FilterPopup
				onclose={() => setOpenModal(false)}
				title={"Top Filter"}
				element={<PhoneAmenitiesFilter />}
			/>
		),
		price: (
			<FilterPopup
				onclose={() => setOpenModal(false)}
				title={"Price (per night)"}
				element={<PhoneBudgetFilter />}
			/>
		),
		other: (
			<FilterPopup
				onclose={() => setOpenModal(false)}
				title={"Other Filters"}
				element={<PhoneMoreFilter />}
			/>
		),
	};

	return (
		<div className="phone__search__filters__container">
			{data.map((v: any, i: number) => {
				return (
					<div
						className={`box ${
							v.name === "Signature" && query.get("signature") === "true"
								? "box__active"
								: v.name === "Club Infinitum" && query.get("club") === "true"
								? "box__active"
								: v.name === "Marriott Bonvoy" && query.get("next") === "true"
								? "box__active"
								: v.name === "Sort" && query.get("sortby") !== null
								? "box__active"
								: v.name === "Top Filters" &&
								  (amenitiesFilter.length > 0 ||
										query.get("breakfast") === "true" ||
										query.get("same_day") === "true" ||
										query.get("pocket") === "true" ||
										query.get("unavail") === "true")
								? "box__active"
								: v.name === "Other Filters" && moreFilter.length > 0
								? "box__active"
								: v.name === "Price (per night)" &&
								  // @ts-ignore
								  query?.get("min")?.length > 0 &&
								  // @ts-ignore
								  query?.get("max")?.length > 0
								? "box__active"
								: ""
						}`}
						key={i}
						onClick={() => {
							if (v.moreFilter) {
								setSelected(v.name.split(" ")[0].toLowerCase());
								setOpenModal(true);
							} else {
								if (v.name === "Signature") {
									if (query?.get("signature") === "true") {
										searchParams.set("signature", "false");
										setSearchParams(searchParams);
									} else {
										searchParams.set("signature", "true");
										setSearchParams(searchParams);
									}
								} else if (v.name === "Club Infinitum") {
									if (query?.get("club") === "true") {
										searchParams.set("club", "false");
										setSearchParams(searchParams);
									} else {
										searchParams.set("club", "true");
										setSearchParams(searchParams);
									}
								} else if (v.name === "Marriott Bonvoy") {
									if (query?.get("next") === "true") {
										searchParams.set("next", "false");
										setSearchParams(searchParams);
									} else {
										searchParams.set("next", "true");
										setSearchParams(searchParams);
									}
								}
							}
						}}
					>
						{v.icon && (
							<span className={v.name === "" ? "next__icon" : "icon"}>
								{v.icon}
							</span>
						)}
						<p>
							{v.name}
							{v.name === "Signature" && query.get("signature") === "true" ? (
								<SvgClose />
							) : v.name === "Club Infinitum" &&
							  query.get("club") === "true" ? (
								<SvgClose />
							) : v.name === "Marriott Bonvoy" &&
							  query.get("next") === "true" ? (
								<SvgClose />
							) : (
								""
							)}
							{v.name === "Top Filters" &&
							// @ts-ignore
							query?.get("amenities")?.length > 0
								? ` (${query?.get("amenities")?.split(",").length})`
								: // @ts-ignore
								v.name === "Other Filters" && query?.get("more")?.length > 0
								? ` (${query?.get("more")?.split(",").length})`
								: ""}
						</p>
						{v.moreFilter && (
							<span className="arrow">{<SvgPhoneDownArrow />}</span>
						)}
					</div>
				);
			})}
			<Modal shouldShow={openModal} onRequestClose={() => setOpenModal(false)}>
				{compObj[selected]}
			</Modal>
		</div>
	);
};
