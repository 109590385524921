import React from "react";

export const SvgContactUsEmail = () => {
	return (
		<svg
			width="13"
			height="9"
			viewBox="0 0 13 9"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.9582 0H0.841731C0.377622 0 0 0.377622 0 0.841731V8.05052C0 8.51463 0.377622 8.89225 0.841731 8.89225H11.9582C12.4223 8.89225 12.7999 8.51463 12.7999 8.05052L12.8 0.841731C12.8 0.377622 12.4224 0 11.9583 0H11.9582ZM11.3943 7.40592C11.3563 7.44361 11.3069 7.46234 11.2573 7.46234C11.2074 7.46234 11.1574 7.44326 11.1194 7.40511L8.36743 4.63623L6.5337 6.37782C6.53231 6.37909 6.53046 6.37955 6.52895 6.38083C6.51173 6.3962 6.49265 6.40869 6.47218 6.4169C6.47033 6.41759 6.46872 6.41794 6.46687 6.41863C6.44524 6.4265 6.4227 6.43124 6.39992 6.43124C6.37715 6.43124 6.3546 6.42661 6.33298 6.41863C6.33113 6.41794 6.32951 6.41771 6.32766 6.4169C6.30708 6.40869 6.28812 6.3962 6.27089 6.38083C6.2695 6.37955 6.26754 6.37909 6.26615 6.37782L4.43241 4.63623L1.68032 7.40511C1.6424 7.44326 1.59245 7.46234 1.5425 7.46234C1.49301 7.46234 1.44341 7.44338 1.40549 7.40592C1.32953 7.3303 1.32907 7.20728 1.40468 7.13109L4.15065 4.36857L1.40882 1.76479C1.33101 1.6908 1.32789 1.56801 1.40166 1.49008C1.47542 1.41215 1.59844 1.40915 1.67637 1.48291L6.40004 5.96891L11.1237 1.48291C11.2014 1.40915 11.3243 1.41215 11.3984 1.49008C11.4722 1.56789 11.4692 1.6908 11.3913 1.76479L8.64931 4.36845L11.3951 7.13097C11.4708 7.20716 11.4705 7.33018 11.3944 7.4058L11.3943 7.40592Z"
				fill="#00306B"
			/>
		</svg>
	);
};
