// @ts-nocheck
import clevertap from "clevertap-web-sdk";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";

// icons
import { SvgClubInfinitumLogo } from "assets/icons/js/SvgClubInfinitumLogo";
// import { SvgReviewReplyIcon } from "assets/icons/js/SvgReviewReplyIcon";
import { SvgScissor as Scissor } from "assets/icons/js/SvgScissor";

import addCommas from "helpers/addCommasToNumber";
import parseHTML from "helpers/parseHTML";

// scss
import "./style.scss";
import { BookingInterface } from "context/BookingData";

export const BookingCouponCard = ({
	coupon,
	setCoupon,
	data,
	bookingData,
	closeModal,
}: any) => {
	const { updateBooking } = useContext(BookingInterface);

	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<div className="coupon-card">
			<span className="card-img">
				{/* <SvgReviewReplyIcon /> */}
				<SvgClubInfinitumLogo />
			</span>
			<div className="card-details">
				<div className="details">
					<p className="title">{parseHTML(data?.DESCRIPTION)}</p>
					<p className="subtitle">*{data?.TNC ? parseHTML(data?.TNC) : ""}</p>
					<p className="title-n" style={{ color: "red" }}>
						{/* {errorApplyCoupon === data?.COUPON_CODE ? "Not Applicable" : ""} */}
					</p>
					{/* <Link
									to="/terms-and-conditions"
									className="link"
									aria-label="term-condition"
								>
									View T&C
								</Link> */}
					<span
						// className={`coupon_code ${coupon === data.COUPON_CODE ? "applied" : "not-applied"}`}
						className="coupon_code applied"
					>
						<Scissor
							color={coupon === data?.COUPON_CODE ? "#11bf0e" : "#2368a2"}
						/>
						{data?.COUPON_CODE}
					</span>
				</div>
				<div className="apply">
					<div className="save-upto">Save ₹{addCommas(data?.discount)}</div>
					<button
						aria-label="Apply Coupon"
						onClick={() => {
							setCoupon(data?.COUPON_CODE);
							// applyCoupon.mutate({ coupon: data?.COUPON_CODE });
							searchParams.set("coupon", data?.COUPON_CODE);
							setSearchParams(searchParams, { replace: true });
							updateBooking.mutate({ COUPON_CODE: data?.COUPON_CODE });

							clevertap.event.push("applycoupon_btn_booking_page", {
								bookingID: data?.BOOKING_ID,
								coupon_code: data?.COUPON_CODE,
								timestamp: new Date(),
							});
							closeModal();
						}}
						// disabled={errorApplyCoupon === data?.COUPON_CODE}
						className={
							coupon === data?.COUPON_CODE ||
							bookingData?.COUPON_CODE === data?.COUPON_CODE
								? "not-active"
								: "active"
						}
					>
						{bookingData?.COUPON_CODE === data?.COUPON_CODE ||
						data?.COUPON_CODE === coupon
							? "Applied"
							: bookingData?.COUPON_CODE === ""
							? "Apply"
							: bookingData?.COUPON_CODE !== "" &&
							  bookingData?.COUPON_CODE !== data?.COUPON_CODE
							? "Not Applied"
							: ""}
					</button>
				</div>
			</div>
		</div>
	);
};
