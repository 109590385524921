// @ts-nocheck
import moment from "moment";
import "intersection-observer";
import React, { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery, useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";

// helpers
import {
	axiosInstance,
	axiosInstance2,
	goAxiosInstance,
} from "helpers/axiosInstance";
import parseHTML from "helpers/parseHTML";

export const SearchPageDataInterface = React.createContext([]);

export const SearchPageData = ({ children, isVillas = "villas" }: any) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const { pathname } = useLocation();

	// query parametrs
	const useSearchQuery = () => {
		const { search } = useLocation();
		return useMemo(() => new URLSearchParams(search), [search]);
	};
	let query = useSearchQuery();

	// lat and lon
	const [location, setLocation] = useState({
		latitude:
			window.location.pathname &&
			window.location.pathname.split("/")[1] !== isVillas
				? parseFloat(query?.get("lat"))
				: "",
		longitude:
			window.location.pathname &&
			window.location.pathname.split("/")[1] !== isVillas
				? parseFloat(query?.get("lon"))
				: "",
	});

	// radius
	const [radius, setRadius] = useState(50);

	// fillters dropdown
	const [showAmenities, setShowAmenities] = useState(true);
	const [showEvent, setShowEvent] = useState(true);
	const [showMore, setShowMore] = useState(true);
	const [showBudget, setShowBudget] = useState(true);

	const [showSortPopup, setShowSortPopup] = useState(false);
	const [openSearchBar, setOpenSearchBar] = useState(false);
	const [showFilter, setShowFilter] = useState(false);

	// search bar popup
	const [showLocationPopup, setShowLocationPopup] = useState(false);
	const [showCalendarPopup, setShowCalendarPopup] = useState(false);
	const [showGuestPopup, setShowGuestPopup] = useState(false);

	//  login poup
	const [showLoginPopup, setShowLoginPopup] = useState(false);

	// filters
	const [allFillter, setAllFillters] = useState([]);
	const [amenitiesFilter, setAmenitiesFilter] = useState([]);
	const [moreFilter, setMoreFilter] = useState([]);
	const [eventFilter, setEventFilter] = useState([]);
	const [selectBudget, setSelectBudget] = useState({
		min: 0,
		max: 0,
	});
	const [clearAll, setClearAll] = useState(false);

	useEffect(() => {
		const minVal = query.get("min") ? query.get("min") : "";
		const clubVal = query.get("club") === "true" ? query.get("club") : "";
		const signatureVal =
			query.get("signature") === "true" ? query.get("signature") : "";
		const liteVal = query.get("lite") === "true" ? query.get("lite") : "";
		const samedayVal =
			query.get("same_day") === "true" ? query.get("same_day") : "";
		const coupleVal = query.get("couple") === "true" ? query.get("couple") : "";
		const pocketVal = query.get("pocket") === "true" ? query.get("pocket") : "";
		const breakfastVal =
			query.get("breakfast") === "true" ? query.get("breakfast") : "";

		setClearAll(false);
		setAllFillters(
			[
				...amenitiesFilter,
				...eventFilter,
				...moreFilter,
				minVal,
				clubVal,
				signatureVal,
				samedayVal,
				pocketVal,
				coupleVal,
				breakfastVal,
				liteVal,
			].filter(Boolean),
		);
	}, [amenitiesFilter, eventFilter, moreFilter, query]);

	// useEffect(() => {
	// 	setAllFillters([]);
	// 	setAmenitiesFilter([]);
	// 	setEventFilter([]);
	// 	setMoreFilter([]);
	// 	if (pathname === "/search" || pathname.split("/")[1] === isVillas) {
	// 		setSelectBudget({
	// 			min: 0,
	// 			max: 0,
	// 		});
	// 	}

	// 	searchParams.set("lite", "false");
	// 	searchParams.set("min", "");
	// 	searchParams.set("max", "");
	// 	searchParams.set("club", "false");
	// 	searchParams.set("signature", "false");
	// 	searchParams.set("couple", "false");
	// 	searchParams.set("pocket", "false");
	// 	searchParams.set("same_day", "false");
	// 	searchParams.set("breakfast", "false");
	// }, [clearAll === true]);

	// useEffect(() => {
	// 	searchParams.set("unavail", true);
	// 	setSearchParams(searchParams, { replace: true });
	// }, []);

	useEffect(() => {
		if (
			(pathname === "/search" || pathname.split("/")[1] === isVillas) &&
			(query.get("amenities") === "" ||
				query.get("amenities")?.split(",").length > 0 ||
				query.get("amenities") === null)
		) {
			searchParams.set("amenities", amenitiesFilter.filter(Boolean));
			setSearchParams(searchParams, { replace: true });
		}
	}, [amenitiesFilter, pathname, query, searchParams, setSearchParams]);

	useEffect(() => {
		if (pathname === "/search" || pathname.split("/")[1] === isVillas) {
			if (query.get("amenities") !== "" || query.get("amenities") === null) {
				const arr = query?.get("amenities")?.split(",").map(Number);
				if (arr?.length > 0) {
					setAmenitiesFilter([...arr]);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		if (
			(pathname === "/search" || pathname.split("/")[1] === isVillas) &&
			(query.get("events") === "" ||
				query.get("events")?.split(",").length > 0 ||
				query.get("events") === null)
		) {
			searchParams.set("events", eventFilter.filter(Boolean));
			setSearchParams(searchParams, { replace: true });
		}
	}, [eventFilter, pathname, query, searchParams, setSearchParams]);

	useEffect(() => {
		if (pathname === "/search" || pathname.split("/")[1] === isVillas) {
			if (query.get("events") !== "" || query.get("events") === null) {
				const arr = query?.get("events")?.split(",").map(Number);
				if (arr?.length > 0) {
					setEventFilter([...arr]);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	useEffect(() => {
		if (
			(pathname === "/search" || pathname.split("/")[1] === isVillas) &&
			(query.get("more") === "" ||
				query.get("more")?.split(",").length > 0 ||
				query.get("more") === null)
		) {
			searchParams.set("more", moreFilter.filter(Boolean));
			setSearchParams(searchParams, { replace: true });
		}
	}, [moreFilter, pathname, query, searchParams, setSearchParams]);

	useEffect(() => {
		if (pathname === "/search" || pathname.split("/")[1] === isVillas) {
			if (query.get("more") !== "" || query.get("more") === null) {
				const arr = query?.get("more")?.split(",").map(Number);
				if (arr?.length > 0) {
					setMoreFilter([...arr]);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const amenitiesQueryFilter =
		query.get("amenities") !== null &&
		(query?.get("amenities") === "null" || query?.get("amenities") !== "")
			? query?.get("amenities")
			: "";
	const moreQueryFilter =
		query.get("more") !== null &&
		(query?.get("more") === "null" || query?.get("more") !== "")
			? query?.get("more")
			: "";
	const eventQueryFilter =
		query.get("events") !== null &&
		(query?.get("events") === "null" || query?.get("events") !== "")
			? query?.get("events")
			: "";
	const combFilter = [
		...amenitiesQueryFilter.split(","),
		...moreQueryFilter.split(","),
	]
		.filter(Boolean)
		.filter((v) => v !== "29")
		.join(",");

	const signatureFilter = query?.get("signature") === "true" ? "Signature" : "";
	const liteFilter = query?.get("lite") === "true" ? "LITE" : "";
	const pocketFilter = query?.get("pocket") === "true" ? "0" : "";
	const clubFilter = query?.get("club") === "true" ? "Y" : "";
	const unvailFilter = query?.get("unavail") === "true" ? "1" : "0";
	const sameDayFilter = query?.get("same_day") === "true" ? "1" : "";
	const coupleFilter = query?.get("couple") === "true" ? "1" : "";
	const breakfastFilter = query?.get("breakfast") === "true" ? "1" : "";
	const couponDis = query.get("coupon") ? query.get("coupon") : "";
	const sortByFilter =
		query?.get("sortby") === "Price: Low to high"
			? "PriceLow"
			: query?.get("sortby") === "Price: High to low"
			? "PriceHigh"
			: query?.get("sortby") === "Popularity"
			? "Popularity"
			: query?.get("sortby") === "Newest first"
			? "Created_On"
			: "";

	const distance = query?.get("radius") === "any" ? 30 : query?.get("radius");

	const locationName =
		(pathname === "/search" &&
			window.location?.search?.split("=")[1]?.split("&")[0]) ||
		(window.location.pathname.split("/")[1] === isVillas &&
			window.location?.pathname?.split("/")[2].split("-")[2]);

	const highlightData = useQuery(
		["highlight", query?.get("lat"), query?.get("lon"), locationName],
		() => {
			return goAxiosInstance({
				method: "get",
				url: `hightlight?lat=${
					location.latitude || parseFloat(query?.get("lat"))
						? location.latitude || parseFloat(query?.get("lat"))
						: ""
				}&lon=${
					location.longitude || parseFloat(query?.get("lon"))
						? location.longitude || parseFloat(query?.get("lon"))
						: ""
				}&location=${
					!parseFloat(query?.get("lat")) && !parseFloat(query?.get("lon"))
						? locationName
						: ""
				}`,
			});
		},
		{
			enabled:
				(window.location.pathname?.split("/")[1] === isVillas ||
					pathname === "/search") &&
				location.latitude &&
				location.longitude
					? true
					: false,
			retry: false,
		},
	);

	const fetchSearchResults = async (pageParam) => {
		return axiosInstance2({
			method: "POST",
			url: `/search3`,
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: {
				S_CHECK_IN: query?.get("checkin")
					? moment(query?.get("checkin")).format("YYYY-MM-DD")
					: "",
				S_CHECK_OUT: query?.get("checkout")
					? moment(query?.get("checkout")).format("YYYY-MM-DD")
					: "",
				loclat: location.latitude || parseFloat(query?.get("lat")),
				loclong: location.longitude || parseFloat(query?.get("lon")),
				keyfeatures: `${combFilter}`,
				special: `${eventQueryFilter}`,
				show_unavailable: unvailFilter,
				PARTICIPATING_REWARDS_PROGRAM: clubFilter,
				SIGNATURE: signatureFilter || liteFilter,
				CLASSIFICATION: pocketFilter,
				SAME_DAY_CHECKIN: sameDayFilter,
				COUPLE_FRIENDLY: coupleFilter,
				sort_by: sortByFilter,
				COUPON_CODE: couponDis,
				num_guest: query?.get("guests") || "",
				S_NUM_SIMILAR_ROOMS: query?.get("rooms") || "",
				SEARCH_LOCATION: locationName
					? locationName.replaceAll("%20", " ")
					: "",
				UUID: localStorage.getItem("uuid") || "",
				DISTANCE_RADIUS:
					(location.latitude || parseFloat(query?.get("lat"))) >= 28 &&
					(location.latitude || parseFloat(query?.get("lat"))) <= 30 &&
					(location.longitude || parseFloat(query?.get("lon")) >= 77) &&
					(location.longitude || parseFloat(query?.get("lon"))) <= 80
						? 500
						: distance > 1
						? (+distance + 1.5) * 50
						: 125,
				per_Page: 9,
				page: pageParam,
				MIN_BUDGET: query.get("min") ? query.get("min") : 0,
				MAX_BUDGET: query.get("max") ? query.get("max") : 0,
				BREAKFAST_INCLUDED: breakfastFilter,
				PET_FRIENDLY: amenitiesQueryFilter?.includes("29") ? "1" : "0",
				// MARRIOT_BONVOY: "1",
				// overlook_capacity: 0,
				MARRIOT_BONVOY: query.get("next") === "true" ? "1" : "",
				NEW_HOME_2: query.get("new_homes") === "true" ? true : false,
				NON_SHARED: query.get("non_shared") === "true" ? "1" : "",
				NUM_PETS: query?.get("pets") || 0,
			},
		});
	};

	const { ref, inView } = useInView();

	const searchResults = useInfiniteQuery(
		[
			"searchResultsQuery",
			query?.get("pocket"),
			query?.get("couple"),
			query?.get("same_day"),
			query?.get("lat"),
			query?.get("lon"),
			query?.get("checkin"),
			query?.get("lite"),
			query?.get("checkout"),
			query?.get("events"),
			query?.get("amenities"),
			query?.get("more"),
			query?.get("club"),
			query?.get("signature"),
			query?.get("same_day"),
			query?.get("unavail"),
			query?.get("sortby"),
			query?.get("guests"),
			query?.get("rooms"),
			query?.get("coupon"),
			query?.get("radius"),
			query?.get("min"),
			query?.get("max"),
			query?.get("breakfast"),
			query?.get("next"),
			query?.get("new_homes"),
			query?.get("non_shared"),
			window.location.pathname?.split("/")[1] === isVillas &&
				!query.get("lat") &&
				!query.get("lon") &&
				location.latitude &&
				location.longitude,
		],
		({ pageParam = 1 }) => fetchSearchResults(pageParam),
		{
			getNextPageParam: (lastPage) => {
				const totalPages = lastPage?.data?.meta?.totalPages;
				const currentPage = lastPage?.data?.meta?.currentPage;
				const nextPage = lastPage?.data?.meta?.nextPage;
				return currentPage < totalPages ? nextPage : undefined;
			},
			enabled:
				window.location.pathname?.split("/")[1] === isVillas ||
				pathname === "/search"
					? true
					: false,
			retry: false,
		},
	);

	const [autoRadius, setAutoRadius] = useState(1);

	useEffect(() => {
		if (query?.get("radius") !== "any") {
			if (autoRadius <= 5) {
				setAutoRadius((prev) => (prev += 1));
				query.set("radius", autoRadius + 1);
			}
		}
	}, [searchResults.failureCount]);

	useEffect(() => {
		if (inView) {
			searchResults.fetchNextPage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	const value: any = {
		searchResults,
		locationName,
		query,
		showSortPopup,
		openSearchBar,
		showFilter,
		eventFilter,
		amenitiesFilter,
		showAmenities,
		showMore,
		showEvent,
		allFillter,
		clearAll,
		showLocationPopup,
		showCalendarPopup,
		showGuestPopup,
		selectBudget,
		showLoginPopup,
		showBudget,
		setShowSortPopup,
		setOpenSearchBar,
		setShowFilter,
		setEventFilter,
		setAmenitiesFilter,
		setShowAmenities,
		setShowEvent,
		setShowMore,
		setClearAll,
		setShowLocationPopup,
		setShowCalendarPopup,
		setShowGuestPopup,
		setShowLoginPopup,
		setSelectBudget,
		setShowBudget,
		setAllFillters,
		setMoreFilter,
		ref,
		location,
		setLocation,
		setRadius,
		highlightData,
		moreFilter,
		setMoreFilter,
	};

	return (
		<SearchPageDataInterface.Provider value={value}>
			{children}
		</SearchPageDataInterface.Provider>
	);
};
