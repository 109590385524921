import { SvgRequestSubmitted } from "assets/icons/js/SvgRequestSubmitted";
// style
import "./style.scss";

export const RequestSubmited = () => {
	return (
		<div className="request__submited__container">
			<img
				src={
					"https://ik.imagekit.io/5tgxhsqev/saffronstays-media/v1677842071/lead%20form%20assets/SSDHAg181m.gif"
				}
				alt=""
				loading="eager"
			/>
			<h3>Contact request submitted!</h3>
			<h4>We will be in touch with you shortly. </h4>
			<p>
				Meanwhile, you can browse through the homes below that match your needs.
			</p>
		</div>
	);
};
