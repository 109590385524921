import React from "react";

export const SvgSearch = (props) => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M3.01122 3.01218C4.30879 1.71461 6.03399 1 7.86901 1C9.70403 1 11.4292 1.71461 12.7268 3.01218C14.0243 4.30973 14.739 6.03493 14.739 7.86992C14.739 9.54761 14.1413 11.1333 13.0472 12.3845L16.8626 16.1999C17.0458 16.3831 17.0458 16.6802 16.8626 16.8635C16.7709 16.9551 16.6508 17.001 16.5308 17.001C16.4107 17.001 16.2906 16.9552 16.199 16.8635L12.3836 13.0482C11.1324 14.1422 9.54671 14.7399 7.86901 14.7399C6.03396 14.7399 4.30883 14.0253 3.01125 12.7277C1.7137 11.4302 0.999094 9.70497 0.999094 7.86995C0.999094 6.03493 1.71367 4.30973 3.01122 3.01218ZM3.67488 12.0641C5.98755 14.3767 9.75044 14.3767 12.0631 12.0641C14.3757 9.75144 14.3757 5.98849 12.0631 3.67585C10.9067 2.5194 9.38807 1.94137 7.86898 1.94137C6.35026 1.94137 4.83104 2.51969 3.67485 3.67585C1.36227 5.98846 1.36227 9.75141 3.67488 12.0641Z"
				fill={props.color ? props.color : "#2368a2"}
				stroke={props.color ? props.color : "#2368a2"}
			/>
		</svg>
	);
};
