export const SvgGoogle = (props) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_35_1283)">
				<path
					d="M17.8252 9.20768C17.8252 8.59588 17.7756 7.98078 17.6697 7.37891H9.18066V10.8446H14.042C13.8402 11.9624 13.1921 12.9512 12.243 13.5795V15.8283H15.1432C16.8463 14.2608 17.8252 11.9459 17.8252 9.20768Z"
					fill="#4285F4"
				/>
				<path
					d="M9.18056 18C11.6079 18 13.6549 17.203 15.1464 15.8273L12.2462 13.5785C11.4392 14.1275 10.3975 14.4384 9.18387 14.4384C6.8359 14.4384 4.84509 12.8543 4.13077 10.7246H1.13794V13.0428C2.66577 16.0819 5.77766 18 9.18056 18Z"
					fill="#34A853"
				/>
				<path
					d="M4.12757 10.725C3.75057 9.60727 3.75057 8.39691 4.12757 7.27914V4.96094H1.13804C-0.138461 7.50402 -0.138461 10.5002 1.13804 13.0432L4.12757 10.725Z"
					fill="#FBBC04"
				/>
				<path
					d="M9.18056 3.56224C10.4637 3.5424 11.7038 4.02523 12.6331 4.9115L15.2026 2.34196C13.5756 0.814129 11.4161 -0.0258495 9.18056 0.000606499C5.77766 0.000606499 2.66577 1.91867 1.13794 4.96111L4.12747 7.27931C4.83847 5.1463 6.83259 3.56224 9.18056 3.56224Z"
					fill="#EA4335"
				/>
			</g>
			<defs>
				<clipPath id="clip0_35_1283">
					<rect
						width="18"
						height="18"
						fill="white"
						transform="translate(0.000610352)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
