// @ts-nocheck

import { createContext, useMemo, useState } from "react";

export const IndicatorContext = createContext(null);

export function IndicatorProvider({ children }) {
	const [active, setActive] = useState(false);
	const value = useMemo(() => ({ active, setActive }), [active, setActive]);

	return (
		<IndicatorContext.Provider value={value}>
			{children}
		</IndicatorContext.Provider>
	);
}
