import { RegisterFormDataInterface } from "context/RegisterForm";
import { useContext, useState } from "react";

export const OtherIssue1 = () => {
	const {
		// @ts-ignore
		setDetails,
		// @ts-ignore
		details,
	} = useContext(RegisterFormDataInterface);

	const onChange = (e: any) => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};

	return (
		<div className="other__issue__one__container personal__step__one__container">
			<div className="top">
				<h1>Choose your reason for connecting</h1>
			</div>
			<div className="bottom">
				<h1>Enter your personal details</h1>
				<div className="bottom__top">
					<div className="left">
						<input
							type="text"
							name="firstname"
							placeholder="Full Name"
							onChange={onChange}
							value={details.firstname}
						/>
					</div>
					<div className="right">
						<input
							type="text"
							name="lastname"
							placeholder="Last Name"
							onChange={onChange}
							value={details.lastname}
						/>
					</div>
				</div>
				<div className="bottom__middle">
					<input
						type="text"
						name="email"
						placeholder="Email"
						onChange={onChange}
						value={details.email}
					/>
				</div>
				<div className="bottom__bottom">
					<div className="left">
						<input
							type="text"
							name="phone"
							placeholder="Phone"
							onChange={onChange}
							maxLength={10}
							minLength={10}
							value={details.phone}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
