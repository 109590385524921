// assets
import { SvgConceernOther } from "assets/icons/contactUsChooseConcern/SvgConceernOther";
import { SvgConcernBook } from "assets/icons/contactUsChooseConcern/SvgConcernBook";
import { SvgConcernListHome } from "assets/icons/contactUsChooseConcern/SvgConcernListHome";
import { SvgConcernQuery } from "assets/icons/contactUsChooseConcern/SvgConcernQuery";
import { SvgConcernTravelAgent } from "assets/icons/contactUsChooseConcern/SvgConcernTravelAgent";

// components
import { BottomContainer } from "../BottomContainer";

// style
import "./style.scss";

const data = [
	{
		title: "I want to book",
		icon: <SvgConcernBook />,
	},
	{
		title: "I have a query regarding my current/past booking",
		icon: <SvgConcernQuery />,
		link: "/contact-us/i-have-a-query-regarding-my-current/past-booking",
	},
	{
		title: "I want to list a home",
		icon: <SvgConcernListHome />,
	},
	{
		title: "I am a Travel Agent",
		icon: <SvgConcernTravelAgent />,
	},
	{
		title: "Other issue",
		icon: <SvgConceernOther />,
		link: "/form",
	},
];

export const Content = () => {
	return (
		<div className="content__container">
			<BottomContainer>
				<div className="content__wrapper">
					<h1>Choose your reason for connecting</h1>
					<div className="content__box__container">
						{data.map((v: any, i: number) => {
							return (
								<div
									className="box"
									onClick={() => {
										if (v.link) {
											window.location.href = v.link;
											return;
										}
										if (v.title === "I want to list a home") {
											window.location.href =
												"https://www.partner.saffronstays.com/";
										} else if (v.title === "I am a Travel Agent") {
											window.location.href = "/travelpartner?popup=true";
										} else {
											window.location.href = `/contact-us/${v.title
												.toLowerCase()
												.split(" ")
												.join("-")}`;
										}
									}}
								>
									<span>{v.icon}</span>
									<p>{v.title}</p>
								</div>
							);
						})}
					</div>
				</div>
			</BottomContainer>
		</div>
	);
};
