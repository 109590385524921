import { NewForm } from "components/NewForm";

// style
import "./style.scss";

export const NewFormPage = () => {
	return (
		<div className="new__form__page__container">
			<NewForm />
		</div>
	);
};
