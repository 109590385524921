import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import clevertap from "clevertap-web-sdk";

// context
import { UserContext } from "store";

// icons
import { SvgNewHomeLogo as NewHomeLogo } from "assets/icons/js/SvgNewHomeLogo";
import { SvgCross as Cross } from "assets/icons/js/SvgCross";
import { ReactComponent as Dropdown } from "assets/icons/downarrow.svg";

// customHooks
import { useUser } from "customHooks/useUser";
import { useMediaQuery } from "customHooks/useMediaQuery";
import { ReactComponent as PhoneSvg } from "assets/phone.svg";

// scss
import "./style.scss";
import { SvgSearch } from "assets/icons/js/SvgSearch";
import { ReactComponent as ExploreIcon } from "./assets/explore.svg";
import { ReactComponent as LocationIcon } from "./assets/location.svg";
import { ReactComponent as BookingIcon } from "./assets/booking.svg";
import { ReactComponent as WishListIcon } from "./assets/wishlist.svg";
import { ReactComponent as ConnectIcon } from "./assets/connect.svg";

import axios from "axios";

export const SideBar = ({ setShouldShowModal, isMenuOpen, setIsMenuOpen }) => {
	const { state } = useContext(UserContext);
	const { currentUser } = state;
	const [explore, setExplore] = useState(false);
	const [homeOwners, setHomeOwners] = useState(false);

	const navigate = useNavigate();

	const width = useMediaQuery();

	const user = useUser();

	const isMobile = width <= "500" ? true : false;

	const handleLogin = () => {
		if (isMobile) {
			// true for mobile device
			navigate("/login");
		} else {
			// false for not mobile device
			setShouldShowModal(true);
			setIsMenuOpen(false);
		}
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("uuid");
		window.location.reload();
		navigate("/");
	};

	const redirectToHostConnect = () => {
		const token = localStorage.getItem("token");
		const isHomeowner = currentUser.isHomeowner;

		if (token && isHomeowner) {
			window.open(
				`http://host.saffronstays.com/account/dashboard?jwt=${token}`,
				"_blank",
			);
		} else {
			window.open(`http://host.saffronstays.com/login`, "_blank");
		}
	};

	const [coll, setColl] = useState([]);
	const [nearby, setNearby] = useState([]);
	const getCollection = async () => {
		const res = await axios("https://go.saffronstays.com/api/home-collections");
		if (res.data.success) {
			setColl(res.data.data);
		}
	};

	const getNearby = async () => {
		const res = await axios(
			"https://ecapi.saffronstays.com/get-nearest-locations",
		);
		if (res.data.success) {
			setNearby(res.data.data);
		}
	};

	useEffect(() => {
		getNearby();
		getCollection();
	}, []);

	return (
		<div className="sidebar">
			<div className="sidebar__logo">
				{/* <div></div> */}
				<div className="cursor-pointer">
					<Link aria-label="homelogo" to="/">
						<NewHomeLogo height={width <= "320" ? 36 : 40} />
					</Link>
				</div>
				<div onClick={() => setIsMenuOpen(!isMenuOpen)}>
					<Cross />
				</div>
			</div>
			<nav className="sidebar__links">
				<div
					className="nav__middle__phone"
					onClick={() => {
						window.location.href = "/mobile-search";
					}}
				>
					<input type="text" placeholder="Search for place, home..." />
					<SvgSearch color="#999999" />
				</div>
				{!user && (
					<button onClick={handleLogin} className="login-btn">
						Log in/Register
					</button>
				)}
				<CollapseWrapper
					title={"Explore Villa Collections"}
					children={
						<div className="collection-wrapper">
							{coll.map((ele) => {
								return (
									<div
										className="col-card"
										onClick={() => {
											window.location.href = "/collection/" + ele.SLUG;
										}}
									>
										<img
											src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-200,h-200,q-80/image/upload/${ele.HOME_COVER_IMAGE}.webp`}
											alt=""
										/>
										<span className="col-title">{ele.TITLE}</span>
									</div>
								);
							})}
						</div>
					}
					icon={<ExploreIcon />}
				/>
				<CollapseWrapper
					title={"Locations"}
					icon={<LocationIcon />}
					children={
						<div className="collection-wrapper  location">
							{nearby.map((ele) => {
								return (
									<>
										<div
											className="col-card"
											onClick={() => {
												window.location.href =
													"/villas/villas-in-" + ele.title.toLowerCase();
											}}
										>
											<img
												src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-200,h-200,q-80/image/upload/${ele.icon}`}
												alt=""
											/>
											<span className="col-title">{ele.title}</span>
										</div>
										<div
											className="col-card"
											onClick={() => {
												window.location.href =
													"/villas/villas-in-" + ele.title.toLowerCase();
											}}
										>
											<img
												src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-200,h-200,q-80/image/upload/${ele.next.icon}`}
												alt=""
											/>
											<span className="col-title">{ele.next.title}</span>
										</div>
									</>
								);
							})}
						</div>
					}
				/>
				{user && (
					<>
						<div
							className="side-links"
							onClick={() => {
								clevertap.event.push("mobilemenu_mybookings", {
									timestamp: new Date(),
								});
								window.location.href = "/my-booking";
							}}
						>
							<BookingIcon /> My Bookings
						</div>
						<div
							className="side-links"
							onClick={() => {
								window.location.href = "/collection";
							}}
						>
							<WishListIcon /> Wishlist
						</div>
					</>
				)}
				<div
					className="side-links"
					onClick={() => {
						window.location.href = "/contact-us";
					}}
				>
					<ConnectIcon /> Connect with us
				</div>
				<a
					href="tel:08069160000"
					style={{
						textDecoration: "none",
						color: "black",
					}}
				>
					<div
						className="sidebar__link-text"
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<PhoneSvg
							style={{
								filter: "brightness(0)",
							}}
						/>
						08069160000
					</div>
				</a>
				<div
					style={{
						marginTop: "20px",
					}}
					className="side-links"
					onClick={redirectToHostConnect}
				>
					For Home Owners
				</div>
				{/* <div
					className="side-links"
					onClick={() => {
						window.location.href = "https://www.saffronstays.com/travelpartner";
					}}
					style={{
						border: 0,
					}}
				>
					For Travel Agents
				</div> */}
				<div
					className="side-links"
					onClick={() => {
						window.location.href = "/travelpartner";
					}}
					style={{
						border: 0,
					}}
				>
					For Travel Partners
				</div>
				{user && (
					<div className="logout" onClick={handleLogout}>
						Logout
					</div>
				)}
			</nav>
		</div>
	);
};

const CollapseWrapper = ({ title, children, icon }) => {
	const [open, setOpen] = useState(false);
	return (
		<div className="collapse-wrapper">
			<div
				className="collapse-header"
				onClick={() => {
					setOpen(!open);
				}}
			>
				<span>
					{icon} {title}
				</span>
				<Dropdown
					style={{
						transition: "0.3s linear",
						transform: open && "rotate(180deg)",
					}}
				/>
			</div>
			<div className={`collapse-body ${open ? "open" : "close"}`}>
				{children}
			</div>
		</div>
	);
};
