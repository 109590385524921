import getImg from "helpers/getCloudinaryImage";
import "./style.scss";

import { ReactComponent as BedIcon } from "../assets/bed.svg";
import { ReactComponent as HomeIcon } from "../assets/home.svg";

const VillaDetails = ({ data }: any) => {
	const img = getImg({
		id: data?.FIRST_IMAGE_ID,
		quality: "40",
		width: "106",
		height: "98",
	});
	return (
		<div className="booking-details-wrapper">
			<div className="booking-title">Booking Details</div>
			<div className="booking-detail-box">
				<div className="villa-details">
					<img src={img} alt="" />
					<div>
						<h3>{data?.VILLA_NAME?.split("-")[0]}</h3>
						<p>
							{data?.VILLA_CITY}, {data?.VILLA_STATE}
						</p>
					</div>
				</div>
				{/* <div className="room-details-wrapper">
					<RoomDetailsCard
						Icon={HomeIcon}
						text={getVillaType(data?.TYPE_FLAG || "")}
					/>
					<RoomDetailsCard
						Icon={BedIcon}
						text={`Sleeps upto ${data?.MAX_CAPACITY}`}
					/>
				</div> */}
			</div>
		</div>
	);
};

export default VillaDetails;

const getVillaType = (flag: string) => {
	switch (flag) {
		case "EV-MR":
			return "Entire Home";
		case "EV":
			return "Entire Home";
		case "SR":
			return "Single Room";
		case "MR":
			return "Multiple Rooms ";
		case "MV":
			return "Multiple Villas";
		default:
			return "";
	}
};

const RoomDetailsCard = ({ Icon, text }: { Icon: any; text: string }) => {
	return (
		<div className="room-detail-card">
			<Icon />
			{text}
		</div>
	);
};
