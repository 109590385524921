import React, { useEffect, useState } from "react";
import "./style.scss";
import { NavbarAndSidebar } from "components/NavbarAndSidebar";
import Footer from "components/Footer";

const EmailSubscriptionComponent = () => {
	const isReEncode = false;

	useEffect(() => {
		if (localStorage.getItem("unSub")) {
			setSuccess(true);
		}
	}, []);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		// Fetch the email id of the user on page load

		window.onload = function () {
			// eslint-disable-next-line no-undef
			$WZRK_WR.getEmail(isReEncode);
		};
	}, []);

	// Function to be called after the email id of the user has been fetched

	// Function to be called after the subscription preferences for the user have been updated
	function wzrk_email_subscription(status) {
		// Status 0: unsubscribed, status 1: subscribed
		// Todo: You can show a success message to the user from here
		var statusLabel = "subscribed";
		if (status === 0) {
			statusLabel = "unsubscribed";
		}
		alert("You've been " + statusLabel);
	}

	// Function to unsubscribe the user
	function unsubscribe() {
		// eslint-disable-next-line no-undef
		$WZRK_WR.unSubEmail(isReEncode);
		wzrk_email_subscription(0);
		setSuccess(true);
		localStorage.setItem("unSub", "true");
	}

	// Function to resubscribe the user
	function resubscribe() {
		// eslint-disable-next-line no-undef
		$WZRK_WR.subEmail(isReEncode);
		wzrk_email_subscription();
	}

	useEffect(() => {
		if (window) {
			const script = document.createElement("script");
			script.innerText = `function wzrk_email_fetched(emailStr) {
		document.getElementById("email").value = emailStr || "";
		console.log(emailStr);}`;
			document.body.appendChild(script);
		}
	}, []);

	return (
		<>
			<NavbarAndSidebar />

			{success ? (
				<main className="unsub-wrapper">
					<h4>You are Successfully Unsubscribed from Future Emails!</h4>
				</main>
			) : (
				<div className="unsub-wrapper">
					<h2>Unsubscribe Your Email from Future Emails!</h2>
					{/* Add your JSX code her*/}
					<input
						type="text"
						id="email"
						placeholder="Enter Your Email"
						readOnly
					/>
					<div className="btn-wrapper">
						{" "}
						<button onClick={unsubscribe}>Unsubscribe</button>
						<button onClick={resubscribe}>Resubscribe</button>
					</div>
				</div>
			)}
			<Footer />
		</>
	);
};

export default EmailSubscriptionComponent;
