import { SvgSearch } from "assets/icons/js/SvgSearch";
import { AutoComplete } from "components/NewForm/NewFormFlows/WantToBook/PersonalStep2/AutoComplete";
import { RegisterFormDataInterface } from "context/RegisterForm";
import { useMediaQuery } from "customHooks/useMediaQuery";
import React, { useContext, useState } from "react";

// style
import "./style.scss";

export const WantToRegister = ({ showBtn = "true" }: any) => {
	const {
		// @ts-ignore
		details,
		// @ts-ignore
		setDetails,
		// @ts-ignore
		setPlanToGo,
		// @ts-ignore
		setLat,
		// @ts-ignore
		websiteLink,
		// @ts-ignore
		setWebsiteLink,
	} = useContext(RegisterFormDataInterface);

	const [showAuto, setShowAuto] = useState(false);
	const [inputDetail, setInputDetail] = useState("");

	const width = useMediaQuery();

	const onChange = (e: any) => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};

	return (
		<div
			className="want__to__register__container personal__step__one__container
			other__details__container
			"
		>
			<div className="top" style={{ border: "none" }}>
				<h2>Register with us as a Travel Agent</h2>
			</div>
			<div className="bottom" style={{ borderBottom: "none" }}>
				<div className="left">
					<input
						type="text"
						name="phone"
						placeholder="Phone"
						onChange={onChange}
						value={details.phone}
						maxLength={10}
						minLength={10}
					/>
				</div>
				<div className="left">
					<input
						type="text"
						name="email"
						placeholder="Email"
						onChange={onChange}
						value={details.email}
					/>
				</div>
				<div className="left">
					<input
						type="text"
						name="companyName"
						placeholder="Company name"
						onChange={onChange}
						value={details.companyName}
					/>
				</div>
				<div className="search__villa">
					<label htmlFor="">Your city</label>
					<div className="span">
						<SvgSearch />
						<input
							type="text"
							placeholder="Search for a villa..."
							value={inputDetail}
							onChange={(e) => setInputDetail(e.target.value)}
							onClick={() => setShowAuto(true)}
						/>
						{showAuto && inputDetail.length > 0 && (
							<AutoComplete
								query={inputDetail}
								type="city"
								setQuery={(v: any) => {
									setPlanToGo(v.title);
									setInputDetail(v.title);
									setLat({
										lat: v.latitude,
										lon: v.longitude,
									});
								}}
								close={() => setShowAuto(false)}
							/>
						)}
					</div>
				</div>
				<div className="left">
					<input
						type="text"
						name="gst"
						placeholder="GST number/Udyog Aadhar number (optional)"
						onChange={onChange}
						value={details.gst}
					/>
				</div>
				<div className="left">
					<input
						type="text"
						name="socialMedia"
						placeholder="Website/Social Media link (optional)"
						onChange={(e) => setWebsiteLink(e.target.value)}
						value={websiteLink}
					/>
				</div>
			</div>
			{showBtn && (
				<div className="bottom__btn">
					<span></span>
					<span></span>
					<button className="btn__continue">Save & Continue</button>
				</div>
			)}
		</div>
	);
};
