export const SvgHide = (props) => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M24.8856 15.7192C24.7248 15.4992 20.8934 10.333 15.9999 10.333C11.1065 10.333 7.27485 15.4992 7.11422 15.719C6.96193 15.9276 6.96193 16.2107 7.11422 16.4194C7.27485 16.6394 11.1065 21.8055 15.9999 21.8055C20.8934 21.8055 24.7248 16.6393 24.8856 16.4196C25.0381 16.2109 25.0381 15.9276 24.8856 15.7192ZM15.9999 20.6187C12.3954 20.6187 9.27345 17.1898 8.3493 16.0689C9.27226 14.9469 12.3876 11.5198 15.9999 11.5198C19.6043 11.5198 22.726 14.9481 23.6505 16.0697C22.7276 17.1916 19.6122 20.6187 15.9999 20.6187Z"
				fill="black"
			/>
			<path
				d="M15.9999 12.5088C14.0367 12.5088 12.4395 14.106 12.4395 16.0692C12.4395 18.0324 14.0367 19.6297 15.9999 19.6297C17.9631 19.6297 19.5604 18.0324 19.5604 16.0692C19.5604 14.106 17.9631 12.5088 15.9999 12.5088ZM15.9999 18.4429C14.691 18.4429 13.6263 17.3781 13.6263 16.0692C13.6263 14.7604 14.6911 13.6956 15.9999 13.6956C17.3087 13.6956 18.3735 14.7604 18.3735 16.0692C18.3735 17.3781 17.3088 18.4429 15.9999 18.4429Z"
				fill="black"
			/>
		</svg>
	);
};
