export const formatDate = (date) => {
	try {
		const d = new Date(date).toDateString();
		const [day, month, n, year] = d.split(" ");

		return `${month} ${n}`;
	} catch (err) {
		return "";
	}
};

export const dateFormate = (date) => {
	try {
		const d = new Date(date).toLocaleDateString();
		const [month, day, year] = d.split("/");
		return `${year}-${month}-${day}`;
	} catch (err) {
		return "";
	}
};

export const shortDateFormat = (inputDateString) => {
	const date = new Date(inputDateString);
	const day = date.getDate();
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const month = monthNames[date.getMonth()];
	return `${day} ${month}`;
};
export const shortDateFormatWithYear = (inputDateString) => {
	const date = new Date(inputDateString);
	const day = date.getDate();
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const month = monthNames[date.getMonth()];
	const year = date.getFullYear();
	return `${day} ${month} ${year}`;
};
