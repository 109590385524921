import React, {
	createContext,
	useReducer,
	ReactElement,
	useContext,
} from "react";

import XSeriesLogo from "assets/x-series.png";

interface ThemeState {}

type ThemeAction = {
	type: string;
	payload: any;
};

const initialState: ThemeState = {
	theme: null,
};

export const ThemeContext = createContext<{
	state: ThemeState;
	dispatch: React.Dispatch<ThemeAction>;
} | null>(null);

const reducer = (state: ThemeState, action: ThemeAction): ThemeState => {
	switch (action.type) {
		case "SET_THEME":
			return { ...state, theme: action.payload };
		default:
			return state;
	}
};

export const ListingTheme = ({ children }: { children: ReactElement }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	return (
		<ThemeContext.Provider value={{ state, dispatch }}>
			{children}
		</ThemeContext.Provider>
	);
};

export const XSeriesTheme = {
	name: "xseries",
	background: "rgba(0, 0, 0, 1)",
	primaryTextColor: "rgba(242, 242, 242, 1)",
	secondaryTextColor: "rgba(136, 136, 136, 1)",
	baseTextColor: "rgba(187, 187, 187, 1)",
	borderColor:
		"linear-gradient(92.47deg, #857D4C 0.28%, #BD9A64 8.19%, #FBDE98 50.24%, #BD9A64 88.21%, #857D4C 98.22%)",
	otherTextColor: "rgba(207, 183, 125, 1)",
	mapBackground: "invert(90%) hue-rotate(180deg)",
	background2: "rgba(51, 51, 51, 1)",
	borderBookingBox: "1px solid rgba(255, 255, 255, 0.2)",
	bookingBtnBackground:
		"linear-gradient(275.65deg, #A5915F 10.11%, #D2BA7F 55.01%, #B49F6B 93.26%)",
	bookingBtnTextColor: "rgba(0, 0, 0, 1)",
	iconColor: "rgba(187, 187, 187, 1)",
	textButtonColor: "#D2BA7F",
	icon: XSeriesLogo,
	nameTag: {
		background: "rgba(0, 0, 0, 1)",
		textColor: "rgba(187, 187, 187, 1)",
		popUpBackground: "rgba(0, 0, 0, 1)",
		textButtonColor: "#D2BA7F",
		popUpTextColor: "rgba(242, 242, 242, 1)",
		iconColor: "rgba(207, 183, 125, 1)",
	},
	unlockPrice: {
		background:
			"linear-gradient(275.65deg, #A5915F 10.11%, #D2BA7F 55.01%, #B49F6B 93.26%)",
	},
	pricePopUp: {
		background: "black",
		textColor: "white",
		boxShadow: "0px 1px 30px rgba(182, 182, 182, 0.15)",
		border: "1px solid rgba(255, 255, 255, 0.2)",
	},
	borderBtn: {
		background: "black",
		border: "1.5px solid #BD9A64",
		color: "rgba(242, 242, 242, 1)",
	},
	progressBar: {
		background:
			"linear-gradient(275.65deg, #A5915F 10.11%, #D2BA7F 55.01%, #B49F6B 93.26%)",
		color: "#B49F6B",
	},
	lineColor: "rgba(255, 255, 255, 0.20)",
	starColor: "#B49F6B",
};

export const useTheme = () => {
	const {
		state: { theme },
	} = useContext<any>(ThemeContext);

	switch (theme) {
		case "X-Series":
			return XSeriesTheme;
		default:
			return {
				name: "",
				background: "white",
				primaryTextColor: "black",
				secondaryTextColor: "#666666",
				borderColor: "",
				otherTextColor: "",
				mapBackground: "",
				background2: "",
				borderBookingBox: "",
				bookingBtnBackground: "#2368a2",
				bookingBtnTextColor: "",
				iconColor: "#666666",
				textButtonColor: "blue",
				icon: "",
				baseTextColor: "",
				unlockPrice: {
					background:
						"linear-gradient(90.42deg, #58c5dd 1.83%, #418eb9 99.92%)",
				},
				pricePopUp: {
					background: "white",
					textColor: "rgba(0, 0, 0, 1)",
				},
				nameTag: {
					background: "rgba(238, 243, 255, 0.6)",
					textColor: "#333333",
					popUpBackground: "white",
					textButtonColor: "blue",
					popUpTextColor: "black",
					iconColor: "#2368a2",
				},
				borderBtn: {
					background: "",
					color: "#333333",
					border: "1px solid #333333",
				},
				progressBar: {
					background: "#2368a2",
					color: "#2368a2",
				},

				lineColor: "rgba(255, 255, 255, 1)",
				starColor: "#66A65C",
			};
	}
};
