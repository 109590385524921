// style
import { RegisterFormDataInterface } from "context/RegisterForm";
import { useContext } from "react";
import "./style.scss";

export const OtherIssue2 = () => {
	const {
		// @ts-ignore
		request,
		// @ts-ignore
		setRequest,
	} = useContext(RegisterFormDataInterface);

	return (
		<div className="other__issue__two__container">
			<h2>Please enter your reason to contact us below</h2>
			<div className="write__issue">
				<label
					htmlFor="
				"
				>
					Let us know more about your issue.
				</label>
				<textarea
					name=""
					id=""
					placeholder="Write about your issue"
					onChange={(e) => setRequest(e.target.value)}
					value={request}
				></textarea>
			</div>
		</div>
	);
};
