export const SVGContactUs = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.6361 15.8981L15.6564 13.8724C15.8174 13.7145 15.9453 13.5261 16.0325 13.3181C16.1197 13.1101 16.1644 12.8868 16.1641 12.6613C16.1638 12.4358 16.1185 12.2126 16.0307 12.0049C15.943 11.7972 15.8146 11.6091 15.6532 11.4516L14.5005 10.302C14.343 10.1448 14.1562 10.0202 13.9505 9.93528C13.7449 9.85037 13.5246 9.80681 13.3021 9.80711C13.0796 9.80741 12.8594 9.85156 12.654 9.93702C12.4486 10.0225 12.2621 10.1476 12.105 10.3052L11.3443 11.0596C9.3814 9.88689 7.73689 8.2497 6.55548 6.29208L7.31627 5.53773C7.47345 5.38029 7.59807 5.19342 7.68298 4.9878C7.7679 4.78217 7.81146 4.56182 7.81116 4.33935C7.81086 4.11688 7.76671 3.89665 7.68125 3.69125C7.59578 3.48586 7.47066 3.29932 7.31305 3.14231L6.18577 2.00959C6.02834 1.85241 5.84147 1.72779 5.63584 1.64288C5.43021 1.55796 5.20987 1.51441 4.9874 1.5147C4.76493 1.515 4.5447 1.55915 4.3393 1.64462C4.1339 1.73008 3.94737 1.8552 3.79035 2.01281L1.75315 4.03853C1.61469 4.17645 1.52823 4.35804 1.50843 4.55247C1.48863 4.7469 1.53673 4.94219 1.64455 5.10519C4.52296 9.42961 8.23763 13.1343 12.5698 16.0011C12.7321 16.1096 12.927 16.1586 13.1213 16.1398C13.3157 16.121 13.4975 16.0356 13.6361 15.8981Z"
				stroke="#666666"
				strokeWidth="1.9"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
