import { BottomContainer } from "components/NewForm/BottomContainer";
import { Stepper } from "components/Stepper";
import React, { useContext, useEffect, useState } from "react";

import PhoneLoginHandler from "components/LoginAndRegister/PhoneLoginHandler";
import { MyBookingCard } from "components/MyBooking/MyBookingWeb/MyBookingTabs/MyBookingCard";

import { ReactComponent as Tick } from "assets/form-tick.svg";

import "./style.scss";
import { UserContext } from "store";
import isUserLoggedIn from "helpers/isUserLoggedIn";
import axios from "axios";
import QueryString from "qs";
import { BottomBar } from "../WantToBook/BottomBar";
import { formatDate } from "helpers/dateFormatter";

import { ReactComponent as ActiveTick } from "assets/active_tick.svg";
import { ReactComponent as CalendarIcon } from "assets/calendar_icon.svg";
import { ReactComponent as GuestIcon } from "assets/guest_icon.svg";
// import { Navbar } from "components/Navbar";
import { NavbarAndSidebar } from "components/NavbarAndSidebar";
import { LeftContact } from "components/NewForm/LeftContact";

import { ReactComponent as BackIcon } from "assets/back.svg";
import EmailLoginHandler from "components/LoginAndRegister/EmailLoginHandler";

const ExistingBooking = () => {
	return (
		<div className="existing-wrapper-parent">
			<NavbarAndSidebar />
			<div className="existing-wrapper">
				{/* <LeftContact /> */}
				<div className="existing-cover">
					<ExistingBookingContainer />
				</div>
			</div>
		</div>
	);
};

const ExistingBookingContainer = () => {
	const [step, setStep] = useState(0);
	const [phone, setPhone] = useState("");
	const {
		state: { currentUser },
	} = useContext(UserContext);

	const [message, setMessage] = useState({
		issue: "",
		message: "",
	});

	const [showSuccessScreen, setShowSuccessScreen] = useState(false);

	const [select, setSelect] = useState({});
	const [flag, setFlag] = useState("Upcoming");

	const createTicket = async () => {
		const temp = {
			//@ts-ignore
			BOOKING_ID: select.BOOKING_ID,
			CRITICALITY: "Yes",
			ISSUE_LEVEL1: "Existing Booking Inquiry",
			ISSUE_LEVEL2: "",
			DESCRIPTION: `${currentUser.fullName} | ${currentUser.email} | ${currentUser.mobile} | Message:  ${message.issue}, ${message.message}`,
			CREATED_BY: currentUser.email,
			ISSUE_STATUS: "",
			ASSIGNED_TO: "",
			SOURCE: "contact-us",
			RESOLVED_BY: "",
			RESOLVED_COMMENTS: "",
			CUSTOMER_CONTACTED: "",
			MOBILE_NUMBER: currentUser.mobile,
			EMAIL_ID: currentUser.email,
			NAME: currentUser.fullName,
		};
		Object.keys(temp).forEach((ele: any) => {
			//@ts-ignore
			if (temp[ele] === "") {
				//@ts-ignore
				delete temp[ele];
			}
		});
		var data = QueryString.stringify(temp);
		const res = await axios.post(
			"https://ecapi.saffronstays.com/customer/issues/create",
			data,
			{
				headers: {
					authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/x-www-form-urlencoded",
				},
			},
		);
		if (res.data.success) {
			setShowSuccessScreen(true);

			window.open("/customer-chat/" + res.data.data.chatIdentifier, "_blank");
		}
	};

	if (showSuccessScreen) {
		return (
			<div className="existing-booking-wrapper">
				<div className="form-done">
					<img
						src="https://ik.imagekit.io/5tgxhsqev/saffronstays-media/v1677842071/lead%20form%20assets/SSDHAg181m.gif"
						alt=""
					/>
					<h4>Issue submitted!</h4>
					<p>
						We have received your query and will be in touch with you shortly.
					</p>
					<p>Thank you for your patience.</p>
					<a href="/">Done</a>
				</div>
			</div>
		);
	}
	return (
		<div className="existing-booking-wrapper">
			<Stepper currentStep={step}>
				{!isUserLoggedIn() && (
					<center>
						{phone ? (
							<PhoneLoginHandler
								onRequestClose={() => {}}
								setPhone={setPhone}
								showRegister={false}
								redirect="i-have-a-query-regarding-my-current/past-booking"
							/>
						) : (
							<EmailLoginHandler
								onRequestClose={() => {}}
								setPhone={setPhone}
								phone={phone}
								redirect="i-have-a-query-regarding-my-current/past-booking"
							/>
						)}
					</center>
				)}
				<>
					<ChooseBooking
						flag={flag}
						selectCard={select}
						setSelectCard={setSelect}
					/>

					<div className="save-btn">
						<div
							className="text-underline"
							onClick={() => {
								setFlag(
									flag !== "Past Bookings" ? "Past Bookings" : "Upcoming",
								);
							}}
						>
							Show{" "}
							{flag !== "Past Bookings" ? "Past Bookings" : "Upcoming Bookings"}
						</div>
						<button
							disabled={Object.keys(select).length === 0}
							onClick={() => {
								setStep(step + 1);
							}}
						>
							Continue
						</button>
					</div>
				</>
				{Object.keys(select).length !== 0 ? (
					<TellOurIssue
						ele={select}
						setStep={setStep}
						message={message}
						setMessage={setMessage}
						createTicket={createTicket}
					/>
				) : (
					<h1>Select a booking that you need help with</h1>
				)}
			</Stepper>
		</div>
	);
};

export default ExistingBooking;

const TellOurIssue = ({
	ele,
	setStep,
	message,
	setMessage,
	createTicket,
}: any) => {
	const name = ele?.VILLA_NAME?.split("-")[0];

	const location = `${ele?.VILLA_CITY}, ${ele?.VILLA_STATE}`;

	const options = [
		"Payment issue",
		"Reschedule/Cancel booking",
		"Modify booking",
		"Need more information about the property",
		"Meals issue",
		"Special request/instructions",
		"Other",
	];
	return (
		<>
			<div className="tell-issue-wrapper">
				<h3>
					<BackIcon
						onClick={() => {
							setStep(0);
						}}
					/>
					Tell us your issue with the booking
				</h3>
				<div className="booking-select-wrapper">
					<div className="data-cover img-cover">
						<img
							src={
								"https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-106,h-98,q-50/" +
								ele.IMAGE_ID
							}
							alt=""
						/>
						<div className="booking-select-detail">
							<div className="booking-select-title">{name}</div>
							<div className="booking-select-location">{location}</div>
						</div>
					</div>
					<div className="data-cover">
						<div className="booking-select-guest">
							<div className="booking-select-calender">
								<CalendarIcon /> {formatDate(ele?.FROM_DATE)} -{" "}
								{formatDate(ele?.TO_DATE)}
							</div>
							<div className="booking-select-total">
								<GuestIcon /> {ele?.NO_OF_GUESTS}
							</div>
						</div>
						<div className="booking-select-price">
							<span>Booking total</span>
							<b>₹{ele.SUB_TOTAL}</b>
						</div>
					</div>
				</div>
				<div
					className="back-step"
					onClick={() => {
						setStep(0);
					}}
				>
					Select other booking
				</div>
				<hr color="#DDDDDD" />
				<span>What do you need help with?</span>
				<select
					onChange={(e) => {
						if (e.target.value !== "Select an option") {
							setMessage({ ...message, issue: e.target.value });
						}
					}}
				>
					<option>Select an option</option>
					{options.map((e) => (
						<option>{e}</option>
					))}
				</select>
				<span>Let us know more about this issue.</span>
				<textarea
					value={message.message}
					onChange={(e) =>
						setMessage({
							...message,
							message: e.target.value,
						})
					}
					placeholder="Write about your issue"
				/>
			</div>
			<div className="save-btn">
				<button
					style={{
						display: "block",
						margin: "auto",
					}}
					onClick={createTicket}
				>
					Submit Issue
				</button>
			</div>
		</>
	);
};

const ChooseBooking = ({ selectCard, setSelectCard, flag }: any) => {
	const [data, setData] = useState([]);
	const [loader, setLoader] = useState(false);
	const getData = async () => {
		setLoader(true);
		setSelectCard({});
		try {
			const res = await axios(
				"https://ecapi.saffronstays.com/get/user/bookings/ss3?STATUS=" +
					flag +
					"&page=1&per_page=5",

				{
					headers: {
						Authorization: "Bearer " + localStorage.getItem("token"),
					},
				},
			);
			if (res.data.success) {
				setData(res.data.data);
				setLoader(false);
			}
		} catch (err) {
			setLoader(false);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		getData();
	}, [flag]);
	return (
		<div className="choose-booking-wrapper">
			<h3>
				<span
					onClick={() => {
						window.history.back();
					}}
				>
					<BackIcon />
				</span>{" "}
				Select a booking that you need help with
			</h3>
			{!loader
				? data.length !== 0
					? data.map((ele: any) => {
							const name = ele?.VILLA_NAME?.split("-")[0];

							const location = `${ele?.VILLA_CITY}, ${ele?.VILLA_STATE}`;
							return (
								<BookingCard
									active={selectCard}
									onClick={() => setSelectCard(ele)}
									key={ele?.BOOKING_ID}
									title={name}
									status={
										ele?.BOOKING_STATUS === 12
											? "Cancelled"
											: ele?.BOOKING_STATUS === 1
											? "Pending payment"
											: ele?.BOOKING_STATUS === 4
											? "Cancelled by guest"
											: ele?.BOOKING_STATUS === 5
											? "Cancelled by host"
											: ele?.BOOKING_STATUS === 6
											? "Cancelled by saffronstays"
											: ""
									}
									cancel={
										ele?.BOOKING_STATUS === 12
											? true
											: ele?.BOOKING_STATUS === 1
											? true
											: ele?.BOOKING_STATUS === 4
											? true
											: ele?.BOOKING_STATUS === 5
											? true
											: ele?.BOOKING_STATUS === 6
											? true
											: false
									}
									location={location}
									image={ele?.IMAGE_ID}
									guest={ele?.NO_OF_GUESTS}
									fromDate={formatDate(ele?.FROM_DATE)}
									toDate={formatDate(ele?.TO_DATE)}
									bookingId={ele?.BOOKING_ID}
									price={ele?.SUB_TOTAL}
								/>
							);
					  })
					: "Unfortunately, we were unable to find any bookings linked to your account."
				: "Loading..."}
		</div>
	);
};

const BookingCard = ({
	onClick,
	location,
	image,
	guest,
	fromDate,
	toDate,
	bookingId,
	cancel,
	status,
	title,
	active,
	price,
}: BookingProps) => {
	return (
		<div
			className={`booking-card-wrapper ${
				bookingId === active.BOOKING_ID && "active-card"
			}`}
			onClick={onClick}
		>
			{bookingId === active.BOOKING_ID && <ActiveTick id="active-tick" />}
			<img
				src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-106,h-98,q-50/${image}`}
				alt="#"
			/>
			<div className="booking-details">
				<div className="booking-title">{title}</div>
				<div className="booking-location">{location}</div>
				<div className="booking-bottom">
					<div className="booking-guest-wrapper">
						<div className="booking-dates">
							<CalendarIcon /> {fromDate} - {toDate}
						</div>
						<div className="booking-dates">
							<GuestIcon /> {guest}
						</div>
					</div>
					<div className="booking-price-wrapper">
						<div className="booking-price-span">Booking total</div>
						<span className="booking-price">₹ {price}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

interface BookingProps {
	onClick: any;
	location: string;
	image: string;
	guest: string;
	fromDate: string;
	toDate: string;
	bookingId: string;
	cancel: boolean;
	status: string;
	title: string;
	active: any;
	price: string;
}
