export const SvgEmail = (props) => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16.7734 2.52344H2.22656C0.998836 2.52344 0 3.52227 0 4.75V14.25C0 15.4777 0.998836 16.4766 2.22656 16.4766H16.7734C18.0012 16.4766 19 15.4777 19 14.25V4.75C19 3.52227 18.0012 2.52344 16.7734 2.52344ZM17.5156 14.25C17.5156 14.6592 17.1827 14.9922 16.7734 14.9922H2.22656C1.81732 14.9922 1.48438 14.6592 1.48438 14.25V4.75C1.48438 4.34076 1.81732 4.00781 2.22656 4.00781H16.7734C17.1827 4.00781 17.5156 4.34076 17.5156 4.75V14.25Z"
				fill="#666666"
			/>
			<path
				d="M17.3896 3.44922L9.50004 9.31758L1.61044 3.44922L0.724487 4.64024L9.50004 11.1676L18.2756 4.64024L17.3896 3.44922Z"
				fill="#666666"
			/>
		</svg>
	);
};
