import { useState } from "react";

// components
import { BottomContainer } from "components/NewForm/BottomContainer";
import { Stepper } from "components/Stepper";

// style
import "./style.scss";
import { ListHomeStep1 } from "./ListHomeStep1";
import { BottomBar } from "./BottomBar";
import { ListHomeStep2 } from "./ListHomeStep2";
import { RequestSubmited } from "components/NewForm/RequestSubmited";

export const ListHome = () => {
	// to control which step is review on
	const [currentStep, setCurrentStep] = useState(0);

	return (
		<div className="want__to__book__container">
			<BottomContainer>
				<Stepper currentStep={currentStep}>
					<ListHomeStep1 />
					<ListHomeStep2 />
					<RequestSubmited />
				</Stepper>
				<BottomBar currentStep={currentStep} setStep={setCurrentStep} />
			</BottomContainer>
		</div>
	);
};
