// import
import { useQuery } from "react-query";
import { createContext, useEffect, useState } from "react";

// helpers
import { goAxiosInstance, axiosInstance2 } from "helpers/axiosInstance";
import useDebounce from "customHooks/useDebounce";
import { useSearchQuery } from "customHooks/useQuery";
import { elasticLog } from "helpers/elastic";

// @ts-ignore
export const SearchBarDataInterface = createContext([]);

export const SearchBarData = ({ children, isVillas = "villas" }: any) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [recomm, setRecomm] = useState("");
	const [isCity, setIsCity] = useState(false);
	const [newResData, setNewResData] = useState([]);

	const sQuery = useSearchQuery();

	const [location, setLocation] = useState({
		latitude: sQuery.get("lat") ? sQuery.get("lat") : "",
		longitude: sQuery.get("lon") ? sQuery.get("lon") : "",
	});

	const query = useDebounce(searchQuery, 500);

	const searchBar = useQuery(
		["searchData", query],
		() => {
			elasticLog({
				index: "autocomplete",
				payload: {
					text: query,
				},
			});
			// @ts-ignore
			return goAxiosInstance({
				method: "GET",
				url: `https://go.saffronstays.com/api/auto-complete?text=${query}`,
			});
		},
		{
			onSuccess: (data) => {
				setIsCity(data?.data?.data[0].type === "City" ? true : false);
			},
			enabled: query.length > 0,
		},
	);

	// recentHomes function fetches the recently viewed homes
	// const recentHomes = useQuery(
	// 	["recentHomes"],
	// 	() => {
	// 		// @ts-ignore
	// 		return axiosInstance2({
	// 			method: "POST",
	// 			url: "user/interactions/useritemrecommendation",
	// 			headers: {
	// 				Authorization: `Bearer ${localStorage.getItem("token")}`,
	// 			},
	// 			data: {
	// 				userId:
	// 					localStorage.getItem("uuid") ||
	// 					localStorage.getItem("guestUniqueId"),
	// 				scenario: "recently_viewed",
	// 				Count: 5,
	// 				cascadeCreate: true,
	// 			},
	// 		});
	// 	},
	// 	{
	// 		onSuccess: (data) => {
	// 			setRecomm(data?.data?.data.recommId);
	// 		},
	// 		enabled:
	// 			localStorage.getItem("uuid") || localStorage.getItem("guestUniqueId")
	// 				? true
	// 				: false,
	// 	},
	// );

	useEffect(() => {
		if (isCity) {
			setNewResData(
				searchBar?.data?.data?.data.filter((v: any) => v.type === "City"),
			);
		} else {
			setNewResData(
				searchBar?.data?.data?.data.filter((v: any) => v.type === "Home"),
			);
		}
	}, [isCity, searchBar?.data?.data?.data]);

	const parsedData: any = {
		searchBar,
		location,
		setSearchQuery,
		setLocation,
		// recentHomes,
		recomm,
		isCity,
		newResData,
	};

	return (
		<SearchBarDataInterface.Provider value={parsedData}>
			{children}
		</SearchBarDataInterface.Provider>
	);
};
