import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { DatePicker } from "react-responsive-calendar-picker";

// helpers
import { formatDate } from "helpers/dateFormatter";
import { sameDayCheckin } from "helpers/sameDayCheckin";
import parseHTML from "helpers/parseHTML";

// customHooks
import { useSearchQuery } from "customHooks/useQuery";

// context
import { SearchBarDataInterface } from "context/SearchBarData";
import { customDates, format } from "multi-date";

// lib
import minNightsData from "lib/min2NightsData";

// style
import "./style.scss";
import { SvgSearch } from "assets/icons/js/SvgSearch";
import { HomeGuestPopUp } from "components/Home/HomeSearchBar/HomeGuestPopUp";
import { useNavigate } from "react-router-dom";
import { HomeSearchLocation } from "components/NewHomePage/HomeSearchLocation";
import { SvgLongArrow } from "assets/icons/js/SvgLongArrow";
import moment from "moment";

import clevertap from "clevertap-web-sdk";

export const NavbarSearchBar = ({ showSearchBar, closeSearchBar }: any) => {
	const query = useSearchQuery();

	// @ts-ignore
	const { searchBar, setSearchQuery, setLocation, location, newResData } =
		useContext(SearchBarDataInterface);

	const navigate = useNavigate();

	/**
	 * * open state open datepicker
	 * * openLocation state open search autocomplete popup
	 * * openGuest state open guest popup
	 */

	const [open, setOpen] = useState(false);
	const [openLocation, setOpenLocation] = useState(false);
	const [openGuest, setOpenGuest] = useState(false);
	const [error, setError] = useState(false);

	const inputLocation =
		window.location.pathname?.split("/")[2]?.split("-")[2] !== "me"
			? parseHTML(
					window.location.pathname.split("/")[1] === "villas" &&
						window.location?.pathname
							?.split("/")[2]
							?.split("-")[2]
							?.replace(/%20/g, " "),
			  )
			: "Villas near me";

	const [searchInput, setSearchInput] = useState(
		inputLocation !== "false" ? inputLocation : "",
	);
	const [searchVillas, setSearchVillas] = useState(
		window.location.pathname?.split("/")[2]?.split("-")[2]
			? parseHTML(window.location.pathname?.split("/")[2]?.split("-")[2])
			: "",
	);

	const [dates, setDates] = useState({
		// @ts-ignore
		checkin: query.get("checkin") ? new Date(query?.get("checkin")) : "",
		// @ts-ignore
		checkout: query.get("checkout") ? new Date(query.get("checkout")) : "",
	});
	const [guestsData, setGuestsData] = useState({
		rooms: query.get("rooms") ? Number(query.get("rooms")) : 1,
		guests: query.get("guests") ? Number(query.get("guests")) : 1,
	});

	const [shake, setShake] = useState(false);
	const locationRef = useRef(null);

	// getting the recentSearch data
	const recentData = window.localStorage.getItem("recent") || [];
	// @ts-ignore
	const recentArray = recentData.length > 0 && JSON.parse(recentData);

	// handleSearchInput handle the search query and the HomeSearchLocation popup
	const handleSearchInput = useCallback((e: any) => {
		setSearchInput(e.target.value);
		setSearchQuery(e.target.value);

		clevertap.event.push("search_keywords", {
			page: window.location.pathname,
			keyword: e.target.value,
		});

		if (recentArray.length > 0 || e.target.value !== "") {
			setOpenLocation(true);
		} else {
			setOpenLocation(false);
		}
	}, []);

	// @ts-ignore
	const lat = JSON.parse(localStorage.getItem("location"))?.lat
		? // @ts-ignore
		  JSON.parse(localStorage.getItem("location"))?.lat
		: "";

	// @ts-ignore
	const lon = JSON.parse(localStorage.getItem("location"))?.lon
		? // @ts-ignore
		  JSON.parse(localStorage.getItem("location"))?.lon
		: "";

	const checkin = moment(dates.checkin).format("YYYY-MM-DD");
	const checkout = moment(dates.checkout).format("YYYY-MM-DD");

	const handleSearch = () => {
		closeSearchBar();
		if (dates.checkin && !dates.checkout) {
			setShake(true);
			setOpen(true);
			setError(true);
			setTimeout(() => {
				setShake(false);
			}, 1000);
		} else {
			if (location.latitude && location.longitude) {
				setError(false);
				// if (
				// 	window.location.pathname
				// 		?.split("/")[2]
				// 		?.split("-")[2]
				// 		?.replaceAll("%20", " ") === "me"
				// ) {
				// 	navigate(
				// 		`/villas/villas-near-me?checkin=${
				// 			checkin !== "Invalid date" ? checkin : ""
				// 		}&checkout=${checkout !== "Invalid date" ? checkout : ""}&guests=${
				// 			guestsData.guests
				// 		}&rooms=${guestsData.rooms}&lat=${location.latitude}&lon=${
				// 			location.longitude
				// 		}&amenities=${
				// 			query.get("amenities") ? query.get("amenities") : ""
				// 		}&events=${query.get("events") ? query.get("events") : ""}&pocket=${
				// 			query.get("pocket") ? query.get("pocket") : ""
				// 		}&same_day=${query.get("same_day") ? query.get("same_day") : ""}&
				// 	club=${query.get("club") ? query.get("club") : ""}&signature=${
				// 			query.get("signature") ? query.get("signature") : ""
				// 		}&breakfast=${
				// 			query.get("breakfast") ? query.get("breakfast") : ""
				// 		}&radius=1`,
				// 	);
				// } else {
				navigate(
					`/villas/villas-in-${searchInput || searchVillas}?checkin=${
						checkin !== "Invalid date" ? checkin : ""
					}&checkout=${checkout !== "Invalid date" ? checkout : ""}&guests=${
						guestsData.guests
					}&rooms=${guestsData.rooms}&lat=${location.latitude}&lon=${
						location.longitude
					}&amenities=${
						query.get("amenities") ? query.get("amenities") : ""
					}&events=${query.get("events") ? query.get("events") : ""}&pocket=${
						query.get("pocket") ? query.get("pocket") : ""
					}&same_day=${query.get("same_day") ? query.get("same_day") : ""}&
					club=${query.get("club") ? query.get("club") : ""}&signature=${
						query.get("signature") ? query.get("signature") : ""
					}&breakfast=${
						query.get("breakfast") ? query.get("breakfast") : ""
					}&radius=1`,
				);
				// }
			} else {
				navigate(
					`/villas/villas-near-me?checkin=${
						checkin !== "Invalid date" ? checkin : ""
					}&checkout=${checkout !== "Invalid date" ? checkout : ""}&guests=${
						guestsData.guests
					}&rooms=${guestsData.rooms}&lat=${lat}&lon=${lon}&amenities=${
						query.get("amenities") ? query.get("amenities") : ""
					}&events=${query.get("events") ? query.get("events") : ""}&pocket=${
						query.get("pocket") ? query.get("pocket") : ""
					}&same_day=${query.get("same_day") ? query.get("same_day") : ""}&
					club=${query.get("club") ? query.get("club") : ""}&signature=${
						query.get("signature") ? query.get("signature") : ""
					}&breakfast=${
						query.get("breakfast") ? query.get("breakfast") : ""
					}&radius=1`,
				);
			}
		}
	};

	const [block, setBlock] = useState([]);
	const [spl, setSpl] = useState(false);
	const setRestrictedDates = (flag = 0) => {
		if (flag === 1) {
			// @ts-ignore
			if (minNightsData().includes(format(dates.checkin, "YYYY-MM-DD"))) {
				setSpl(true);
				// @ts-ignore
				setBlock([format(customDates(dates.checkin, 1, "day"), "YYYY-MM-DD")]);
			}
		} else {
			setSpl(false);
			setBlock([]);
		}
	};

	useEffect(() => {
		if (dates.checkin && !dates.checkout) {
			setRestrictedDates(1);
		} else if (dates.checkin && dates.checkout) {
			setRestrictedDates();
			setOpen(false);
			setOpenGuest(true);
		}
	}, [dates]);

	return (
		<div
			className={
				showSearchBar
					? "navbar__search__container navbar__animate"
					: "navbar__search__container navbar__reanimate"
			}
		>
			<div className="navbar__search">
				<div className="search__container">
					<div className="left">
						<div className="search__input block ">
							<span>Location</span>
							<input
								type="text"
								placeholder="Search for a location"
								value={searchInput}
								autoComplete="off"
								autoFocus
								required
								onChange={handleSearchInput}
								onFocus={() => {
									if (recentArray.length > 0 || searchInput !== "") {
										setOpenLocation(true);
									} else {
										setOpenLocation(false);
									}
								}}
							/>
							<HomeSearchLocation
								// @ts-ignore
								open={openLocation}
								setSearchInput={setSearchInput}
								searchBar={searchBar}
								setSearchQuery={setSearchQuery}
								setOpen={setOpenLocation}
								setDateOpen={() => setOpen(true)}
								query={query}
								setLocation={setLocation}
								newResData={newResData}
							/>
						</div>
						<div className="search__date">
							<DatePicker
								dates={dates}
								open={open}
								setOpen={setOpen}
								setDates={setDates}
								sameDay={sameDayCheckin(1) ? 1 : 0}
								blocked={block}
								spl={spl}
								style={{
									// animation: shake && "shake 0.5s",
									zIndex: "10",
									top: "70px",
									left: "25%",
								}}
							>
								<div
									className="search__checkin__checkout "
									onClick={() => {
										setOpen(true);
									}}
								>
									<div className="checkin__date block add__date ">
										<span>Check in</span>
										<input
											type="text"
											placeholder="Add Checkin"
											autoComplete="off"
											autoFocus
											required
											className="search__checkin"
											value={dates.checkin ? formatDate(dates.checkin) : ""}
											onChange={() => {}}
										/>
									</div>
									<div className="line">
										<h5>-</h5>
									</div>
									<div className="checkout__date block add__date">
										<span>Check out</span>
										<input
											type="text"
											placeholder="Add Checkout"
											autoComplete="off"
											autoFocus
											required
											className="search__checkout"
											value={dates.checkout ? formatDate(dates.checkout) : ""}
											onChange={() => {}}
										/>
									</div>
								</div>
							</DatePicker>
						</div>
						<div className="search__guest block">
							<span>Guest</span>
							<span
								className={
									guestsData.guests === 1 && guestsData.rooms === 1
										? "add__guest__text"
										: "add__guest__text guest__text__active"
								}
								onClick={() => setOpenGuest(true)}
							>
								{guestsData.guests === 1 && guestsData.rooms === 1
									? "Add Guest"
									: `${guestsData.rooms} rooms, ${guestsData.guests} guests`}
							</span>
							<HomeGuestPopUp
								data={guestsData}
								open={openGuest}
								setOpen={setOpenGuest}
								setData={setGuestsData}
							/>
						</div>
					</div>
					<button
						aria-label="submit"
						type="submit"
						className="btn__search"
						onClick={handleSearch}
					>
						{/* <SvgSearch color={"#ffffff"} /> */}
						Go
						<SvgLongArrow color={"#ffffff"} />
					</button>
				</div>
			</div>
		</div>
	);
};
