export const SvgRecentSearch = (props: any) => {
	return (
		<svg
			width="23"
			height="22"
			viewBox="0 0 23 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M12.3336 21C7.45424 21 3.2875 17.4782 2.47422 12.6672L2.2209 13.0438C2.01458 13.3495 1.59994 13.4302 1.2943 13.2238C0.988651 13.0175 0.907989 12.6029 1.11431 12.2972L2.44756 10.2974C2.55722 10.1307 2.73587 10.0217 2.93419 10.0007H3.00086C3.17751 10.0014 3.3465 10.072 3.47082 10.1974L5.13738 11.8639C5.3977 12.1242 5.3977 12.5469 5.13738 12.8072C4.87707 13.0675 4.45443 13.0675 4.19411 12.8072L3.78081 12.3906C4.54842 17.1149 9.00047 20.322 13.7245 19.5544C18.4485 18.7868 21.6563 14.3351 20.8887 9.61073C20.2071 5.41567 16.5837 2.33386 12.3336 2.33453C9.6531 2.28587 7.11792 3.55045 5.54403 5.72098C5.33037 6.02096 4.91407 6.09129 4.61409 5.87764C4.3141 5.66398 4.24378 5.24768 4.45743 4.9477C6.27998 2.42386 9.22112 0.950285 12.3336 1.00128C17.8562 1.00128 22.333 5.47799 22.333 11.0006C22.333 16.5233 17.8562 21 12.3336 21Z"
				fill="#666666"
				stroke="#666666"
				strokeWidth="0.5"
			/>
			<path
				d="M15.898 13.9713L12.8259 11.6672V6.97374C12.8259 6.50182 12.4444 6.12036 11.9725 6.12036C11.5006 6.12036 11.1191 6.50182 11.1191 6.97374V12.0939C11.1191 12.3627 11.2454 12.6162 11.4605 12.7766L14.8739 15.3367C15.0275 15.4519 15.2068 15.5074 15.3851 15.5074C15.6454 15.5074 15.9014 15.3905 16.0687 15.1652C16.352 14.7888 16.2752 14.2538 15.898 13.9713Z"
				fill="#666666"
			/>
		</svg>
	);
};
