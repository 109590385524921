import { axiosInstance } from "helpers/axiosInstance";
import { useMutation } from "react-query";

const useForgotPassword = () => {
	const mutation = useMutation(({ email }) => {
		return axiosInstance({
			method: "post",
			url: "/login/forgot/password",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("token")}`,
			},
			data: {
				DOMAIN: "www.saffronstays.com",
				EMAIL_ID: email,
			},
		});
	});
	return mutation;
};

export default useForgotPassword;
