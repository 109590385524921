import { RequestSubmited } from "components/NewForm/RequestSubmited";
import { RegisterFormDataInterface } from "context/RegisterForm";
import { useContext, useState } from "react";
import { DatePicker } from "react-responsive-calendar-picker";

// style
import "./style.scss";

export const HaveQueryIssue = ({ showBtn = "true" }: any) => {
	const {
		// @ts-ignore
		request,
		// @ts-ignore
		setRequest,
		// @ts-ignore
		registerFormDataMutation,
	} = useContext(RegisterFormDataInterface);

	if (registerFormDataMutation.isSuccess) {
		return (
			<div className="request__sub__success">
				<RequestSubmited />
			</div>
		);
	}

	return (
		<div className="have__query__issue__container personal__step__one__container">
			<h2>Please enter your reason to contact us below</h2>
			<div className="top__query">
				<div className="write__issue">
					<label
						htmlFor="
				"
					>
						Let us know more about your issue.
					</label>
					<textarea
						name=""
						id=""
						placeholder="Write about your issue"
						onChange={(e) => setRequest(e.target.value)}
						value={request}
					></textarea>
				</div>
			</div>
			{/* <div
				className="bottom__last"
				style={{ borderTop: "1px solid #dddddd", paddingTop: "24px" }}
			>
				<h1>Choose when you would like a callback</h1>
				<div className="bottom__last__top">
					<div className="left">
						<DatePicker
							style={{
								zIndex: 10,
							}}
							noMonth={1}
							open={openCalenderCheckIn}
							setOpen={setOpenCalendarCheckIn}
							dates={dates}
							setDates={setDates}
						>
							<div
								className="input"
								onClick={() => {
									// @ts-ignore
									setOpenCalendarCheckIn((prev) => !prev);
								}}
							>
								{dates.checkin ? dates.checkin : "Choose a date"}
							</div>
						</DatePicker>
					</div>
					<div className="right">
						<div className="input">Choose a time</div>
					</div>
				</div>
			</div> */}
			{showBtn && (
				<div className="bottom__btn">
					<span></span>
					<span></span>
					<button
						className="btn__continue"
						onClick={() => registerFormDataMutation.mutate()}
					>
						Save & Continue
					</button>
				</div>
			)}
		</div>
	);
};
