import { useUser } from "customHooks/useUser";
import React from "react";
import { HaveQueryIssue } from "./HaveQueryIssue";
import { HaveQueryLogin } from "./HaveQueryLogin";

export const HaveQuery = () => {
	const user = useUser();

	return (
		<div className="have__query__container">
			{user ? <HaveQueryIssue /> : <HaveQueryLogin />}
		</div>
	);
};
