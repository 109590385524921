import { RegisterFormDataInterface } from "context/RegisterForm";
import { useContext, useEffect, useState } from "react";

// style
import "./style.scss";

export const BottomBar = ({ currentStep, setStep, callback }: any) => {
	// @ts-ignore
	const { registerFormDataMutation, details } = useContext(
		RegisterFormDataInterface,
	);

	const [tabNumber, setTabNumber] = useState(0);

	return (
		<div className="review__bottom__bar__container">
			<div className="review__bottom__container">
				{currentStep === 0 ? (
					<>
						<div className="buttons__1">
							<span></span>
							<span></span>
							<button
								className="btn__continue"
								disabled={
									details.firstname &&
									details.lastname &&
									details.email &&
									details.phone
										? false
										: true
								}
								onClick={() => {
									registerFormDataMutation.mutate();
									window.scrollTo({
										top: 0,
										behavior: "smooth",
									});
									if (!callback) {
										setStep((prev: number) => (prev += 1));
									}
									if (callback) {
										callback();
									}
								}}
							>
								Save & Continue
							</button>
						</div>
					</>
				) : (
					<>
						<div className="buttons">
							{currentStep !== 2 && (
								<button
									className="btn__prev"
									onClick={() => {
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
										if (currentStep === 1) {
											if (tabNumber === 0) {
												setStep((prev: number) => (prev -= 1));
											} else {
												setTabNumber((prev) => (prev -= 1));
											}
										} else {
											setStep((prev: number) => (prev -= 1));
										}
									}}
								>
									Back
								</button>
							)}
							<div className="right">
								<button
									className="btn__continue"
									onClick={() => {
										registerFormDataMutation.mutate();
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
										if (currentStep > 0 && currentStep < 2) {
											if (
												currentStep === 1 &&
												registerFormDataMutation.isSuccess === true
											) {
												setStep((prev: number) => (prev += 1));
											} else {
												setStep((prev: number) => (prev += 1));
											}
										}
										if (currentStep === 2) {
											window.location.href = "/";
										}
									}}
								>
									{currentStep === 2 ? "Done" : "Save & Continue"}
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
