import clevertap from "clevertap-web-sdk";
import { useSearchParams } from "react-router-dom";

// styles
import "./styles.scss";

export const SortbyFilter = ({ handleClose }: any) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const sortingOptions = [
		"Price: Low to high",
		"Price: High to low",
		"Popularity",
		"Newest first",
	];

	return (
		<div className="sort__popup__container">
			<div className="sort__popup__middle">
				{sortingOptions.map((option, idx) => (
					<p
						key={idx}
						className="text"
						onClick={() => {
							if (
								searchParams.get("sortby") === null ||
								searchParams.get("sortby") !== ""
							) {
								searchParams.set("sortby", option);
								setSearchParams(searchParams);
							}
							clevertap.event.push("search_filter", {
								search_location: window.location.pathname
									?.split("/")[2]
									?.split("-")[2],
								type_of_filter: `Sort By: ${option}`,
								timestamp: new Date(),
								url: window.location.pathname + window.location.search,
							});
							handleClose();
						}}
						style={
							option === searchParams.get("sortby")
								? {
										color: "#2368a2",
								  }
								: {}
						}
					>
						{option}
					</p>
				))}
			</div>
		</div>
	);
};
