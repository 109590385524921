import { Route, Routes } from "react-router-dom";

// components
import { TravelAgentOptionPage } from "./TravelAgentOptionPage";
import { HaveQuery } from "./TravelAgentOptionPage/HaveQuery";
import { WantToRegister } from "./TravelAgentOptionPage/WantToRegister";
import { WantToRegisterAndHaveQuery } from "./TravelAgentOptionPage/WantToRegisterAndHaveQuery";

export const TravelAgent = () => {
	return (
		<div className="travel__agent__container">
			<Routes>
				<Route path="/" element={<TravelAgentOptionPage />} />
				<Route element={<HaveQuery />} path="/i-have-query" />
				<Route path="/i-want-to-register" element={<WantToRegister />} />
				<Route
					path="/i-want-to-register-and-have-query"
					element={<WantToRegisterAndHaveQuery />}
				/>
			</Routes>
		</div>
	);
};
