import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { Input } from "../Input";
import { PrimaryButton } from "../PrimaryButton";
import { EmailSent } from "../EmailSent";

// helpers
import validateEmail from "helpers/validateEmail";

//api
import forgotPassword from "context/Mutations/forgotPassword";

// assets
import { SvgClose as Close } from "assets/icons/js/SvgClose";
import { ReactComponent as Loader } from "assets/spinning_wheel.svg";
import { SvgNavigationArrow as NavigationArrow } from "assets/icons/js/SvgNavigationArrow";

// css
import "./style.scss";

export const ForgetPassword = ({ onRequestClose }) => {
	const [emailSent, setEmailSent] = useState(false);
	const [email, setEmail] = useState("");
	const [inputErrorEmail, setInputErrorEmail] = useState(false);

	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleChange = (e) => {
		setInputErrorEmail(false);
		setEmail(e.target.value);
	};

	const handlePrimaryClick = (e) => {
		e.preventDefault();
		if (!validateEmail(email)) {
			setInputErrorEmail(true);
			return;
		}
		mutation.mutate({ email: email });
		if (!mutation.isLoading) setEmailSent(true);
	};

	const mutation = forgotPassword();

	return !emailSent ? (
		<div className="forgetPassword">
			<div className="forgetPassword-container">
				{pathname !== "/login" && pathname !== "/forget-password" && (
					<div className="close-icon">
						<span onClick={onRequestClose}>
							<Close height={12} width={12} />
						</span>
					</div>
				)}
				{pathname === "/forget-password" && (
					<div className="close-icon" onClick={() => navigate(-1)}>
						<span>
							<NavigationArrow />
						</span>
					</div>
				)}
				<div className="forgetPassword__sub-container">
					<div className="forgetPassword__title">
						<p>Reset Password</p>
					</div>
					<div className="forgetPassword__subtitle">
						<p>
							Enter your email and we'll send you a link to reset your password
						</p>
					</div>
					<div className="forgetPassword__input">
						<Input
							id="email"
							type="text"
							placeholder={"Email"}
							name="email"
							value={email}
							onChange={handleChange}
						/>
						{inputErrorEmail && (
							<p style={{ color: "red", fontSize: "12px", margin: "6px 0" }}>
								Please enter a valid email
							</p>
						)}
					</div>
					<div>
						<PrimaryButton
							type="submit"
							value={
								mutation.isLoading ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Loader
											style={{
												width: "30px",
												height: "30px",
											}}
										/>
									</div>
								) : (
									"Send link to email"
								)
							}
							onClick={handlePrimaryClick}
						/>
					</div>
				</div>
			</div>
		</div>
	) : (
		<EmailSent onRequestClose={onRequestClose} email={email} />
	);
};
