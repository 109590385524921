import React from "react";

export const SvgConcernBook = () => {
	return (
		<svg
			width="59"
			height="42"
			viewBox="0 0 59 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M50.2593 40.9996H5.91626C4.61298 40.9978 3.36329 40.4794 2.44195 39.5581C1.52016 38.6363 1.0018 37.3866 1 36.0833V10.303C1.00176 8.99925 1.52016 7.75001 2.44195 6.82822C3.36331 5.90643 4.61298 5.38807 5.91626 5.38672H30.0335C30.5765 5.38672 31.0167 5.82699 31.0167 6.36997C31.0167 6.91295 30.5765 7.35322 30.0335 7.35322H5.91626C5.13449 7.3541 4.3843 7.66488 3.83166 8.21796C3.27857 8.77104 2.96736 9.5208 2.96648 10.303V36.0833C2.96736 36.8656 3.27857 37.6153 3.83166 38.168C4.3843 38.721 5.13449 39.0323 5.91626 39.0331H50.2593C51.0415 39.0322 51.7912 38.721 52.3443 38.168C52.8974 37.6153 53.2082 36.8656 53.2091 36.0833V27.4711C53.2091 26.9281 53.6493 26.4878 54.1923 26.4878C54.7353 26.4878 55.1756 26.9281 55.1756 27.4711V36.0833C55.1743 37.3866 54.6559 38.6363 53.7341 39.5581C52.8123 40.4795 51.563 40.9978 50.2593 40.9996Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.8"
			/>
			<path
				d="M46.4343 23.5825C43.4398 23.5825 40.5677 22.3929 38.4502 20.2754C36.3326 18.1578 35.1431 15.2858 35.1431 12.2912C35.1431 9.29675 36.3326 6.42463 38.4502 4.3071C40.5677 2.18957 43.4397 1 46.4343 1C49.4289 1 52.3009 2.18957 54.4184 4.3071C56.536 6.42463 57.7255 9.29663 57.7255 12.2912C57.722 15.2849 56.5316 18.1548 54.4149 20.2714C52.2979 22.3881 49.428 23.579 46.4343 23.5825ZM46.4343 2.96673C43.9612 2.96673 41.5896 3.94911 39.8408 5.69793C38.0919 7.44675 37.1096 9.81837 37.1096 12.2915C37.1096 14.7645 38.0919 17.1361 39.8408 18.885C41.5896 20.6338 43.9612 21.6162 46.4343 21.6162C48.9074 21.6162 51.279 20.6338 53.0278 18.885C54.7767 17.1362 55.759 14.7645 55.759 12.2915C55.7564 9.81927 54.7731 7.4489 53.0248 5.70107C51.2768 3.95268 48.9066 2.96943 46.4344 2.96684L46.4343 2.96673Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.8"
			/>
			<path
				d="M51.445 12.8493H41.4232C40.8802 12.8493 40.4399 12.409 40.4399 11.8661C40.4399 11.3226 40.8802 10.8828 41.4232 10.8828H51.445C51.9879 10.8828 52.4282 11.3226 52.4282 11.8661C52.4282 12.409 51.9879 12.8493 51.445 12.8493Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.8"
			/>
			<path
				d="M46.4339 17.8594C46.1732 17.8594 45.923 17.7558 45.7386 17.5714C45.5543 17.3871 45.4507 17.1368 45.4507 16.8761V6.85435C45.4507 6.31137 45.891 5.87109 46.4339 5.87109C46.9769 5.87109 47.4172 6.31137 47.4172 6.85435V16.8761C47.4176 17.1368 47.314 17.3871 47.1297 17.5714C46.9449 17.7558 46.6947 17.8594 46.4339 17.8594Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.8"
			/>
		</svg>
	);
};
