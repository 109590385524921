export const SvgPhone = (props) => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.1624 10.92L13.5527 9.60125C12.504 8.7421 10.8761 8.8234 9.91937 9.78007L9.85547 9.84397C9.69505 10.0044 9.43374 10.0031 9.27558 9.84493L6.1655 6.73481C6.00849 6.5778 6.00539 6.32526 6.15669 6.16492C6.15945 6.162 6.16225 6.15913 6.16509 6.15625L6.15654 6.1648C6.15937 6.16196 6.16221 6.15909 6.16505 6.15625C7.11648 5.19571 7.20754 3.56028 6.37413 2.50659L5.06858 0.856027C4.22942 -0.204946 2.77093 -0.286901 1.81433 0.669692L1.57452 0.909501C-0.124606 2.6086 -0.525036 5.671 0.749419 7.70712C0.749419 7.70712 1.49964 9.44704 4.03148 11.9789C6.56332 14.5108 8.30323 15.261 8.30323 15.261C10.3583 16.5045 13.4007 16.1362 15.0994 14.4375L15.3278 14.2091C16.2852 13.2517 16.2104 11.7786 15.1624 10.92ZM14.1713 13.0526L13.9429 13.281C12.7767 14.4472 10.5612 14.7156 9.14986 13.8617L9.05384 13.8036L8.9508 13.7592C8.82431 13.7046 8.50722 13.5327 8.02784 13.2112C7.17644 12.6401 6.22035 11.8549 5.18796 10.8225C4.15556 9.79004 3.37032 8.83394 2.79929 7.98254C2.47777 7.50315 2.30581 7.18606 2.2513 7.05956L2.20193 6.94506L2.13577 6.83938C1.27772 5.46852 1.5544 3.24262 2.731 2.06604L2.97081 1.82623C3.23673 1.56031 3.55466 1.57841 3.78588 1.87073L5.09143 3.5213C5.41334 3.92828 5.37166 4.63682 5.00013 5.00838C4.20978 5.80796 4.21367 7.09605 5.00902 7.89138L8.1191 11.0015C8.91637 11.7988 10.2133 11.7992 11.012 11.0005L11.0759 10.9366C11.4386 10.574 12.1183 10.5404 12.5162 10.8665L14.1259 12.1853C14.4265 12.4315 14.444 12.7799 14.1713 13.0526Z"
				fill={props.color ? props.color : "#1A1A1A"}
			/>
		</svg>
	);
};
