import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { BookingInterface } from "context/BookingData";
import { NavbarAndSidebar } from "components/NavbarAndSidebar";
import VillaDetails from "./VillaDetails";
import GuestDetail from "./GuestDetails";
import MealWrapper from "./Meals";
import Payments from "./Payments";
import PersonalDetails from "./PersonalDetails";
import BookingChoosePayment from "./BookingChoosePayment";
import CancellationTimeline from "./CancellationTimeline";
import Footer from "components/Footer";
import { useMediaQuery } from "customHooks/useMediaQuery";

import { ReactComponent as TickIcon } from "./assets/tick.svg";
import { ReactComponent as LockIcon } from "./assets/lock.svg";
import { ReactComponent as Loader } from "./assets/loader.svg";

// clevertap
import clevertap from "clevertap-web-sdk";
import { useLocation } from "react-router-dom";
import { inBetweenDates } from "multi-date";
import moment from "moment";
import { useQuery } from "react-query";
import { UserContext } from "store";
import DeadlineCoupon from "./DeadlineCoupon";
import AskHelp from "./AskHelp";
import {
	axiosInstance,
	axiosInstance2,
	goAxiosInstance,
} from "helpers/axiosInstance";
import BookingExperiences from "./BookingExperiences";
import { AddOnInterface } from "context/AddonData";
import axios from "axios";

const BookingWrapper = () => {
	const {
		bookingData,
		updateBooking,
		fetchPaymentGateway,
		acceptTC,
		setAcceptTC,
	}: any = useContext(BookingInterface);

	const { pathname } = useLocation();
	const newData = bookingData?.data?.data;
	const bookingID = pathname.split("/")[2];
	const [dates, setDates] = useState({
		checkin: "",
		checkout: "",
	});
	const fetchCoupons = useQuery(
		["get coupons", dates?.checkin, dates?.checkout],
		() =>
			// @ts-ignore
			axiosInstance2({
				method: "GET",
				url: `/ss3/fetch/coupons/new?FROM_DATE=${
					dates?.checkin
						? moment(dates?.checkin).format("YYYY-MM-DD")
						: newData?.FROM_DATE
				}&TO_DATE=${
					dates?.checkout
						? moment(dates?.checkout).format("YYYY-MM-DD")
						: newData?.TO_DATE
				}&LISTING_ID=${newData?.LISTING_ID}&BOOKING_ID=${bookingID}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			}),
		{ enabled: false },
	);
	const { state } = useContext(UserContext);
	const { currentUser } = state;
	const visitsData = useQuery(["visits"], () => {
		return goAxiosInstance({
			url: `visits?id=${data.data?.listing_list?.id}
				`,
			method: "GET",
		});
	});

	// clevertap.event.push("view_booking_page", {
	// 	booking_id: `${bookingID}`,
	// 	listing_id: `${newData?.LISTING_ID}`,
	// 	listing_name: `${newData?.VILLA_NAME.split("-")[0]}`,
	// 	seo_name: `${newData?.VILLA_NAME}`,
	// 	days_left_till_checkin: `${parseInt(
	// 		inBetweenDates(Date.now(), newData?.TO_DATE),
	// 	)}`,
	// 	checkin_date: new Date(newData?.TO_DATE),
	// 	checkout_date: new Date(newData?.FROM_DATE),
	// 	no_of_nights: `${parseInt(
	// 		inBetweenDates(newData?.TO_DATE, newData?.FROM_DATE),
	// 	)}`,
	// 	only_checkin_date: moment(checkInDate).format("DD-MM-YYYY"),
	// 	only_checkout_date: moment(checkOutDate).format("DD-MM-YYYY"),
	// 	no_of_guests: `${newData?.NO_OF_GUESTS}`,
	// 	no_of_bedrooms: `${newData?.NO_OF_ROOMS}`,
	// 	accomodation_charges: `${newData?.PAYABLE_AT_TIME_BOOKING}`,
	// 	saving_with_reward_points: `${currentUser?.rewardPoint}`,
	// 	reward_tier: `${currentUser?.rewardTier}`,
	// 	location_of_home: `${newData?.VILLA_CITY} ${newData?.VILLA_STATE}`,
	// 	home_city: newData?.VILLA_CITY,
	// 	home_state: newData?.VILLA_STATE,
	// 	number_of_nights: `${newData?.NO_OF_NIGHTS}`,
	// 	timestamp: new Date(),
	// 	coupon_name: `${
	// 		fetchCoupons?.data?.data?.data?.length > 0
	// 			? fetchCoupons?.data?.data?.data[0]?.COUPON_CODE
	// 			: ""
	// 	}`,
	// 	coupon_discount: `${
	// 		fetchCoupons?.data?.data?.data?.length > 0
	// 			? fetchCoupons?.data?.data?.data[0]?.discount
	// 			: ""
	// 	}`,
	// 	coupon_expiry_date: `${
	// 		fetchCoupons?.data?.data?.data?.length > 0
	// 			? new Date(fetchCoupons?.data?.data?.data[0]?.EXPIRY_DATE)
	// 			: ""
	// 	}`,
	// });

	// fetchAllAddons

	useEffect(() => {
		// @ts-ignore
		if (newData) {
			if (document) {
				var gScript = document.createElement("script");
				gScript.type = "text/javascript";
				gScript.innerHTML = `gtag("event", "begin_checkout", {
							currency: "INR",
							value: "${newData?.ROOM_CHARGES}",
							coupon: "${newData?.COUPON_CODE}",
							items: [
								{
									item_id: "${bookingID}",
									item_name: "${newData?.VILLA_NAME}",
									coupon: "${newData?.COUPON_CODE}",
									currency: "INR",
									discount: "${newData?.DISCOUNT}",
									item_brand: "${newData?.VILLA_STATE}",
									item_category: "${newData?.VILLA_CITY}",
									item_category2: "${newData?.SHARE_SCHEME}",
									item_category3: "${newData?.NO_OF_ROOMS}",
									item_variant: "${newData?.TYPE}",
									price: "${newData?.PRICE_PER_NIGHT}",
									quantity: "${newData?.NO_OF_NIGHTS}"
								}
							]
						});
					`;
				gScript.defer = true;
				document.head.appendChild(gScript);

				const fbScript = document.createElement("script");
				const fbScript2 = document.createElement("script");
				fbScript.innerHTML = `fbq('track', 'Lead', {
					value: '${newData?.ROOM_CHARGES}',
					currency: 'INR',
					content_ids: '["${newData?.LISTING_ID}"]',
					city: '${newData?.VILLA_CITY}',
					region: '${newData?.VILLA_STATE}',
					country: 'India',
					content_type: 'hotel',
					checkin_date: '${newData?.TO_DATE}',
					checkout_date: '${newData?.FROM_DATE}',
					num_adults: '${newData?.NO_OF_GUESTS}',
					num_children: '${newData?.NUM_CHILDREN}'
				});`;
				fbScript2.innerHTML = `fbq('track', 'AddToCart', {
					content_ids: ['${newData?.LISTING_ID}'],
					content_type: 'product',
					value: ${newData?.ROOM_CHARGES},
					currency: 'INR',checkin_date: '${newData?.TO_DATE}',
					checkout_date: '${newData?.FROM_DATE}',
					num_adults: '${newData?.NO_OF_GUESTS}',
					num_children: '${newData?.NUM_CHILDREN}'

				});`;
				fbScript.defer = true;
				fbScript2.defer = true;
				document.head.appendChild(fbScript);
				document.head.appendChild(fbScript2);

				const tfq = document.createElement("script");
				tfq.innerHTML = `_tfa.push({notify: 'event', name: 'checkout', id: 1634482, revenue: ${newData?.TOTAL_AMOUNT_WITH_SERVICE}, orderid: ${bookingID},  currency: 'INR'});`;
				tfq.defer = true;
				document.head.appendChild(tfq);

				const checkInDate = new Date(newData?.FROM_DATE);
				const checkOutDate = new Date(newData?.TO_DATE);

				clevertap.event.push("view_booking_page", {
					booking_id: `${bookingID}`,
					listing_id: `${newData?.LISTING_ID}`,
					listing_name: `${newData?.VILLA_NAME.split("-")[0]}`,
					seo_name: `${newData?.VILLA_NAME}`,
					days_left_till_checkin: `${parseInt(
						inBetweenDates(Date.now(), newData?.TO_DATE),
					)}`,
					checkin_date: new Date(newData?.TO_DATE),
					checkout_date: new Date(newData?.FROM_DATE),
					no_of_nights: `${parseInt(
						inBetweenDates(newData?.TO_DATE, newData?.FROM_DATE),
					)}`,
					only_checkin_date: moment(checkInDate).format("DD-MM-YYYY"),
					only_checkout_date: moment(checkOutDate).format("DD-MM-YYYY"),
					no_of_guests: `${newData?.NO_OF_GUESTS}`,
					no_of_bedrooms: `${newData?.NO_OF_ROOMS}`,
					accomodation_charges: `${newData?.PAYABLE_AT_TIME_BOOKING}`,
					saving_with_reward_points: `${currentUser?.rewardPoint}`,
					reward_tier: `${currentUser?.rewardTier}`,
					location_of_home: `${newData?.VILLA_CITY} ${newData?.VILLA_STATE}`,
					home_city: newData?.VILLA_CITY,
					home_state: newData?.VILLA_STATE,
					number_of_nights: `${newData?.NO_OF_NIGHTS}`,
					timestamp: new Date(),
					coupon_name: `${
						newData?.COUPON_CODE || fetchCoupons?.data?.data?.data?.length > 0
							? fetchCoupons?.data?.data?.data[0]?.COUPON_CODE
							: ""
					}`,
					coupon_discount: `${
						newData?.discount || fetchCoupons?.data?.data?.data?.length > 0
							? fetchCoupons?.data?.data?.data[0]?.discount
							: ""
					}`,
					coupon_expiry_date: `${
						fetchCoupons?.data?.data?.data?.length > 0
							? new Date(fetchCoupons?.data?.data?.data[0]?.EXPIRY_DATE)
							: ""
					}`,
					high_demand_message:
						visitsData?.data?.data?.data?.VALUE >= 50 &&
						`${visitsData?.data?.data?.data?.VALUE} Travelers saw this property in the last few hours`,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newData]);
	const width = useMediaQuery();

	useEffect(() => {
		if (document) {
			document.body.style.overflowX = "hidden";
			document.body.style.background =
				"linear-gradient(270deg, #F9FAFB 0%, rgba(255, 255, 255, 0.00) 100%)";
		}
		return () => {
			document.body.style.background = "white";
			document.body.style.overflowX = "auto";
		};
	}, []);

	const applyRewardHandler = async (value: boolean) => {
		await updateBooking.mutateAsync({
			APPLY_REWARDS: value,
		});
	};
	const data = bookingData;

	if (data.isLoading) {
		return <>Loading...</>;
	}

	return (
		<>
			<NavbarAndSidebar />
			<div className="booking-wrapper">
				<div className="booking-left">
					<VillaDetails data={data?.data?.data} />
					<GuestDetail data={data?.data?.data} />
					{width < 1000 && (
						<div className="booking-payment-mob">
							<DeadlineCoupon data={data?.data?.data} />
							<Payments data={data?.data?.data} />
						</div>
					)}
					{data?.data?.data?.mealDetails?.MEAL_PREFERENCE && (
						<MealWrapper data={data?.data?.data} />
					)}
					<BookingExperiences bookingData={bookingData} />
					<BookingChoosePayment data={data?.data?.data} />
					<CancellationTimeline data={data?.data?.data} />
					<PersonalDetails data={data?.data?.data} />
					<div className="show-mob-only">
						<AskHelp data={data?.data?.data} />
					</div>
				</div>
				<div className="booking-right">
					<Payments data={data?.data?.data} />
				</div>
				<div className="payment-bottom-bar">
					<div className="payment-bottom-wrapper">
						{/* {data?.data?.data?.SECURITY_DEPOSIT && (
							<span>
								Security deposit statement - Home requires a{" "}
								<b>
									{data?.data?.data?.SECURITY_DEPOSIT?.toLocaleString("hi-IN", {
										style: "currency",
										currency: "INR",
										maximumFractionDigits: "0",
									})}
								</b>{" "}
								refundable security deposit.
							</span>
						)} */}
						<div className="terms-condition-wrapper">
							<span>
								By selecting the button below, I agree to all the terms and
								conditions mentioned{" "}
								<a
									target="_blank"
									href="https://www.saffronstays.com/policy-center"
									rel="noreferrer"
								>
									here
								</a>
							</span>
						</div>
						<button
							className={
								(fetchPaymentGateway.isLoading ||
									fetchPaymentGateway.isFetching) &&
								"loading-btn"
							}
							disabled={
								updateBooking.isLoading ||
								!acceptTC ||
								bookingData.isLoading ||
								bookingData.isFetching ||
								fetchPaymentGateway.isLoading ||
								fetchPaymentGateway.isFetching
							}
							onClick={() => {
								clevertap.event.push("booknow_btn_booking_page", {
									bookingID: data?.data?.data?.bookingID,
									listingID: data?.data?.data?.LISTING_ID,
									amount: data?.data?.data?.TOTAL_AMOUNT_WITH_SERVICE,
									timestamp: new Date(),
								});
								fetchPaymentGateway.mutateAsync();
							}}
						>
							{data?.TOTAL_AMOUNT_WITH_SERVICE === 0 ? (
								"Book Now"
							) : (
								<>
									<LockIcon />
									Pay{" "}
									{updateBooking.isLoading ||
									bookingData.isLoading ||
									bookingData.isFetching ||
									fetchPaymentGateway.isLoading ||
									fetchPaymentGateway.isFetching ? (
										<Loader
											style={{
												width: "17",
												height: "17",
											}}
										/>
									) : (
										data?.data?.data?.TOTAL_AMOUNT_WITH_SERVICE?.toLocaleString(
											"hi-IN",
											{
												style: "currency",
												currency: "INR",
												maximumFractionDigits: "0",
											},
										)
									)}{" "}
									Securely
								</>
							)}
						</button>
					</div>
				</div>
			</div>
			{/* <div className="booking-wrapper booking-bottom-wrapper">
				<div className="booking-left" style={{ paddingTop: 24 }}></div>
			</div> */}
			<Footer />
		</>
	);
};

export default BookingWrapper;
