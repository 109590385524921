import "./style.scss";
import { ReactComponent as LockIcon } from "../assets/lock.svg";
import { ReactComponent as TickIcon } from "../assets/tick.svg";
import { ReactComponent as UnLockIcon } from "../assets/unlock.svg";
import { ReactComponent as GiftIcon } from "../assets/gift.svg";
import { useContext } from "react";
import { BookingInterface } from "context/BookingData";
import { Tooltip } from "react-tooltip";
import { useMutation } from "react-query";
import "react-tooltip/dist/react-tooltip.css";
import { useState, useMemo } from "react";

import clevertap from "clevertap-web-sdk";
import { axiosInstance } from "helpers/axiosInstance";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import DeadlineCoupon from "../DeadlineCoupon";
import AskHelp from "../AskHelp";
import { useListingCalculations } from "customHooks/useListingCalculations";
import addCommas from "helpers/addCommasToNumber";
import { ReactComponent as TaStars } from "components/ListingNew/ListingNormal/BookingBox/assets/taStars.svg";
import { UserContext } from "store";
const Payments = ({ data }: any) => {
	const {
		updateBooking,
		fetchPaymentGateway,
		acceptTC,
		setAcceptTC,
		fetchData,
		bookingData,
	}: any = useContext(BookingInterface);
	const applyRewardHandler = async (value: boolean) => {
		await updateBooking.mutateAsync({
			APPLY_REWARDS: value,
		});
	};
	const isMealDiscount = data?.mealDetails?.IS_MEAL_FIVE && data?.MEAL_CHARGES;
	const {
		state: { currentUser },
	} = useContext(UserContext);

	return (
		<div className="payment-wrapper">
			<h3>Price Summary</h3>
			<div className="summary-box">
				<div className="payment-element">
					<div className="payment-title">Total rental charges</div>
					<span>
						{data?.ROOM_CHARGES?.toLocaleString("hi-IN", {
							style: "currency",
							currency: "INR",
						}) || "-"}
					</span>
				</div>

				{data?.EXTRA_CHARGES || data?.TOTAL_BIG_CHILD_CHARGES ? (
					<div className="payment-element">
						<div className="payment-title">
							Extra guest charges{" "}
							<span
								id={"extra-guest-tooltip-" + data?.NO_OF_ROOMS * data?.CAPACITY}
								data-tooltip-html={`More than ${
									data?.NO_OF_ROOMS * data?.CAPACITY
								} guests will be charged as extra`}
								style={{ cursor: "pointer" }}
							>
								ⓘ
							</span>
						</div>
						<span>
							{(
								data?.EXTRA_CHARGES + data?.TOTAL_BIG_CHILD_CHARGES
							)?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}{" "}
							<Tooltip
								delayHide={0}
								events={["hover"]}
								anchorId={
									"extra-guest-tooltip-" + data?.NO_OF_ROOMS * data?.CAPACITY
								}
							/>
						</span>
					</div>
				) : null}
				{data?.MEAL_CHARGES ? (
					<div className="payment-element">
						<div className="payment-title">Meal charges</div>
						<span>
							{data?.MEAL_CHARGES?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}
				{data?.DRIVER_CHARGES ? (
					<div className="payment-element">
						<div className="payment-title">Driver charges</div>
						<span>
							{data?.DRIVER_CHARGES?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}
				{data?.MAID_CHARGES ? (
					<div className="payment-element">
						<div className="payment-title">Maid charges</div>
						<span>
							{data?.MAID_CHARGES?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}
				{data?.PET_CHARGES ? (
					<div className="payment-element">
						<div className="payment-title">Pet charges</div>
						<span>
							{data?.PET_CHARGES?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}
				{!!isMealDiscount && data?.MEAL_DISCOUNT_AMOUNT > 0 && (
					<div className="payment-element" style={{ color: "#11bf0e" }}>
						<div className="payment-title">Meal Discount (5% Off)</div>
						<span>
							-{" "}
							{data?.MEAL_DISCOUNT_AMOUNT?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				)}

				{data?.COUPONAMOUNT_TAX ? (
					<div className="payment-element" style={{ color: "#11bf0e" }}>
						<div className="payment-title">
							Discount {data?.COUPON_CODE && `(${data?.COUPON_CODE})`}
						</div>
						<span>
							-{" "}
							{data?.COUPONAMOUNT_TAX?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}
				{data?.ADJ_AGAINST_REWARD ? (
					<div className="payment-element" style={{ color: "#2368a2" }}>
						<div className="payment-title">Reward Point</div>
						<span>
							-{" "}
							{data?.ADJ_AGAINST_REWARD?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}

				<div className="payment-element">
					<div className="payment-title">Total GST</div>
					<span>
						{data?.BOOKING_FEE_SERVICE_TAX_AMOUNT?.toLocaleString("hi-IN", {
							style: "currency",
							currency: "INR",
						}) || "-"}
					</span>
				</div>
				{data?.TOTAL_SERVICE_CHARGE ? (
					<div className="payment-element">
						<div className="payment-title">
							Convenience fees{" "}
							<span
								id={"convenfee-tooltip-" + data.TOTAL_SERVICE_CHARGE}
								data-tooltip-html={`Convenience Fee: ${data?.SERVICE_CHARGE?.toLocaleString(
									"hi-IN",
									{
										style: "currency",
										currency: "INR",
									},
								)} + Tax: ${data?.SERVICE_CHARGE_TAX?.toLocaleString("hi-IN", {
									style: "currency",
									currency: "INR",
								})}`}
								style={{ cursor: "pointer" }}
							>
								ⓘ
							</span>
							<Tooltip
								delayHide={0}
								events={["hover"]}
								anchorId={"convenfee-tooltip-" + data?.TOTAL_SERVICE_CHARGE}
							/>
						</div>
						<span>
							{data?.TOTAL_SERVICE_CHARGE?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}

				{data?.ADJ_AGAINST_TRAVEL_CREDIT ? (
					<div className="payment-element" style={{ color: "#11bf0e" }}>
						<div className="payment-title">Travel Credits</div>
						<span>
							-{" "}
							{data?.ADJ_AGAINST_TRAVEL_CREDIT?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
							}) || "-"}
						</span>
					</div>
				) : null}
				{currentUser?.isTravelAgent && (
					<div className="ta-commission">
						<div className="con-space-between">
							<div
								style={{
									display: "flex",
									alignItems: "center",
									gap: "6.75px",
								}}
								// className="text-sm-light"
								// style={{ color: theme.primaryTextColor }}
							>
								<TaStars />
								Your Commission
							</div>
							<div
							// className="text-sm-bold"
							// style={{ color: theme.primaryTextColor }}
							>
								₹{addCommas(bookingData?.data?.data?.TA_COMMISSION)}
							</div>
						</div>
						<div
							style={{
								paddingTop: "5px",
								textAlign: "left",
								fontSize: "12px",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<p>
								{bookingData?.data?.data?.TA_COMMISSION === 0 &&
									"Your commission is 0, please make a booking with a larger amount for the commission"}
							</p>
							{/* <p
								style={{
									fontWeight: "700",
								}}
							>
								{bookingData?.data?.data?.TA_COMMISSION === 0 &&
								bookingData?.data?.data?.COUPON_CODE?.includes("TAC") === false
									? "Add the TA coupon from the coupon section"
									: null}
							</p> */}
						</div>
					</div>
				)}

				<div className="payment-element-total">
					<div className="payment-title">Total Payable</div>
					<span>
						{parseInt(data?.TOTAL_AMOUNT_WITH_SERVICE) <
							parseInt(data?.TOTAL_AMOUNT) && (
							<span className="cross-text">
								{data?.TOTAL_AMOUNT?.toLocaleString("hi-IN", {
									style: "currency",
									currency: "INR",
									maximumFractionDigits: "0",
								})}
							</span>
						)}
						{data?.TOTAL_AMOUNT_WITH_SERVICE?.toLocaleString("hi-IN", {
							style: "currency",
							currency: "INR",
							maximumFractionDigits: "0",
						}) || "-"}
					</span>
				</div>
			</div>
			{/* {data?.REWARDPOINTS_APPLICABLE &&
				(!fetchData?.data?.data?.data?.COUPON_CODE ? (
					<div
						className="deadline-coupon-wrapper"
						onClick={() => {
							createDis.mutateAsync();
						}}
					>
						<UnLockIcon /> Click to Unlock Best Price!!{" "}
					</div>
				) : (
					<div className="deadline-coupon-apply">
						<div className="deadline-percentage">
							Get {fetchData?.data?.data?.data?.DISCOUNT}% OFF
							<GiftIcon />
						</div>
						<div className="deadline-details">
							<div className="top-detail">
								<div>
									Use your special coupon code{" "}
									<b>{fetchData?.data?.data?.data?.COUPON_CODE}</b>
									&nbsp;while booking
								</div>
								<span
									onClick={async () => {
										await updateBooking.mutateAsync({
											COUPON_CODE: fetchData?.data?.data?.data?.COUPON_CODE,
										});
										searchParams.set(
											"coupon",
											fetchData?.data?.data?.data?.COUPON_CODE,
										);
										setSearchParams(searchParams, { replace: true });
									}}
								>
									Apply
								</span>
							</div>
							<div className="bottom-detail">
								Expires in{" "}
								<span>
									{moment(
										new Date(
											fetchData?.data?.data?.data?.EXPIRY_DATE,
										)?.getTime() -
											time.getTime() -
											19800000,
									).format("HH:mm:ss")}
								</span>
							</div>
						</div>
					</div>
				))} */}
			<DeadlineCoupon data={data} from={"payment"} />
			{data?.REWARDPOINTS_APPLICABLE && data?.REWARD_POINTS > 0 ? (
				<div className="rewards-wrapper">
					<div
						className="box"
						onClick={() => {
							applyRewardHandler(data?.ADJ_AGAINST_REWARD ? false : true);
						}}
					>
						{data?.ADJ_AGAINST_REWARD ? <TickIcon /> : ""}
					</div>{" "}
					Use &nbsp;<b>{data?.REWARD_POINTS}</b>&nbsp; Reward Points &nbsp;
					<span
						id={"rewards-tooltip"}
						data-tooltip-html={
							"The value of a reward point is determined by SaffronStays,<br/> read all the terms and conditions"
						}
						style={{ cursor: "pointer" }}
					>
						ⓘ
					</span>
					<Tooltip
						delayHide={0}
						events={["hover"]}
						anchorId={"rewards-tooltip"}
					/>
				</div>
			) : null}
			{data?.SECURITY_DEPOSIT ? (
				<div className="security-wrapper mobile-security">
					Home requires a{" "}
					<b>
						{data?.SECURITY_DEPOSIT?.toLocaleString("hi-IN", {
							style: "currency",
							currency: "INR",
							maximumFractionDigits: "0",
						})}
					</b>{" "}
					refundable security deposit.
				</div>
			) : null}

			<div className="bottom-bar-payment">
				{data?.SECURITY_DEPOSIT ? (
					<div className="security-wrapper">
						Home requires a{" "}
						<b>
							{data?.SECURITY_DEPOSIT?.toLocaleString("hi-IN", {
								style: "currency",
								currency: "INR",
								maximumFractionDigits: "0",
							})}
						</b>{" "}
						refundable security deposit.
					</div>
				) : null}
				<div className="terms-condition-wrapper">
					<span>
						By selecting the button below, I agree to all the terms and
						conditions mentioned{" "}
						<a
							target="_blank"
							href="https://www.saffronstays.com/policy-center"
							rel="noreferrer"
						>
							here
						</a>
					</span>
				</div>
				<button
					disabled={
						!acceptTC ||
						updateBooking.isLoading ||
						bookingData.isLoading ||
						bookingData.isFetching
					}
					className={
						(fetchPaymentGateway.isLoading || fetchPaymentGateway.isFetching) &&
						"button__loader"
					}
					onClick={() => {
						clevertap.event.push("booknow_btn_booking_page", {
							bookingID: data?.bookingID,
							listingID: data?.LISTING_ID,
							amount: data?.TOTAL_AMOUNT_WITH_SERVICE,
							timestamp: new Date(),
						});
						fetchPaymentGateway.mutateAsync();
					}}
				>
					<LockIcon />{" "}
					{data?.TOTAL_AMOUNT_WITH_SERVICE === 0 ? "Book Now" : "Pay Securely"}
				</button>
			</div>
			<div className="desktop-only">
				<AskHelp data={data} />
			</div>
		</div>
	);
};
export default Payments;
