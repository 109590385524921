import { memo, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

// components
import CustomCheckbox from "components/Search/CustomCheckbox";

// context
import { SearchPageDataInterface } from "context/SearchPageData";

// style
import "./style.scss";

const NewCategoryMoreFilters = memo(({ moreFilters }) => {
	const { setMoreFilter, moreFilter } = useContext(SearchPageDataInterface);
	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<div className="filter__category__wrapper">
			{moreFilters.map((filter) => {
				if (filter.type === "val") {
					return (
						<div className="filter-item" key={Math.random() * 100}>
							<p
								style={{
									fontWeight: moreFilter?.includes(filter.apiValue)
										? "600"
										: "400",
								}}
							>
								{filter.label}
							</p>
							<input
								id="filter"
								type="checkbox"
								checked={
									searchParams.get(filter.param) === "" ||
									searchParams.get(filter.param) === "false" ||
									searchParams.get(filter.param) === null
										? false
										: true
								}
								value={
									searchParams.get(filter.param) === "false" ? false : true
								}
								onChange={() => {
									if (
										searchParams.get(filter.param) === "" ||
										searchParams.get(filter.param) === null ||
										searchParams.get(filter.param) === "false"
									) {
										searchParams.set(filter.param, "true");
										setSearchParams(searchParams, { replace: true });
									} else if (
										searchParams.get(filter.param) !== null &&
										searchParams.get(filter.param) === "true"
									) {
										searchParams.set(filter.param, "false");
										setSearchParams(searchParams, { replace: true });
									}
									clevertap.event.push("search_filter", {
										type_of_filter: `${filter.label}`,
										timestamp: new Date(),
										url: window.location.pathname + window.location.search,
										search_location: window.location.pathname
											?.split("/")[2]
											?.split("-")[2],
									});
									window.scrollTo(0, 0);
								}}
							/>
						</div>
					);
				} else {
					return (
						<div className="filter-item" key={Math.random() * 100}>
							<p
								style={{
									fontWeight: moreFilter?.includes(filter.apiValue)
										? "600"
										: "400",
								}}
							>
								{filter.label}
							</p>
							<CustomCheckbox
								filter={filter.apiValue}
								filterName={filter.label}
								setChanged={setMoreFilter}
								type="more"
							/>
						</div>
					);
				}
			})}
		</div>
	);
});

export default NewCategoryMoreFilters;
