export const otherDetailsAmenitesList = [
	"Cable Television",
	"Breakfast Included In Tariff",
	"WiFi Fibre Or WiFi Cable",
	"AC in living room",
	"AC in Bedrooms",
	"100 Percent Pet Friendly",
	"Ensuite Bathroom",
	"Child friendly",
	"Elderly Friendly",
	"Guests Allowed",
	"Pet Friendly",
	"Unmarried Couples Allowed",
	"LGBTQ Friendly",
	"Only Jain and Vegetarian Meals",
	"Alcohol Allowed",
	"Smoking Allowed",
	"Meals Provided At Additional Charges",
	"Elevator in Building",
	"Equipped kitchen",
	"Washing machine",
	"Hair Dryer",
	"Genset",
	"Parking Available",
	"Corporate Guests Only",
	"Family ONLY",
	"Women ONLY",
	"Accommodation For Driver",
	"Swimming Pool",
	"Towels and Toiletries",
	"Toiletries",
	"Meals Included In Tariff",
	"Shoots",
	"Self Catered",
	"Inverter",
	"Meals Not Provided",
	"Proposal or Anniversary or Babymoon",
	"Pre Wedding Shoots",
	"Small Functions and Weddings",
	"Big Weddings",
	"Anniversary Celebrations",
	"Milestone Birthdays",
	"Team Offsites",
	"Strategic Meetings and Gatherings",
	"Safe Deposit Box",
	"Fire Extinguisher",
	"Heater in Bedrooms",
	"WiFi Dongle",
	"Massage At Additional Charges",
	"TV in Living Room",
	"TV in Bedrooms",
	"Heater in Living Room",
	"TV in Games Room",
	"Games Room",
	"CCTV",
	"Vegetarian and Non Vegetarian Meals",
	"Jain and Vegetarian and Non Vegetarian Meals",
	"Fireplace",
	"Jacuzzi",
	"Heated Jacuzzi",
	"Ensuite Bathroom in Some Rooms",
	"Caretaker Available on Demand",
	"Caretaker Always On Site",
	"Library or Books",
	"Accommodation for Maid",
	"Smart TV",
	"WiFi Available Across the Estate",
	"WiFi Available in Limited Spaces",
	"FnB Outlets Available Nearby",
	"Lawns",
];
