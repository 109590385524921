import clevertap from "clevertap-web-sdk";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import FacebookLoginWrapper from "../FacebookLoginWrapper";
import GoogleLoginWrapper from "../GoogleLoginWrapper";
import { Input } from "../Input";
import { PrimaryButton } from "../PrimaryButton";
import { RegisterHeader } from "../RegisterHeader";
import { RememberMe } from "../RememberMe";
import { TermsAndConditions } from "../TermsAndConditions";

// import { CreatingAccount } from "../CreatingAccount/CreatingAccount";
// import { ForgetPassword } from "../ForgetPassword/ForgetPassword";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// helpers
import { axiosInstance } from "helpers/axiosInstance";
import getUserData from "helpers/getUserDataAfterLogin";
import validateEmail from "helpers/validateEmail";

// context
import { UserContext } from "store";

// assets
import { SvgFacebook as Facebook } from "assets/icons/js/SvgFacebook";
import { SvgGoogle as Google } from "assets/icons/js/SvgGoogle";
import { SvgHide as Hide } from "assets/icons/js/SvgHide";
import { SvgPhone as Phone } from "assets/icons/js/SvgPhone";
import { ReactComponent as Loader } from "assets/spinning_wheel.svg";

// scss
import "./style.scss";

export const Login = ({ setPhone, setForgetPassword, redirect }) => {
	const [hidePassword, setHidePassword] = useState(true);
	const [values, setValues] = useState({
		email: "",
		password: "",
	});
	const [remember, setRemember] = useState(false);
	const [inputErrorEmail, setInputErrorEmail] = useState(false);
	const [inputErrorPassword, setInputErrorPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const { email, password } = values;

	const width = useMediaQuery();

	const navigate = useNavigate();

	const { dispatch } = useContext(UserContext);

	const isMobile = width <= 500 ? true : false;

	const handleClick = () => {
		setPhone(true);
	};

	const handleChange = (event) => {
		event.preventDefault();
		setErrorMessage("");
		setInputErrorEmail(false);
		setInputErrorPassword(false);
		setValues({
			...values,
			[event.target.name]: event.target.value,
		});
	};

	const handleCheck = (event) => {
		setRemember(event.target.checked);
	};

	const validatelogin = () => {
		let flag = true;
		if (!validateEmail(email)) {
			setInputErrorEmail(true);
			flag = false;
		}
		if (password.length < 8) {
			setInputErrorPassword(true);
			flag = false;
		}
		return flag;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setErrorMessage("");
		if (!validatelogin()) return;

		const requestOptions = {
			url: "/users/login",
			method: "POST",
			data: {
				usermail: email,
				password: password,
			},
		};
		setLoading(true);
		axiosInstance(requestOptions)
			.then((res) => {
				if (res.data.success) {
					clevertap.onUserLogin.push({
						Site: {
							Name: res?.data?.data?.profile.LAST_NAME
								? `${res?.data?.data?.profile.FIRST_NAME} ${res?.data?.data?.profile.LAST_NAME}`
								: `${res?.data?.data?.profile.FIRST_NAME}`,
							Identity: res?.data?.data?.profile.UUID,
							Email: res?.data?.data?.profile.EMAIL_ID
								? res?.data?.data?.profile.EMAIL_ID
								: "",
							Phone: res?.data?.data?.profile.MOBILE_NUMBER
								? `+91${res?.data?.data?.profile.MOBILE_NUMBER}`
								: "",
							RewardTier: res.data.data.profile.REWARDS_TIER
								? res.data.data.profile.REWARDS_TIER
								: "BLUE",
							DOB: res?.data?.data?.profile.DATE_OF_BIRTH
								? new Date(res?.data?.data?.profile.DATE_OF_BIRTH)
								: "",
							isAgent:
								res?.data?.data?.profile.IS_AGENT &&
								res?.data?.data?.profile.IS_AGENT === "Y"
									? true
									: false,

							"MSG-email": true,
							"MSG-push": true,
							"MSG-sms": true,
							"MSG-whatsapp": true,
						},
					});
					localStorage.setItem("token", res.data.data.token);
					localStorage.setItem("uuid", res.data.data.profile.UUID);
					localStorage.setItem("allow", "true");
					getUserData(dispatch);
					setErrorMessage(null);
					if (redirect !== "") {
						window.location.href = redirect;
					}
					if (isMobile) {
						navigate(-1);
					} else {
						window.location.reload();
					}
					setLoading(false);
				}
			})
			.catch((error) => {
				if (error.response) {
					setErrorMessage(error.response.data?.error);
				} else if (error.request) {
					setErrorMessage(error.request);
				} else {
					setErrorMessage("Something went wrong");
				}
				setLoading(false);
			});
	};

	return (
		<div className="login__wrapper--page">
			<div className="login__wrapper--page-2">
				<RegisterHeader subtitle={"log you in"} title={"Back"} />
				<div className="login__space"></div>
				<div className="login__form">
					<form onSubmit={(e) => handleSubmit(e)}>
						<Input
							id="email"
							type={"text"}
							name={"email"}
							placeholder={"Email"}
							value={email}
							onChange={handleChange}
							error={inputErrorEmail}
						/>
						<div className={!inputErrorEmail && "login__space"}></div>
						{inputErrorEmail && (
							<p style={{ color: "red", fontSize: "12px", margin: "6px 0" }}>
								Please enter a valid email
							</p>
						)}
						<div className="login__input-hide">
							<Input
								id="password"
								type={hidePassword ? "password" : "text"}
								name={"password"}
								value={password}
								placeholder={"Password"}
								onChange={handleChange}
								error={inputErrorPassword}
							/>
							<span
								className="svg-hide"
								onClick={() => setHidePassword(!hidePassword)}
							>
								<Hide />
							</span>
						</div>
						<div className={!inputErrorPassword && "login__space"}></div>
						{(inputErrorPassword || errorMessage) && (
							<p style={{ color: "red", fontSize: "12px", margin: "6px 0" }}>
								{errorMessage
									? errorMessage
									: "Passwords must be atleast 8 characters long"}
							</p>
						)}
						<RememberMe
							setForgetPassword={setForgetPassword}
							handleCheck={handleCheck}
							remember={remember}
						/>
						<div className="login__space"></div>
						<PrimaryButton
							type="submit"
							value={
								loading ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Loader
											style={{
												width: "30px",
												height: "30px",
											}}
										/>
									</div>
								) : (
									"Log in"
								)
							}
						/>
					</form>
					<div className="login__line">
						<hr />
						<span>OR</span>
						<hr />
					</div>
					<div className="login__different-login">
						<GoogleLoginWrapper rememberMe={remember}>
							<span className="google icon">
								<Google />
								<p>Google</p>
							</span>
						</GoogleLoginWrapper>
						<FacebookLoginWrapper>
							<span className="facebook icon">
								<Facebook />
								<p>Facebook</p>
							</span>
						</FacebookLoginWrapper>
						<span className="phone icon" onClick={handleClick}>
							<Phone />
							<p>Phone</p>
						</span>
					</div>
					<div className="login__space"></div>
					<TermsAndConditions />
				</div>
			</div>
		</div>
	);
};
