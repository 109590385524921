import React from "react";

export const SvgBTVacation = () => {
	return (
		<svg
			width="19"
			height="16"
			viewBox="0 0 19 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.6823 7.11217C18.6823 7.23026 18.6353 7.3436 18.5519 7.42703C18.4683 7.5106 18.3551 7.55741 18.237 7.55741H12.9056V15.1095H15.5714C15.8182 15.1125 16.0166 15.3135 16.0166 15.5603C16.0166 15.6778 15.9695 15.7905 15.886 15.8731C15.8022 15.9557 15.6889 16.0014 15.5714 16H0.445108C0.327576 16.0014 0.214379 15.9557 0.130666 15.8731C0.047096 15.7905 0 15.6778 0 15.5603C0 15.3135 0.198448 15.1125 0.445094 15.1095H3.04965V13.0726L0.150225 10.1842C0.0655351 10.1015 0.0178808 9.98812 0.0178808 9.86975C0.0178808 9.75138 0.0655351 9.63804 0.150225 9.55531C0.323937 9.38201 0.605265 9.38201 0.779103 9.55531L3.67853 12.4549H12.0262V7.55744H6.68375C6.43792 7.55744 6.23851 7.35816 6.23851 7.11221C6.24144 5.54069 6.83848 4.02832 7.9098 2.87843C8.98127 1.72854 10.4478 1.02642 12.0151 0.912549V0.445094C12.0151 0.199271 12.2144 0 12.4604 0C12.7062 0 12.9055 0.199285 12.9055 0.445094V0.912549C14.4731 1.02645 15.9395 1.72857 17.0108 2.87843C18.0821 4.02832 18.6792 5.54069 18.6821 7.11221L18.6823 7.11217Z"
				fill="#2D66A1"
			/>
		</svg>
	);
};
