import React from "react";
import { Link } from "react-router-dom";

// scss
import "./style.scss";

export const TermsAndConditions = () => {
	return (
		<div className="termsAndConditions__terms">
			<p>
				By signing in or creating an account, you agree with our{" "}
				<Link aria-label="terms-conditions" to="/terms-service">
					{" "}
					Terms & conditions{" "}
				</Link>{" "}
				and{" "}
				<Link aria-label="privacy-polciy" to="/privacy-policy">
					{" "}
					Privacy statement
				</Link>{" "}
			</p>
		</div>
	);
};
