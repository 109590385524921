import React from "react";

export const SvgScissor = (props: any) => {
	return (
		<svg
			width="11"
			height="10"
			viewBox="0 0 11 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M4.28937 5L0.0313444 0.731283C-0.0355005 0.664438 0.0112906 0.548128 0.106211 0.548128H1.3161C1.34418 0.548128 1.37225 0.558824 1.39097 0.578877L5.04471 4.24198L6.2506 3.03209C6.08348 2.73663 5.98723 2.39572 5.98723 2.03209C5.98723 0.910428 6.89765 0 8.01931 0C9.14097 0 10.0514 0.910428 10.0514 2.03209C10.0514 3.15374 9.14097 4.06417 8.01931 4.06417C7.64899 4.06417 7.30273 3.96524 7.00461 3.79278L5.8014 5L7.00594 6.20722C7.31432 6.02883 7.6644 5.9352 8.02065 5.93583C9.14231 5.93583 10.0527 6.84626 10.0527 7.96791C10.0527 9.08957 9.14231 10 8.02065 10C6.89899 10 5.98856 9.08957 5.98856 7.96791C5.98856 7.60428 6.08482 7.26337 6.25193 6.96791L5.04605 5.75802L1.39231 9.42112C1.37225 9.44118 1.34552 9.45187 1.31744 9.45187H0.106211C0.0112906 9.45187 -0.0368376 9.3369 0.0313444 9.26872L4.28937 5ZM8.02065 3.1016C8.61022 3.1016 9.09017 2.62166 9.09017 2.03209C9.09017 1.44251 8.61022 0.962567 8.02065 0.962567C7.43108 0.962567 6.95113 1.44251 6.95113 2.03209C6.95113 2.62166 7.43108 3.1016 8.02065 3.1016ZM8.02065 9.03743C8.61022 9.03743 9.09017 8.55749 9.09017 7.96791C9.09017 7.37834 8.61022 6.8984 8.02065 6.8984C7.43108 6.8984 6.95113 7.37834 6.95113 7.96791C6.95113 8.55749 7.43108 9.03743 8.02065 9.03743Z"
				fill={props.color ? props.color : "#2368a2"}
			/>
		</svg>
	);
};
