import { RegisterFormDataInterface } from "context/RegisterForm";
import { useContext, useEffect, useState } from "react";

// style
import "./style.scss";

export const BottomBar = ({ currentStep, setStep }: any) => {
	// @ts-ignore
	const { registerFormDataMutation } = useContext(RegisterFormDataInterface);

	const [tabNumber, setTabNumber] = useState(0);

	return (
		<div className="review__bottom__bar__container">
			<div className="review__bottom__container">
				{currentStep === 0 ? (
					<>
						<div className="buttons__1">
							<span></span>
							<span></span>
							<button
								className="btn__continue"
								onClick={() => {
									window.scrollTo({
										top: 0,
										behavior: "smooth",
									});
									setStep((prev: number) => (prev += 1));
								}}
							>
								Save & Continue
							</button>
						</div>
					</>
				) : (
					<>
						<div className="buttons">
							<button
								className="btn__prev"
								onClick={() => {
									window.scrollTo({
										top: 0,
										behavior: "smooth",
									});
									if (currentStep === 1) {
										if (tabNumber === 0) {
											setStep((prev: number) => (prev -= 1));
										} else {
											setTabNumber((prev) => (prev -= 1));
										}
									} else {
										setStep((prev: number) => (prev -= 1));
									}
								}}
							>
								Back
							</button>
							<div className="right">
								<button
									className="btn__continue"
									onClick={() => {
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
										setStep((prev: number) => (prev += 1));
									}}
								>
									Save & Continue
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
