import React from "react";

export const SvgArrowDown = (props) => {
	return (
		<svg
			width="12"
			height="8"
			viewBox="0 0 12 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M5.50854 7.14042C5.31109 7.14042 5.11366 7.06503 4.96313 6.91457L0.226007 2.17741C-0.0753357 1.87606 -0.0753357 1.38749 0.226007 1.08627C0.527228 0.785046 1.01571 0.785046 1.31707 1.08627L5.50854 5.27797L9.70002 1.08641C10.0014 0.785193 10.4898 0.785193 10.791 1.08641C11.0925 1.38763 11.0925 1.87621 10.791 2.17755L6.05395 6.91472C5.90334 7.06521 5.70591 7.14042 5.50854 7.14042Z"
				fill="#333333"
			/>
		</svg>
	);
};
