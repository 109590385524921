import { useEffect, useState } from "react";
import Collapse from "../Collapse";
import { useQuery } from "react-query";
import { axiosInstance } from "helpers/axiosInstance";
import "./style.scss";

const CancellationTimeline = ({ data }: any) => {
	const getCancellationData = useQuery(["cancellation", data?.BOOKING_ID], () =>
		axiosInstance({
			method: "GET",
			url: `https://ecapi.saffronstays.com/booking/getCancellationRange?BOOKING_ID=${data?.BOOKING_ID}`,
			headers: {
				"Content-Type": "application/json",
			},
		}),
	);

	const [dateArr, setDateArr] = useState<any>([]);

	useEffect(() => {
		if (getCancellationData?.data?.data?.data?.data) {
			if (getCancellationData?.data?.data?.data?.data?.length === 1)
				setDateArr([getCancellationData?.data?.data?.data?.startDate]);
			else {
				setDateArr(getCancellationData?.data?.data?.data?.data);
			}
		}
	}, [getCancellationData?.data?.data?.data?.data]);

	return (
		<Collapse init={true} title="Cancellation Policy">
			{getCancellationData.isLoading ? (
				"Loading..."
			) : (
				<div className="cancellation-container">
					{dateArr?.map((e: any, indx: number) => {
						// if (indx !== dateArr.length - 1)
						return (
							<div className="cancellation-card">
								<DateCard date={e?.date} percentage={e?.percentage} />{" "}
								<div className="cancellation-text">
									{typeof e?.percentage === "undefined" ? (
										<b>No refund on cancellation</b>
									) : (
										<>
											{" "}
											Get{" "}
											{e?.percentage === 0 ||
											typeof e?.percentage === "undefined"
												? "No"
												: e?.percentage === 100
												? "full"
												: `${e?.percentage}%`}{" "}
											refund when you cancel{" "}
											{e?.percentage === 100
												? "up until"
												: e?.percentage === 0 ||
												  typeof e?.percentage === "undefined"
												? "after"
												: `between`}{" "}
											<b>
												{e?.percentage !== 100 && dateArr[indx - 1]?.date}
												{e?.percentage !== 100 && e?.percentage !== 0 && " - "}
												{e?.percentage !== 0 && e?.date} {indx === 0 && "*"}
												{/* {e?.time && `(${e?.time})`} */}
											</b>
										</>
									)}
								</div>
							</div>
						);
						// else return null;
					})}
					<a
						href="https://www.saffronstays.com/cancellation-policy"
						target="_blank"
						className="policy-link-btn"
						rel="noreferrer"
					>
						Read full policy →
					</a>
				</div>
			)}
		</Collapse>
	);
};

export default CancellationTimeline;

const DateCard = ({
	date,
	percentage,
}: {
	date: string;
	percentage: number;
}) => {
	const style = () => {
		if (percentage === 100) {
			return "full-refund";
		} else if (percentage < 100 && percentage > 0) {
			return "part-refund";
		} else {
			return "no-refund";
		}
	};

	return (
		<div className={`date-card-wrapper ${style()}`}>
			<span>{date?.split(" ")[0]}</span>
			<p>{date?.split(" ")[1]}</p>
		</div>
	);
};

const formatDate = () => {
	let currentDate = new Date();
	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	let day = currentDate?.getDate();
	let month = months[currentDate?.getMonth()];

	let dayWithSuffix =
		day +
		(day % 10 === 1 && day !== 11
			? "st"
			: day % 10 === 2 && day !== 12
			? "nd"
			: day % 10 === 3 && day !== 13
			? "rd"
			: "th");

	return `${dayWithSuffix} ${month}`;
};
