export const sameDayCheckin = (sameDayBooking: number): boolean => {
	const time = new Date();

	if (time.getHours() < 18 && sameDayBooking === 1) {
		return true;
	}
	return false;
};

// moment(dates.checkin).isBefore(dates.checkout)
