import { createContext, useReducer } from "react";

export const UserContext = createContext();

export const initialState = {
	global: {
		// User tries to protect protected route, is redirected to /login
		// After login, they need to get back to the pages they were trying to access
		// This will store the path to that pages
		redirectUrlAfterLogin: "",
	},
	searchQuery: {
		checkin: "",
		checkout: "",
		location: "",
		guests: 0,
		rooms: 0,
	},
	calender: {
		inv: 1,
	},
	currentUser: {
		uuid: "",
		firstName: "",
		lastName: "",
		fullName: "",
		gender: "",
		mobile: "",
		cc: "",
		email: "",
		dateOfBirth: "",
		anniversaryDate: "",
		spouseBirthday: "",
		address: "",
		city: "",
		state: "",
		country: "",
		pincode: "",
		isHomeowner: false,
		isVIP: false,
		isTravelAgent: false,
		profilePic: "",
		referralCode: "",
		rewardPoint: 0,
		rewardPointExpire: "",
		travelCredit: 0,
		travelCreditExpire: "",
		rewardTier: "",
		mobileVerified: false,
		// TA USER
		couponCode: "",
		couponId: "",
		amount: "",
		discountPercentage: "",
		gstName: "",
		gstNumber: "",
	},
	datesToReserve: {
		checkin: "",
		checkout: "",
	},
	guestsAndRooms: {
		rooms: 1,
		guests: 1,
	},
	isFromBookingBtn: false,
	highlight: false,
	// bookingDetails: [],
};

export const Store = (props) => {
	function reducer(state, action) {
		switch (action.type) {
			case "SET_INV":
				return {
					...state,
					calender: {
						inv: action.payload,
					},
				};
			case "SET_GLOBAL":
				return {
					...state,
					global: {
						...state.global,
						redirectUrlAfterLogin: action.payload,
					},
				};
			case "SET_CURRENT_USER":
				return {
					...state,
					currentUser: {
						...state.currentUser,
						...action.payload,
					},
				};
			case "SET_DATES":
				return {
					...state,
					datesToReserve: {
						...state.datesToReserve,
						...action.payload,
					},
				};
			case "SET_GUESTS_AND_ROOMS":
				return {
					...state,
					guestsAndRooms: {
						...state.guestsAndRooms,
						...action.payload,
					},
				};
			case "SET_SEARCH_QUERY":
				return {
					...state,
					searchQuery: {
						...state.searchQuery,
						...action.payload,
					},
				};
			case "FROM_BOOK_BUTTON":
				return {
					...state,
					isFromBookingBtn: action.payload,
				};
			case "SHOW_HIGHLIGHT":
				return { ...state, highlight: action.payload };
			default:
				return null;
		}
	}
	const [state, dispatch] = useReducer(reducer, initialState);

	//console.log the state after every update
	// useEffect(() => {
	// 	;
	// }, [state]);

	return (
		<UserContext.Provider value={{ state, dispatch }}>
			{props.children}
		</UserContext.Provider>
	);
};
