import React from "react";

export const SvgConcernListHome = () => {
	return (
		<svg
			width="49"
			height="40"
			viewBox="0 0 49 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.1932 0.448224C24.9102 0.161495 24.5244 0 24.1215 0C23.7185 0 23.3327 0.161495 23.0497 0.448224L0.444222 23.0595C0.0610452 23.4423 -0.0884874 24.0004 0.0516952 24.5234C0.191878 25.0464 0.600482 25.455 1.12345 25.5951C1.64682 25.7353 2.20496 25.5858 2.58777 25.203L6.87526 20.9155V38.4938C6.87526 38.8934 7.03376 39.2763 7.31638 39.5589C7.59862 39.8411 7.9818 40 8.38105 40H39.8724C40.2716 40 40.6548 39.8411 40.937 39.5589C41.2193 39.2763 41.3782 38.8935 41.3782 38.4938V20.905L45.6657 25.1925C46.0485 25.5756 46.6066 25.7252 47.1296 25.585C47.653 25.4448 48.0616 25.0362 48.2017 24.5132C48.3419 23.9899 48.192 23.4317 47.8092 23.0489L25.1932 0.448224ZM38.3607 36.9877H9.88109V17.8868L24.1204 3.6475L38.3597 17.8868L38.3607 36.9877Z"
				fill="#666666"
			/>
			<path
				d="M22.6161 19.5331V23.2206H18.9234C18.3854 23.2206 17.8883 23.5073 17.6191 23.9735C17.3503 24.4393 17.3503 25.0135 17.6191 25.4792C17.8882 25.9454 18.3854 26.2321 18.9234 26.2321H22.6161V29.9248C22.6161 30.4628 22.9028 30.9599 23.369 31.2292C23.8348 31.4979 24.409 31.4979 24.8747 31.2292C25.3409 30.96 25.6276 30.4628 25.6276 29.9248V26.2378H29.3204C29.8583 26.2378 30.3554 25.9507 30.6247 25.4849C30.8934 25.0187 30.8934 24.4449 30.6247 23.9787C30.3555 23.5129 29.8583 23.2258 29.3204 23.2258H25.6276V19.5331C25.6276 18.9952 25.3409 18.498 24.8747 18.2292C24.4089 17.9601 23.8348 17.9601 23.369 18.2292C22.9028 18.498 22.6161 18.9952 22.6161 19.5331Z"
				fill="#666666"
			/>
		</svg>
	);
};
