import BookingWrapper from "components/Booking";
import { BookingData } from "context/BookingData";
import MetaTags from "react-meta-tags";

const BookingPage = () => {
	return (
		<BookingData>
			<MetaTags>
				<title>Booking - Saffronstays</title>
			</MetaTags>
			<BookingWrapper />
		</BookingData>
	);
};

export default BookingPage;
