// scss
import { useContext } from "react";
import { UserContext } from "store";
import "./style.scss";

export const RegisterHeader = ({ subtitle, title }) => {
	const { state } = useContext(UserContext);

	return (
		<div className="registerHeader__form--header">
			<div className="registerHeader__header--title">
				<p>{!state.isFromBookingBtn ? `Welcome ${title}!` : "Get Started!"}</p>
			</div>
			{!state.isFromBookingBtn && (
				<>
					<div className="registerHeader__space-h"></div>
					<div className="registerHeader__header--subtitle">
						<p>Let's {subtitle} </p>
					</div>
				</>
			)}
		</div>
	);
};
