export const getBlockedDates = async (id: any, rooms: any, data: any) => {
	try {
		const res = data;
		let blockedDates: string[] = [];
		Object.keys(res).forEach((ele) => {
			if (res[ele].available === "" || res[ele].available === "0") {
				blockedDates.push(ele);
			} else if (
				res[ele].status === "unavailable" ||
				res[ele].status === "booked" ||
				res[ele].status === "blocked"
			) {
				blockedDates.push(ele);
			} else if (parseInt(res[ele].available) < rooms) {
				blockedDates.push(ele);
			}
		});
		return blockedDates;
	} catch (err) {
		return [];
	}
};
