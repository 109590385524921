import { useEffect, useRef } from "react";

// icons
import { ReactComponent as PlusIcon } from "assets/icons/svg/add.svg";
import { ReactComponent as MinusIcon } from "assets/icons/svg/minus.svg";

// style
import "./style.scss";

export const HomeGuestPopUp = ({
	open,
	setOpen,
	data,
	setData,
	maxGuests = Number.MAX_SAFE_INTEGER,
	minGuests = 1,
	maxRooms = Number.MAX_SAFE_INTEGER,
	minRooms = 1,
}) => {
	const popRef = useRef();

	useEffect(() => {
		function handleClickOutside(event) {
			if (popRef.current && !popRef.current.contains(event.target)) {
				setOpen(false);
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [popRef]);

	if (open)
		return (
			<div className="homeguest__popup-wrapper" ref={popRef}>
				<div className="guest-elements">
					<h3>Rooms</h3>

					<div className="guest-counter">
						<button
							aria-label="minus"
							className={
								(data.rooms === 1 || data.rooms === minRooms) && "disable"
							}
							disabled={data.rooms === 1 || data.rooms === minRooms}
							onClick={() => {
								if (data.rooms > 1)
									setData({
										...data,
										rooms: data.rooms - 1,
									});
							}}
						>
							<MinusIcon />
						</button>
						<span className="counter">{data.rooms}</span>

						<button
							aria-label="plus"
							className={data.rooms === maxRooms && "disable"}
							disabled={data.rooms === maxRooms}
							onClick={() => {
								setData({
									...data,
									rooms: data.rooms + 1,
								});
							}}
						>
							<PlusIcon />
						</button>
					</div>
				</div>

				<div className="guest-elements">
					<h3>Guests</h3>

					<div className="guest-counter">
						<button
							aria-label="minus"
							className={
								(data.guests === 1 || data.guests === minGuests) && "disable"
							}
							disabled={data.guests === 1 || data.guests === minGuests}
							onClick={() => {
								if (data.guests > 1) {
									setData({
										...data,
										guests: data.guests - 1,
									});
								}
							}}
						>
							<MinusIcon />
						</button>
						<span className="counter">{data.guests}</span>
						<button
							aria-label="plus"
							className={data.guests === maxGuests && "disable"}
							disabled={data.guests === maxGuests}
							onClick={() => {
								setData({
									...data,
									guests: data.guests + 1,
								});
							}}
						>
							<PlusIcon />
						</button>
					</div>
				</div>
			</div>
		);
	return null;
};
