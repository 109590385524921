export const SvgPhoneDownArrow = () => {
	return (
		<svg
			width="9"
			height="5"
			viewBox="0 0 9 5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4.58673 4.5L6.6301 2.5L8.67346 0.5H0.5L2.54337 2.5L4.58673 4.5Z"
				fill="#333333"
			/>
		</svg>
	);
};
