import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Cross } from "assets/cross.svg";
import "./style.scss";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import { ReactComponent as Info } from "../../../UniversalUrlNew/UniUrlAssets/info.svg";
import { BookingInterface } from "context/BookingData";
import { ToastContainer, toast } from "react-toastify";
const BookingExperienceCalc = ({
	bookingId,
	data,
	onClose,
	refreshData,
	onDetailsClose,
}: {
	bookingId: string;
	data: any;
	onClose: () => void;
	refreshData: () => void;
	onDetailsClose?: any;
}) => {
	const [quantity, setQuantity] = useState(data?.MIN_QUANTITY);
	const [night, setNight] = useState(data?.MIN_NIGHTS);
	const [reCalData, setReCalData] = useState<any>({});
	const [isClickedValue, setIsClickedValue] = useState("1");
	const [isLoading, setIsLoading] = useState(false);
	const { bookingData }: any = useContext(BookingInterface);
	const noOfNights = () => {
		const fromDate: any = new Date(bookingData?.data?.data?.FROM_DATE);
		const toDate: any = new Date(bookingData?.data?.data?.TO_DATE);
		const differenceMs = Math.abs(toDate - fromDate);
		const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
		return differenceDays;
	};
	const increaseQuantity = () => {
		setQuantity((prev: any) => prev + 1);
	};
	const decreaseQuantity = () => {
		setQuantity((prevQuantity: any) =>
			prevQuantity && prevQuantity > data?.MIN_QUANTITY
				? prevQuantity - 1
				: data?.MIN_QUANTITY,
		);
	};
	const fetchRecall = async () => {
		const payload = {
			BOOKING_ID: bookingId,
			JSON_METADATA: [
				{
					MAP_ID: data?.MAP_ID,
					NO_OF_GUEST: quantity,
					NO_OF_NIGHTS: night,
				},
			],
		};
		try {
			const res = await axios.post(
				"https://ecapi2.saffronstays.com/recal/experience/charge",
				payload,
				{
					headers: {
						Authorization: `Bearer ${localStorage?.getItem("token")}`,
					},
				},
			);
			setReCalData(res?.data?.data);
		} catch (error: any) {
			console.log(error?.message);
		}
	};

	const requestAddon = async () => {
		setIsLoading(true);
		try {
			const res = await axios.get(
				`https://go.saffronstays.com/api/addon-request?bookingId=${bookingId}&mapId=${
					data?.MAP_ID
				}&quantity=${quantity}&type=${
					data?.TYPE
				}&nights=${night}&isBookingPage=${true}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + localStorage.getItem("token"),
					},
				},
			);
			if (res?.data.success) {
				setIsLoading(false);
				toast.success(`${data?.ADD_ON_NAME} has been requested`, {
					position: window.innerWidth < 800 ? "top-right" : "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					icon: true,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
				});
				refreshData();
				onDetailsClose();
				onClose();
			}
		} catch (error: any) {
			console.log(error?.message);
		}
	};

	useEffect(() => {
		fetchRecall();
	}, [quantity, night]);

	return (
		<>
			<ToastContainer />
			<div className="booking-experience-cal-wrapper">
				<div className="booking-experience-cal-content">
					<div className="booking-experience-cal-title">
						<p>{data?.ADD_ON_NAME}</p>
					</div>
					<div className="booking-experience-cal-price">
						<p>₹ {reCalData?.TOTAL_GST_AMOUNT}</p>
						<p>Including GST</p>
					</div>
					{data?.TYPE !== "PN" && (
						<div className="booking-experience-cal-qty">
							<p>Quantity</p>
							{data?.MIN_QUANTITY > 1 && (
								<>
									<Info
										data-tooltip-html={`Experience needs to be availed for minimum ${data?.MIN_QUANTITY}`}
										id="experience-qty-toolkit"
									/>
									<Tooltip anchorId="experience-qty-toolkit"></Tooltip>
								</>
							)}
							<div className="booking-experience-cal-value">
								<button onClick={decreaseQuantity}>-</button>
								<p>{quantity}</p>
								<button onClick={increaseQuantity}>+</button>
							</div>
						</div>
					)}
					{data?.TYPE !== "PP" && (
						<div className="booking-experience-cal-duration">
							<p>Duration</p>
							<div className="booking-experience-cal-days">
								<button
									value={"1"}
									onClick={(e) => {
										const buttonValue = (e.target as HTMLInputElement).value;
										console.log(buttonValue);
										setIsClickedValue(buttonValue);
										setNight("1");
									}}
									className={isClickedValue === "1" ? "clicked" : ""}
								>
									1 Night
								</button>
								{noOfNights() !== 1 && (
									<button
										value={"2"}
										onClick={(e) => {
											const buttonValue = (e.target as HTMLInputElement).value;
											setIsClickedValue(buttonValue);
											setNight("2");
										}}
										className={isClickedValue === "2" ? "clicked" : ""}
									>
										2 Nights
									</button>
								)}
								{noOfNights() !== 1 && (
									<button
										value={"Whole trip"}
										onClick={(e) => {
											const buttonValue = (e.target as HTMLInputElement).value;
											setIsClickedValue(buttonValue);
											setNight(noOfNights());
										}}
										className={isClickedValue === "Whole trip" ? "clicked" : ""}
									>
										Whole trip
									</button>
								)}
							</div>
						</div>
					)}
					<div className="booking-experience-cal-btn">
						<button onClick={onClose}>Cancel</button>
						<button
							disabled={isLoading}
							onClick={() => {
								requestAddon();
							}}
						>
							Request
						</button>
					</div>
				</div>
				<div onClick={onClose} className="booking-experience-cal-cross">
					<Cross />
				</div>
			</div>
		</>
	);
};

export default BookingExperienceCalc;
