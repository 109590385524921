import { useState } from "react";

// components
import EmailLoginHandler from "../LoginAndRegister/EmailLoginHandler";
import Modal from "../LoginAndRegister/Modal";
import PhoneLoginHandler from "../LoginAndRegister/PhoneLoginHandler";
import { Navbar } from "../Navbar";
import { SideBar } from "../Sidebar";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// scss
import "./style.scss";
import { HomeNavbar } from "components/NewHomePage/HomeNavbar";

export const NavbarAndSidebar = ({
	link = "",
	isBackgroundTransparent = false,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [shouldShowModal, setShouldShowModal] = useState(false);
	const [phone, setPhone] = useState(true);

	const width = useMediaQuery();

	const isTabletOrMobile = width <= 1250 ? true : false;

	const closeModal = () => {
		if (localStorage.getItem("token")) {
			window.location.reload();
		}
		setShouldShowModal(false);
	};

	return (
		<>
			<Modal shouldShow={shouldShowModal} onRequestClose={closeModal}>
				{phone ? (
					<PhoneLoginHandler
						onRequestClose={closeModal}
						setPhone={setPhone}
						phone={phone}
					/>
				) : (
					<EmailLoginHandler
						onRequestClose={closeModal}
						setPhone={setPhone}
						phone={phone}
					/>
				)}
			</Modal>
			{isMenuOpen && isTabletOrMobile ? (
				<SideBar
					setShouldShowModal={setShouldShowModal}
					isMenuOpen={isMenuOpen}
					setIsMenuOpen={setIsMenuOpen}
				/>
			) : (
				<HomeNavbar
					setShouldShowModal={setShouldShowModal}
					setIsMenuOpen={setIsMenuOpen}
					isMenuOpen={isMenuOpen}
					link={link}
					isBackgroundTransparent={isBackgroundTransparent}
					isTabletOrMobile={isTabletOrMobile}
				/>
			)}
		</>
	);
};
