export const SvgLogout = (props: any) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.54337 8.21297C6.93389 7.82244 6.93389 7.18928 6.54337 6.79875C6.15285 6.40823 5.51968 6.40823 5.12916 6.79875L6.54337 8.21297ZM3.33626 10.0059L2.62916 9.29875C2.23863 9.68928 2.23863 10.3224 2.62916 10.713L3.33626 10.0059ZM5.12916 13.213C5.51968 13.6035 6.15285 13.6035 6.54337 13.213C6.93389 12.8224 6.93389 12.1893 6.54337 11.7988L5.12916 13.213ZM12.5029 11.0059C13.0552 11.0059 13.5029 10.5581 13.5029 10.0059C13.5029 9.45357 13.0552 9.00586 12.5029 9.00586L12.5029 11.0059ZM5.12916 6.79875L2.62916 9.29875L4.04337 10.713L6.54337 8.21297L5.12916 6.79875ZM2.62916 10.713L5.12916 13.213L6.54337 11.7988L4.04337 9.29875L2.62916 10.713ZM12.5029 9.00586L3.33626 9.00586V11.0059L12.5029 11.0059L12.5029 9.00586Z"
				fill="#666666"
			/>
			<path
				d="M7.5 5.83301V4.16634C7.5 3.7061 7.8731 3.33301 8.33333 3.33301H15.8333C16.2936 3.33301 16.6667 3.7061 16.6667 4.16634V15.833C16.6667 16.2932 16.2936 16.6663 15.8333 16.6663H8.33333C7.8731 16.6663 7.5 16.2932 7.5 15.833V14.1663"
				stroke="#666666"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
