import { useSearchParams } from "react-router-dom";

// components
import RangeSlider from "pages/registerForm/otherDetails/rangeSlider";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// style
import "./style.scss";
import SearchRangeSlider from "components/NewSearch/SearchRangeSlider";

export const NewCategoryBudgetFilters = ({
	query,
	setShowBudget,
	setSelectBudget,
	selectBudget,
}: any) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const width = useMediaQuery();

	return (
		<div className="filter__category__wrapper__budget budget__container">
			<SearchRangeSlider
				min={
					(+query?.get("min") || selectBudget?.min) !== 0
						? +query?.get("min") || selectBudget?.min
						: 4999
				}
				max={
					(+query?.get("max") || selectBudget?.max) !== 0
						? +query?.get("max") || selectBudget?.max
						: 249999
				}
				defaultMin={4999}
				defaultMax={249999}
				onChange={({ min, max }: any) =>
					setSelectBudget({
						// @ts-ignore
						min: min,
						max: max,
					})
				}
			/>
			{width >= 1069 && (
				<button
					type="submit"
					onClick={() => {
						// @ts-ignore
						searchParams.set("min", selectBudget?.min);
						// @ts-ignore
						searchParams.set("max", selectBudget?.max);
						setSearchParams(searchParams);
						// setShowBudget(false);
					}}
				>
					Apply
				</button>
			)}
		</div>
	);
};
