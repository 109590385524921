export const SvgFacebook = (props) => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_35_1255)">
				<path
					d="M18.9995 9.5C18.9995 4.25329 14.7462 0 9.49951 0C4.2528 0 -0.000488281 4.25329 -0.000488281 9.5C-0.000488281 14.2416 3.47351 18.1719 8.01514 18.8846V12.2461H5.60303V9.5H8.01514V7.40703C8.01514 5.02609 9.43346 3.71094 11.6034 3.71094C12.6425 3.71094 13.73 3.89648 13.73 3.89648V6.23437H12.5321C11.352 6.23437 10.9839 6.96673 10.9839 7.71875V9.5H13.6187L13.1975 12.2461H10.9839V18.8846C15.5255 18.1719 18.9995 14.2416 18.9995 9.5Z"
					fill="#1877F2"
				/>
				<path
					d="M13.1975 12.2461L13.6187 9.5H10.9839V7.71875C10.9839 6.96747 11.352 6.23437 12.5321 6.23437H13.73V3.89648C13.73 3.89648 12.6429 3.71094 11.6034 3.71094C9.43346 3.71094 8.01514 5.02609 8.01514 7.40703V9.5H5.60303V12.2461H8.01514V18.8846C8.99874 19.0385 10.0003 19.0385 10.9839 18.8846V12.2461H13.1975Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_35_1255">
					<rect
						width="19"
						height="19"
						fill="white"
						transform="translate(-0.000488281)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
