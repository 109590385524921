import React from "react";

export const SvgConcernQuery = () => {
	return (
		<svg
			width="42"
			height="42"
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.1978 20.9921C3.1978 20.3851 2.70591 19.8932 2.0989 19.8932C1.49221 19.8932 1 20.3851 1 20.9921C0.998366 25.7042 2.66011 30.2655 5.6929 33.8724C8.72569 37.4788 12.9345 39.8987 17.5769 40.7048C22.2194 41.5113 26.9977 40.6528 31.0693 38.2804C35.1404 35.9083 38.2435 32.1746 39.8311 27.7377C41.4186 23.3012 41.3886 18.4466 39.7461 14.0301C38.104 9.61328 34.9551 5.91822 30.8545 3.59626C26.7538 1.27455 21.9653 0.475224 17.3328 1.33894C12.7008 2.20298 8.52235 4.67456 5.53419 8.31821L4.87485 3.46113C4.78785 2.85412 4.22533 2.43256 3.6183 2.51955C3.01162 2.60655 2.59007 3.16908 2.67706 3.7761L3.73217 11.2633C3.81524 11.802 4.27834 12.2001 4.82353 12.201H4.97724L12.4717 11.1316H12.472C13.0787 11.0446 13.5002 10.482 13.4132 9.875C13.3262 9.26799 12.7637 8.84677 12.157 8.93376L7.28521 9.66636C9.95689 6.43899 13.6817 4.25819 17.8028 3.50734C21.9246 2.75676 26.1795 3.48445 29.8174 5.56222C33.4552 7.64028 36.2433 10.9351 37.6909 14.8666C39.1381 18.7981 39.1518 23.1146 37.7295 27.0554C36.3071 30.996 33.5399 34.3088 29.9152 36.41C26.2905 38.5113 22.0407 39.2655 17.914 38.5411C13.7872 37.8167 10.049 35.6594 7.35704 32.4491C4.66476 29.239 3.1917 25.1819 3.19664 20.9921H3.1978Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.6"
			/>
			<path
				d="M13.5571 27.4664C13.788 27.4671 14.0137 27.3951 14.2018 27.2611L21.6376 21.8836V21.8839C21.9221 21.675 22.0909 21.3433 22.0919 20.9901V10.5286C22.0919 9.92157 21.6 9.42969 20.993 9.42969C20.386 9.42969 19.8941 9.92157 19.8941 10.5286V20.4258L12.9344 25.4807C12.5517 25.761 12.3921 26.2555 12.5387 26.7065C12.6852 27.1579 13.1048 27.464 13.579 27.4663L13.5571 27.4664Z"
				fill="#666666"
				stroke="#666666"
				stroke-width="0.6"
			/>
		</svg>
	);
};
