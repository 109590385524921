import { useContext } from "react";

// style
import { SvgBTCorporate } from "assets/icons/bookingType/SvgBTCorporate";
import { SvgBTEvent } from "assets/icons/bookingType/SvgBTEvent";
import { SvgBTVacation } from "assets/icons/bookingType/SvgBTVacation";
import { RegisterFormDataInterface } from "context/RegisterForm";
import { useMediaQuery } from "customHooks/useMediaQuery";

import "./style.scss";

const data = [
	{
		icon: <SvgBTVacation />,
		title: "Vacation",
	},
	{
		icon: <SvgBTCorporate />,
		title: "Corporate",
	},
	{
		icon: <SvgBTEvent />,
		title: "Event",
	},
];

export const PersonalStep1 = () => {
	const {
		// @ts-ignore
		setType,
		// @ts-ignore
		type,
		// @ts-ignore
		setDetails,
		// @ts-ignore
		details,
		// @ts-ignore
		setOtherDetails,
		// @ts-ignore
		otherDetails,
		// @ts-ignore
		setPlaceOfOrigin,
		// @ts-ignore
		placeOfOrigin,
	} = useContext(RegisterFormDataInterface);

	const width = useMediaQuery();

	const isLaptopOrMobile = width < 768 ? true : false;

	const onChange = (e: any) => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};

	const onChangeOtherDetails = (e: any) => {
		setPlaceOfOrigin(e.target.value);
	};

	return (
		<div className="personal__step__one__container">
			<div className="top">
				{/* <h1>Choose your reason for connecting</h1> */}
				{/* <span className="book__change">
					<span className="icon">
						<SvgRight />
					</span>
					<p>I want to book</p>
					<span>Change</span>
				</span> */}
			</div>
			<div className="middle">
				{!isLaptopOrMobile ? <h1>Select the type of your booking</h1> : ""}
				<div className="box__container">
					{data.map((v: any, i: number) => {
						return (
							<div
								className={type === v.title ? "box box__active" : "box"}
								onClick={() => setType(v.title)}
							>
								{v.icon}
								{v.title}
							</div>
						);
					})}
				</div>
			</div>
			<div className="bottom">
				<h1>Enter your personal details</h1>
				<div className="bottom__top">
					<div className="left">
						{/* <label htmlFor="First Name">Full Name</label> */}
						<input
							type="text"
							name="firstname"
							placeholder="Full Name"
							onChange={onChange}
							value={details.firstname}
						/>
					</div>
				</div>
				<div className="bottom__middle">
					{/* <label htmlFor="Last Name">Email</label> */}
					<input
						type="text"
						name="email"
						placeholder="Email"
						onChange={onChange}
						value={details.email}
					/>
				</div>
				<div className="bottom__bottom">
					<div className="left">
						{/* <label htmlFor="First Name">Phone</label> */}
						<input
							type="text"
							name="phone"
							placeholder="Phone"
							onChange={onChange}
							maxLength={10}
							minLength={10}
							value={details.phone}
						/>
					</div>
					<div className="right">
						{/* <label htmlFor="Last Name">Your city (optional)</label> */}
						<input
							type="text"
							name="pinncode"
							placeholder="Enter your pincode"
							onChange={onChangeOtherDetails}
							value={placeOfOrigin}
							maxLength={6}
							minLength={6}
						/>
					</div>
				</div>
			</div>
			{/* <CallbackComp /> */}
		</div>
	);
};
