export const SvgBooking = (props: any) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16.6667 6.66699H3.33333C2.8731 6.66699 2.5 7.04009 2.5 7.50033V15.8337C2.5 16.2939 2.8731 16.667 3.33333 16.667H16.6667C17.1269 16.667 17.5 16.2939 17.5 15.8337V7.50033C17.5 7.04009 17.1269 6.66699 16.6667 6.66699Z"
				stroke="#666666"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.66699 6.66634C6.66699 4.82539 8.15938 3.33301 10.0003 3.33301C11.8413 3.33301 13.3337 4.82539 13.3337 6.66634"
				stroke="#666666"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
