import React from "react";

export const SvgCalendar = (props) => {
	return (
		<svg
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.25 2.33366V1.25033C13.25 0.652326 13.7353 0.166992 14.3333 0.166992C14.9313 0.166992 15.4167 0.652326 15.4167 1.25033V2.33366C17.8098 2.33366 19.75 4.27391 19.75 6.66699V17.5003C19.75 19.8934 17.8098 21.8337 15.4167 21.8337C12.3183 21.8337 7.68058 21.8337 4.58333 21.8337C2.19025 21.8337 0.25 19.8934 0.25 17.5003V6.66699C0.25 4.27391 2.19025 2.33366 4.58333 2.33366V1.25033C4.58333 0.652326 5.06867 0.166992 5.66667 0.166992C6.26467 0.166992 6.75 0.652326 6.75 1.25033V2.33366H13.25ZM17.5833 11.0003H2.41667V17.5003C2.41667 18.6963 3.38625 19.667 4.58333 19.667H15.4167C16.6127 19.667 17.5833 18.6963 17.5833 17.5003V11.0003ZM15.4167 4.50033V5.58366C15.4167 6.18166 14.9313 6.66699 14.3333 6.66699C13.7353 6.66699 13.25 6.18166 13.25 5.58366V4.50033H6.75V5.58366C6.75 6.18166 6.26467 6.66699 5.66667 6.66699C5.06867 6.66699 4.58333 6.18166 4.58333 5.58366V4.50033C3.38625 4.50033 2.41667 5.46991 2.41667 6.66699V8.83366H17.5833V6.66699C17.5833 5.46991 16.6127 4.50033 15.4167 4.50033Z"
				fill={props.color ? props.color : "#2368a2"}
			/>
		</svg>
	);
};
