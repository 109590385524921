import { useContext, useEffect, useState } from "react";

// assets
import { SvgAddDriver } from "assets/icons/js/SvgAddDriver";
import { SvgAddMaid } from "assets/icons/js/SvgAddMaid";
import { SvgAddPet } from "assets/icons/js/SvgAddPet";
import reduceGuestFaded from "assets/icons/reduceGuestFaded.svg";
import addGuest from "assets/icons/addGuest.svg";
import reduceGuest from "assets/icons/reduceGuest.svg";
import ImgFmaily from "assets/images/Form/ImgFmaily.png";
import ImgFriends from "assets/images/Form/ImgFriends.png";
import ImgColleagues from "assets/images/Form/ImgColleagues.png";
import ImgMen from "assets/images/Form/ImgMen.png";
import ImgWomen from "assets/images/Form/ImgWomen.png";
import ImgMixed from "assets/images/Form/ImgMixed.png";

// context
import { RegisterFormDataInterface } from "context/RegisterForm";

// style
import "./style.scss";

const data = [
	{
		title: "Family",
		icon: ImgFmaily,
	},
	{
		title: "Friends",
		icon: ImgFriends,
	},
	{
		title: "Colleagues",
		icon: ImgColleagues,
	},
];

const groupCompData = [
	{
		title: "All men",
		icon: ImgMen,
	},
	{
		title: "All women",
		icon: ImgWomen,
	},
	{
		title: "mixed",
		icon: ImgMixed,
	},
];

export const GuestInfo = () => {
	const {
		// @ts-ignore
		setGuest,
		// @ts-ignore
		guest,
		// @ts-ignore
		guestDetails,
		// @ts-ignore
		setGuestDetail,
	} = useContext(RegisterFormDataInterface);

	// disbale click
	const disableClick = (event: any) => {
		event.preventDefault();
	};

	const iguestCount = (key: any) => {
		const tempArr = guestDetails.map((ele: any) => {
			if (ele.key === key) {
				return { ...ele, count: ele.count + 1 };
			}
			return ele;
		});
		setGuestDetail([...tempArr]);
	};

	// decrease guest count
	const dguestCount = (key: any) => {
		const tempArr = guestDetails.map((ele: any) => {
			if (ele.key === key) {
				return { ...ele, count: ele.count - 1 };
			}
			return ele;
		});
		setGuestDetail([...tempArr]);
	};

	return (
		<div className="personal__step__three__container">
			{guestDetails.map((guestV: any) => {
				return (
					<>
						<GuestCount
							guestCount={guestV.count}
							needAcc={guestV.needAcc}
							guestImg={guestV.guestImg}
							guestName={guestV.guestName}
							guestValue={guestV.guestValue}
							guestKey={guestV.key}
							disableClick={disableClick}
							increaseGuestCount={iguestCount}
							decreaceGuestCount={dguestCount}
							setGuest={setGuest}
							guest={guest}
							age={guestV.age}
						/>
					</>
				);
			})}
			{guest.maid === 0 || guest.driver === 0 || guest.pet === 0 ? (
				<div className="guest__bottom">
					{guest.maid === 0 ? (
						<span
							onClick={() => {
								setGuest((prev: any) => ({
									...prev,
									maid: 1,
								}));
								iguestCount("maid");
							}}
						>
							<SvgAddMaid />
							<p>Add Maid</p>
						</span>
					) : null}
					{guest.driver === 0 ? (
						<span
							onClick={() => {
								setGuest((prev: any) => ({
									...prev,
									driver: 1,
								}));
								iguestCount("driver");
							}}
						>
							<SvgAddDriver />
							<p>Add Driver</p>
						</span>
					) : null}
					{guest.pet === 0 ? (
						<span
							onClick={() => {
								setGuest((prev: any) => ({
									...prev,
									pet: 1,
								}));
								iguestCount("pet");
							}}
						>
							<SvgAddPet />
							<p>Add Pet</p>
						</span>
					) : null}
				</div>
			) : null}
		</div>
	);
};

const GuestCount = ({
	guestImg,
	guestName,
	needAcc,
	guestCount,
	disableClick,
	decreaceGuestCount,
	increaseGuestCount,
	guestValue,
	guestKey,
	setGuest,
	guest,
	age,
}: any) => {
	useEffect(() => {
		if (guestValue === "Adult") {
			setGuest((prev: any) => {
				return { ...prev, adult: guestCount };
			});
		} else if (guestValue === "Children") {
			setGuest((prev: any) => {
				return { ...prev, children: guestCount };
			});
		} else if (guestValue === "Room") {
			setGuest((prev: any) => {
				return { ...prev, room: guestCount };
			});
		} else if (guestValue === "Maid") {
			setGuest((prev: any) => {
				return { ...prev, maid: guestCount };
			});
		} else if (guestValue === "Driver") {
			setGuest((prev: any) => {
				return { ...prev, driver: guestCount };
			});
		} else if (guestValue === "Pet") {
			setGuest((prev: any) => {
				return { ...prev, pet: guestCount };
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [guestCount, guestValue]);

	const html = (
		<div className="guest__details__container">
			<div className="guest__name">
				<div className="guest__name__content">
					<img src={guestImg} alt="" />
					<span>
						<h5>{guestName}</h5>
						<p>{age}</p>
					</span>
				</div>
			</div>
			<div className="guest__value">
				{guestCount === 0 ? (
					<img src={reduceGuestFaded} alt="" onClick={() => disableClick} />
				) : (
					<img
						src={reduceGuest}
						alt=""
						onClick={() => {
							decreaceGuestCount(guestKey);
						}}
					/>
				)}
				<span>{guestCount}</span>
				<img
					src={addGuest}
					alt=""
					onClick={() => {
						increaseGuestCount(guestKey);
						if (guestValue === "Adult") {
							setGuest((prev: any) => {
								return { ...prev, adult: guestCount };
							});
						} else if (guestValue === "Children") {
							setGuest((prev: any) => {
								return { ...prev, children: guestCount };
							});
						} else if (guestValue === "Room") {
							setGuest((prev: any) => {
								return { ...prev, room: guestCount };
							});
						} else if (guestValue === "Maid") {
							setGuest((prev: any) => {
								return { ...prev, maid: guestCount };
							});
						} else if (guestValue === "Driver") {
							setGuest((prev: any) => {
								return { ...prev, driver: guestCount };
							});
						} else if (guestValue === "Pet") {
							setGuest((prev: any) => {
								return { ...prev, pet: guestCount };
							});
						}
					}}
				/>
			</div>
		</div>
	);

	if (guestValue === "Maid" && guest.maid > 0) {
		return html;
	}
	if (guestValue === "Driver" && guest.driver > 0) {
		return html;
	}
	if (guestValue === "Pet" && guest.pet > 0) {
		return html;
	}
	if (
		guestValue === "Adult" ||
		guestValue === "Children" ||
		guestValue === "Room"
	) {
		return html;
	}

	return null;
};
