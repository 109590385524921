export const SvgContactUsFaqs = () => {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.89081 0H2.90908C2.13785 0.000974025 1.39849 0.307794 0.853142 0.853142C0.307794 1.39849 0.000972454 2.13785 0 2.90908V7.56365C0.00087663 8.23397 0.232788 8.88355 0.656781 9.40289C1.08067 9.92213 1.67063 10.2795 2.32731 10.4145V12.2181C2.32721 12.3592 2.37854 12.4956 2.47156 12.6016C2.56458 12.7078 2.69295 12.7765 2.83281 12.795C2.97278 12.8135 3.1146 12.7805 3.23197 12.7022L6.57447 10.4726H9.89079C10.662 10.4718 11.4014 10.1649 11.9467 9.6196C12.4921 9.07425 12.7989 8.33489 12.7999 7.56366V2.90909C12.7989 2.13786 12.4921 1.39849 11.9467 0.853147C11.4014 0.307799 10.662 0.000972454 9.89081 0ZM6.39995 8.72715C6.24566 8.72715 6.09761 8.66589 5.98852 8.5568C5.87943 8.44771 5.81817 8.29967 5.81817 8.14538C5.81817 7.99108 5.87943 7.84304 5.98852 7.73395C6.09761 7.62487 6.24565 7.5636 6.39995 7.5636C6.55424 7.5636 6.70228 7.62487 6.81137 7.73395C6.92046 7.84304 6.98172 7.99108 6.98172 8.14538C6.98172 8.29967 6.92046 8.44771 6.81137 8.5568C6.70228 8.66589 6.55424 8.72715 6.39995 8.72715ZM7.43152 4.89868V4.89877C7.16981 5.06962 7.00334 5.35326 6.98172 5.66502V6.39982C6.98172 6.60767 6.87088 6.79975 6.69089 6.90368C6.51089 7.0076 6.28901 7.0076 6.10901 6.90368C5.929 6.79975 5.81817 6.60768 5.81817 6.39982V5.66502C5.83492 4.98116 6.17932 4.34717 6.74377 3.96088C6.90682 3.84137 6.99624 3.64578 6.97987 3.44436C6.96341 3.24283 6.84361 3.0643 6.66343 2.97274C6.48314 2.88128 6.26836 2.88985 6.09596 2.99544C5.92356 3.10112 5.81836 3.28862 5.81816 3.49071C5.81816 3.69857 5.70722 3.89064 5.52723 3.99457C5.34723 4.0985 5.12545 4.0985 4.94545 3.99457C4.76545 3.89064 4.65451 3.69857 4.65451 3.49071C4.65451 2.8841 4.96951 2.32093 5.48651 2.00341C6.0034 1.68599 6.64812 1.65968 7.18928 1.93396C7.73033 2.20834 8.09014 2.74394 8.13951 3.34861C8.18899 3.95327 7.92085 4.54021 7.43149 4.89875L7.43152 4.89868Z"
				fill="#00306B"
			/>
		</svg>
	);
};
