import clevertap from "clevertap-web-sdk";
import { useContext, useEffect, useRef, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { FinishPhoneSignup } from "../FinishPhoneSignup";
import { PrimaryButton } from "../PrimaryButton";

import { UserContext } from "store";

//helpers
import { axiosInstance } from "helpers/axiosInstance";
import getUserData from "helpers/getUserDataAfterLogin";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// icons
import { SvgClose as Close } from "assets/icons/js/SvgClose";
import { ReactComponent as Loader } from "assets/spinning_wheel.svg";

// scss
import "./style.scss";

export const OTP = ({
	onRequestClose,
	phoneNumber = "",
	setSend,
	active,
	redirectPhone = "",
	CC = "",
}) => {
	const [otp, setOTP] = useState("");
	const [finish, setFinish] = useState(false);
	const [shouldRequest, setShouldRequest] = useState(false);
	const [shouldSend, setShouldSend] = useState(true);
	const { pathname } = useLocation();

	const { uuid, otpLoading, otpError } = useSendOTP({
		phoneNumber: phoneNumber,
		shouldRequest: shouldSend,
		setShouldRequest: setShouldSend,
		cc: CC,
	});

	const handlePrimaryClick = () => {
		setShouldRequest(true);
	};

	const {
		redirect,
		verifyError,
		verifyLoading,
		userName,
		userEmail,
		userUUID,
		userMobileNumber,
	} = useVerifyOTP({
		uuid: uuid,
		otp: otp,
		shouldRequest: shouldRequest,
		setShouldRequest: setShouldRequest,
		setFinish: setFinish,
		redirectPhone: redirectPhone,
	});

	useEffect(() => {
		if (!verifyLoading && redirect && !verifyError && active !== "Log in") {
			setFinish(true);
		}
	}, [active, redirect, verifyError, verifyLoading]);

	return !finish ? (
		<div className="otp">
			<div className="otp__container">
				{pathname !== "/phone" ||
					(pathname !== "/booking" && (
						<div className="close-icon">
							<span onClick={onRequestClose}>
								<Close height={12} width={12} color={"#fff"} />
							</span>
						</div>
					))}
				<div className="otp__sub-container">
					<div>
						<div className="otp__header">
							<div className="otp__header-title">
								<p>Enter OTP</p>
							</div>
							<div className="otp__header-subtitle">
								<p>
									Please enter the code we've sent via SMS to +91 {phoneNumber}{" "}
									<span onClick={() => setSend(false)}>Change</span>
								</p>
							</div>
						</div>
						<div>
							<form
								method="get"
								className="digit-group"
								data-group-name="digits"
								data-autosubmit="false"
								autoComplete="off"
							>
								<div className="form__input">
									<OTPInput
										value={otp}
										onChange={setOTP}
										numInputs={4}
										containerStyle="otp-box"
										inputStyle="otp-field"
										shouldAutoFocus={true}
										isInputNum
										hasError={otpError || verifyError}
										errorStyle={{ color: "red" }}
									/>
								</div>
							</form>
							<div className="otp__resend">
								<p>
									Haven't received a code?{" "}
									<span onClick={() => setShouldSend(true)}>Resend now</span>{" "}
								</p>
							</div>
						</div>
					</div>
					<div className="otp__error text-12px">{otpError || verifyError}</div>
					<div>
						<PrimaryButton
							type="submit"
							value={
								otpLoading || verifyLoading ? (
									<div
										style={{
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Loader
											style={{
												width: "30px",
												height: "30px",
											}}
										/>
									</div>
								) : (
									"Verify OTP"
								)
							}
							disabled={otp.length < 4}
							onClick={handlePrimaryClick}
						/>
					</div>
				</div>
			</div>
		</div>
	) : (
		<FinishPhoneSignup
			redirect={redirect}
			onRequestClose={() => {}}
			userName={userName.current}
			userEmail={userEmail.current}
			userUUID={userUUID.current}
			userMobileNumber={userMobileNumber.current}
		/>
	);
};

const useSendOTP = ({
	shouldRequest = true,
	setShouldRequest = () => {},
	phoneNumber = "",
	cc = "",
}) => {
	const [otpLoading, setLoading] = useState(false);
	const [otpError, setError] = useState(false);
	const [uuid, setUuid] = useState("");

	useEffect(() => {
		if (!shouldRequest) return;
		setLoading(true);
		setError(false);
		axiosInstance({
			url: "/ssalliance/sendOTP",
			method: "POST",
			data: {
				MOBILE_NUMBER: phoneNumber,
				CC: cc,
			},
		})
			.then((res) => {
				if (res.data.success === true) {
					setUuid(res.data.data.uuid);
				}
				setShouldRequest(false);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					setError(error.response.data.error);
					setLoading(false);
				} else setError("Something went Wrong");
				setShouldRequest(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldRequest]);

	return { uuid, otpLoading, otpError };
};

const useVerifyOTP = ({
	shouldRequest = true,
	setShouldRequest = () => {},
	uuid = "",
	otp = "",
	setFinish,
	redirectPhone,
}) => {
	const { dispatch } = useContext(UserContext);

	const [verifyLoading, setLoading] = useState(false);
	const [verifyError, setError] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const userName = useRef("");
	const userEmail = useRef("");
	const userUUID = useRef("");
	const userMobileNumber = useRef("");
	const navigate = useNavigate();

	const width = useMediaQuery();

	const isMobile = width <= "1250" ? true : false;

	useEffect(() => {
		if (!shouldRequest || otp.length < 4) return;
		setLoading(true);
		setError(false);
		setRedirect(false);
		axiosInstance({
			url: "/ssalliance/verifyOTP",
			method: "POST",
			data: {
				UUID: uuid,
				MOBILE_VERIFICATION_CODE: otp,
			},
		})
			.then((res) => {
				if (res.data.success === true) {
					localStorage.setItem("token", res.data.data.token);
					localStorage.setItem("uuid", res.data.data.UUID);

					localStorage.setItem("allow", "true");
					setError(false);
					setRedirect(true);
					setShouldRequest(false);

					// * Check further
					userName.current =
						res.data.data.FIRST_NAME + " " + res.data.data.LAST_NAME;
					userEmail.current = res.data.data.EMAIL_ID;
					userUUID.current = res.data.data.UUID;
					userMobileNumber.current = res.data.data.MOBILE_NUMBER;

					if (redirectPhone !== "") {
						window.location.href = redirect;
					}

					if (userName.current.length > 0 && userEmail.current.length > 0) {
						clevertap.onUserLogin.push({
							Site: {
								Name: res.data.data.LAST_NAME
									? `${res.data.data.FIRST_NAME} ${res.data.data.LAST_NAME}`
									: `${res.data.data.FIRST_NAME}`,
								Identity: res.data.data.UUID,
								Email: res.data.data.EMAIL_ID ? res.data.data.EMAIL_ID : "",
								Phone: res.data.data.MOBILE_NUMBER
									? `+91${res.data.data.MOBILE_NUMBER}`
									: "",
								DOB: res.data.data.DOB ? res.data.data.DOB : "",

								"MSG-email": true,
								"MSG-push": true,
								"MSG-sms": true,
								"MSG-whatsapp": true,
							},
						});
						clevertap.event.push("user_login", {
							Name: res.data.data.LAST_NAME
								? `${res.data.data.FIRST_NAME} ${res.data.data.LAST_NAME}`
								: `${res.data.data.FIRST_NAME}`,
							Identity: res.data.data.UUID,
							Email: res.data.data.EMAIL_ID ? res.data.data.EMAIL_ID : "",
							Phone: res.data.data.MOBILE_NUMBER
								? res.data.data.MOBILE_NUMBER
								: "",
							DOB: res.data.data.DOB ? new Date(res.data.data.DOB) : "",
							isAgent:
								res?.data?.data?.IS_AGENT && res?.data?.data?.IS_AGENT === "Y"
									? true
									: false,
							RewardTier: res.data.data.REWARDS_TIER
								? res.data.data.REWARDS_TIER
								: "BLUE",
							RefferalCode: res.data.data.REF_CODE
								? res.data.data.REF_CODE
								: "",
							timestamp: new Date(),
						});
						if (isMobile) {
							getUserData(dispatch);
							// window.location.reload();
						}
						window.location.reload();
					} else {
						setFinish(true);
					}
					setLoading(false);
				}
			})
			.catch((error) => {
				if (error.response) {
					setRedirect(false);
					setLoading(false);
					setShouldRequest(false);
					setError(error.response.data.error);
				}
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		shouldRequest,
		otp,
		uuid,
		setShouldRequest,
		isMobile,
		navigate,
		setFinish,
	]);

	return {
		redirect,
		verifyLoading,
		verifyError,
		userName,
		userEmail,
		userUUID,
		userMobileNumber,
	};
};
