import { SvgClubInfinitumLogo } from "assets/icons/js/SvgClubInfinitumLogo";
// import { SvgReviewReplyIcon } from "assets/icons/js/SvgReviewReplyIcon";

// helpers
import parseHTML from "helpers/parseHTML";

export const BookingCouponCardUnavailable = ({ data }: any) => {
  return (
    <div className="coupon-card unavailable">
      <span className="card-img">
        <SvgClubInfinitumLogo />
      </span>
      <div className="card-details">
        <div className="details">
          <p className="title">{parseHTML(data.DESCRIPTION)}</p>
          <p className="subtitle">
            *{data.TNC ? parseHTML(data.TNC) : ""}
          </p>
          {/* <Link
									to="/terms-and-conditions"
									className="link"
									aria-label="term-condition"
								>
									View T&C
								</Link> */}
          <div className="save-upto not-applicable">
            Not applicable
          </div>
        </div>
      </div>
    </div>
  )
}
