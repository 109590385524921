import React, { useState } from "react";
import dropDown from "assets/icons/DropDown.svg";
import "./style.scss";

const DropDownWithImg = ({
	dropDownOptions,
	selected,
	setSelected,
	selectedValue,
	setSelectedValue,
}) => {
	const [active, setActive] = useState(false);
	return (
		<div className="dropdown" onClick={() => setActive(!active)}>
			<div className="dropdown_btn">
				<div className="selected_values">{selected ? selected : "Select"}</div>
				<span>
					<img src={dropDown} alt="" />
				</span>
			</div>
			{active && (
				<div className="dropdown_content">
					{dropDownOptions.map((elem) => {
						return (
							<>
								<div
									className="dropdown_item"
									onClick={(e) => {
										setSelected(() => [
											<img src={elem.image} alt="" />,
											elem.label,
										]);
										setActive(false);
										setSelectedValue(elem.label);
									}}
								>
									<img src={elem.image} alt="" />
									<p>{elem.label}</p>
								</div>
							</>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default DropDownWithImg;
