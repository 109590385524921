export const SvgHomeIconDark = () => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.5138 17.9384H3.48615C2.88584 17.9384 2.39741 17.4499 2.39741 16.8496V9.54585C2.39741 9.46207 2.38543 9.3776 2.32147 9.35437L1.5011 8.89343C1.21145 8.73066 1.03004 8.46092 1.00337 8.15336C0.976738 7.84579 1.10902 7.54888 1.36637 7.33871L8.74693 1.31231C8.95343 1.14369 9.2209 1.05078 9.49998 1.05078C9.77906 1.05078 10.0465 1.14364 10.2531 1.31231L17.6336 7.33862C17.8909 7.54879 18.0233 7.8457 17.9966 8.15326C17.97 8.46083 17.7886 8.73061 17.4989 8.89334L16.6785 9.35428C16.6213 9.38603 16.6026 9.43235 16.6026 9.52308V16.8495C16.6026 17.45 16.1142 17.9384 15.5138 17.9384ZM3.6676 16.2219C3.6676 16.6529 4.01854 16.6682 4.01854 16.6682H14.7113C14.7113 16.6682 15.3324 16.7512 15.3324 16.0556C15.3324 14.4115 15.3324 9.47903 15.3324 9.47903C15.3324 8.7293 15.635 8.48379 16.0519 8.24953C16.0519 8.24953 16.1642 8.19332 16.1972 8.16787C16.4502 7.97326 16.179 7.79285 16.179 7.79285C16.179 7.79285 11.6764 4.15224 10.0506 2.79077C9.66088 2.46442 9.44913 2.37555 8.94767 2.78968C7.3117 4.14071 2.80913 7.80142 2.80913 7.80142C2.80913 7.80142 2.55369 7.98837 2.78277 8.15658C2.82102 8.18466 2.94817 8.24948 2.94817 8.24948C3.36507 8.48374 3.66765 8.71415 3.66765 9.47899C3.6676 9.47903 3.6676 14.5362 3.6676 16.2219Z"
				fill="#666666"
				stroke="#666666"
				strokeWidth="0.5"
			/>
		</svg>
	);
};
