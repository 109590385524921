import React, { useEffect, useState } from "react";
import "./style.scss";
import Collapse from "../Collapse";
import axios from "axios";
import BookingExperienceCard from "./BookingExperienceCard";
import Modal from "components/LoginAndRegister/Modal";
import BookingExperienceDetails from "./BookingExperienceDetails";
// import SaffSlider from "components/SaffSlider";
const BookingExperiences = ({ bookingData }: { bookingData: any }) => {
	const [allAddons, setAllAddons] = useState([]);
	const [index, setIndex] = useState<any>();
	const [isAddonDetails, setIsAddonDetails] = useState(false);
	const fetchAllAddons = async () => {
		try {
			const res = await axios.get(
				`https://go.saffronstays.com/api/get-listing-addons?listingId=${bookingData?.data?.data?.LISTING_ID}&bookingId=${bookingData?.data?.data?.BOOKING_ID}`,
			);
			setAllAddons(
				res?.data?.data?.filter(
					(ele: any) => ele?.PAYMENT_TYPE !== "Prepaid online",
				),
			);
		} catch (error: any) {
			console.log(error?.message);
		}
	};
	useEffect(() => {
		fetchAllAddons();
	}, []);

	return (
		allAddons && (
			<Collapse init={true} title="Experiences">
				<div className="booking-experience-wrapper">
					{allAddons
						?.map((ele: any, index: number) => {
							return (
								<BookingExperienceCard
									key={index}
									setIndex={setIndex}
									setIsAddonDetails={setIsAddonDetails}
									allAddons={allAddons?.filter(
										(ele: any) => ele?.PAYMENT_TYPE !== "Prepaid online",
									)}
									index={index}
									bookingId={bookingData?.data?.data?.BOOKING_ID}
									data={ele}
									refreshData = {()=>fetchAllAddons()}
								/>
							);
						})}
				</div>
				<Modal
					shouldShow={isAddonDetails}
					onRequestClose={() => {
						setIsAddonDetails(false);
					}}
				>
					<BookingExperienceDetails
						allAddons={allAddons}
						refreshData={() => fetchAllAddons()}
						bookingId={bookingData?.data?.data?.BOOKING_ID}
						id={index}
						onClose={() => {
							setIsAddonDetails(false);
						}}
					/>
				</Modal>
			</Collapse>
		)
	);
};

export default BookingExperiences;
