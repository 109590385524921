import React from "react";

export const SvgClubinfintum = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				width="24"
				height="24"
				rx="12"
				fill="url(#paint0_linear_16840_22966)"
			/>
			<rect
				x="2.19922"
				y="2.19336"
				width="19.6083"
				height="19.6083"
				rx="9.80417"
				stroke="url(#paint1_linear_16840_22966)"
				stroke-width="2"
			/>
			<path
				d="M9.00781 10.4543L9.27899 11.2228C9.27899 11.2228 12.3148 9.33182 13.3999 8.66129C14.8313 7.41817 13.5882 5.43709 12.2323 5.45191C11.9042 5.44243 11.5799 5.52399 11.2953 5.68754C11.0108 5.85109 10.777 6.09025 10.6201 6.37851C10.1004 7.21472 10.5823 8.41307 11.0418 8.6456C11.102 8.60781 11.569 8.26775 11.7048 8.17852C11.0116 7.75678 10.8619 6.37037 12.1718 6.23493C13.204 6.19714 13.5054 7.576 12.9252 8.0579C12.7369 8.17183 9.00781 10.4543 9.00781 10.4543Z"
				fill="url(#paint2_linear_16840_22966)"
			/>
			<path
				d="M12.6055 9.62022L13.3362 9.19092C13.3362 9.19092 16.2892 11.3906 16.7112 11.6167C16.8563 11.7087 17.0226 11.7618 17.1942 11.7707C17.3657 11.7796 17.5367 11.744 17.6905 11.6675C17.8443 11.591 17.9757 11.4761 18.0721 11.3339C18.1685 11.1917 18.2265 11.027 18.2406 10.8558C18.3008 9.43943 16.3723 9.47722 16.1915 10.5846C15.8977 10.3811 15.8375 10.3134 15.5437 10.11C15.9355 8.91219 17.3969 8.74652 18.1502 9.14558C18.455 9.30574 18.7047 9.55357 18.8672 9.85708C19.0296 10.1606 19.0974 10.5058 19.0617 10.8482C18.896 12.4 17.3591 13.0028 16.3799 12.355C15.875 12.0082 12.6055 9.62022 12.6055 9.62022Z"
				fill="url(#paint3_linear_16840_22966)"
			/>
			<path
				d="M14.8319 11.8184L15.4797 12.3005C15.4797 12.3005 14.071 16.1953 14.0184 16.3697C13.9787 16.5237 13.9749 16.6848 14.007 16.8406C14.0392 16.9963 14.1066 17.1427 14.204 17.2685C14.3014 17.3942 14.4262 17.496 14.569 17.5661C14.7118 17.6363 14.8687 17.6728 15.0278 17.673C16.4215 17.5901 16.429 15.6163 14.9374 15.5861C15.0879 15.1417 15.1257 15.0737 15.2237 14.8252C15.7026 14.8921 16.138 15.1388 16.4416 15.5152C16.7452 15.8916 16.8942 16.3694 16.8583 16.8516C16.7797 17.2726 16.5643 17.6559 16.2456 17.942C15.9268 18.228 15.5225 18.4009 15.0955 18.4336C13.6114 18.4627 13.0842 17.0701 13.2644 16.2188C13.4155 15.7959 14.8319 11.8184 14.8319 11.8184Z"
				fill="url(#paint4_linear_16840_22966)"
			/>
			<path
				d="M12.9615 15.7726L13.2554 14.9297C13.2554 14.9297 9.1197 14.9372 8.51776 14.9372C7.01102 15.005 6.59684 16.4213 6.83023 17.2651C7.73416 19.7356 10.9284 18.3114 10.3108 16.2708C10.0472 16.2632 9.61758 16.2556 9.49696 16.2556C9.73791 16.9488 9.3839 17.7246 8.57792 17.7473C8.3266 17.7475 8.0844 17.6532 7.89939 17.4831C7.71438 17.313 7.6001 17.0795 7.57924 16.8291C7.55838 16.5786 7.63246 16.3295 7.78678 16.1311C7.94109 15.9328 8.16436 15.7997 8.41225 15.7583C8.68285 15.7659 12.9615 15.7726 12.9615 15.7726Z"
				fill="url(#paint5_linear_16840_22966)"
			/>
			<path
				d="M8.99277 14.568H9.8592C9.8592 14.568 8.54836 10.4471 8.48064 10.2137C8.40382 9.96659 8.27455 9.73893 8.10167 9.54632C7.92879 9.3537 7.71637 9.20068 7.47892 9.0977C7.24147 8.99471 6.98459 8.9442 6.72583 8.94961C6.46706 8.95502 6.21252 9.01622 5.97958 9.12904C5.29393 9.55833 4.84952 10.1611 5.0152 11.0953C5.20354 12.135 6.23593 13.1142 7.68919 12.3309C7.57613 11.9239 7.53108 11.7496 7.46307 11.5022C7.31682 11.6443 7.13125 11.7391 6.93044 11.7744C6.72964 11.8097 6.52285 11.7839 6.33691 11.7002C6.15097 11.6166 5.99446 11.479 5.88768 11.3053C5.78089 11.1316 5.72876 10.9298 5.73805 10.7262C5.76335 10.5059 5.85902 10.2997 6.01085 10.1381C6.16269 9.97655 6.36259 9.86828 6.58086 9.82937C6.79913 9.79046 7.02413 9.823 7.22244 9.92214C7.42075 10.0213 7.58179 10.1818 7.68164 10.3797C7.76505 10.6279 8.99277 14.568 8.99277 14.568Z"
				fill="url(#paint6_linear_16840_22966)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_16840_22966"
					x1="12"
					y1="0"
					x2="12"
					y2="24"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#0F0F0F" />
					<stop offset="0.567708" stop-color="#1D1C1C" />
					<stop offset="0.682292" stop-color="#444444" />
					<stop offset="0.760417" stop-color="#1F1D1D" />
					<stop offset="1" stop-color="#201F1F" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_16840_22966"
					x1="4.44047"
					y1="4.43461"
					x2="16.4943"
					y2="22.3494"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#BD9A64" />
					<stop offset="0.46875" stop-color="#FBDE98" />
					<stop offset="1" stop-color="#BD9A64" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_16840_22966"
					x1="9.76212"
					y1="6.31692"
					x2="13.1249"
					y2="10.6714"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#BD9A64" />
					<stop offset="0.46875" stop-color="#FBDE98" />
					<stop offset="1" stop-color="#BD9A64" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_16840_22966"
					x1="13.5753"
					y1="9.50032"
					x2="15.0423"
					y2="13.3507"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#BD9A64" />
					<stop offset="0.46875" stop-color="#FBDE98" />
					<stop offset="1" stop-color="#BD9A64" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_16840_22966"
					x1="13.7754"
					y1="12.8107"
					x2="17.6786"
					y2="15.9964"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#BD9A64" />
					<stop offset="0.46875" stop-color="#FBDE98" />
					<stop offset="1" stop-color="#BD9A64" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_16840_22966"
					x1="7.74241"
					y1="15.4764"
					x2="9.19446"
					y2="19.3169"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#BD9A64" />
					<stop offset="0.46875" stop-color="#FBDE98" />
					<stop offset="1" stop-color="#BD9A64" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_16840_22966"
					x1="5.71228"
					y1="9.79204"
					x2="8.9888"
					y2="14.0203"
					gradientUnits="userSpaceOnUse"
				>
					<stop stop-color="#BD9A64" />
					<stop offset="0.46875" stop-color="#FBDE98" />
					<stop offset="1" stop-color="#BD9A64" />
				</linearGradient>
			</defs>
		</svg>
	);
};
