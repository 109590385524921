// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// components
// icon
import { SvgClose } from "assets/icons/js/SvgClose";
import { SvgNavigationArrow as NavigationArrow } from "assets/icons/js/SvgNavigationArrow";
import { ReactComponent as Loader } from "assets/spinning_wheel.svg";

// customHooks
import { useMediaQuery } from "customHooks/useMediaQuery";

// scss
import { BookingCouponCardUnavailable } from "components/OldBooking/BookingWeb/BookingCouponCardUnavailable";
import "./style.scss";
// import { toast } from "react-toastify";
import { BookingInterface } from "context/BookingData";
import { BookingCouponCard } from "../BookingCouponCard";

const BookingCoupon = ({ data, closeModal }: any) => {
	// @ts-ignore
	const { bookingData, updateBooking, applyCoupon, fetchCoupons } =
		useContext(BookingInterface);

	const [searchParams, setSearchParams] = useSearchParams();

	const [error, setError] = useState("");

	const [text, setText] = useState();
	const [coupon, setCoupon] = useState("");

	const width = useMediaQuery();

	const isMobile = width < 900 ? true : false;

	const newData = fetchCoupons?.data?.data?.data;

	if (fetchCoupons.isLoading) {
		return <div className="loading">Loading...</div>;
	}

	return (
		<div className="booking__coupon">
			<div className="booking__coupon-container">
				<div className="booking__coupon-top">
					<div className="coupon-top">
						<span onClick={closeModal}>{isMobile && <NavigationArrow />}</span>
						{!isMobile && <p>Coupons & Offers</p>}
						<span onClick={closeModal}>{!isMobile && <SvgClose />}</span>
					</div>
					<div className="coupon-bottom">
						<input
							id="text-apply-cupon"
							type="text"
							placeholder="Enter Coupon Code"
							value={text}
							onChange={(e) => setText(e.target.value)}
						/>
						<button
							aria-label="Apply Coupon"
							type="submit"
							onClick={async () => {
								await updateBooking.mutateAsync({
									COUPON_CODE: text,
								});
								searchParams.set("coupon", text);
								setSearchParams(searchParams, { replace: true });
								closeModal();
							}}
						>
							{updateBooking.isLoading ? (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
									}}
								>
									<Loader
										style={{
											width: "30px",
											height: "30px",
										}}
									/>
								</div>
							) : (
								"Apply"
							)}
						</button>
					</div>
					{error && applyCoupon.isError && (
						<div className="error-apply">
							<p style={{ color: "red" }}>Coupon code is not valid</p>
						</div>
					)}
				</div>
				<div className="booking__coupon-content">
					<div className="booking__coupon-middle">
						<p>Available Coupons</p>
					</div>
					<div className="booking__coupon-bottom">
						<div className="booking__coupon-card">
							{newData &&
								newData.length > 0 &&
								newData?.map((item: any, index: number) =>
									item?.isApplicable ? (
										<BookingCouponCard
											key={index * Math.random() * 100}
											coupon={coupon}
											setCoupon={setCoupon}
											data={item}
											bookingData={bookingData?.data?.data}
											closeModal={closeModal}
										/>
									) : null,
								)}
						</div>
					</div>
					<div className="booking__coupon-middle">
						<p>Unavailable Coupons</p>
					</div>
					<div className="booking__coupon-bottom">
						<div className="booking__coupon-card">
							{newData &&
								newData.length > 0 &&
								newData?.map((item: any, index: number) =>
									!item?.isApplicable ? (
										<BookingCouponCardUnavailable
											key={index * Math.random() * 100}
											data={item}
										/>
									) : null,
								)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookingCoupon;
