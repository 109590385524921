export const SvgFooterFacebook = (props: any) => {
	return (
		<svg
			width="10"
			height="18"
			viewBox="0 0 10 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M2.52 9.97803H0.441C0.105 9.97803 0 9.85203 0 9.53703V6.99603C0 6.66003 0.126 6.55503 0.441 6.55503H2.52V4.70703C2.49514 3.87974 2.69084 3.06073 3.087 2.33403C3.50331 1.60852 4.16669 1.05695 4.956 0.780032C5.47469 0.594408 6.02212 0.501985 6.573 0.507032H8.631C8.925 0.507032 9.051 0.633032 9.051 0.927032V3.32103C9.051 3.61503 8.925 3.74103 8.631 3.74103C8.064 3.74103 7.497 3.74103 6.931 3.76203C6.81392 3.74467 6.6944 3.75496 6.58202 3.79209C6.46963 3.82922 6.3675 3.89215 6.28381 3.97584C6.20012 4.05954 6.13719 4.16167 6.10006 4.27405C6.06293 4.38644 6.05264 4.50595 6.07 4.62303C6.049 5.25303 6.07 5.86203 6.07 6.51303H8.506C8.842 6.51303 8.968 6.63903 8.968 6.97503V9.51603C8.968 9.85203 8.863 9.95703 8.506 9.95703H6.069V16.807C6.069 17.164 5.969 17.29 5.586 17.29H2.961C2.646 17.29 2.52 17.164 2.52 16.849V9.97803Z"
				fill="white"
			/>
		</svg>
	);
};
